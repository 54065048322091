import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HelpMenu from "../../components/common/HelpMenu";
import { CheckoutArt } from "../../assets/images";
import { Button, Grid, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiCheck, mdiOpenInNew } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import BigRadio from "../../components/common/BigRadio";
import FreePlanCheckoutFlow from "./FreePlanCheckoutFlow";
import BasicPlanCheckoutFlow from "./BasicPlanCheckoutFlow";
import PremiumPlanCheckoutFlow from "./PremiumPlanCheckoutFlow";
import { capitalize } from "../../utils/Utils";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import ManagePremiumUpgrade from "../settings/subscription/components/ManagePremiumUpgrade";

function Checkout(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();

    const [currentPlan, setCurrentPlan] = React.useState('');
    const [showPlanStep, setShowPlanStep] = React.useState(false);

    const [packagePlan, setPackagePlan] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState();

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/package-list').then((response) => {
            if (response.status === 200) {
                setPackagePlan(response?.data?.data?.packages);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/checkout");
                    }
                }
            });
    }

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [showPlanStep]);

    return (<>
        <Helmet>
            <title>Checkout | {capitalize(props?.name || 'SolvCRM')}</title>
        </Helmet>

        <section className="primary-container checkout-layout size-lg">
            <div className="form-block">
                {!showPlanStep &&
                    <>
                        <div className="block-title">
                            <Typography variant="display-small-bold" component={'h1'}>Your Trial Has Ended – Choose Your Plan</Typography>
                            <p className="text-secondary">Your 14-day trial has ended, but the great features of SolvCRM are just beginning. Select the plan that fits your needs to continue enjoying full access to all our tools and services.</p>
                        </div>
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            {
                                packagePlan?.length > 0 && packagePlan.map((packages, index) => {
                                    const parsedFeatures = packages?.plan_feature ? JSON.parse(packages?.plan_feature) : {};
                                    const features = Object.values(parsedFeatures)[0] || [];

                                    return < Grid item xs={4} key={index} >
                                        <BigRadio
                                            title={packages?.name}
                                            description={packages?.description}
                                            input={<input type="radio" value={packages?.slug} name="plan-radio" checked={currentPlan === packages?.slug} onChange={e => { setCurrentPlan(e.target.value); setSelectedPlanId(packages?.id) }} />}
                                            content={
                                                <>
                                                    {
                                                        features?.length > 0 &&
                                                        <ul className="plan-list">
                                                            {features.map((feature, index) => (
                                                                <li key={index}>
                                                                    <Icon path={mdiCheck} color={'currentColor'} size={0.666667} className="icon" />
                                                                    <span>{feature}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </>
                                            }
                                        />
                                    </Grid>
                                }
                                )
                            }
                        </Grid>

                        <a href="https://www.solvcrm.co/crm-software-pricing/" target="_blank" rel="noreferrer" className="primary-link">
                            <Icon path={mdiOpenInNew} color={'currentColor'} size={0.833334} />
                            <Typography variant="body-medium-semibold">Compare Plans and Pricing</Typography>
                        </a>

                        <div className="action-block">
                            <Button onClick={e => { setShowPlanStep(true) }} disabled={currentPlan === ''}>
                                <span>
                                    <span>Next</span>
                                    <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                </span>
                            </Button>
                        </div>
                    </>
                }

                {showPlanStep && currentPlan === "free" && <FreePlanCheckoutFlow onReset={(returnFlag) => {
                    if (returnFlag === 'upgrade-plan') {
                        setCurrentPlan('basic');
                        setSelectedPlanId(3)
                    } else {
                        setShowPlanStep(false);
                        setCurrentPlan('');
                    }
                }} selectedPlan={selectedPlanId} />}

                {showPlanStep && currentPlan === "basic" && <BasicPlanCheckoutFlow onReset={(returnFlag) => {
                    setShowPlanStep(false);
                    setCurrentPlan('');
                }} selectedPlan={selectedPlanId} />}

                {showPlanStep && currentPlan === "premium" && <ManagePremiumUpgrade onReset={(returnFlag) => {
                    setShowPlanStep(false);
                    setCurrentPlan('');
                }} selectedPlan={selectedPlanId} />}
            </div>
            <div className="image-block">
                <img src={CheckoutArt} alt="Abstract Art" />
            </div>
        </section >

        <HelpMenu />
    </>);
}

export default Checkout;