import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useLoader } from "../context/LoaderContext";
import { DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";

const WhatsAppSignup = ({ currentTenant, handleSave }) => {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);

    const showWhatsAppButton = currentTenant?.whatsapp_access_token_status === 'verified' && currentTenant?.whatsapp_access_token;

    useEffect(() => {
        // Load the Facebook SDK script dynamically
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";

        // Initialize Facebook SDK after script loads
        script.onload = () => {
            if (!window.FB) {
                console.error("Facebook SDK failed to load.");
                showSnackbar("Facebook SDK failed to load.", "error");
                return;
            }

            // Initialize SDK with a stable version (v18.0)
            window.FB.init({
                appId: "477586348219179",
                autoLogAppEvents: true,
                xfbml: true,
                version: "v22.0",
            });
        };

        document.body.appendChild(script);

        // Event listener for messages
        const handleMessage = (event) => {
            if (
                event.origin !== "https://www.facebook.com" &&
                event.origin !== "https://web.facebook.com"
            ) {
                return;
            }

            try {
                const data = JSON.parse(event.data);
                if (data.type === "WA_EMBEDDED_SIGNUP") {
                    if (data.event === "FINISH") {
                        const { phone_number_id, waba_id } = data.data;
                        console.log("Phone:", phone_number_id, "WABA:", waba_id);
                        axiosInstance
                            .post("/api/meta-signup", { phone_number_id, waba_id })
                            .then((response) => {
                                if (response?.data?.code === 200) {
                                    showSnackbar(response.data.message, "success");
                                }
                            })
                            .catch((error) => {
                                if (error.response?.status === 400) {
                                    showSnackbar(error.response.data.message, "warning");
                                }
                            });

                    } else if (data.event === "CANCEL") {
                        showSnackbar(`Cancelled at step: ${data.data.current_step}`, "error");
                    } else if (data.event === "ERROR") {
                        showSnackbar(data.data.error_message, "error");
                    }
                }
            } catch {
                console.log("Non-JSON response:", event.data);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const fbLoginCallback = (response) => {
        if (response.authResponse) {
            const code = response.authResponse.code;
            showLoader()
            axiosInstance
                .post("/api/meta-signup-auth", { code })
                .then((response) => {
                    hideLoader()
                    if (response?.data?.code === 200) {
                        showSnackbar(response.data.message, "success");
                    }
                })
                .catch((error) => {
                    hideLoader()
                    if (error.response?.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                });
        }
    };

    const launchWhatsAppSignup = () => {
        if (!window.FB) {
            showSnackbar("Facebook SDK not loaded yet.", "error");
            return;
        }

        window.FB.login(fbLoginCallback, {
            config_id: "616761884691669", //Using Embeddeb SignUp 2
            response_type: "code",
            override_default_response_type: true,
            scope: 'business_management,whatsapp_business_management',
            extras: {
                setup: {},
                featureType: '',
                sessionInfoVersion: '2',
            }
        });
    };

    const handleLoginWithFacebook = () => {
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        handleSave('pending', 'whatsapp_access_token_status');
                        hideLoader();
                        setReloadPage(reloadPage ? false : true);
                    },
                    cancel: () => {
                        hideLoader();
                    },
                    confirmMessage: 'Confirm Unsubscribe WhatsApp Message',
                    message: 'Are you sure you want to Unsubscribe WhatsApp Message',
                    buttomName: 'Unsubscribe'
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    return (
        <>
            {/* <button
                onClick={launchWhatsAppSignup}
                style={{
                    backgroundColor: "#1877f2",
                    border: "none",
                    borderRadius: "4px",
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "bold",
                    height: "40px",
                    padding: "0 24px",
                }}
            >
                Login with Facebook
            </button> */}
            {showWhatsAppButton ? (
                <button
                    onClick={handleLoginWithFacebook}
                    style={{
                        backgroundColor: "#1877f2",
                        border: "none",
                        borderRadius: "4px",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "bold",
                        height: "40px",
                        padding: "0 24px",
                    }}
                >
                    Unsubscribe from Meta WhatsApp
                </button>
            ) : (
                <button
                    onClick={launchWhatsAppSignup}
                    style={{
                        backgroundColor: "#1877f2",
                        border: "none",
                        borderRadius: "4px",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "bold",
                        height: "40px",
                        padding: "0 24px",
                    }}
                >
                    Set Up WhatsApp Messaging
                </button>
            )}
        </>
    );
};

export default WhatsAppSignup;
