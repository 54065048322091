import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../../App";
import { Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiMinus, mdiPlus } from "@mdi/js";
import { ContactSales3D } from "../../../../assets/images";
import useAxios from "../../../../hooks/useAxios";
import { useLoader } from "../../../../components/context/LoaderContext";
import { useSnackbar } from "../../../../components/context/SnackbarContext";
import { Formik } from "formik";
import * as yup from "yup";

function ManagePremiumUpgrade({ onReset }) {
    const { currentTheme } = useContext(ThemeContext);
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [currentStep, setCurrentStep] = React.useState('action-required');
    const [tenantUserCount, setTenantUserCount] = useState(10);


    const validationSchema = yup.object({
        email: yup.string().email('Invalid email format').required('Email is required'),
        description: yup.string().required('Description is required'),
        bestTimeToContact: yup.string().required('Please select a time to contact'),
    });

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            email: values.email,
            description: values.description,
            bestTimeToContact: values.bestTimeToContact,
            requiredUser: tenantUserCount,
        }
        showLoader()
        await axiosInstance.post(`/api/subscription-update-premium`, data).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, 'success')
                setCurrentStep('action-confirm')
                hideLoader()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });

    }

    return (
        <>
            {
                currentStep === 'action-required' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Tell Us About Your Needs</Typography>
                        <p className="text-secondary">Our Premium Plan is tailored to fit your unique business requirements. Please provide the following details so we can evaluate your needs and contact you to discuss a customized solution.</p>
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                            description: '',
                            bestTimeToContact: 'Morning',
                        }}

                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {props => {
                            const {
                                touched,
                                errors,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Contact Person Email</Typography>
                                    <TextField
                                        label="email"
                                        variant="filled"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.email && touched.email}
                                        helperText={(errors.email && touched.email) && errors.email}
                                    />

                                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Users to Be Included in Billing</Typography>
                                    <TextField
                                        variant="filled"
                                        value={tenantUserCount}
                                        onChange={(e) => setTenantUserCount(e.target.value)}
                                        className="no-label text-center number-stepper"
                                        //disabled={isEdittable}
                                        InputProps={{
                                            startAdornment: (
                                                <IconButton onClick={() => {
                                                    const newCount = Math.max(tenantUserCount - 1, 1)
                                                    setTenantUserCount(newCount)
                                                }}>
                                                    <Icon path={mdiMinus} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            ),
                                            endAdornment: (
                                                <IconButton onClick={() => setTenantUserCount(tenantUserCount + 1)}>
                                                    <Icon path={mdiPlus} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            ),

                                        }}
                                    />

                                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>What kind of customization is needed?</Typography>
                                    <TextField
                                        label="Description"
                                        variant="filled"
                                        multiline
                                        rows={5}
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.description && touched.description}
                                        helperText={(errors.description && touched.description) && errors.description}
                                    />

                                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Best Time to Contact</Typography>
                                    <RadioGroup
                                        row
                                        name="bestTimeToContact"
                                        value={values.bestTimeToContact}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="Morning" control={<Radio />} label="Morning" />
                                        <FormControlLabel value="Noon" control={<Radio />} label="Noon" />
                                        <FormControlLabel value="Afternoon" control={<Radio />} label="Afternoon" />
                                        <FormControlLabel value="Evening" control={<Radio />} label="Evening" />
                                    </RadioGroup>
                                    {errors.description && touched.description && (
                                        <Typography color="error">{errors.bestTimeToContact}</Typography>
                                    )}

                                    <div className="action-block">
                                        <Button variant="text" onClick={e => {
                                            onReset(e, 'cancel-downgrade');
                                        }}>Back</Button>
                                        <Button type="submit">
                                            <span>
                                                <span>Submit and Contact Me</span>
                                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                            </span>
                                        </Button>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </>
            }

            {
                currentStep === 'action-confirm' &&
                <>

                    <div className="block-title">
                        {currentTheme?.name === 'solvcrm' && <img src={ContactSales3D} alt="Check" />}
                        <Typography variant="display-small-bold" component={'h1'}>Thank You for Your Interest!</Typography>
                        <p className="text-secondary">We appreciate your interest in the Premium Plan. Our team is reviewing your request and will contact you shortly to discuss your requirements and tailor a solution that fits your business needs.</p>
                    </div>

                    <div className="action-block mt-0">
                        <Button onClick={() => window.location.reload()}>
                            <span>
                                <span>Go to Subscription Details</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }
        </>
    );
}

export default ManagePremiumUpgrade;