import React, { Suspense, useEffect, useState, useContext } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, Dialog, Grid, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import AvatarInitials from "../../../components/common/AvatarInitials";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import { useParams } from "react-router-dom";

function AddSingleContactDialog({ onClose, open }) {

    const { id } = useParams();
    const ContactId = id;
    const axiosInstance = useAxios();
    const { showLoader, hideLoader, loading } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [reloadPage, setReloadPage] = useState(false);
    const [addFrom, setAddFrom] = useState("lead");
    const [records, setRecords] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [contactLists, setContactLists] = useState("");



    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData();
        }
        fetchData();
    }, [
        reloadPage, addFrom, selectedOption
    ]);

    const getData = async () => {
        showLoader()
        const params = {
            type: addFrom,
            select_id: selectedOption,
        };
        await axiosInstance.get(`api/whatsapp-contact-list/store/` + ContactId, { params }).then((response) => {
            if (response.status === 200) {
                setRecords(response?.data?.data?.records);
                setContactLists(response?.data?.data?.contactLists);
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const addContact = async () => {
        const params = {
            type: addFrom,
            select_id: selectedOption,
            contact_id: ContactId,
        };
        try {
            const response = await axiosInstance.post(`api/whatsapp-contact-list/store`, params);
            if (response.status === 200) {
                // Handle success
                hideLoader();
                showSnackbar("Contact added successfully", "success");
                setAddFrom("lead");
                setSelectedOption("");
                setContactLists("");
                onClose();
                setReloadPage(true);
            }
        } catch (error) {
            hideLoader();
            if (error.response && error.response.status === 400) {
                showSnackbar(error.response.data.message, "warning");
            }
        }
    };


    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Add Contact</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                <TextField
                    select
                    label="Add From"
                    variant="filled"
                    value={addFrom}
                    onChange={(e) => {
                        setAddFrom(e.target.value);
                        setSelectedOption("");
                    }}
                >
                    <MenuItem key={1} value={'lead'}>Lead</MenuItem>
                    <MenuItem key={2} value={'deal'}>Deal</MenuItem>
                </TextField>

                <TextField
                    select
                    label={addFrom === "lead" ? "Select Lead" : "Select Deal"}
                    variant="filled"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                >
                    {records ? (
                        records.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>{item.name} </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No data available</MenuItem>
                    )}
                </TextField>

                <Typography variant="body-large-bold" component={'p'} className="pb-16">Contact Detail</Typography>
                {contactLists.length > 0 && (
                    <Box className="pb-24">
                        <Stack spacing={2}>
                            {contactLists.map((record, index) => (
                                <Stack key={index} spacing={1.5} direction={"row"}>
                                    <AvatarInitials name={record.name} />
                                    <Stack>
                                        <Typography variant="title-medium-bold" component={'p'}>
                                            {record.name}
                                        </Typography>
                                        <Typography variant="body-medium" className="text-secondary" component={'p'}>
                                            {record.mobile_number}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Box>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button className="btn-block" onClick={() => addContact()} >Add Contact</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" className="btn-block" onClick={() => { setAddFrom("lead"); setSelectedOption(""); setContactLists(""); onClose(); }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

export default AddSingleContactDialog;