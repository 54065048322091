import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import { Box, Button, Checkbox, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiArrowRight, mdiMinus, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import AvatarInitials from "../../components/common/AvatarInitials";
import { PaymentDone3D } from "../../assets/images";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { displayCurrencySymbol, displayDate, getMainDomain } from "../../utils/Utils";

//=========Stripe Payment data==========
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./components/stripe/CheckoutForm";
import { useSelector } from "react-redux";
import moment from "moment-timezone";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function BasicPlanCheckoutFlow({ onReset, selectedPlan }) {

    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [preferredCurrencyData, setPreferredCurrencyData] = useState({});
    const hostname = getMainDomain()
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);

    const { currentTheme } = useContext(ThemeContext);
    const [currentStep, setCurrentStep] = React.useState('manage-user-count');

    const [tenantUsers, setTenantUsers] = useState([]);
    const [tenantUserCount, setTenantUserCount] = useState(0);
    const [displayUserBox, setDisplayUserBox] = useState(true);
    const [pageReload, setPageReload] = useState(false);

    const [displayDeleteUserMsg, setDisplayDeleteUserMsg] = useState(false);
    const [displayDeleteUserCnt, setDisplayDeleteUserCnt] = useState(0);
    const [displayNextBtn, setDisplayNextBtn] = useState(false);

    const [deleteUserId, setDeleteUserId] = useState([]);
    const [packageDetail, setPackageDetail] = useState([]);
    const [displayPlanDetail, setDisplayPlanDetail] = useState([]);

    const [finalPlanDetail, setFinalPlanDetail] = useState([]);

    //for stripe
    const [payAbleAmount, setPayAbleAmount] = useState(0);


    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [currentStep]);


    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [pageReload]);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/package-detail/' + selectedPlan).then((response) => {
            if (response.status === 200) {
                setTenantUsers(response?.data?.data?.userLists);
                setTenantUserCount(response?.data?.data?.userLists?.length || 0)
                setPackageDetail(response?.data?.data?.package)
                hideLoader();
                if (hostname === '.solvcrm.co') {
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                } else {
                    //setPreferredCurrencyData({ 'currencyCode': 'INR', 'currencySymbol': '₹' });
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                }
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/checked");
                    }
                }
            });
    }

    useEffect(() => {
        if (tenantUserCount >= tenantUsers?.length) {
            setDisplayUserBox(false)
        } else {
            setDisplayUserBox(true)
        }

        //for show hide delete used box
        if (tenantUserCount <= tenantUsers?.length) {
            setDisplayDeleteUserMsg(true)
            setDisplayDeleteUserCnt(Number(tenantUsers?.length) - Number(tenantUserCount))
        } else {
            setDisplayDeleteUserMsg(false)
        }

        //Handle click for show hide Next Button
        if (tenantUserCount < tenantUsers?.length) {
            setDisplayNextBtn(true)
        } else {
            setDisplayNextBtn(false)
        }
    }, [tenantUserCount]);


    const handleCheckboxChange = (userId) => {
        setDeleteUserId((prevState) => {
            if (prevState.includes(userId)) {
                return prevState.filter((id) => id !== userId); // Uncheck
            } else {
                return [...prevState, userId]; // Check
            }
        });
    };

    const deleteUser = async (type, userIds) => {
        if (type === 'single') {
            userIds = [userIds]
        }

        showLoader();
        const data = {
            required_user: 10, //for testing make it static data
            user_ids: userIds
        }
        // Prepare query parameters
        const params = new URLSearchParams(data).toString();

        await axiosInstance.get(`api/package-detail/${selectedPlan}?${params}`).then((response) => {
            if (response.status === 200) {
                setPageReload(pageReload ? false : true);
                showSnackbar(response.data.message, "success");
                setDeleteUserId([])
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }


    const updatePlan = async () => {
        const data = {
            id: selectedPlan,
            required_user: tenantUserCount
        }
        showLoader();
        await axiosInstance.post('api/update-package', data).then((response) => {
            if (response.status === 200) {
                setDisplayPlanDetail(response?.data?.data?.displayPlanDetail)
                setPayAbleAmount(response?.data?.data?.displayPlanDetail?.final_amount)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    const extraData = {
        'userCount': parseInt((displayPlanDetail?.total_user) + 1),
        'plan_id': selectedPlan,
    }

    // =========Start::Manage Stripe checkout process=============//
    const handlePaymentRes = async (res) => {
        hideLoader()
        if (res?.response) {
            showSnackbar(res?.message, "success");
            getSubscriptionDetail()
        } else {
            showSnackbar(res?.message, "error");
        }
    }
    // =========END::Manage Stripe checkout process=============//

    const getSubscriptionDetail = async () => {
        setCurrentStep('plan-activated')
        await axiosInstance.get(`api/getsubscription`).then((response) => {
            if (response.status === 200) {
                setFinalPlanDetail(response?.data?.data?.subscriptionDetails)
                //showSnackbar(response.data.message, "success");
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }
    return (
        <>
            {currentStep === 'manage-user-count' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Manage Your Users Before Proceeding</Typography>
                        <p className="text-secondary">You currently have {tenantUsers?.length} users in your Basic plan. You can increase or decrease the number of users based on your needs.<br /><br />Once finalized, proceed to confirm and pay the updated charges for your Basic plan.</p>
                    </div>

                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Users to Be Included in Billing</Typography>

                    <Grid item sx={{ marginBottom: 4 }}>
                        <TextField
                            variant="filled"
                            value={tenantUserCount}
                            onChange={(e) => setTenantUserCount(e.target.value)}
                            className="no-label text-center number-stepper"
                            //disabled={isEdittable}
                            InputProps={{
                                startAdornment: (
                                    <IconButton onClick={() => {
                                        const newCount = Math.max(tenantUserCount - 1, 1)
                                        setTenantUserCount(newCount)
                                    }}>
                                        <Icon path={mdiMinus} color={'currentColor'} size={'20px'} />
                                    </IconButton>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => setTenantUserCount(tenantUserCount + 1)}>
                                        <Icon path={mdiPlus} color={'currentColor'} size={'20px'} />
                                    </IconButton>
                                ),

                            }}
                        />
                    </Grid>

                    {
                        displayUserBox &&
                        <>
                            {
                                displayDeleteUserMsg &&
                                <>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ marginBottom: 0.5 }}>
                                        <Typography variant="body-large-bold" component={'p'}>Delete {displayDeleteUserCnt === 1 ? `${displayDeleteUserCnt} User` : `${displayDeleteUserCnt} Users`}</Typography>
                                        <Icon path={mdiAlertCircleOutline} color={'currentColor'} size={0.833334} className="text-danger" />
                                    </Stack>
                                    <Typography variant="body-medium" className="text-secondary" component={'p'} sx={{ marginBottom: 2 }}>Deleting a user will permanently remove all data associated with that user, including leads, deals, quotes, and any other records linked to them. This action cannot be undone.</Typography>
                                </>
                            }

                            <div className="checkout-user-list">
                                <ul className="inner">
                                    {tenantUsers?.length > 0 && tenantUsers.map(
                                        (user, index) => {
                                            return (
                                                <li key={index}>
                                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                                        <Checkbox className='ml-n8 mr-n8' onClick={e => { e.stopPropagation(); }}
                                                            onChange={() => handleCheckboxChange(user.id)}
                                                            checked={deleteUserId.includes(user.id)}
                                                        />
                                                        <AvatarInitials name={user.name} sx={{ flexShrink: 0 }} />
                                                        <div>
                                                            <Typography variant="title-medium-bold" component={'p'} sx={{ marginBottom: '-2px' }}>{user.name}</Typography>
                                                            <Typography variant="body-medium" className="text-secondary">{user.email}</Typography>
                                                        </div>
                                                        <button type="button" onClick={() => deleteUser('single', user.id)} className="icon-button ml-auto flex-shrink-0 mr-n8">
                                                            <Icon path={mdiTrashCanOutline} color={"currentColor"} size={1} />
                                                        </button>
                                                    </Stack>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        deleteUserId.length !== 0 &&
                                        <li className="action-wrapper">
                                            <Button size="small" onClick={() => deleteUser('multiple', deleteUserId)}>
                                                <span>
                                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                    <span>Delete Selected ({deleteUserId.length})</span>
                                                </span>
                                            </Button>
                                        </li>
                                    }

                                </ul>
                            </div>
                        </>
                    }

                    <div className="action-block">
                        <Button variant="text" onClick={onReset}>Back</Button>
                        <Button onClick={e => { setCurrentStep('review-payment-details'); updatePlan() }} disabled={displayNextBtn}>
                            <span>
                                <span>Next</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }

            {currentStep === 'review-payment-details' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Review and Complete Your Payment</Typography>
                        <p className="text-secondary">Here’s a summary of your payment details. Please review everything carefully and provide your payment information to complete your subscription.</p>
                    </div>

                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Order Summary</Typography>
                    <div className="item-summary">
                        <ul>
                            <li>
                                <Typography component={'p'}>Plan</Typography>
                                <Typography variant="body-large-medium">{displayPlanDetail?.plan_name} Plan</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Total Billable Users ({displayPlanDetail?.total_user} Users + 1 Tenant)</Typography>
                                <Typography variant="body-large-medium">{parseInt((displayPlanDetail?.total_user) + 1)} Users</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Cost Per User ({packageDetail?.duration_type})</Typography>
                                <Typography variant="body-large-medium">
                                    {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                    {displayPlanDetail?.cost_user ? (Math.round(displayPlanDetail?.cost_user * 100) / 100).toFixed(2) : 'N/A'}
                                </Typography>
                            </li>
                        </ul>
                        <div className="highlight">
                            <Typography variant="title-large" component={'p'}>Amount Payable</Typography>
                            <Typography variant="title-large-semibold">
                                {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                {displayPlanDetail?.final_amount ? (Math.round(displayPlanDetail?.final_amount * 100) / 100).toFixed(2) : 'N/A'}
                            </Typography>
                        </div>
                    </div>

                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Provide Card Details</Typography>

                    <div className="row s-box">
                        <React.Fragment>
                            <div className="col-md-12 order-md-1">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm amount={(Math.round(payAbleAmount * 100) / 100).toFixed(2)} onReset={e => { setCurrentStep('manage-user-count') }} handlePaymentRes={handlePaymentRes} extraData={extraData} />
                                </Elements>
                            </div>
                        </React.Fragment>
                    </div>



                    {/* <div className="action-block">
                        <Button variant="text" onClick={e => { setCurrentStep('manage-user-count') }}>Back</Button>
                        <Button onClick={e => { setCurrentStep('plan-activated') }}>
                            <span>
                                <span>Pay and Activate Plan</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div> */}
                </>
            }

            {currentStep === 'plan-activated' &&
                <>
                    <div className="block-title">
                        {currentTheme?.name === 'solvcrm' && <img src={PaymentDone3D} alt="Check" />}
                        <Typography variant="display-small-bold" component={'h1'}>Payment Successful!</Typography>
                        <p className="text-secondary">Thank you for your payment! Your subscription is now active, and you can continue using SolvCRM to manage your business efficiently. Explore all the features and get the most out of your plan.</p>
                    </div>

                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Plan Details</Typography>

                    <div className="item-summary">
                        <ul>
                            <li>
                                <Typography component={'p'}>Plan</Typography>
                                <Typography variant="body-large-medium">{finalPlanDetail?.planName} Plan</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Total Billable Users</Typography>
                                <Typography variant="body-large-medium">{finalPlanDetail?.totalBillableUser} Users</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Next Billing Date</Typography>
                                <Typography variant="body-large-medium">{finalPlanDetail?.nextBillingData
                                    ? displayDate(moment.utc(finalPlanDetail.nextBillingData).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                    : "N/A"}</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Next Bill Amount ({finalPlanDetail?.paymentCycle || 'monthly'})</Typography>
                                <Typography variant="body-large-medium">
                                    {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                    {finalPlanDetail?.NextBillAmount ? (Math.round(finalPlanDetail?.NextBillAmount * 100) / 100).toFixed(2) : 'N/A'}
                                </Typography>
                            </li>
                        </ul>
                    </div>

                    <div className="action-block mt-0">
                        <Button onClick={() => navigate('/dashboard')}>
                            <span>
                                <span>Go to Dashboard</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }
        </>
    );
}

export default BasicPlanCheckoutFlow;