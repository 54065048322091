import React, { useState } from 'react';
import {
    useStripe, useElements,
} from '@stripe/react-stripe-js';

import { useSnackbar } from '../../../../../components/context/SnackbarContext';
import { useLoader } from '../../../../../components/context/LoaderContext';
import { getMainDomain } from '../../../../../utils/Utils';
import useAxios from '../../../../../hooks/useAxios';
import { Button } from '@mui/material';
import Icon from '@mdi/react';
import { mdiArrowRight } from '@mdi/js';



export default function ExistingCardPayment(props) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [isDisablepaymentButton, setIsDisablepaymentButton] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    // useEffect(() => {
    //     if (props.amount == 0.00) {
    //         setIsDisablepaymentButton(true)
    //     }
    // }, [props?.amount]);




    const handleSubmit = async () => {

        if (!stripe || !elements) {
            showSnackbar('Stripe is not loaded yet.', "warning");
            return;
        }

        const data = ({
            amount: props.amount,
            extraData: props.extraData,
        })
        setIsDisablepaymentButton(true)
        stripePayment(data);

    };

    const stripePayment = async (data) => {
        showLoader();
        await axiosInstance.post("/api/subscription-make-payment", data).then((response) => {
            if (response.status === 200) {
                //Need to check subscription status and response (if success meand card are normal and payment are done)
                if (response?.data?.data?.paymentIntentResponse === 'success') {
                    hideLoader();
                    props.handlePaymentRes({
                        'response': true,
                        'message': response.data.message,
                    });
                } else {
                    //if response get in else than handle 3D Secure card detail for this transection/subscrirption
                    storePaymentDetail(response)
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                showSnackbar(error?.response?.data?.message, "error");
                setIsDisablepaymentButton(false)
                props.handlePaymentRes({
                    'response': false,
                    'message': error.response.data.message,
                });
            }
        });

    }

    const storePaymentDetail = async (responseData) => {
        if (responseData.data.data) {
            if (responseData.data.data.paymentIntentResponse['isSubscription']) {//recurring payment
                stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret']).then(async (result) => {
                    if (result.error) {
                        props.handlePaymentRes({
                            'response': false,
                            'message': result.error.message,
                        });
                        showSnackbar(result?.error?.message, "error");
                    } else {
                        // Successful subscription payment
                        // Post the transaction info to the server-side script and redirect to the payment status page
                        const requestData = {
                            'paymentIntent': result?.paymentIntent,
                            'subscriptionId': responseData?.data?.data?.paymentIntentResponse['subscriptionId'],
                            'clientSecret': responseData?.data?.data?.paymentIntentResponse['clientSecret'],
                            'customerId': responseData?.data?.data?.paymentIntentResponse['customerId'],
                            'orderId': responseData?.data?.data?.paymentIntentResponse['orderId'],
                            'isUpdate': responseData?.data?.data?.paymentIntentResponse['isUpdate'],
                            'requestUserCount': responseData?.data?.data?.paymentIntentResponse['requestUserCount'] ? responseData?.data?.data?.paymentIntentResponse['requestUserCount'] : '',
                            'amount': responseData?.data?.data?.paymentIntentResponse['amount'],
                            'subscriptionType': responseData?.data?.data?.paymentIntentResponse['subscriptionType'],
                        }
                        showLoader()
                        await axiosInstance.post("/api/subscription-recurring-payment-confirm", requestData).then(async (response) => {
                            if (response.status === 200) {
                                hideLoader()
                                props.handlePaymentRes({
                                    'response': true,
                                    'message': 'Payment successfull',
                                });
                                showSnackbar('Payment was completed successfully.', "success");
                            }
                        }).then(json => {

                        }).catch(error => {
                            if (error.response) {
                                hideLoader();
                                props.handlePaymentRes({
                                    'response': false,
                                    'message': error.response.data.message,
                                });
                                showSnackbar(error?.response?.data?.message, "error");
                                setIsDisablepaymentButton(false)
                            }
                        });
                    }
                });
            }
        } else {
            hideLoader()
            props.handlePaymentRes({
                'response': false,
                'message': 'Payment failed!',
            });
            showSnackbar('Payment failed!', "error");
        }

    }

    return (
        <React.Fragment>
            <Button variant="text" onClick={e => {
                props.onReset(e, 'cancel');
            }}>Back</Button>
            <Button onClick={() => handleSubmit()} disabled={isDisablepaymentButton}>
                <span>
                    <span>Pay Now</span>
                    <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                </span>
            </Button>
        </React.Fragment>
    );
}

