import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoader } from "../context/LoaderContext";
import { DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";

const MetaAddAccount = ({ currentTenant, handleSave }) => {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);

    const showWhatsAppButton = currentTenant?.meta_add_account_user_access_token_status === 'verified' && currentTenant?.meta_add_account_user_access_token;

    const getBaseUrl = () => {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ""}/`;
    };
    const baseUrl = getBaseUrl();

    function openFacebookAuthPopup() {
        const clientId = '477586348219179';
        const redirectUri = baseUrl + 'meta/oauth/callback'; //call a MetaAuth page for getting access token
        const scope = 'ads_management,leads_retrieval,pages_manage_ads,pages_manage_metadata,pages_read_engagement';
        //const scope = 'whatsapp_business_management,business_management,pages_show_list';

        const facebookAuthUrl = `https://www.facebook.com/v21.0/dialog/oauth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=code`;

        const width = 600;
        const height = 700;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        const popup = window.open(
            facebookAuthUrl,
            'Facebook Auth',
            `width=${width},height=${height},top=${top},left=${left},scrollbars=no,resizable=no`
        );

        if (popup) {
            const checkPopupClosed = setInterval(() => {
                if (popup.closed) {
                    clearInterval(checkPopupClosed);
                    showSnackbar('Authentication completed or canceled', "success");
                    // Optionally fetch the user's access token if the redirect_uri is handled in your backend.
                }
            }, 500);

            // Expose a function for the popup to call
            window.onPopupSuccess = () => {
                clearInterval(checkPopupClosed);
                popup.close(); // Ensure the popup is closed
                showSnackbar('Authentication successful!', "success");
            };
        } else {
            showSnackbar('Popup blocked. Please allow popups for this website.', "warning");
        }
    }

    const handleConnectWithFacebookAds = () => {
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        handleSave('pending', 'meta_add_account_user_access_token_status');
                        hideLoader();
                        setReloadPage(reloadPage ? false : true);
                    },
                    cancel: () => {
                        hideLoader();
                    },
                    confirmMessage: 'Confirm Unsubscribe Facebook Ads',
                    message: 'Are you sure you want to Unsubscribe Facebook Ads',
                    buttomName: 'Unsubscribe'
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    return (
        <>
            {/* <button
            onClick={openFacebookAuthPopup}
            style={{
                backgroundColor: "#1877f2",
                border: "none",
                borderRadius: "4px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "16px",
                fontWeight: "bold",
                height: "40px",
                padding: "0 24px",
            }}
        >
            Connect with Facebook for Ads
        </button> */}
            {currentTenant?.meta_add_account_user_access_token_status === 'verified' && currentTenant?.meta_add_account_user_access_token != null ? (
                <button
                    onClick={handleConnectWithFacebookAds}
                    style={{
                        backgroundColor: "#1877f2",
                        border: "none",
                        borderRadius: "4px",
                        color: "#fff",
                        cursor: "pointer",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontSize: "16px",
                        fontWeight: "bold",
                        height: "40px",
                        padding: "0 24px",
                    }}
                >
                    Unsubscribe from Meta Ads
                </button>
            ) : (
                <button
                    onClick={openFacebookAuthPopup}
                    style={{
                        backgroundColor: "#1877f2",
                        border: "none",
                        borderRadius: "4px",
                        color: "#fff",
                        cursor: "pointer",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontSize: "16px",
                        fontWeight: "bold",
                        height: "40px",
                        padding: "0 24px",
                    }}
                >
                    Connect With Meta Ads
                </button>
            )}
        </>
    );

};

export default MetaAddAccount;
