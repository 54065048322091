import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, capitalize, Grid, IconButton, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import MessageChatItem from "../../components/common/MessageChatItem";
import { mdiClose, mdiDelete, mdiFileDocument, mdiFilePdfBox, mdiFilePlusOutline, mdiFormatBold, mdiFormatItalic, mdiFormatStrikethroughVariant, mdiImage, mdiMusicBox, mdiPlus, mdiTrashCanOutline, mdiVideo } from "@mdi/js";
import Icon from "@mdi/react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, convertToRaw, EditorState, Modifier } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

function EditTemplate(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const [headerType, setHeaderType] = React.useState('none');

    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [variables, setVariables] = useState([]); // Initial empty variable
    const [addNewBtnDefaultValue, setAddNewBtnDefaultValue] = useState(''); // Initial empty variable
    const [nextVarId, setNextVarId] = useState(1);
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [selectedButtons, setSelectedButtons] = useState([]);
    const [submitStatus, setSubmitStatus] = useState('submit_review');
    const [recordData, setRecordData] = useState([]);
    const [uplodedFile, setUplodedFile] = useState('');

    //for preview 
    const [formattedHeader, setFormattedHeader] = useState({ head: '', fileLink: '', fileName: '' })
    const [formattedBody, setFormattedBody] = useState('')
    const [viewBody, setViewBody] = useState('')
    const [formattedFooter, setFormattedFooter] = useState('')

    let currentTime = moment.utc(new Date()).tz(preTimeZoneKey).format(preferredDateFormat)
    const timeOnly = moment(currentTime, 'DD-MM-YYYY h:mm a').format('h:mm a');

    const [defaultValue, setdefaultValue] = useState({
        category: '',
        language: '',
        header_format: 'none',
        header_text: '',
        body_text: '',
        body_examples: variables,
        template_name: '',
        header_media_handle: [],
        footer_text: '',
        error_list: [],
        // buttons: [
        //     {
        //         type: '',
        //         text: '',
        //         url: '',
        //         copy_text: '',
        //         phone_number: ''
        //     }
        // ]
        buttons: []
    })

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [id]);


    const getData = async () => {
        showLoader();

        await axiosInstance.get('api/edit-whatsapp-template/' + id).then((response) => {
            if (response.status === 200) {
                const contentData = response?.data?.data?.record?.content ? JSON.parse(response?.data?.data?.record?.content) : [];
                const whatsAppContentData = response?.data?.data?.record?.whatsapp_content ? JSON.parse(response?.data?.data?.record?.whatsapp_content) : [];

                //here we have to check witch header are used (for diplay images and body text like strong,em,del)
                const headerData = response?.data?.data?.record?.status != 'APPROVED' ? contentData.filter(item => item.type === "HEADER") : whatsAppContentData.filter(item => item.type === "HEADER")
                const bodyData = response?.data?.data?.record?.status != 'APPROVED' ? contentData.filter(item => item.type === "BODY") : whatsAppContentData.filter(item => item.type === "BODY");
                const footerData = contentData.filter(item => item.type === "FOOTER");
                const buttonsData = contentData.filter(item => item.type === "BUTTONS");

                // //extra datas
                setHeaderType(headerData?.length && headerData.length > 0 ? headerData[0]['format'].toLowerCase() : 'none')


                if ((headerData?.length && headerData.length > 0) && (headerData[0]['format'].toLowerCase() == 'image' || headerData[0]['format'].toLowerCase() == 'video' || headerData[0]['format'].toLowerCase() == 'document')) {
                    //get file name from meta uplaoded file
                    let sendFileName = '';
                    if (response?.data?.data?.record?.file_name && response?.data?.data?.record?.file_name != 'N/A') {
                        sendFileName = response?.data?.data?.record?.file_name;
                    } else {
                        const uplodedFileurl = headerData[0]['example']['header_handle'][0];
                        sendFileName = uplodedFileurl && uplodedFileurl.split('?')[0];
                        sendFileName = sendFileName && sendFileName.slice(-20);
                    }

                    setFormattedHeader({ head: '', fileLink: headerData[0]['example']['header_handle'][0], fileName: sendFileName });
                    if (response?.data?.data?.record?.status == 'DRAFT') {
                        setUplodedFile(response?.data?.data?.record?.file_path)
                        setFormattedHeader({ head: '', fileLink: response?.data?.data?.record?.file_path, fileName: response?.data?.data?.record?.file_name });
                        //setSelectedFiles([{ name: response?.data?.data?.record?.file_name, size: response?.data?.data?.record?.file_size }]);
                    }

                    //setSelectedFiles([{ name: response?.data?.data?.record?.file_name, size: response?.data?.data?.record?.file_size }]);
                }

                if ((headerData?.length && headerData.length > 0) && (headerData[0]['format'].toLowerCase() == 'text')) {
                    setFormattedHeader({ head: headerData?.length && headerData.length > 0 ? headerData[0]['text'] : '', fileLink: '', fileName: '' });
                }

                //manage show prefield data on editor
                if (bodyData && bodyData?.[0]?.['text']) {
                    setViewBody(bodyData[0]['text'])

                    // Convert the prefilled HTML content to Draft.js content blocks
                    const blocksFromHTML = convertFromHTML(bodyData[0]['text']);

                    // Create a ContentState object from the blocks
                    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
                    // Create an EditorState from the ContentState
                    setEditorState(EditorState.createWithContent(contentState));

                    //need to set editor dynamic variable 
                    const bodyDynamicVariable = bodyData?.length ? bodyData?.[0]?.['example']?.['body_text'] : []

                    if (bodyDynamicVariable?.length) {
                        const formattedVariables = bodyDynamicVariable?.length && bodyDynamicVariable.flat().map((item, index) => ({
                            id: index + 1, // or generate a unique id based on your logic
                            value: item, // Assuming each element inside the array has only one value
                        }));

                        setVariables(formattedVariables);
                    }

                }

                //set uo footer data
                if (footerData?.length && footerData.length > 0) {
                    setFormattedFooter(footerData[0]['text'])
                }

                //manage button array
                if (buttonsData?.length && buttonsData.length > 0) {
                    const originalButtons = buttonsData?.length ? buttonsData[0]['buttons'] : []
                    const transformedButtons = originalButtons?.length && originalButtons.map(button => ({
                        type:
                            button.type === 'QUICK_REPLY' ? 'quick-reply' :
                                button.type === 'PHONE_NUMBER' ? 'call-phone-number' :
                                    button.type === 'URL' ? 'visit-website' :
                                        button.type === 'COPY_CODE' ? 'copy-offer-code' : '',

                        text: button.text || '',
                        url: button.type === 'URL' ? button.url || '' : '',
                        copy_text: button.type === 'COPY_CODE' ? button?.example?.[0] || '' : '',
                        phone_number: button.type === 'PHONE_NUMBER' ? button.phone_number || '' : ''
                    }));
                    setSelectedButtons(transformedButtons)
                }

                setRecordData(response?.data?.data?.record || [])
                setdefaultValue({
                    category: response?.data?.data?.record?.category?.toLowerCase() || '',
                    language: response?.data?.data?.record?.language || '',
                    header_format: headerData?.length && headerData.length > 0 ? headerData[0]['format'].toLowerCase() : 'none',
                    header_text: headerData?.length && headerData.length > 0 ? headerData[0]['text'] : '',
                    body_text: bodyData?.length && bodyData.length > 0 ? bodyData[0]['text'] : '',
                    body_examples: variables,
                    template_name: response?.data?.data?.record?.template_name || '',
                    header_media_handle: [],
                    footer_text: footerData?.length && footerData.length > 0 ? footerData[0]['text'] : '',
                    buttons: selectedButtons,
                    error_list: [],
                })
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/marketing/templates");
                    }
                }
            });
    }

    const getAllowedMimeTypes = (type) => {
        switch (type) {
            case 'image':
                return ['image/jpeg', 'image/png', 'image/gif'];
            case 'video':
                return ['video/mp4', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv', 'video/x-matroska'];
            case 'document':
                return [
                    'application/pdf', 'text/plain', 'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'text/csv'
                ];
            default:
                return [];
        }
    };

    const allowedMimeTypes = getAllowedMimeTypes(headerType);
    const acceptFileType = allowedMimeTypes.join(',');
    const acceptFileTypeMsg = allowedMimeTypes.map(type => type.split('/')[1]).join(', ');

    const iconMapping = {
        image: mdiImage,
        video: mdiVideo,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
    };

    const removeFile = (index, setFieldValue, errors) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
        const newErrors = Array.isArray(errors.header_media_handle) ? [...errors.header_media_handle] : [];
        newErrors.splice(index, 1);
        setFieldValue("header_media_handle", newErrors);
        setFormattedHeader({ head: '', fileLink: '', fileName: '' }); //When remove file at time also delete from whatsapp preview screen
        setUplodedFile('')
    };

    const validationSchema = yup.object().shape({
        //template_name: yup.string().required('Enter Template Name').min(2, 'Must be at least 2 characters').max(255, 'Must be at most 255 characters'),
        category: yup.string().required('Select Category'),
        //language: yup.string().required('Select Language'),
        header_text: yup.string().nullable().min(2, 'Must be at least 2 characters').max(60, 'Must be at most 60 characters'),
        body_examples: yup.array().of(
            yup.object().shape({
                value: yup.string().required('Value is required')  // Ensures each variable has a value
            })
        ),
        header_media_handle: yup
            .array()
            .nullable()
            .of(
                yup
                    .mixed()
                    .test('fileFormat', 'Unsupported file format. Please upload a valid file type.', function (value) {
                        const allowedTypes = getAllowedMimeTypes(this.options.context.headerType);
                        return value && allowedTypes.includes(value.type);
                    })
                    .test('fileSize', 'File size exceeds 4 MB', (value) => {
                        return value && value.size <= 4 * 1024 * 1024;
                    })
            )
            .test('validFiles', 'Files are not uploaded due to validation errors', function (value) {
                if (value) {
                    const allowedTypes = getAllowedMimeTypes(this.options.context.headerType);
                    return value.every(file => {
                        const isValidFormat = allowedTypes.includes(file.type);
                        const isValidSize = file.size <= 4 * 1024 * 1024;
                        return isValidFormat && isValidSize;
                    });
                }
                return true;
            }),
        footer_text: yup.string().min(2, 'Must be at least 2 characters').max(60, 'Must be at most 60 characters'),

        body_text: yup
            .string()
            .nullable() // Allow null values to prevent runtime errors
            .test(
                'is-not-empty',
                'Body content is required',
                (value) => {
                    if (!value) return false; // If value is empty or null, return false
                    const plainText = value
                        .replace(/<[^>]+>/g, '') // Remove HTML tags
                        .replace(/&nbsp;/g, '') // Remove non-breaking spaces
                        .trim();
                    return plainText.length > 0; // Ensure there is actual content
                }
            ),


        buttons: yup.array().of(
            yup.object().shape({
                text: yup.string().required('Button text is required'),  // Required for all buttons

                url: yup.string()
                    .when('type', {
                        is: 'visit-website',
                        then: (schema) => schema
                            .required('Website URL is required')
                            .matches(
                                /^(https?:\/\/)?([\w\d-]+\.)+[\w]+(\/[\w\d-]*)*\/?$/,
                                'Enter a valid URL (e.g., https://example.com)'
                            ),
                        otherwise: (schema) => schema.notRequired()
                    }),

                phone_number: yup.string()
                    .when('type', {
                        is: 'call-phone-number',
                        then: (schema) => schema
                            .required('Phone number is required')
                            .matches(
                                /^\d{10,15}$/,
                                'Enter a valid phone number with country code (e.g., 918888888888)'
                            ),
                        otherwise: (schema) => schema.notRequired()
                    }),

                copy_text: yup.string()
                    .when('type', {
                        is: 'copy-offer-code',
                        then: (schema) => schema.required('Offer code is required'),
                        otherwise: (schema) => schema.notRequired()
                    }),
            })
        )
    });


    const handleFileChange = (event) => {
        setUplodedFile('')
        const file = event.currentTarget.files[0]; // Get the first file
        const errors = [];

        if (!file) return; // If no file is selected, exit early

        const allowedMimeTypes = getAllowedMimeTypes(headerType);

        if (file.size > 4 * 1024 * 1024) {
            errors.push(`${file.name} is too large.`);
        } else if (!allowedMimeTypes.includes(file.type)) {
            errors.push(`${file.name} has an unsupported file type.`);
        } else {
            const imageUrl = URL.createObjectURL(file);
            setFormattedHeader({ head: '', fileLink: imageUrl, fileName: file.name });
        }

        if (errors.length > 0) {
            showSnackbar(`${errors.length} file(s) not uploaded due to validation errors`, 'error');
        } else {
            // Optional: Set the file as the selected one
            setSelectedFiles([file]);
        }
        event.target.value = ''; // Reset the file input
    };

    const handleSubmit = async (values, onSubmitProps) => {

        const formData = new FormData();
        formData.append('id', id)
        formData.append('template_name', values?.template_name || '')
        formData.append('category', values?.category || '')
        formData.append('language', values?.language || '')
        formData.append('header_format', values?.header_format || '')
        formData.append('header_text', values?.header_text || '')
        formData.append('body_text', values?.body_text || '')
        formData.append('footer_text', values?.footer_text || '')
        formData.append('form_status', submitStatus || '')
        if (uplodedFile) {
            formData.append('form_uploded_file', uplodedFile || '')
        }

        for (var i = 0; i < selectedFiles.length; i++) {
            formData.append('header_media_handle', uplodedFile ? [] : selectedFiles[i]?.type ? selectedFiles[i] : [])
        }

        for (let j = 0; j < selectedButtons.length; j++) {
            formData.append('buttons[]', JSON.stringify(selectedButtons[j])); // Ensure this is serialized as a string
        }

        // If you have any other complex data (e.g., `body_examples`), ensure it's serialized to JSON
        // if (values.body_examples && Array.isArray(values.body_examples)) {
        //     formData.append('body_examples', JSON.stringify(values.body_examples));
        // }

        for (let k = 0; k < values.body_examples.length; k++) {
            formData.append('body_examples[]', values.body_examples[k].value);
        }

        try {
            showLoader();
            const response = await axiosInstance.post('/api/update-message-template', formData);
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success');
                navigate("/marketing/templates");
            }
        } catch (error) {
            hideLoader();
            if (error.response) {
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error');
                }
            } else {

            }
        } finally {
            hideLoader();
        }
    }

    const handleAddVariable = () => {
        const newId = variables.length > 0 ? Math.max(...variables.map(variable => variable.id)) + 1 : 1;
        setVariables([...variables, { id: newId, value: '' }]);
        insertPlaceholder(newId); // Insert the new variable as a placeholder in the editor
    };

    useEffect(() => {
        setdefaultValue((prev) => ({
            ...prev,
            body_examples: variables,
        }));
    }, [variables]);

    const handleVariableChange = (index, value) => {
        const updatedVariables = [...variables];
        updatedVariables[index].value = value;
        setVariables(updatedVariables);
    };



    const buttonLimits = {
        "quick-reply": Infinity, // No limit
        "marketing-opt-out": 1, // Max 1
        "visit-website": 2, // Max 2
        "call-phone-number": 1, // Max 1
        "copy-offer-code": 1 // Max 1
    };


    const handleAddButton = (e, setFieldValue) => {
        const newButtonType = e.target.value;

        setSelectedButtons(prevButtons => {
            // Get the current count of the new button type in the updated state
            const currentCount = prevButtons.filter(btn => btn.type === newButtonType).length;

            // Check if the limit for the button type is not exceeded
            if (buttonLimits[newButtonType] > currentCount) {
                // Create the new button with default values
                //const newName = newButtonType.replace(/-/g, " ").toUpperCase();
                const newName = newButtonType.replace(/-/g, ' ')
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
                    .join(' ')
                const newButton = { type: newButtonType, text: newName, url: "", copy_text: "", phone_number: "" };

                // Update selectedButtons state
                const updatedButtons = [...prevButtons, newButton];

                // Sync the updated buttons state with Formik's values
                setFieldValue("buttons", updatedButtons);
                setAddNewBtnDefaultValue('')
                return updatedButtons; // Return the updated state
            } else {
                alert(`You can only add up to ${buttonLimits[newButtonType]} ${newButtonType} button(s).`);
                setAddNewBtnDefaultValue('')
                return prevButtons; // Return previous state if limit is reached
            }
        });
    };


    const handleButtonChange = (index, field, value, setFieldValue) => {
        const updatedButtons = [...selectedButtons];
        updatedButtons[index][field] = value;
        setSelectedButtons(updatedButtons);
        setFieldValue("buttons", updatedButtons);
    };

    const handleRemoveButton = (index, setFieldValue) => {
        const updatedButtons = selectedButtons.filter((_, i) => i !== index);
        setSelectedButtons(updatedButtons);
        setFieldValue("buttons", updatedButtons);
    };

    const formattedButtons = selectedButtons?.length && selectedButtons?.map(btn => ({ type: btn.type, text: btn.text })) || [];

    // Function to detect and add a variable when user types {{}} manually
    const handleEditorChange = (newState) => {
        if (newState) {
            const contentState = newState.getCurrentContent();
            const blocks = contentState.getBlocksAsArray();

            let text = "";
            blocks.forEach(block => {
                text += block.getText(); // Concatenate text from all blocks
            });

            text = text.trim();

            // Match {{1}}, {{2}}, etc., strictly
            const match = text.match(/\{\{\d*\}\}/g);
            if (match) {
                const newVariables = match.map(variable => {
                    const id = parseInt(variable.replace(/[^\d]/g, '')); // Extract the number
                    const existingVariable = variables.find(v => v.id === id); // Check if it already exists
                    return { id, value: existingVariable ? existingVariable.value : "" }; // Add the variable
                });

                setVariables(newVariables);
            } else {
                setVariables([]);  // Clear variables if no placeholders are found
                setNextVarId(1);    // Reset ID for new variables
            }
        }
    };

    useEffect(() => {
        setdefaultValue((prev) => ({
            ...prev,
            body_examples: variables,
        }));
    }, [variables]);

    useEffect(() => {
        replacePlaceholders();
    }, [formattedBody]);


    const replacePlaceholders = () => {
        let updatedText = formattedBody;

        variables.forEach((el, index) => {
            const placeholder = `{{${index + 1}}}`;  // This will create {{index}}
            if (el.value) {
                const escapedPlaceholder = placeholder.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');  // Escape any special characters
                // Now use the escaped placeholder in the RegExp to replace all instances
                updatedText = updatedText.replace(new RegExp(escapedPlaceholder, 'g'), el.value);
            }
        });

        updatedText = updatedText.slice(1, -3);
        while (updatedText.includes('\\n')) {
            updatedText = updatedText.replace('\\n', '<br/>');
        }
        setViewBody(updatedText);

    };

    // Function to insert {{1}} at the current cursor position
    const insertPlaceholder = (newId) => {
        const currentState = editorState;
        const selection = currentState.getSelection();

        if (selection.isCollapsed()) {  // Ensure the cursor is not at the start or end
            const contentState = currentState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity('PLACEHOLDER', 'MUTABLE', {});
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

            const newEditorState = EditorState.set(currentState, {
                currentContent: Modifier.insertText(
                    contentState,
                    selection,
                    `{{${newId}}}`,
                    null,
                    entityKey
                ),
            });

            setEditorState(newEditorState);
            setNextVarId(nextVarId + 1);  // Increment next available variable ID
        }
    };


    return (
        <>

            <Helmet>
                <title>Add Template | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <div className="add-template-block">
                <div className="form-block">
                    <Formik
                        initialValues={defaultValue}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {props => {
                            const {
                                touched,
                                errors,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched
                            } = props;
                            return (
                                <>
                                    <form onSubmit={handleSubmit}>

                                        <Stack spacing={2}>
                                            <div className="card p-16 pb-0">
                                                <div className="card-header">
                                                    <h6 className="title">Template Details</h6>
                                                </div>

                                                <Grid container columnSpacing={2}>
                                                    <Grid item xxl={6} xs={12}>
                                                        <TextField
                                                            label="Template Name"
                                                            variant="filled"
                                                            name="template_name"
                                                            value={values?.template_name || ''}
                                                            //onChange={handleChange}
                                                            onChange={(e) => {
                                                                // Replace spaces with underscores
                                                                const updatedValue = e.target.value.replace(/\s+/g, '_');  // Replace spaces with underscores

                                                                // Allow only alphanumeric characters and underscores
                                                                const sanitizedValue = updatedValue.replace(/[^a-zA-Z0-9_]/g, '');  // Remove any special characters
                                                                handleChange({
                                                                    target: {
                                                                        name: e.target.name,
                                                                        value: sanitizedValue,
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={handleBlur}
                                                            error={errors.template_name && touched.template_name}
                                                            helperText={(errors.template_name && touched.template_name) && errors.template_name}
                                                            disabled={recordData?.whatsapp_template_id ? true : false}
                                                        />
                                                    </Grid>
                                                    <Grid item xxl={3} xs={6}>
                                                        <TextField
                                                            select
                                                            label="Category"
                                                            value={values?.category || ''}
                                                            name="category"
                                                            variant="filled"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.category && touched.category}
                                                            helperText={(errors.category && touched.category) && errors.category}
                                                        >
                                                            <MenuItem key={1} value="marketing">Marketing</MenuItem>
                                                            <MenuItem key={2} value="utility">Utility</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xxl={3} xs={6}>
                                                        <TextField
                                                            select
                                                            label="Language"
                                                            value={values?.language || ''}
                                                            name="language"
                                                            variant="filled"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.language && touched.language}
                                                            helperText={(errors.language && touched.language) && errors.language}
                                                            disabled={recordData?.whatsapp_template_id ? true : false}
                                                        >
                                                            <MenuItem key={1} value="en_US">English - US</MenuItem>
                                                            <MenuItem key={2} value="en_IN">English - IN</MenuItem>
                                                            <MenuItem key={3} value="en_UK">English - US</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </div>



                                            <div className="card p-16 pb-0">
                                                <div className="card-header">
                                                    <h6 className="title">Header <Typography className="text-secondary font-normal" variant="body-large">- Optional</Typography></h6>
                                                </div>
                                                <TextField
                                                    select
                                                    label="Type"
                                                    value={values?.header_format || ''}
                                                    name="header_format"
                                                    variant="filled"
                                                    onChange={(event) => {
                                                        handleChange(event);  // Call handleChange properly
                                                        setHeaderType(event.target.value); // Call setHeaderType properly
                                                        setFormattedHeader(event.target.value === 'none' ? { head: '', fileLink: '', fileName: '' } : { head: '', fileLink: '', fileName: '' });
                                                        setSelectedFiles(event.target.value === 'none' ? [] : []);
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={errors.header_format && touched.header_format}
                                                    helperText={(errors.header_format && touched.header_format) && errors.header_format}
                                                >

                                                    <MenuItem key={1} value={'none'}>None</MenuItem>
                                                    <MenuItem key={2} value={'text'}>Text</MenuItem>
                                                    <MenuItem key={3} value={'image'}>Image</MenuItem>
                                                    <MenuItem key={4} value={'video'}>Video</MenuItem>
                                                    <MenuItem key={5} value={'document'}>Document</MenuItem>
                                                </TextField>

                                                {headerType === 'text' && (
                                                    <TextField
                                                        label="Header Text"
                                                        variant="filled"
                                                        name="header_text"
                                                        value={values?.header_text || ''}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFormattedHeader({ head: event.target.value, fileLink: '', fileName: '' });
                                                        }}
                                                        onBlur={handleBlur}
                                                        inputProps={{
                                                            maxLength: 60, // Set the max length to 60 characters
                                                        }}
                                                        error={errors.header_text && touched.header_text}
                                                        helperText={(errors.header_text && touched.header_text) && errors.header_text}
                                                    />
                                                )}

                                                {(headerType === 'image' || headerType === 'video' || headerType === 'document') &&
                                                    <>
                                                        <div className="psuedo-upload-block inline mb-24">
                                                            <div className="content">
                                                                <Button color="secondary" className="btn-block" tabIndex={-1}>
                                                                    <span>
                                                                        <Icon path={mdiFilePlusOutline} color={'currentColor'} size={1} />
                                                                        <span>Upload File</span>
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                            <input
                                                                type="file"
                                                                name="header_media_handle"
                                                                className="psuedo-upload-element"
                                                                onChange={(event) => {
                                                                    handleFileChange(event);
                                                                    setFieldValue("header_media_handle", Array.from(event.currentTarget.files));
                                                                }}
                                                                onBlur={handleBlur}
                                                                accept={acceptFileType}
                                                                title={`Allow file type are ${acceptFileTypeMsg} and MAX each file size are 4MB`}
                                                            />
                                                        </div>
                                                        <br />
                                                    </>
                                                }

                                                {
                                                    (headerType === 'image' || headerType === 'video' || headerType === 'document') && selectedFiles && selectedFiles.length > 0 &&

                                                    selectedFiles.map((attachment, index) => {
                                                        const fileExtension = attachment && attachment?.name?.split('.').pop().toLowerCase();

                                                        let fileType = '';
                                                        if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
                                                            fileType = 'image';
                                                        } else if (['mp3', 'wav', 'ogg', 'mp4'].includes(fileExtension)) {
                                                            fileType = 'video';
                                                        } else if (['pdf'].includes(fileExtension)) {
                                                            fileType = 'pdf';
                                                        } else {
                                                            fileType = 'file';
                                                        }
                                                        const IconComponent = iconMapping[fileType];

                                                        return (
                                                            <ul className="attachments single inline mb-16" key={index}>
                                                                <li key={index}>
                                                                    <a href={attachment.fileUrl}>
                                                                        <div className="icon-wrapper">
                                                                            <Icon path={IconComponent} color={'currentColor'} size={1} />
                                                                        </div>
                                                                        <div className="info">
                                                                            <Typography variant="body-large" component={'p'}>{attachment.name}</Typography>
                                                                            <Typography variant="body-medium">Size: {(attachment?.size / 1024 / 1024).toFixed(2)} MB</Typography>
                                                                        </div>
                                                                    </a>
                                                                    {selectedFiles &&
                                                                        <Tooltip title="Remove">
                                                                            <button className="icon-button size-small" onClick={() => removeFile(index, setFieldValue, errors)}>
                                                                                <Icon path={mdiClose} color={'currentColor'} size={1} />
                                                                            </button>
                                                                        </Tooltip>}
                                                                </li>
                                                            </ul>
                                                        )
                                                    }
                                                    )
                                                }


                                            </div>



                                            <div className="card p-16 pb-0">
                                                <div className="card-header">
                                                    <h6 className="title">Body</h6>
                                                </div>
                                                <Editor
                                                    label="Enter body content here"
                                                    editorState={editorState}
                                                    onEditorStateChange={(editorState) => {
                                                        setEditorState(editorState);
                                                        setFieldValue('body_text', draftToHtml(convertToRaw(editorState.getCurrentContent())));
                                                        setFormattedBody(JSON.stringify(draftToHtml(convertToRaw(editorState.getCurrentContent()))));
                                                        handleEditorChange(editorState);
                                                    }}

                                                    toolbar={{
                                                        options: ['inline'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'strikethrough'], // Only these options are enabled
                                                        },
                                                    }}
                                                    error={errors.body_text && touched.body_text}
                                                    helperText={(errors.body_text && touched.body_text) && errors.body_text}
                                                />
                                                {errors.body_text && touched.body_text && (
                                                    <span className="easy-edit-validation-error">{errors.body_text}</span>
                                                )}


                                                <Stack direction={"row"} sx={{ marginTop: "-20px", marginBottom: "24px" }} spacing={0.25}>
                                                    <Button variant="text" size="small" className="ml-auto" onClick={handleAddVariable}>
                                                        <Icon path={mdiPlus} color={"currentColor"} size={1} />
                                                        <span>Add Variable</span>
                                                    </Button>
                                                </Stack>
                                                {
                                                    variables && variables.length > 0 &&
                                                    <>
                                                        <Typography variant="body-large-bold" component={'p'}>Variables</Typography>
                                                        <Typography variant="body-medium" component={'p'} className="text-secondary pb-8">Provide sample values for following variables for review purpose only, don’t provide real information.</Typography>
                                                    </>
                                                }

                                                {variables.map((variable, index) => (
                                                    <>
                                                        <Stack direction={"row"} spacing={2} alignItems={"center"} className="mb-24" key={variable.id}>
                                                            <Box component={"span"} sx={{ flexShrink: 0 }}>
                                                                {`{{${variable.id}}}`}
                                                            </Box>
                                                            <TextField
                                                                label="Value"
                                                                value={variable.value}
                                                                //name="body_examples[]"
                                                                name={`body_examples[${index}].value`}  // Dynamic field name
                                                                variant="filled"
                                                                sx={{ flexGrow: 1 }}
                                                                className="no-margin"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleVariableChange(index, e.target.value);
                                                                    replacePlaceholders();
                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors.body_examples && errors.body_examples[index]?.value && touched.body_examples?.[index]?.value}
                                                                helperText={(errors.body_examples && errors.body_examples[index]?.value && touched.body_examples?.[index]?.value) && errors.body_examples[index]?.value}
                                                            />
                                                            {/* <IconButton onClick={() => handleDeleteVariable(index)} color="error">
                                                                <Icon path={mdiTrashCanOutline} size={1} />
                                                            </IconButton> */}
                                                        </Stack>

                                                    </>
                                                ))}
                                            </div>

                                            <div className="card p-16 pb-0">
                                                <div className="card-header">
                                                    <h6 className="title">Footer <Typography className="text-secondary font-normal" variant="body-large">- Optional</Typography></h6>
                                                </div>

                                                <TextField
                                                    label="Footer Text"
                                                    variant="filled"
                                                    name="footer_text"
                                                    value={values?.footer_text || ''}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setFormattedFooter(e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={errors.footer_text && touched.footer_text}
                                                    helperText={(errors.footer_text && touched.footer_text) && errors.footer_text}
                                                />
                                            </div>



                                            <div className="card p-16 pb-0">
                                                <div className="card-header">
                                                    <h6 className="title">Button <Typography className="text-secondary font-normal" variant="body-large">- Optional</Typography></h6>
                                                </div>

                                                <Grid container spacing={2}>
                                                    <Grid item xl={6} xs={12}>
                                                        <TextField
                                                            select
                                                            label="Add New Button"
                                                            variant="filled"
                                                            name="add_new_button"
                                                            value={addNewBtnDefaultValue}
                                                            //onChange={(e) => handleAddButton(e, setFieldValue)}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                handleAddButton(e, setFieldValue);
                                                            }}
                                                            fullWidth
                                                        >
                                                            {/* "None" option */}
                                                            <MenuItem value="">None</MenuItem>
                                                            {Object.keys(buttonLimits).map((key) => (
                                                                <MenuItem key={key} value={key}>
                                                                    {key.replace(/-/g, ' ')
                                                                        .split(' ')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
                                                                        .join(' ')}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>

                                                <TableContainer className="border-top">
                                                    <Table>
                                                        <TableBody>
                                                            {selectedButtons.map((btn, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell className="pl-0 pr-0">
                                                                        <Grid container spacing={2}>
                                                                            {/* Button Type */}
                                                                            <Grid item xs="auto">
                                                                                <TextField
                                                                                    variant="filled"
                                                                                    label="Type Of Action"
                                                                                    name={`buttons.${index}.type_of_button`} // Ensure correct name
                                                                                    select
                                                                                    value={btn.type}
                                                                                    disabled
                                                                                    fullWidth
                                                                                >
                                                                                    {Object.keys(buttonLimits).map((key) => (
                                                                                        <MenuItem key={key} value={key}>
                                                                                            {key.replace(/-/g, ' ')
                                                                                                .split(' ')
                                                                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
                                                                                                .join(' ')}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            </Grid>

                                                                            {/* Button Text */}
                                                                            <Grid item xs>
                                                                                <TextField
                                                                                    variant="filled"
                                                                                    label="Button Text"
                                                                                    name={`buttons.${index}.text`} // Ensure correct name
                                                                                    value={btn.text}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        handleButtonChange(index, "text", e.target.value, setFieldValue)
                                                                                    }}
                                                                                    //onChange={(e) => handleButtonChange(index, "text", e.target.value, setFieldValue)}
                                                                                    onBlur={handleBlur}
                                                                                    disabled={btn.type === "copy-offer-code" || btn.type === 'marketing-opt-out'}
                                                                                    fullWidth
                                                                                    error={Boolean(errors?.buttons?.[index]?.text && touched?.buttons?.[index]?.text)}
                                                                                    helperText={(errors?.buttons?.[index]?.text && touched?.buttons?.[index]?.text) && errors?.buttons?.[index]?.text}
                                                                                />
                                                                            </Grid>

                                                                            {/* Dynamic Fields */}
                                                                            {btn.type === "visit-website" && (
                                                                                <Grid item xs>
                                                                                    <TextField
                                                                                        variant="filled"
                                                                                        label="Website URL"
                                                                                        name={`buttons.${index}.url`} // Ensure correct name
                                                                                        value={btn.url}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleButtonChange(index, "url", e.target.value, setFieldValue)
                                                                                        }}
                                                                                        //onChange={(e) => handleButtonChange(index, "url", e.target.value, setFieldValue)}
                                                                                        error={Boolean(errors?.buttons?.[index]?.url && touched?.buttons?.[index]?.url)}
                                                                                        helperText={(errors?.buttons?.[index]?.url && touched?.buttons?.[index]?.url) && errors?.buttons?.[index]?.url}
                                                                                        fullWidth
                                                                                        title="Enter valida url For Ex. https://abc.com"
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            {btn.type === "call-phone-number" && (
                                                                                <Grid item xs>
                                                                                    <TextField
                                                                                        variant="filled"
                                                                                        label="Phone Number"
                                                                                        name={`buttons.${index}.phone_number`} // Ensure correct name
                                                                                        value={btn.phone_number}
                                                                                        //onChange={(e) => handleButtonChange(index, "url", e.target.value, setFieldValue)}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleButtonChange(index, "phone_number", e.target.value, setFieldValue)
                                                                                        }}
                                                                                        error={Boolean(errors?.buttons?.[index]?.phone_number && touched?.buttons?.[index]?.phone_number)}
                                                                                        helperText={(errors?.buttons?.[index]?.phone_number && touched?.buttons?.[index]?.phone_number) && errors?.buttons?.[index]?.phone_number}
                                                                                        fullWidth
                                                                                        title="Enter phone with country code For Ex. 918888888888"
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            {btn.type === "copy-offer-code" && (
                                                                                <Grid item xs>
                                                                                    <TextField
                                                                                        variant="filled"
                                                                                        label="Offer Code"
                                                                                        name={`buttons.${index}.copy_text`} // Ensure correct name
                                                                                        value={btn.copy_text}
                                                                                        //onChange={(e) => handleButtonChange(index, "copy_text", e.target.value, setFieldValue)}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleButtonChange(index, "copy_text", e.target.value, setFieldValue)
                                                                                        }}
                                                                                        error={Boolean(errors?.buttons?.[index]?.copy_text && touched?.buttons?.[index]?.copy_text)}
                                                                                        helperText={(errors?.buttons?.[index]?.copy_text && touched?.buttons?.[index]?.copy_text) && errors?.buttons?.[index]?.copy_text}
                                                                                        fullWidth
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </TableCell>

                                                                    {/* Delete Button */}
                                                                    <TableCell width={10}>
                                                                        <Tooltip title="Delete">
                                                                            <button type="button" className="icon-button" onClick={() => handleRemoveButton(index, setFieldValue)}>
                                                                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            <Box
                                                sx={{
                                                    display: { xs: 'block', xl: 'none' }
                                                }}
                                                className="card p-16">
                                                <div className="card-header">
                                                    <h6 className="title">Template Preview</h6>
                                                </div>

                                                <div className="message-preview">
                                                    <MessageChatItem
                                                        head={formattedHeader}
                                                        headType={headerType}
                                                        body={viewBody}
                                                        footer={formattedFooter}
                                                        buttons={formattedButtons}
                                                        time={timeOnly}
                                                    />
                                                </div>
                                            </Box>
                                            <div className="card p-16 sticky-bottom">
                                                <div className="card-text">
                                                    <h6 className="title">You can only edit a template once in 24 hours.</h6>
                                                </div>
                                                <Stack direction={'row'} spacing={1}>
                                                    {/* <Button type="submit" name="action" value="save_draft" onClick={() => setSubmitStatus('save_draft')}>Save Draft</Button> */}
                                                    <Button className="ml-auto" type="submit" name="action" value="submit_review" onClick={() => setSubmitStatus('submit_review')}>Submit for Review</Button>
                                                    <Button color="secondary" onClick={e => navigate('/marketing/templates')}>Cancel</Button>
                                                </Stack>
                                            </div>
                                        </Stack>

                                    </form >
                                </>
                            );
                        }}
                    </Formik>
                </div>
                <div className="preview-block">
                    <div className="card p-16">
                        <div className="card-header">
                            <h6 className="title">Template Preview</h6>
                        </div>

                        <div className="message-preview">
                            <MessageChatItem
                                head={formattedHeader}
                                headType={headerType}
                                body={viewBody}
                                footer={formattedFooter}
                                buttons={formattedButtons}
                                time={timeOnly}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default EditTemplate;