import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Popover, Radio, RadioGroup, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCalendarWeekBeginOutline, mdiClose, mdiHistory, mdiSort } from "@mdi/js";
import { Link } from "react-router-dom";
import { capitalize } from "../../../utils/Utils";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

function Orders(props) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const [sortByAnchorEl, setSortByAnchorEl] = useState(null);
    const { showLoader, hideLoader } = useLoader();
    const [orders, setOrderData] = useState([]);
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    // const [sortField, setSortField] = useState();
    // const [sortOrder, setSortOrder] = useState("desc");


    // const handleSortByClick = (event) => {
    //     setSortByAnchorEl(event.currentTarget);
    // };
    // const handleSortByClose = (apply = false) => {
    //     setSortByAnchorEl(null);
    //     if (apply === true) {
    //         getData(currentPage, perPage, sortField, sortOrder);
    //     }else{
    //         setSortField();
    //         setSortOrder();
    //     }
    // };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const sortByOpen = Boolean(sortByAnchorEl);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            getData(currentPage, perPage);
        }
        fetchData();
    }, []);

    const getData = async (currentPage, perPage) => {

        const params = {
            // sort_field: sortField,
            sort_order: "desc",
            per_page: perPage,
        };

        await axiosInstance.get(`api/order?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                // setReloadPage(reloadPage ? false : true);
                setOrderData(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }


    return (
        <>
            <Helmet>
                <title>Orders | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container className='page-action-bar'>
                {/* <Grid item xs={'auto'}>
                        <TextField
                            select
                            defaultValue="All Users"
                            variant="filled"
                            className='size-small no-label no-margin'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' className='clear-filter'>
                                        <IconButton>
                                            <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem key={1} value={'All Users'}>All Users</MenuItem>
                            <MenuItem key={2} value={'User 1'}>User 1</MenuItem>
                            <MenuItem key={3} value={'User 2'}>User 2</MenuItem>
                        </TextField>
                    </Grid> */}

                {/* <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Button variant="contained" color="secondary" size='small' onClick={handleSortByClick}>
                        <span>
                            <Icon path={mdiSort} color="currentColor" size={1} />
                            <span>Sort by</span>
                        </span>
                    </Button>
                    <Popover
                        open={sortByOpen}
                        anchorEl={sortByAnchorEl}
                        onClose={handleSortByClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        classes={{
                            paper: 'utility-menu'
                        }}
                    >
                        <Typography variant='label-small-semibold' className='small-title'>Sort by</Typography>
                        <RadioGroup
                            defaultValue="Order Total"
                            name="sort-by-radio"
                            value={sortField}
                            onChange={(e) => setSortField(e.target.value)}
                        >
                            <FormControlLabel value="final_total" control={<Radio />} label="Order Total" />
                            <FormControlLabel value="payment_status_label" control={<Radio />} label="Payment Status" />
                            <FormControlLabel value="subscription_end_date" control={<Radio />} label="Subscription End Date" />
                        </RadioGroup>

                        <Typography variant='label-small-semibold' className='small-title'>Sort Order</Typography>
                        <RadioGroup
                            defaultValue="Ascending"
                            name="sort-order-radio"
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                        >
                            <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                            <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                        </RadioGroup>

                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Button size='small' className='btn-block' onClick={() => handleSortByClose(true)}>Apply</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button size='small' color="secondary" className='btn-block' onClick={() => handleSortByClose(false)}>Reset</Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid> */}
            </Grid>

            <ul className="primary-list">
                {orders.map((order, index) =>
                    <li key={index + ''}>
                        <Link to={`/order-details/${order?.id}`} className="card">
                            <Typography variant='title-medium-bold' component={'h6'}>{order?.added_by?.name}</Typography>
                            <ul className='inline-details'>
                                <li><Tooltip title="Order Total"><span className="bold">₹{order?.final_total}</span></Tooltip></li>
                                <li><span className="title">Package:</span>{order?.package?.name}</li>
                                <li><span className="title">Subscription User:</span>{order?.user_count}</li>
                                <li>
                                    <Tooltip title="Created Date">
                                        <span>
                                            <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                            {order?.created_at
                                                ? moment.utc(order?.created_at)
                                                    .tz(preTimeZoneKey)
                                                    .format(preferredDateFormat)
                                                : "N/A"}
                                        </span>
                                    </Tooltip>
                                </li>
                                <li>
                                    <Tooltip title="Payment Status">
                                        <span>
                                            {order?.payment_status_label === 'Completed' && <span className="status-badge success">{order?.payment_status_label}</span>}
                                            {order?.payment_status_label === 'Pending' && <span className="status-badge warning">{order?.payment_status_label}</span>}
                                            {order?.payment_status_label === 'Failed' && <span className="status-badge warning">{order?.payment_status_label}</span>}
                                        </span>
                                    </Tooltip>
                                </li>
                            </ul>
                            <ul className='inline-details other-info ml-0'>
                                <li><Tooltip title="Transaction ID"><span>{order?.transaction_id}</span></Tooltip></li>
                                <li><span className="title">Subscription End:</span> {order?.subscription_end_date
                                    ? moment.utc(order?.subscription_end_date)
                                        .tz(preTimeZoneKey)
                                        .format(preferredDateFormat)
                                    : "N/A"}</li>
                                <li>
                                    <Tooltip title="Last Updated">
                                        <span>
                                            <Icon path={mdiHistory} color="currentColor" size={1} />{order?.updated_at
                                                ? moment.utc(order?.updated_at)
                                                    .tz(preTimeZoneKey)
                                                    .format(preferredDateFormat)
                                                : "N/A"}
                                        </span>
                                    </Tooltip>
                                </li>
                            </ul>
                        </Link>
                    </li>
                )}
            </ul>

            {/* <TablePagination
                component="div"
                count={237}
                page={0}
                rowsPerPage={10}
                onPageChange={e => { }}
            /> */}
            {
                orders && orders?.length > 0 &&
                <div className="p-16">
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </div>
            }
        </>
    );
}

export default Orders;