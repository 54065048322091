import { mdiClose, mdiHelp } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Popover, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import AttachmentCenter from "./AttachmentCenter";

function HelpMenu() {
    const [helpMenuAnchorEl, setHelpMenuAnchorEl] = React.useState(null);

    const handleHelpMenuClick = (event) => {
        setHelpMenuAnchorEl(event.currentTarget);
        document.body.classList.add('show-popover-backdrop');
    };

    const handleHelpMenuClose = () => {
        setHelpMenuAnchorEl(null);
        document.body.classList.remove('show-popover-backdrop');
    };

    const helpMenuOpen = Boolean(helpMenuAnchorEl);

    return (
        <>
            <Tooltip title="Help">
                <button type="button" className="icon-button size-medium floating-action" onClick={handleHelpMenuClick}>
                    <Icon path={mdiHelp} color={'currentColor'} size={1} />
                </button>
            </Tooltip>

            <Popover
                open={helpMenuOpen}
                anchorEl={helpMenuAnchorEl}
                onClose={handleHelpMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                classes={{
                    paper: 'help-menu spaced'
                }}
            >
                <div className="menu-title">
                    <Typography variant="headline-small-bold">Write to us</Typography>
                    <Tooltip title="Close">
                        <button type="button" className="icon-button close-btn" onClick={handleHelpMenuClose}>
                            <Icon path={mdiClose} size={1} color={'currentColor'} />
                        </button>
                    </Tooltip>
                </div>

                <TextField
                    label="Subject"
                    variant="filled"
                />

                <TextField
                    label="Description"
                    variant="filled"
                    multiline
                    rows={3}
                />

                <AttachmentCenter attachments={[
                    {
                        name: 'client_meeting.jpg',
                        size: '2.4 MB',
                        type: 'image',
                        fileUrl: '-'
                    },
                    {
                        name: 'meeting_notes.m4a',
                        size: '1.2 MB',
                        type: 'audio',
                        fileUrl: '-'
                    },
                    {
                        name: 'project_proposal.pdf',
                        size: '850 KB',
                        type: 'file',
                        fileUrl: '-'
                    }
                ]} />

                <Button size="large" className="btn-block">Submit</Button>

            </Popover>
        </>
    );
}

export default HelpMenu;