import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import MarketingTab from "./components/MarketingTab";
import { Button, LinearProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { capitalize } from "../../utils/Utils";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import EmptyState from "../../components/common/EmptyState";

function MarketingCampaings(props) {
    const { currentTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [campaigns, setCampaigns] = useState();
    const [errorData, setErrorData] = useState();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [templateId, setTemplateId] = useState('');
    const [contentData, setContentData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );

    const [sortBy, setSortBy] = useState("created_at");

    // State management for search fields
    const [search, setSearch] = useState("");

    //handle checkbox
    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        reloadPage,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ) => {
        const params = {
            search: search,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/whatsapp-campaigns?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setCampaigns(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    const statusClasses = {
        'completed': 'success',
        'failed': 'danger',
        'pending': 'warning',
        'in_progress': 'info',
        'schedule': 'warning',
    };

    const getPercentage = (value, total) => {
        return parseFloat(((value / total) * 100).toFixed(1));
    };

    const formatString = (str) => {
        return str
            .replace(/_/g, ' ')  // Replace underscores with spaces (optional)
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => match.toUpperCase()); // Capitalize first letter of each word
    };

    return (
        <>
            <Helmet>
                <title>Campaigns | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <MarketingTab />

            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Campaigns</h6>
                    <Button size="small" className="action" onClick={e => navigate('/marketing/add-campaign')}>
                        <span>
                            <Icon path={mdiPlus} color={'currentColor'} size={1} />
                            <span>Add Campaign</span>
                        </span>
                    </Button>
                </div>


                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="long-text">Title</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Sent</TableCell>
                                <TableCell>Delivered</TableCell>
                                <TableCell>Read</TableCell>
                                <TableCell>Failed</TableCell>
                                {/* <TableCell width={10}></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaigns && campaigns.length > 0 && campaigns.map((campaign, index) => {
                                const statusClass = statusClasses[campaign.status] || 'danger';
                                return (
                                    <TableRow key={index} className={(campaign?.status !== 'schedule' ? "row-click " : '') + (campaign.read > 0 ? "text-success" : '')} onClick={e => campaign?.status !== 'schedule' ? navigate('/marketing/campaign-details/' + campaign?.id) : ''}>
                                        <TableCell>{campaign.message_title}</TableCell>
                                        <TableCell>
                                            <Tooltip title={campaign?.schedule_date ? moment.utc(campaign.schedule_date).tz(preTimeZoneKey).format(preferredDateFormat) : formatString(campaign?.status)}>
                                                <span className={`status-badge ${statusClass}`}>
                                                    {formatString(campaign?.status)}
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell><NumericFormat value={campaign.total_recipients} displayType="text" thousandsGroupStyle="lakh" thousandSeparator="," /></TableCell>

                                        <TableCell>
                                            <Stack direction="row" alignItems="baseline" justifyContent={"space-between"} spacing={2}>
                                                <NumericFormat value={campaign.sent_count} displayType="text" thousandsGroupStyle="lakh" thousandSeparator="," />
                                                {campaign.sent_count > 0 && <Typography variant="body-medium" className="text-secondary">{getPercentage(campaign.sent_count, campaign.total_recipients)}%</Typography>}
                                            </Stack>
                                            {campaign.sent_count > 0 && <LinearProgress className="progress mt-4" variant="determinate" value={getPercentage(campaign.sent_count, campaign.total_recipients)} />}
                                        </TableCell>

                                        <TableCell>
                                            <Stack direction="row" alignItems="baseline" justifyContent={"space-between"} spacing={2}>
                                                <NumericFormat value={campaign.delivered_count} displayType="text" thousandsGroupStyle="lakh" thousandSeparator="," />
                                                {campaign.delivered_count > 0 && <Typography variant="body-medium" className="text-secondary">{getPercentage(campaign.delivered_count, campaign.total_recipients)}%</Typography>}
                                            </Stack>
                                            {campaign.delivered_count > 0 && <LinearProgress className="progress mt-4" variant="determinate" value={getPercentage(campaign.delivered_count, campaign.total_recipients)} />}
                                        </TableCell>

                                        <TableCell>
                                            <Stack direction="row" alignItems="baseline" justifyContent={"space-between"} spacing={2}>
                                                <NumericFormat value={campaign.read_count} displayType="text" thousandsGroupStyle="lakh" thousandSeparator="," />
                                                {campaign.read_count > 0 && <Typography variant="body-medium">{getPercentage(campaign.read_count, campaign.total_recipients)}%</Typography>}
                                            </Stack>
                                            {campaign.read_count > 0 && <LinearProgress className="progress mt-4" variant="determinate" value={getPercentage(campaign.read_count, campaign.total_recipients)} />}
                                        </TableCell>

                                        <TableCell>
                                            <Stack direction="row" alignItems="baseline" justifyContent={"space-between"} spacing={2}>
                                                <NumericFormat value={campaign.failed_count} displayType="text" thousandsGroupStyle="lakh" thousandSeparator="," />
                                                {campaign.failed_count > 0 && <Typography variant="body-medium">{getPercentage(campaign.failed_count, campaign.total_recipients)}%</Typography>}
                                            </Stack>
                                            {campaign.failed_count > 0 && <LinearProgress className="progress mt-4 danger" variant="determinate" value={getPercentage(campaign.failed_count, campaign.total_recipients)} />}
                                        </TableCell>

                                        {/* <TableCell className="pr-0">
                                            {campaign.status === 'Draft' && <Tooltip title="Delete">
                                                <button type="button" className='icon-button'>
                                                    <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                </button>
                                            </Tooltip>}
                                        </TableCell> */}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {(campaigns?.length == 0) &&
                    <EmptyState type={'campaign'} hideActionBtn />
                }

                {
                    campaigns && campaigns.length > 0 &&
                    <div className="pt-16">
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPage}
                            rowsPerPage={perPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default MarketingCampaings;