import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../App";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiMinus, mdiPlus } from "@mdi/js";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { PaymentDone3D } from "../../../../assets/images";
import useAxios from "../../../../hooks/useAxios";
import { useLoader } from "../../../../components/context/LoaderContext";
import { useSnackbar } from "../../../../components/context/SnackbarContext";
import { displayCurrencySymbol, displayDate, getMainDomain } from "../../../../utils/Utils";
import { useSelector } from "react-redux";

//=========Stripe Payment data============//
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./stripe/CheckoutForm";
import moment from "moment-timezone";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function ManageBasicUpgrade(props) {
    const axiosInstance = useAxios();
    const hostname = getMainDomain()
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const { currentTheme } = useContext(ThemeContext);
    const [currentStep, setCurrentStep] = React.useState('manage-user-count');
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [tenantUserCount, setTenantUserCount] = useState(0);
    const [packageDetail, setPackageDetail] = useState([]);
    const [displayPlanDetail, setDisplayPlanDetail] = useState([]);
    const [finalPlanDetail, setFinalPlanDetail] = useState([]);
    const [preferredCurrencyData, setPreferredCurrencyData] = useState({});
    //for stripe
    const [payAbleAmount, setPayAbleAmount] = useState(0);

    useEffect(() => {
        async function fetchData() {
            if (props?.planId) {
                getData();
            }
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/subscription-package-detail/' + props?.planId).then((response) => {
            if (response.status === 200) {
                setTenantUserCount(parseInt(response?.data?.data?.userCountDetail?.planUserCount - 1))
                setPackageDetail(response?.data?.data?.package)
                hideLoader();
                if (hostname === '.solvcrm.co') {
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                } else {
                    //setPreferredCurrencyData({ 'currencyCode': 'INR', 'currencySymbol': '₹' });
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                }
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }


    const updatePlan = async () => {
        const data = {
            id: props?.planId,
            required_user: tenantUserCount
        }
        showLoader();
        await axiosInstance.post('api/subscription-update-package', data).then((response) => {
            if (response.status === 200) {
                setDisplayPlanDetail(response?.data?.data?.displayPlanDetail)
                setPayAbleAmount(response?.data?.data?.displayPlanDetail?.final_amount)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    const extraData = {
        'userCount': parseInt((displayPlanDetail?.total_user) + 1),
        'plan_id': props?.planId,
        'type': 'after_free_trial',
    }

    // =========Start::Manage Stripe checkout process=============//
    const handlePaymentRes = async (res) => {
        if (res?.response) {
            showSnackbar(res?.message, "success");
            getSubscriptionDetail()
        } else {
            hideLoader()
            showSnackbar(res?.message, "error");
        }
    }
    // =========END::Manage Stripe checkout process=============//

    const getSubscriptionDetail = async () => {
        await axiosInstance.get(`api/getsubscription-detail`).then((response) => {
            if (response.status === 200) {
                setFinalPlanDetail(response?.data?.data?.subscriptionDetails)
                setCurrentStep('payment-success')
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    return (
        <>

            {currentStep === 'manage-user-count' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Select the Number of Users</Typography>
                        <p className="text-secondary">Your current setup includes 1 user. Add more users to match your team’s size. Once finalized, proceed to confirm and complete your payment.</p>
                    </div>
                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Users to Be Included in Billing</Typography>
                    <Grid item sx={{ marginBottom: 4 }}>
                        <TextField
                            variant="filled"
                            value={tenantUserCount}
                            onChange={(e) => setTenantUserCount(e.target.value)}
                            className="no-label text-center number-stepper"
                            InputProps={{
                                startAdornment: (
                                    <IconButton onClick={() => {
                                        const newCount = Math.max(tenantUserCount - 1, 1)
                                        setTenantUserCount(newCount)
                                    }}>
                                        <Icon path={mdiMinus} color={'currentColor'} size={'20px'} />
                                    </IconButton>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => setTenantUserCount(tenantUserCount + 1)}>
                                        <Icon path={mdiPlus} color={'currentColor'} size={'20px'} />
                                    </IconButton>
                                ),

                            }}
                        />
                    </Grid>
                    <div className="action-block">
                        <Button variant="text" onClick={() => props.onReset()}>Back</Button>
                        <Button onClick={e => { setCurrentStep('review-and-complate'); updatePlan() }}>
                            <span>
                                <span>Next</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }



            {
                currentStep === 'review-and-complate' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Review and Complete Your Payment</Typography>
                        <p className="text-secondary">Here’s a summary of your payment details. Please review everything carefully and provide your payment information to complete your subscription.</p>
                    </div>
                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Order Summary</Typography>
                    <div className="item-summary">
                        <ul>
                            <li>
                                <Typography component={'p'}>Plan</Typography>
                                <Typography variant="body-large-medium">{displayPlanDetail?.plan_name} Plan</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Total Billable Users ({displayPlanDetail?.total_user} Users + 1 Tenant)</Typography>
                                <Typography variant="body-large-medium">{parseInt((displayPlanDetail?.total_user) + 1)} Users</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Cost Per User ({packageDetail?.duration_type})</Typography>
                                <Typography variant="body-large-medium">{displayPlanDetail?.cost_user ? preferredCurrencyData.currencySymbol + (Math.round(displayPlanDetail.cost_user * 100) / 100).toFixed(2) : '0.00'}</Typography>
                            </li>
                        </ul>
                        <div className="highlight">
                            <Typography variant="title-large" component={'p'}>Amount Payable</Typography>
                            <Typography variant="title-large-semibold">{displayPlanDetail?.final_amount ? preferredCurrencyData.currencySymbol + (Math.round(displayPlanDetail.final_amount * 100) / 100).toFixed(2) : '0.00'}</Typography>
                        </div>
                    </div>

                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Provide Card Details</Typography>

                    <div className="row s-box">
                        <React.Fragment>
                            <div className="col-md-12 order-md-1">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        amount={(Math.round(payAbleAmount * 100) / 100).toFixed(2)}
                                        onReset={(e, data) => {
                                            if (data && data === 'cancel') {
                                                props.onReset();
                                            }
                                        }}
                                        handlePaymentRes={handlePaymentRes}
                                        extraData={extraData}
                                    />
                                </Elements>
                            </div>
                        </React.Fragment>
                    </div>
                </>
            }




            {
                currentStep === 'payment-success' && finalPlanDetail?.planName &&
                <>
                    <div className="block-title">
                        {currentTheme?.name === 'solvcrm' && <img src={PaymentDone3D} alt="Check" />}
                        <Typography variant="display-small-bold" component={'h1'}>Payment Successful!</Typography>
                        <p className="text-secondary">Thank you for your payment! Your subscription is now active, and you can continue using SolvCRM to manage your business efficiently. Explore all the features and get the most out of your plan.</p>
                    </div>

                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Plan Details</Typography>

                    <div className="item-summary">
                        <ul>
                            <li>
                                <Typography component={'p'}>Plan</Typography>
                                <Typography variant="body-large-medium">{finalPlanDetail?.planName} Plan</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Total Billable Users</Typography>
                                <Typography variant="body-large-medium">{finalPlanDetail?.totalBillableUser} Users</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Next Billing Date</Typography>
                                <Typography variant="body-large-medium">{finalPlanDetail?.nextBillingData
                                    ? displayDate(moment.utc(finalPlanDetail.nextBillingData).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                    : "N/A"}</Typography>
                            </li>
                            <li>
                                <Typography component={'p'}>Next Bill Amount ({finalPlanDetail?.paymentCycle || 'monthly'})</Typography>
                                <Typography variant="body-large-medium">
                                    {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                    {finalPlanDetail?.NextBillAmount ? (Math.round(finalPlanDetail?.NextBillAmount * 100) / 100).toFixed(2) : 'N/A'}
                                </Typography>
                            </li>
                        </ul>
                    </div>
                    <div className="action-block mt-0">
                        <Button onClick={() => window.location.reload()}>
                            <span>
                                <span>View Subscription Details</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }
        </>
    );
}

export default ManageBasicUpgrade;