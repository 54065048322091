import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import MarketingTab from "./components/MarketingTab";
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import { mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import useAxios from "../../hooks/useAxios";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from '../../components/context/SnackbarContext';
import { useSelector } from "react-redux";
import moment from "moment-timezone";

function MarketingContactLists(props) {
    const navigate = useNavigate();
    const { showLoader, hideLoader, loading } = useLoader();
    const axiosInstance = useAxios();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [reloadPage, setReloadPage] = useState(false);
    const [contactLists, setContactLists] = useState();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [totalCount, setTotalCount] = useState(0);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        reloadPage,
    ]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const getData = async (
        currentPage,
        perPage,
    ) => {
        const params = {
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/whatsapp-contacts?page=${currentPage + 1}`, { params })
            .then((response) => {
                if (response.status === 200) {
                    setContactLists(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                    hideLoader();
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        navigate("/dashboard");
                    }
                }
            });
    };

    const deleteContactslist = async (ContactId) => {
        const params = {
            id: ContactId,
        };

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        try {
                            showLoader()
                            const response = await axiosInstance.delete('api/whatsapp-contacts/destroy', { params });
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                hideLoader();
                                setReloadPage(reloadPage ? false : true);
                            }
                        } catch (error) {
                            if (error.response) {
                                hideLoader();
                                if (error.response.status === 400) {
                                    showSnackbar(error.response.data.message, "warning");
                                }
                            }
                        }
                    },
                    cancel: () => {
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact Lists | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <MarketingTab />

            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Contact Lists</h6>
                    <Button size="small" className="action" onClick={e => navigate('/marketing/add-contact-list')}>
                        <span>
                            <Icon path={mdiPlus} color={'currentColor'} size={1} />
                            <span>Add Contact List</span>
                        </span>
                    </Button>
                </div>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="long-text">Title</TableCell>
                                <TableCell>Total Contacts</TableCell>
                                <TableCell>Subscribed</TableCell>
                                <TableCell>Unsubscribed</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell width={10}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {contactLists && contactLists.length > 0 && (
                            <TableBody>
                                {contactLists.map((contactList, index) => (
                                    <TableRow key={index}>
                                        <TableCell className="row-click" onClick={e => navigate('/marketing/contact-list-details/' + contactList?.id)}>{contactList.name}</TableCell>
                                        <TableCell className="row-click" onClick={e => navigate('/marketing/contact-list-details/' + contactList?.id)}>{contactList.total_contact_count}</TableCell>
                                        <TableCell className="row-click" onClick={e => navigate('/marketing/contact-list-details/' + contactList?.id)}>{contactList.subscribe_count}</TableCell>
                                        <TableCell className="row-click" onClick={e => navigate('/marketing/contact-list-details/' + contactList?.id)}>{contactList.un_subscribe_count}</TableCell>
                                        <TableCell className="row-click" onClick={e => navigate('/marketing/contact-list-details/' + contactList?.id)}>{contactList?.updated_at ? moment.utc(contactList.updated_at).tz(preTimeZoneKey).format(preferredDateFormat) : "N/A"}</TableCell>
                                        <TableCell className="pr-0">
                                            <Stack direction="row">
                                                <Tooltip title="Edit" >
                                                    <button type="button" className='icon-button' onClick={e => { navigate('/marketing/contact-list-details/' + contactList?.id); }}>
                                                        <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <button type="button" className='icon-button' onClick={() => deleteContactslist(contactList.id)}>
                                                        <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                    </button>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {
                    contactLists && contactLists?.length > 0 &&
                    <div className="pt-16">
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPage}
                            rowsPerPage={perPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </div>
                }
            </div>

        </>
    );
}

export default MarketingContactLists;