import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import { LogoColorFull, LogoFullXlFinancialDark, PublicAbstractArt, UserAvatar } from "../../assets/images";
import { Box, Button, Grid, Stack, TextField, Tooltip, Typography, FormControlLabel, capitalize } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCardAccountPhoneOutline, mdiEmailOutline, mdiFacebook, mdiInstagram, mdiLinkedin, mdiPhoneOutline, mdiShareVariantOutline, mdiWhatsapp, mdiYoutube } from "@mdi/js";
import { CallUser, Twitter } from "../../components/common/Icons";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

function PublicProfile(props) {
    const { id } = useParams();
    const ProfilId = id
    const currentTheme = useContext(ThemeContext);
    const { showLoader, hideLoader } = useLoader();
    const axiosInstance = useAxios();
    const [profileData, setProfileData] = useState();
    const { showSnackbar } = useSnackbar();

    const shareContent = (title, text, url) => {
        if (navigator.share) {
            // Modern browsers with Web Share API
            navigator.share({
                title: title,
                text: text,
                url: url
            }).then(() => {
                showSnackbar('Shared successfully.', "success");
            }).catch((error) => {
                showSnackbar('Error on sharing.', "error");
            });
        } else {
            // Fallback for older browsers (manual copy + alert box fallback)
            const fallbackText = `${title}\n${text}\n${url}`;

            // Create a temporary textarea to copy text to clipboard
            const textArea = document.createElement('textarea');
            textArea.value = fallbackText;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                showSnackbar('Link copied to clipboard. You can now share it manually.', "warning");
            } catch (err) {
                showSnackbar('Unable to copy. Please copy manually', "warning");
            }
            document.body.removeChild(textArea);
        }
    }

    useEffect(() => {
        document.body.classList.add('public-profile-page');
        async function fetchData() {
            getData();
        }
        fetchData();
    }, []);


    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/public-profile/' + ProfilId).then((response) => {
            if (response.status === 200) {
                setProfileData(response?.data?.data?.record);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    const ContactNumber = profileData?.whatsapp_number ?? profileData?.phone1;

    const createVCard = () => {
        const vcard = `BEGIN:VCARD
                        VERSION:4.0
                        FN:${profileData?.name}
                        N:${profileData?.last_name};${profileData?.first_name};${''};; 
                        ORG:${profileData?.tenant_name}
                        TITLE:${'SolvCRM'}
                        PHOTO;TYPE=JPEG;ENCODING=b:[${profileData?.profile_image}]
                        TEL;TYPE=WORK,VOICE:${ContactNumber}
                        ADR;TYPE=WORK,PREF:;;${profileData?.address1 + profileData?.address2};${profileData?.city};${profileData?.state};${profileData?.zipcode};${profileData?.country_name[0]?.country_name}
                        EMAIL:${profileData?.email}
                        BDAY:''
                        URL:${profileData?.profile_url}
                        ROLE:''
                        X-TWITTER:${profileData?.twitter_profile_url}
                        X-FACEBOOK:${profileData?.facebook_profile_url}
                        X-LINKEDIN:${profileData?.linkedin_profile_url}
                        X-INSTAGRAM:${profileData?.instagram_profile_url}
                        X-YOUTUBE:${profileData?.youtube_profile_url}
                        REV:${new Date().toISOString()}
                        END:VCARD`;
        downloadToFile(vcard, 'vcard.vcf', 'text/vcard');
    };
    // Function to handle file download
    const downloadToFile = (content, filename, contentType) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    };


    const [defaultValue, setDefaultValue] = useState({
        name: '',
        mobile: '',
        email: '',
        message: '',
    });

    const validationSchema = yup.object().shape({
        name: yup.string().required('Enter Name').max(255, 'Must be at most 255 characters'),
        mobile: yup.string().required('Enter Mobile Number').max(25, 'Phone must be at most 25 characters'),
        email: yup.string().required('Enter Email').email('Please enter valid email address').min(2, 'Must be at least 2 characters').max(100, 'Must be at most 100 characters'),
        message: yup.string().max(1500, 'Must be at most 1500 characters'),
    });

    const handleSubmit = async (values, onSubmitProps) => {

        const data = {
            uuid: ProfilId,
            name: values.name,
            mobile: values.mobile,
            email: values.email,
            message: values?.message,
        };

        await axiosInstance.post(`/api/public-getin-touch`, data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onSubmitProps.resetForm();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });

                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Public Profile | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <section className="public-profile">
                <div className="inner">
                    {currentTheme?.name !== 'xl-financial' && <img src={LogoColorFull} alt="SolvCRM" className="logo" />}
                    {currentTheme?.name === 'xl-financial' && <img src={LogoFullXlFinancialDark} alt="xl Financial" className="logo" />}

                    <Box sx={{
                        marginBottom: {
                            md: 3,
                            xs: 2
                        }
                    }}>
                        <div className="card profile-card">
                            <img src={PublicAbstractArt} alt="Abstract Art" className="abstract-art" />
                            <div className="profile-pic">
                                <img src={profileData?.profile_image} alt="User" />
                                {/* <AvatarInitials name={'Laura Johnson'} invert /> */}
                            </div>
                            <Typography variant="headline-small-bold" className="pb-4" component={'h6'}>{profileData?.name}</Typography>
                            <ul className="profile-info">
                                {profileData?.whatsapp_number && (
                                    <li>
                                        <a href={`tel:${profileData?.whatsapp_number}`} title="Call Now">
                                            <Icon path={mdiPhoneOutline} color="currentColor" size={0.83334} />
                                            <Typography variant="body-medium-medium" className="text-secondary">{profileData?.whatsapp_number}</Typography>
                                        </a>
                                    </li>
                                )}
                                {profileData?.name && (
                                    <li>
                                        <a href={`mailto:${profileData?.name}`} title="Email Now">
                                            <Icon path={mdiEmailOutline} color="currentColor" size={0.83334} />
                                            <Typography variant="body-medium-medium" className="text-secondary">{profileData?.email}</Typography>
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <hr className="separator" />
                            <Tooltip title="Share">
                                <button className="icon-button size-small share-btn" onClick={e => {
                                    shareContent(`${profileData?.name}`, '', 'https://app.solvcrm.co/pp/546784564');
                                }}>
                                    <Icon path={mdiShareVariantOutline} color='currentColor' size={1} />
                                </button>
                            </Tooltip>
                            <Stack direction={'row'} spacing={1}>
                                <Button size="small" onClick={() => createVCard()}>
                                    <span>
                                        <Icon path={mdiCardAccountPhoneOutline} color="currentColor" size={0.83334} />
                                        <span>Download VCard</span>
                                    </span>
                                </Button>
                                <Button size="small" color="secondary" className="ml-auto" onClick={e => {
                                    window.open('https://wa.me/' + profileData?.whatsapp_number, '_blank');
                                }}>
                                    <span>
                                        <Icon path={mdiWhatsapp} color="currentColor" size={0.83334} />
                                        <span>Chat</span>
                                    </span>
                                </Button>
                            </Stack>
                        </div>
                        <div className="card social-card">
                            <Stack direction={{ md: 'row', xs: 'column' }} spacing={1} alignItems={'center'}>
                                <Typography variant="body-large-semibold" component={'p'}>Follow <span className="text-brand">{profileData?.name}</span> on Social Media</Typography>
                                <ul className="social-btns">
                                    {profileData?.linkedin_profile_url && (
                                        <li>
                                            <Tooltip title="Follow on Linkedin">
                                                <a href={profileData?.linkedin_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                    <Icon path={mdiLinkedin} color='currentColor' size={0.91667} />
                                                </a>
                                            </Tooltip>
                                        </li>
                                    )}
                                    {profileData?.facebook_profile_url && (
                                        <li>
                                            <Tooltip title="Follow on Facebook">
                                                <a href={profileData?.facebook_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                    <Icon path={mdiFacebook} color='currentColor' size={0.91667} />
                                                </a>
                                            </Tooltip>
                                        </li>
                                    )}
                                    {profileData?.instagram_profile_url && (
                                        <li>
                                            <Tooltip title="Follow on Instagram">
                                                <a href={profileData?.instagram_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                    <Icon path={mdiInstagram} color='currentColor' size={0.91667} />
                                                </a>
                                            </Tooltip>
                                        </li>
                                    )}
                                    {profileData?.youtube_profile_url && (
                                        <li>
                                            <Tooltip title="Follow on YouTube">
                                                <a href={profileData?.youtube_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                    <Icon path={mdiYoutube} color='currentColor' size={0.91667} />
                                                </a>
                                            </Tooltip>
                                        </li>
                                    )}
                                    {profileData?.twitter_profile_url && (
                                        <li>
                                            <Tooltip title="Follow on Twitter">
                                                <a href={profileData?.twitter_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                    <Twitter />
                                                </a>
                                            </Tooltip>
                                        </li>
                                    )}
                                </ul>
                            </Stack>
                        </div>
                    </Box>

                    <Box sx={{
                        padding: {
                            md: 3,
                            xs: 2
                        }
                    }} className="card">
                        <Stack direction={'row'} spacing={{ md: 2, xs: 1.5 }} alignItems={'flex-start'}>
                            <div className="pt-2">
                                <CallUser />
                            </div>
                            <div>
                                <Typography variant="title-medium-large-semibold" className="pb-2" component={'h6'}>Get in Touch with <strong className="text-brand">{profileData?.name}</strong></Typography>
                                <Typography variant="body-medium" component={'p'} className="text-secondary">Have a question or collaboration opportunity? Fill out the form, and Laura Johnson will get back to you soon!</Typography>
                            </div>
                        </Stack>

                        <hr className="separator mb-20" />

                        <Formik
                            initialValues={defaultValue}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        //enableReinitialize
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <TextField
                                                label="Your Name"
                                                variant="filled"
                                                name="name"
                                                value={values?.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.name && touched.name}
                                                helperText={(errors.name && touched.name) && errors.name}
                                            />
                                            <Grid container columnSpacing={2}>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Your Mobile Number"
                                                        variant="filled"
                                                        name="mobile"
                                                        value={values?.mobile}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.mobile && touched.mobile}
                                                        helperText={(errors.mobile && touched.mobile) && errors.mobile}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        label="Your Email"
                                                        variant="filled"
                                                        name="email"
                                                        value={values?.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.email && touched.email}
                                                        helperText={(errors.email && touched.email) && errors.email}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <TextField
                                                label="Message"
                                                variant="filled"
                                                name="message"
                                                value={values?.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.message && touched.message}
                                                helperText={(errors.message && touched.message) && errors.message}
                                            />
                                            <Button size="small" type="submit">Send Message</Button>
                                        </form>
                                    </>
                                );
                            }}
                        </Formik>

                    </Box>
                </div>
            </section>
        </>
    );
}

export default PublicProfile;