import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const PercentageFormatCustom = React.forwardRef(function PercentageFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            // inputMode="numeric"
            // decimalScale={0}
            // isAllowed={(values) => {
            //     const { floatValue } = values;
            //     if (floatValue === undefined) return true;
            //     return floatValue > 0 && floatValue <= 100;
            // }}
            inputMode="decimal"
            decimalScale={2} // Allow up to 2 decimal places
            //fixedDecimalScale={true} // Ensures decimal places are maintained (Add zero at end on value ex. 2.5=>2.50)
            allowNegative={false} // Prevents negative values
            isAllowed={(values) => {
                const { floatValue } = values;
                if (floatValue === undefined) return true;
                return floatValue >= 0 && floatValue <= 100; // Ensure value is between 0 and 100
            }}
        />
    );
});

PercentageFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default PercentageFormatCustom;

// const AmountFormatCustom = React.forwardRef(
//     function NumericFormatCustom(props, ref) {
//         const { onChange, ...other } = props;

//         return (
//             <NumericFormat
//                 {...other}
//                 getInputRef={ref}
//                 onValueChange={(values) => {
//                     onChange({
//                         target: {
//                             name: props.name,
//                             value: values.value,
//                         },
//                     });
//                 }}
//                 inputMode="numeric"
//                 thousandsGroupStyle="lakh"
//                 thousandSeparator=","
//                 decimalSeparator="."
//                 decimalScale={2}
//                 prefix={currencySymbol}
//             />
//         );
//     },
// );

// AmountFormatCustom.propTypes = {
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
// };
