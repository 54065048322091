import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { CheckoutArt } from "../../../assets/images";
import BigRadio from "../../../components/common/BigRadio";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiCheck, mdiOpenInNew } from "@mdi/js";
import ManagePremiumUpgrade from "./components/ManagePremiumUpgrade";
import ManageFreeUpgrade from "./components/ManageFreeUpgrade";
import ManageBasicUpgrade from "./components/ManageBasicUpgrade";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { useSnackbar } from "../../../components/context/SnackbarContext";

function UpgradePlan({ onReset, plan }) {
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();

    const [currentPlan, setCurrentPlan] = React.useState(plan);
    const [showNextStep, setShowNextStep] = React.useState(false);
    const [packagePlan, setPackagePlan] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState();

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/subscription-package').then((response) => {
            if (response.status === 200) {
                setPackagePlan(response?.data?.data?.packages);
                setSelectedPlanId(3);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }


    useEffect(() => {
        if (plan === 'free' || plan === 'premium') {
            setShowNextStep(true);
        }
    }, [plan]);

    return (<>
        <div className="card">
            <section className="checkout-layout inner-page">
                <div className="form-block">






                    {plan === 'basic' && !showNextStep &&
                        <>
                            <div className="block-title">
                                <Typography variant="display-small-bold" component={'h1'}>Ready to Unlock More Features?</Typography>
                                <p className="text-secondary">Take your SolvCRM experience to the next level. Upgrade to a plan that matches your business needs and gain access to advanced tools, enhanced collaboration, and more powerful features. Choose your plan and start growing today!</p>
                            </div>
                            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                                {
                                    packagePlan?.length > 0 && packagePlan.map((packages, index) => {
                                        const parsedFeatures = JSON.parse(packages?.plan_feature);
                                        const features = Object.values(parsedFeatures)[0] || [];

                                        return < Grid item xs={6} key={index} >
                                            <BigRadio
                                                title={packages?.name}
                                                description={packages?.description}
                                                input={<input type="radio" value={packages?.slug} name="plan-radio" checked={currentPlan === packages?.slug} onChange={e => { setCurrentPlan(e.target.value); setSelectedPlanId(packages?.id) }} />}
                                                content={
                                                    <>
                                                        <ul className="plan-list">
                                                            {features.map((feature, index) => (
                                                                <li key={index}>
                                                                    <Icon path={mdiCheck} color={'currentColor'} size={0.666667} className="icon" />
                                                                    <span>{feature}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                }
                                            />
                                        </Grid>
                                    }
                                    )
                                }
                            </Grid>

                            <a href="https://www.solvcrm.co/crm-software-pricing/" target="_blank" rel="noreferrer" className="primary-link">
                                <Icon path={mdiOpenInNew} color={'currentColor'} size={0.833334} />
                                <Typography variant="body-medium-semibold">Compare Plans and Pricing</Typography>
                            </a>

                            <div className="action-block">
                                <Button variant="text" onClick={onReset}>Cancel Upgrade</Button>
                                <Button onClick={(e) => {
                                    setShowNextStep(true);
                                }}>
                                    <span>
                                        <span>Next</span>
                                        <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                    </span>
                                </Button>
                            </div>
                        </>
                    }





                    {currentPlan === 'basic' && showNextStep &&
                        <>
                            <ManageBasicUpgrade plan={plan} planId={selectedPlanId} onReset={(e) => {
                                setShowNextStep(false);
                                setCurrentPlan('basic')
                            }} />
                        </>
                    }



                    {(plan === 'premium' || currentPlan === 'premium') && showNextStep &&
                        <>
                            <ManagePremiumUpgrade onReset={(e, data) => {
                                if (data && data === 'cancel-downgrade') {
                                    onReset(e);
                                }
                                setShowNextStep(false);
                                setCurrentPlan('free')
                            }} />
                        </>
                    }

                    {plan === 'free' && showNextStep &&
                        <>
                            <ManageFreeUpgrade plan={plan} planId={selectedPlanId} onReset={(e, data) => {
                                if (data && data === 'cancel-downgrade') {
                                    onReset(e);
                                }
                                setShowNextStep(false);
                                setCurrentPlan('free')
                            }} />
                        </>
                    }

                </div>
                <div className="image-block">
                    <img src={CheckoutArt} alt="Abstract Art" />
                </div>
            </section>
        </div>
    </>);
}

export default UpgradePlan;