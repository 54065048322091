import { mdiAlertCircleOutline, mdiCheckAll, mdiClockOutline, mdiContentCopy, mdiFileDocument, mdiFilePdfBox, mdiImage, mdiOpenInNew, mdiPhone, mdiPlay, mdiReply, mdiVideo } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function MessageChatItem(props) {
    const iconMapping = {
        image: mdiImage,
        video: mdiVideo,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
    };

    let fileType = '';
    if (props?.headType === 'document' && props?.head?.fileName) {
        const fileExtension = props?.headType === 'document' && props?.head?.fileName && props?.head?.fileName.split('.').pop().toLowerCase();

        if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            fileType = 'image';
        } else if (['mp3', 'wav', 'ogg', 'mp4'].includes(fileExtension)) {
            fileType = 'video';
        } else if (['pdf'].includes(fileExtension)) {
            fileType = 'pdf';
        } else {
            fileType = 'file';
        }
    }

    const IconComponent = iconMapping[fileType];

    return (
        <>
            <div className={"message" + (props.sent ? " sent " : '')}>
                <div className="block">
                    {props?.head && props.headType && props.headType !== 'none' &&
                        <div className="head">
                            {props.headType === 'text' && props?.head?.head}
                            {props.headType === 'image' && props?.head?.fileLink &&
                                <a href={props?.head?.fileLink} target="_blank" rel="noreferrer">
                                    <img src={props?.head?.fileLink} alt="" />
                                </a>
                            }
                            {props.headType === 'video' && props?.head?.fileLink &&
                                <a href={props?.head?.fileLink} target="_blank" rel="noreferrer">
                                    <video src={props?.head?.fileLink}></video>
                                    <span className="video-button">
                                        <Icon path={mdiPlay} color={'currentColor'} size={1.333334} />
                                    </span>
                                </a>
                            }
                            {props.headType === 'document' && props?.head?.fileLink &&
                                <a href={props?.head?.fileLink} target="_blank" rel="noreferrer" className="document">
                                    <Icon path={IconComponent} color={'currentColor'} size={1.333334} />
                                    <span>{props?.head?.fileName}</span>
                                </a>
                            }
                        </div>
                    }
                    {props?.body && <div className="body" dangerouslySetInnerHTML={{ __html: props.body }} />}
                    {!props?.body && !props?.hideBody && <div className="body">Type in your message</div>}
                    {props?.footer && <div className="footer">{props.footer}</div>}
                    <div className="time">
                        <span>{props.time ? props.time : '12:42 pm'}</span>
                        {props?.delivered && <Icon path={mdiCheckAll} color={'currentColor'} size={0.6666667} />}
                        {props?.read && <Icon path={mdiCheckAll} color={'#34B7F1'} size={0.6666667} />}
                        {props?.pending && <Icon path={mdiClockOutline} color={'currentColor'} size={0.6666667} />}
                        {props?.failed && <Icon path={mdiAlertCircleOutline} className="text-danger" color={'currentColor'} size={0.6666667} />}
                    </div>
                    {props?.buttons && props?.buttons?.length > 0 &&
                        <div className="button-wrapper">
                            {props.buttons.map((button, index) => {
                                switch (button.type) {
                                    case 'URL':
                                    case 'link':
                                    case 'visit-website':
                                        return (
                                            <div className="button" key={index}>
                                                <Icon path={mdiOpenInNew} color={'currentColor'} size={0.75} />
                                                <span>{button.text}</span>
                                            </div>
                                        );
                                    case 'PHONE_NUMBER':
                                    case 'PHONE NUMBER':
                                    case 'call-phone-number':
                                        return (
                                            <div className="button" key={index}>
                                                <Icon path={mdiPhone} color={'currentColor'} size={0.75} />
                                                <span>{button.text}</span>
                                            </div>
                                        );
                                    case 'offer':
                                        return (
                                            <div className="button" key={index}>
                                                <Icon path={mdiContentCopy} color={'currentColor'} size={0.75} />
                                                <span>{button.text}</span>
                                            </div>
                                        );
                                    case 'COPY_CODE':
                                    case 'COPY CODE':
                                    case 'copy-offer-code':
                                        return (
                                            <div className="button" key={index}>
                                                <Icon path={mdiContentCopy} color={'currentColor'} size={0.75} />
                                                <span>{button.text}</span>
                                            </div>
                                        );
                                    case 'QUICK_REPLY':
                                    case 'QUICK REPLY':
                                    case 'quick-reply':
                                        return (
                                            <div className="button" key={index}>
                                                <Icon path={mdiReply} color={'currentColor'} size={0.75} />
                                                <span>{button.text}</span>
                                            </div>
                                        );
                                    case 'MARKETING_OPT_OUT':
                                    case 'Marketing Opt Out':
                                    case 'marketing-opt-out':
                                        return (
                                            <div className="button" key={index}>
                                                <Icon path={mdiReply} color={'currentColor'} size={0.75} />
                                                <span>Stop promotions</span>
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default MessageChatItem;