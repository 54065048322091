import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Stack, Tooltip } from "@mui/material";
import React from "react";
import MessageChatItem from "../../../components/common/MessageChatItem";
import { useSnackbar } from "../../../components/context/SnackbarContext";

function TemplatePreviewDialog({ onClose, open, id, data }) {

    const { showSnackbar } = useSnackbar();
    let jsonData = [];

    try {
        jsonData = typeof data === "string" ? JSON.parse(data) : data;
    } catch (error) {
        showSnackbar('Error parsing JSON data', "error");
        onClose()
        return null;
    }

    // Function to replace placeholders {{1}}, {{2}}... with actual example data
    const replacePlaceholders = (text, examples) => {
        if (!text) return "";
        if (!examples) return text;
        return text.replace(/{{(\d+)}}/g, (_, index) => examples[index - 1] || '');
    };

    // Extract Header, Body, Footer, and Buttons
    const header = jsonData.find(item => item.type === "HEADER");
    const body = jsonData.find(item => item.type === "BODY");
    const footer = jsonData.find(item => item.type === "FOOTER");
    const buttons = jsonData.find(item => item.type === "BUTTONS");


    // Get formatted values
    let formattedHeader = ({ head: '', fileLink: '', fileName: '' });
    let headerType = "text";

    if (header) {
        if (header.format === "IMAGE" && header.example?.header_handle?.length > 0) {
            formattedHeader = ({ head: '', fileLink: header.example.header_handle[0], fileName: '' }); // Image URL
            headerType = "image";
        } else if (header.format === "DOCUMENT" && header.example?.header_handle?.length > 0) {
            const uplodedFileurl = header?.example?.header_handle[0] || '';
            const expFileUrl = uplodedFileurl?.split('?')[0] || '';
            const sendFileName = expFileUrl?.slice(-20) || '';

            formattedHeader = ({ head: '', fileLink: header.example.header_handle[0], fileName: sendFileName }); // Image URL
            headerType = "document";
        } else if (header.format === "VIDEO" && header.example?.header_handle?.length > 0) {
            formattedHeader = ({ head: '', fileLink: header.example.header_handle[0], fileName: '' }); // Image URL
            headerType = "video";
        } else if (header.format === "TEXT") {
            formattedHeader = ({ head: header.text || "", fileLink: '', fileName: '' });
            headerType = "text";
        }
    }

    const formattedBody = body ? replacePlaceholders(body.text, body.example?.body_text?.[0]) : "";
    const formattedFooter = footer ? footer.text : "";
    const formattedButtons = buttons?.buttons?.map(btn => ({ type: btn.type, text: btn.text })) || [];

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Preview Template</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                <div className="message-preview mb-24">
                    <MessageChatItem
                        head={formattedHeader}
                        headType={headerType}
                        body={formattedBody}
                        footer={formattedFooter}
                        buttons={formattedButtons}
                    />
                </div>

                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Button onClick={onClose} color="secondary">Close</Button>
                </Stack>
            </Dialog>
        </>
    );
}

export default TemplatePreviewDialog;