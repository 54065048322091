import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import FormGroupStatic from "../../../components/common/FormGroupStatic";
import { capitalize, displayCurrencySymbol } from "../../../utils/Utils";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiTrayArrowDown } from "@mdi/js";


function OrderDetails(props) {
    const { id } = useParams();
    const OrderId = id;
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);
    const [order, setOrderData] = useState([]);
    const [subscriptions, setOrderSubcriptionData] = useState([]);

    const { preferredDateFormat, preTimeZoneKey, currencySymbol, currencyCode, currencyStringValue } = useSelector((state) => state?.user);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        await axiosInstance.get('api/order/view/' + OrderId).then((response) => {
            if (response.status === 200) {
                // setReloadPage(reloadPage ? false : true);
                setOrderData(response?.data?.data?.record);
                setOrderSubcriptionData(response?.data?.data?.record?.order_subcription);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    const downloadInvoice = async (id) => {
        showLoader();
        await axiosInstance.get('api/generate-order-file/' + id).then(response => {
            if (response.data.code === 200) {
                if (response.data.data.fileName && response.data.data.fullPath) {
                    setTimeout(() => {
                        const linkSource = 'data:application/pdf;base64, ' + response.data.data.fullPath
                        const downloadLink = document.createElement("a");
                        downloadLink.href = linkSource;
                        downloadLink.download = response.data.data.fileName;
                        downloadLink.click();
                    }, 1000);
                } else {
                    showSnackbar('Sorry...! Filename cannot found.', "warning");
                }
                hideLoader();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Order Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Typography variant='title-medium-bold' component={'h6'}>{order?.added_by?.name}</Typography>
                        <ul className='inline-details'>
                            <li><span className="title">Last Updated:</span>{order?.updated_at
                                ? moment.utc(order.updated_at)
                                    .tz(preTimeZoneKey)
                                    .format(preferredDateFormat)
                                : "N/A"}</li>
                        </ul>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Order Information</Typography>

                        <Grid container spacing={1.5}>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Package name" value={order?.package?.name} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Order Total" value={order?.final_total ? displayCurrencySymbol(order?.final_total, currencyCode, currencySymbol, currencyStringValue) : 'N/A'} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Package Detail" value={order?.description} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Payment Method" value={order?.payment_type_label} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Paid for" value={order?.user_count + " User"} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Payment Status" element={
                                    <>
                                        {order?.payment_status_label === 'Completed' && <span className="status-badge success">{order?.payment_status_label}</span>}
                                        {order?.payment_status_label === 'Pending' && <span className="status-badge warning">{order?.payment_status_label}</span>}
                                        {order?.payment_status_label === 'Failed' && <span className="status-badge warning">{order?.payment_status_label}</span>}
                                    </>
                                } />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Order Type" value={order?.payment_method} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Transaction ID" value={order?.transaction_id} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Modified by" value={`${order?.added_by?.name || "N/A"} (${order?.updated_at
                                    ? moment.utc(order.updated_at).tz(preTimeZoneKey).format(preferredDateFormat)
                                    : "N/A"
                                    })`} />
                            </Grid>
                            <Grid item xxl={3} xl={4} xs={6}>
                                <FormGroupStatic label="Created by" value={`${order?.added_by?.name || "N/A"} (${order?.created_at
                                    ? moment.utc(order.created_at).tz(preTimeZoneKey).format(preferredDateFormat)
                                    : "N/A"
                                    })`} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Subscription List</Typography>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Transaction ID</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell className="long-text">Plan Duration</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell className="long-text">Created Date</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subscriptions.map((subscription, index) => (
                                        <TableRow key={index + ''}>
                                            <TableCell>{subscription?.subscription_type}</TableCell>
                                            <TableCell>{subscription?.stripe_subscription_id}</TableCell>
                                            <TableCell>{subscription?.paid_amount ? displayCurrencySymbol(subscription?.paid_amount, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</TableCell>
                                            <TableCell>{subscription?.plan_period_start
                                                ? moment.utc(subscription?.plan_period_start)
                                                    .tz(preTimeZoneKey)
                                                    .format(preferredDateFormat)
                                                : "N/A"} - {subscription?.plan_period_end
                                                    ? moment.utc(subscription?.plan_period_end)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}</TableCell>
                                            <TableCell align="center" className="text-nowrap">
                                                {subscription?.status === 'active' && <span className="status-badge success">Active</span>}
                                                {subscription?.status === 'trialing' && <span className="status-badge warning">Trialing</span>}
                                                {subscription?.status === 'past_due' && <span className="status-badge warning">Payment Pending</span>}
                                                {subscription?.status === 'unpaid' && <span className="status-badge warning">Unpaid</span>}
                                                {subscription?.status === 'canceled' && <span className="status-badge warning">Canceled</span>}
                                                {subscription?.status === 'incomplete' && <span className="status-badge warning">Incomplete Payment</span>}
                                                {subscription?.status === 'incomplete_expired' && <span className="status-badge warning">Expired</span>}
                                                {subscription?.status === 'paused' && <span className="status-badge warning">Paused</span>}
                                            </TableCell>
                                            <TableCell>{subscription?.created_at
                                                ? moment.utc(subscription?.created_at)
                                                    .tz(preTimeZoneKey)
                                                    .format(preferredDateFormat)
                                                : "N/A"}</TableCell>
                                            <TableCell className="pr-0">
                                                {
                                                    subscription?.invoice_file &&
                                                    <Tooltip title="Download Invoice">
                                                        <button type="button" className='icon-button' onClick={() => { downloadInvoice(subscription?.id) }}>
                                                            <Icon path={mdiTrayArrowDown} color="currentColor" size={1} />
                                                        </button>
                                                    </Tooltip>
                                                }

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default OrderDetails;