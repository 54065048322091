import React, { Suspense, useEffect, useState } from "react";
import { Button, Chip, Grid, Menu, MenuItem, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAttachment, mdiCalendarCheckOutline, mdiCheck, mdiCheckboxMarkedCirclePlusOutline, mdiCheckboxMultipleMarkedCircleOutline, mdiDotsVertical, mdiMapMarkerOutline, mdiMapMarkerPlusOutline, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import AttachmentViewerDialog from "./AttachmentViewerDialog";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import AddEventDialog from "./AddEventDialog";
import AddTaskDialog from "./AddTaskDialog";
import AddVisitLogDialog from "./AddVisitLogDialog";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayDate } from "../../utils/Utils";
import EmptyState from "./EmptyState";


const ActivityList = (props) => {
    const { type, dataId } = props
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const userAccessModule = useSelector((state) => state.user.moduleAccess);
    const [reloadPage, setReloadPage] = useState(false);
    const [editId, setEditId] = useState();
    const [isOpenAs, setIsOpenAs] = useState();
    const [activityList, setActivityList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [priorityStatus, setPriorityStatus] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [plannedList, setPlannedList] = useState([]);
    const [doneList, setDoneList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await getActivity();
        }
        fetchData();
    }, [reloadPage]);

    const currentDateTime = new Date();
    const formattedDate = `${currentDateTime.getFullYear()}-${(currentDateTime.getMonth() + 1).toString().padStart(2, '0')}-${currentDateTime.getDate().toString().padStart(2, '0')}`;
    const formattedTime = `${currentDateTime.getHours().toString().padStart(2, '0')}:${currentDateTime.getMinutes().toString().padStart(2, '0')}:${currentDateTime.getSeconds().toString().padStart(2, '0')}`;
    const currentFullDate = formattedDate + ' ' + formattedTime;

    async function getActivity() {
        showLoader()
        await axiosInstance.get('api/' + type + '/activity/' + dataId).then(response => {
            if (response.data.code === 200) {
                setActivityList(response?.data?.data?.records);
                setPriorityList(response?.data?.data?.priority)
                setPriorityStatus(response?.data?.data?.priorityStatus)

                //====Store activity to base on time======//
                const plannedData = response?.data?.data?.records?.filter((item) => {
                    if ((item?.is_completed === 0 || (item?.is_completed == null || item?.is_completed === '')) && item?.end_date) {
                        const activityEndDate = moment.utc(item.end_date).tz(preTimeZoneKey).format('YYYY-MM-DD HH:mm:ss');
                        return activityEndDate >= currentFullDate; // Check if activityEndDate is in the future
                    }
                    return false;

                })
                const doneData = response?.data?.data?.records?.filter((item) => {
                    if (item?.is_completed === 1) {
                        return true;
                    }

                    // Ensure end_date is defined
                    if (item?.end_date) {
                        const activityEndDate = moment.utc(item.end_date).tz(preTimeZoneKey).format('YYYY-MM-DD HH:mm:ss');
                        return activityEndDate <= currentFullDate;
                    }
                    return false;
                })

                setCurrentActivityTab(plannedData?.length ? 0 : 1)
                setPlannedList(plannedData)
                setDoneList(doneData)

                hideLoader()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    }

    const handleReloadFunc = () => {
        setReloadPage(reloadPage ? false : true);
    }

    //======Start::Write code for handle Activity Tab changes code ========//
    const [currentActivityTab, setCurrentActivityTab] = React.useState(0);
    const [addActivityContextMenuAnchorEl, setAddActivityContextMenuAnchorEl] = React.useState(null);
    const addActivityContextMenuOpen = Boolean(addActivityContextMenuAnchorEl);
    const handleAddActivityContextMenuClick = (event, id) => {
        setAddActivityContextMenuAnchorEl(event.currentTarget);
    };
    const handleAddActivityContextMenuClose = () => {
        setAddActivityContextMenuAnchorEl(null);
    };
    //======END::Write code for handle Activity Tab changes code ========//

    //======Start::Write code for handle add activity menu box like (event,task,visit-log)========//
    const [activityContextMenuAnchorEl, setActivityContextMenuAnchorEl] = React.useState(null);
    const activityContextMenuOpen = Boolean(activityContextMenuAnchorEl);
    const handleActivityContextMenuClick = (event, id, openType) => {
        setActivityContextMenuAnchorEl(event.currentTarget);
        setEditId(id)
        setIsOpenAs(openType)
    };
    const handleActivityContextMenuClose = () => {
        setActivityContextMenuAnchorEl(null);
    };
    //======END::Write code for handle add activity menu box like (event,task,visit-log)========//

    //========Start::Write a code for handle Event, Task and Visit Log=========//
    const [addEventDialogOpen, setAddEventDialogOpen] = React.useState(false);
    const openAddEventDialog = () => {
        setAddEventDialogOpen(true);
    }
    const closeAddEventDialog = () => {
        setAddEventDialogOpen(false);
        setEditId()
        setIsOpenAs()
    }

    //Task
    const [addTaskDialogOpen, setAddTaskDialogOpen] = React.useState(false);
    const openAddTaskDialog = () => {
        setAddTaskDialogOpen(true);
    }
    const closeAddTaskDialog = () => {
        setAddTaskDialogOpen(false);
        setEditId()
        setIsOpenAs()
    }

    //visit Log
    const [addVisitLogDialogOpen, setAddVisitLogDialogOpen] = React.useState(false);
    const openAddVisitLogDialog = () => {
        setAddVisitLogDialogOpen(true);
    }
    const closeAddVisitLogDialog = () => {
        setAddVisitLogDialogOpen(false);
        setEditId()
        setIsOpenAs()
    }

    //show Attachment
    const [attachmentViewerDialogOpen, setAttachmentViewerDialogOpen] = React.useState(false);
    const openAttachmentViewerDialog = (attachment) => {
        setAttachments(attachment)
        setAttachmentViewerDialogOpen(true);
    }

    const closeAttachmentViewerDialog = () => {
        setAttachmentViewerDialogOpen(false);
        setAttachments([])
        setEditId()
        setIsOpenAs()
    }
    //========End::Write a code for handle Event=========//

    //=========Start::Write a code for handle Activity status (make it Done/Complated)==========//
    const handleChangeStatus = async (id) => {
        const data = {
            id: id,
            leadId: dataId,
            dealId: dataId,
        };

        try {
            showLoader();
            const response = await axiosInstance.post(`api/${type}-event/action`, data);
            if (response.status === 200) {
                showSnackbar(response.data.message, 'success');
                handleReloadFunc();
            }
        } catch (error) {
            hideLoader();
            if (error.response) {
                showSnackbar(error.response.data.message, 'error');
            }
        } finally {
            hideLoader();
        }
    }
    //=========END::Write a code for handle Activity status (make it Done/Complated)==========//

    //=========Start::Write a code for handle delete Activity (task, event, log-visit)==========//
    const deleteData = async () => {
        const id = editId
        const deleteType = isOpenAs
        var deleteUrl = ''

        if (id && deleteType) {
            const data = {
                leadId: dataId,
                dealId: dataId,
                type: type,
                id: id,
            };

            if (deleteType === 'event') {
                deleteUrl = 'event'
            } else if (deleteType === 'task') {
                deleteUrl = 'event'
            } else if (deleteType === 'Log a Visit') {
                deleteUrl = 'log-visit'
            }

            try {
                DeleteDialog.fire({
                    ...DeleteDialogConfig({
                        confirm: async () => {
                            showLoader()
                            await axiosInstance.delete(`api/${type}-${deleteUrl}/destroy`, { data })
                                .then((response) => {
                                    if (response.status === 200) {
                                        showSnackbar(response.data.message, 'success');
                                        handleReloadFunc();
                                    }
                                })
                                .then((json) => { })
                                .catch((error) => {
                                    if (error.response) {
                                        hideLoader();
                                        showSnackbar(error.response.data.message, 'error');
                                    }
                                });
                        },
                        cancel: () => {
                            hideLoader();
                        }
                    })
                });
            } catch (error) {
                hideLoader();
                showSnackbar('Oops Something want wrong..!', "error");
            }
        } else {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', 'error')
        }
    }
    //=========End::Write a code for handle delete Activity (task, event, log-visit)==========//

    //=====Start::Write a code for handle delete attachments=======//
    const HandleDeleteAttachment = async (id, parentId) => {
        const data = {
            id: parentId,
            attachment_id: id,
            type: type,
            leadId: dataId,
            dealId: dataId,
        };

        try {
            closeAttachmentViewerDialog()
            const willDelete = await Swal.fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            });

            if (willDelete.isConfirmed) {
                showLoader()
                axiosInstance.delete(`api/${type}-log-visit-attachment/destroy`, { data })
                    .then((response) => {
                        if (response.status === 200) {
                            showSnackbar(response.data.message, "success");
                            hideLoader();
                            setReloadPage(reloadPage ? false : true);
                        }
                    })
                    .then((json) => { })
                    .catch((error) => {
                        if (error.response) {
                            hideLoader();
                            if (error.response.status === 400) {
                                showSnackbar(error.response.data.message, "warning");
                            }
                        }
                    });
            } else {
                hideLoader();
            }
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle delete attachments=======//

    const requiredPermissionsForActivityAccess = ["event_create", "task_create", "activity_create", "visitlog_create"];
    const ActivityEditPermission = ["event_edit", "task_edit", "activity_edit", "visitlog_edit", "event_delete", "task_delete", "activity_delete", "visitlog_delete"];

    return (
        <>
            <div className="card p-16 flex-grow-1">
                <div className="card-header">
                    <h6 className="title">Activity</h6>
                    {requiredPermissionsForActivityAccess.some(permission => props?.userPermissions.includes(permission)) &&
                        <Button color="secondary" size="small" className="action" onClick={handleAddActivityContextMenuClick}>
                            <span>
                                <Icon path={mdiPlus} color={'currentColor'} size={1} />
                                <span>Add</span>
                            </span>
                        </Button>
                    }
                    <Menu
                        anchorEl={addActivityContextMenuAnchorEl}
                        open={addActivityContextMenuOpen}
                        onClose={handleAddActivityContextMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        {props.userPermissions && props.userPermissions.includes("event_create") && userAccessModule?.includes("event") &&
                            <MenuItem onClick={e => {
                                handleAddActivityContextMenuClose();
                                openAddEventDialog();
                            }}>
                                <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} />
                                <span>Create Event</span>
                            </MenuItem>
                        }
                        {props.userPermissions && props.userPermissions.includes("task_create") && userAccessModule?.includes("task") &&
                            <MenuItem onClick={e => {
                                handleAddActivityContextMenuClose();
                                openAddTaskDialog();
                            }}>
                                <Icon path={mdiCheckboxMarkedCirclePlusOutline} color={'currentColor'} size={1} />
                                <span>Create Task</span>
                            </MenuItem>
                        }
                        {props.userPermissions && props.userPermissions.includes("visitlog_create") && userAccessModule?.includes("visitlog") &&
                            <MenuItem onClick={e => {
                                handleAddActivityContextMenuClose();
                                openAddVisitLogDialog();
                            }}>
                                <Icon path={mdiMapMarkerPlusOutline} color={'currentColor'} size={1} />
                                <span>Create Visit</span>
                            </MenuItem>
                        }
                    </Menu>
                </div>

                {
                    activityList && activityList.length > 0 &&
                    <Stack direction="row" spacing={1} className="mb-16">
                        {currentActivityTab === 0 &&
                            <>
                                <Chip
                                    icon={<Icon path={mdiCheck} color={'currentColor'} size={1} />}
                                    label="Planned"
                                    color="primary"
                                    variant="filled"
                                />
                                <Chip
                                    label="Done"
                                    onClick={e => { setCurrentActivityTab(1); }}
                                />
                            </>
                        }
                        {currentActivityTab === 1 &&
                            <>
                                <Chip
                                    label="Planned"
                                    onClick={e => { setCurrentActivityTab(0); }}
                                />
                                <Chip
                                    icon={<Icon path={mdiCheck} color={'currentColor'} size={1} />}
                                    label="Done"
                                    color="primary"
                                    variant="filled"
                                />
                            </>
                        }
                    </Stack>
                }

                {(!activityList || activityList?.length === 0) && !loading &&
                    <EmptyState type={'activity'} hideActionBtn size="small" />
                }

                {activityList && activityList.length > 0 && currentActivityTab === 0 &&
                    <Grid container spacing={1} className="activity-list" component={'ul'}>
                        {plannedList.map((activity, index) => {
                            var isFullDay = activity?.is_fullday ? true : false
                            var endDate = activity?.end_date ? moment(activity.end_date) : moment()

                            return (
                                <Grid item component={'li'} xxl={12} lg={6} xs={12} key={index + ''}>
                                    <div className={"activity undone " + (activity?.attachment?.length !== 0 ? 'has-attachment' : '')}>
                                        <button type="button" className="check" onClick={() => handleChangeStatus(activity.id)}>
                                            <Icon path={mdiCheck} size={1} color={'currentColor'} />
                                        </button>
                                        <Stack direction={'row'} spacing={1}>
                                            {activity?.activity_type === 'event' && <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} className="icon" />}
                                            {activity?.activity_type === 'task' && <Icon path={mdiCheckboxMultipleMarkedCircleOutline} color={'currentColor'} size={1} className="icon" />}
                                            {activity?.activity_type === 'Log a Visit' && <Icon path={mdiMapMarkerOutline} color={'currentColor'} size={1} className="icon" />}
                                            <div className="details">
                                                <Typography variant="title-medium-bold" component={'p'}>{capitalize(activity?.name || 'N/A')}</Typography>
                                                <ul className="inline-details">
                                                    <li>{
                                                        activity?.end_date ? isFullDay ? displayDate(moment.utc(endDate).tz(preTimeZoneKey).format(preferredDateFormat), 'half') : moment.utc(endDate).tz(preTimeZoneKey).format(preferredDateFormat) : 'N/A'
                                                    }
                                                    </li>
                                                    {activity?.activity_type === 'task' && <><li>{activity?.priority ? priorityList[activity?.priority] : ''}</li><li>{activity?.activity_status ? priorityStatus[activity?.activity_status] : ''}</li></>}
                                                </ul>
                                                <Typography variant="body-medium" component={'p'}>{activity?.description}</Typography>
                                            </div>
                                        </Stack>
                                        {activity?.attachment?.length !== 0 && <Tooltip title="View Attachments">
                                            <button type="button" className="attachment-btn" onClick={() => openAttachmentViewerDialog(activity?.attachment)}>
                                                {activity?.attachment?.length}
                                                <Icon path={mdiAttachment} color={'currentColor'} size={1} />
                                            </button>
                                        </Tooltip>}
                                        {ActivityEditPermission.some(permission => props?.userPermissions.includes(permission)) &&
                                            <Tooltip title="More">
                                                <button type="button" className="icon-button action" onClick={(e) => handleActivityContextMenuClick(e, activity?.id, activity?.activity_type)}>
                                                    <Icon path={mdiDotsVertical} color={'currentColor'} size={1} />
                                                </button>
                                            </Tooltip>
                                        }
                                    </div>
                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                }

                {activityList && activityList.length > 0 && currentActivityTab === 1 &&
                    <Grid container spacing={1} className="activity-list" component={'ul'}>
                        {doneList.map((activity, index) => {
                            var isExpired = activity?.is_completed === 0 && moment.utc(activity?.end_date).tz(preTimeZoneKey).format('YYYY-MM-DD HH:mm:ss') <= currentFullDate ? true : false
                            var isFullDay = activity?.is_fullday ? true : false
                            var endDate = activity?.end_date ? moment(activity.end_date) : moment()
                            return (
                                <Grid item component={'li'} xxl={12} lg={6} xs={12} key={index + ''}>
                                    <div className={"activity " + (activity?.attachment?.length !== 0 ? 'has-attachment ' : '') + (isExpired ? 'expired ' : '')}>
                                        <Stack direction={'row'} spacing={1}>
                                            {activity?.activity_type === 'event' && <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} className="icon" />}
                                            {activity?.activity_type === 'task' && <Icon path={mdiCheckboxMultipleMarkedCircleOutline} color={'currentColor'} size={1} className="icon" />}
                                            {activity?.activity_type === 'Log a Visit' && <Icon path={mdiMapMarkerOutline} color={'currentColor'} size={1} className="icon" />}
                                            <div className="details">
                                                <Typography variant="title-medium-bold" component={'p'}>{capitalize(activity?.name || 'N/A')}</Typography>
                                                <ul className="inline-details">
                                                    <li>{
                                                        activity?.end_date ? isFullDay ? displayDate(moment.utc(endDate).tz(preTimeZoneKey).format(preferredDateFormat), 'half') : moment.utc(endDate).tz(preTimeZoneKey).format(preferredDateFormat) : 'N/A'
                                                    }
                                                    </li>
                                                    {activity?.activity_type === 'task' && <><li>{activity?.priority ? priorityList[activity?.priority] : ''}</li><li>{activity?.activity_status ? priorityStatus[activity?.activity_status] : ''}</li></>}
                                                    {isExpired && <li className="badge-wrapper"><span className={"status-badge dark"}>Expired</span></li>}
                                                </ul>
                                                <Typography variant="body-medium" component={'p'}>{activity?.description}</Typography>
                                            </div>
                                        </Stack>
                                        {activity?.attachment?.length !== 0 && <Tooltip title="View Attachments">
                                            <button type="button" className="attachment-btn" onClick={() => openAttachmentViewerDialog(activity?.attachment)}>
                                                {activity?.attachment?.length}
                                                <Icon path={mdiAttachment} color={'currentColor'} size={1} />
                                            </button>
                                        </Tooltip>}
                                        {ActivityEditPermission.some(permission => props?.userPermissions.includes(permission)) &&
                                            <Tooltip title="More">
                                                <button type="button" className="icon-button action" onClick={(e) => handleActivityContextMenuClick(e, activity?.id, activity?.activity_type)}>
                                                    <Icon path={mdiDotsVertical} color={'currentColor'} size={1} />
                                                </button>
                                            </Tooltip>
                                        }
                                    </div>
                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                }

                <Menu
                    anchorEl={activityContextMenuAnchorEl}
                    open={activityContextMenuOpen}
                    onClose={handleActivityContextMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    {(isOpenAs === 'Log a Visit' && props.userPermissions && props.userPermissions.includes("visitlog_create")) ||
                        (isOpenAs === 'event' && props.userPermissions && props.userPermissions.includes("event_create")) ||
                        (isOpenAs === 'task' && props.userPermissions && props.userPermissions.includes("task_create")) ? (
                        <MenuItem onClick={() => {
                            handleActivityContextMenuClose();

                            if (isOpenAs === 'Log a Visit') {
                                openAddVisitLogDialog();
                            } else if (isOpenAs === 'event') {
                                openAddEventDialog();
                            } else if (isOpenAs === 'task') {
                                openAddTaskDialog();
                            }
                        }}>
                            <Icon path={mdiPencilOutline} color={'currentColor'} size={1} />
                            <span>Edit</span>
                        </MenuItem>
                    ) : null}
                    {(isOpenAs === 'Log a Visit' && props.userPermissions && props.userPermissions.includes("activitylog_delete")) ||
                        (isOpenAs === 'event' && props.userPermissions && props.userPermissions.includes("event_delete")) ||
                        (isOpenAs === 'task' && props.userPermissions && props.userPermissions.includes("task_delete")) ? (
                        <MenuItem onClick={(e) => {
                            handleActivityContextMenuClose(); deleteData();
                        }}>
                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                            <span>Delete</span>
                        </MenuItem>
                    ) : null}
                </Menu>

            </div>

            {
                addEventDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AddEventDialog open={addEventDialogOpen} onClose={closeAddEventDialog} type={type} dataId={dataId} editId={editId} handleReloadFunc={handleReloadFunc} />
                    </Suspense>
                )
            }

            {
                addTaskDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AddTaskDialog open={addTaskDialogOpen} onClose={closeAddTaskDialog} type={type} dataId={dataId} editId={editId} handleReloadFunc={handleReloadFunc} priority={priorityList} status={priorityStatus} />
                    </Suspense>
                )
            }

            {
                addVisitLogDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AddVisitLogDialog open={addVisitLogDialogOpen} onClose={closeAddVisitLogDialog} type={type} dataId={dataId} editId={editId} handleReloadFunc={handleReloadFunc} />
                    </Suspense>
                )
            }

            {
                attachmentViewerDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AttachmentViewerDialog open={attachmentViewerDialogOpen} onClose={closeAttachmentViewerDialog} attachments={attachments} attachmentType='/log-visit/' HandleDeleteAttachment={HandleDeleteAttachment} />
                    </Suspense>
                )
            }



        </>
    )
}

export default ActivityList;