import { mdiClose, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import AvatarInitials from "../../../components/common/AvatarInitials";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import { useNavigate } from "react-router-dom";

function AddToMarketingListDialog({ onClose, open, handleReloadFunc, marketingSeletedListContact, marketingList, phoneNumber, fullName, dealId }) {

    const [selectedList, setSelectedList] = useState("");
    const { showLoader, hideLoader, loading } = useLoader();
    const axiosInstance = useAxios();
    const [reloadPage, setReloadPage] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [addFrom, setAddFrom] = useState("deal");
    const navigate = useNavigate();

    const addContact = async () => {
        const params = {
            whatsapp_contact_id: selectedList,
            type: addFrom,
            contact_from_id: dealId,
            name: fullName,
            mobile_number: phoneNumber,
        };
        try {
            const response = await axiosInstance.post(`api/whatsapp-contact-list/singal/store`, params);

            if (response.status === 200) {
                // Handle success
                hideLoader();
                showSnackbar("Contact added successfully", "success");
                handleReloadFunc();
                navigate("/deal-detail/" + dealId);
                onClose();

            }
        } catch (error) {
            hideLoader();
            if (error.response && error.response.status === 400) {
                showSnackbar(error.response.data.message, "warning");
            }
        }
    };

    const deleteContactslist = async (ContactId) => {
        try {
            showLoader();
            const params = {
                id: ContactId,
                phoneNumber: phoneNumber,
            };
            await axiosInstance.delete('api/whatsapp-contact-list/singal/destroy', { params }).then((response) => {
                if (response.status === 200) {
                    hideLoader();
                    showSnackbar('Contacts deleted successfully', 'success');
                    handleReloadFunc();
                    navigate("/deal-detail/" + dealId);
                    onClose();
                }
            });
        } catch (error) {
            if (error.response) {
                showSnackbar(error.response.data.message, 'error');
            } else {
                showSnackbar('Error occurred while deleting contacts', 'error');
            }
        } finally {
            hideLoader();
        }
    };

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Add to Marketing List</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                <Stack spacing={1.5} direction={"row"} alignItems={'center'}>
                    <AvatarInitials name={fullName} />
                    <Stack>
                        <Typography variant="title-medium-bold" component={'p'}>{fullName}</Typography>
                        <Typography variant="body-medium" className="text-secondary" component={'p'}>{phoneNumber && phoneNumber.join(',')}</Typography>
                    </Stack>
                </Stack>

                <hr className="mt-20 mb-20" />

                <Typography variant="body-large-bold">Add in new marketing list</Typography>
                <Typography className="pb-16 text-secondary" variant="body-medium">Please select contact list below and save in order to add this lead’s contact details in that marketing list.</Typography>

                <TextField
                    select
                    label="Select contact list"
                    variant="filled"
                    value={selectedList}
                    onChange={(e) => setSelectedList(e.target.value)}
                    fullWidth
                >
                    {marketingList.length > 0 ? (
                        marketingList.map((list) => (
                            <MenuItem key={list.id} value={list.id}>
                                {list.name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No lists available</MenuItem>
                    )}
                </TextField>

                <Grid container spacing={1.5}>
                    <Grid item xs={6}>
                        <Button className="btn-block pl-8 pr-8" onClick={addContact}>
                            Add in contact list
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" className="btn-block" onClick={() => setSelectedList("")}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>

                <hr className="mt-20 mb-20" />

                <Typography variant="body-large-bold">Contact exists in following lists</Typography>
                <Typography className="pb-8 text-secondary" variant="body-medium">This contact details exists in below given contact lists for marketing messages.</Typography>

                <TableContainer>
                    <Table>
                        <TableBody>
                            {marketingSeletedListContact && marketingSeletedListContact.length > 0 ? (
                                marketingSeletedListContact.map((lead, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{lead.name}</TableCell>
                                        <TableCell width="10" className="pr-0">
                                            <Tooltip title="Delete">
                                                <button type="button" className="icon-button" onClick={() => deleteContactslist(lead.id)}>
                                                    <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                </button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </>
    );
}

export default AddToMarketingListDialog;