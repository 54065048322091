import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../App";
import { Check3D } from "../../../../assets/images";
import { Button, Stack, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";
import AvatarInitials from "../../../../components/common/AvatarInitials";
import BigRadio from "../../../../components/common/BigRadio";
import useAxios from "../../../../hooks/useAxios";
import { useLoader } from "../../../../components/context/LoaderContext";
import { useSnackbar } from "../../../../components/context/SnackbarContext";

function ManageFreeUpgrade({ onReset, plan, planId }) {
    const { currentTheme } = useContext(ThemeContext);
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [currentStep, setCurrentStep] = React.useState('action-required');
    const [currentPlan, setCurrentPlan] = React.useState(plan);
    const [tenantUsers, setTenantUsers] = useState([]);

    useEffect(() => {
        if (!planId) return;
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [planId]);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/subscription-package-detail/' + planId).then((response) => {
            if (response.status === 200) {
                setTenantUsers(response?.data?.data?.userLists);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    const makeFreePlan = async () => {
        showLoader();
        await axiosInstance.get('api/subscription-make-free').then((response) => {
            if (response.status === 200) {
                hideLoader();
                showSnackbar(response.data.message, 'success')
                setCurrentStep('confirm');
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    return (
        <>
            {
                currentStep === 'action-required' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Action Required: Manage Your Users</Typography>
                        <p className="text-secondary">Your Free plan allows only 1 user. Since you were previously on the Basic plan with multiple users, please remove any additional users to continue with the Free plan. You can always upgrade to the Basic or Premium plans to keep multiple users.</p>
                    </div>
                    <Stack spacing={2}>
                        <BigRadio
                            title={"Proceed to Remove Additional Users"}
                            description={'Removing a user will delete all leads, deals, quotes, and other data associated with that user. This action cannot be undone.'}
                            input={<input type="radio" name="remove-user-radio" value="remove-users" checked={currentPlan === 'free'} onChange={e => { setCurrentPlan('free') }} />}
                        />
                        <BigRadio
                            title={"Stay on the Basic Plan"}
                            description={'Keep all your users and their data for just $9/month per user.'}
                            input={<input type="radio" name="remove-user-radio" value="upgrade-plan" checked={currentPlan === 'basic'} onChange={e => {
                                onReset(e, 'cancel-downgrade')
                            }} />}
                        />
                    </Stack>

                    <div className="action-block">
                        <Button variant="text" onClick={e => {
                            onReset(e, 'cancel-downgrade');
                        }}>Cancel Downgrade</Button>
                        <Button onClick={() => setCurrentStep('final-check')}>
                            <span>
                                <span>Next</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }



            {
                currentStep === 'final-check' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Final Check: Users Marked for Deletion</Typography>
                        <p className="text-secondary">Heads up! The users listed below, along with all their associated data—leads, deals, quotes, and more—will be permanently removed.<br /><br />Changed your mind? No worries! You can keep your users and their data intact by staying on the current plan. Your team doesn’t have to shrink if you don’t want it to!</p>
                    </div>
                    {tenantUsers?.length > 0 &&
                        <>
                            <Typography variant="body-large-bold" sx={{ marginBottom: 1 }} component={'p'}>Users Marked for Removal ({tenantUsers?.length} users)</Typography>
                            <div className="checkout-user-list">
                                <ul className="inner">
                                    {tenantUsers?.length > 0 && tenantUsers.map(
                                        (user, index) => {
                                            return (
                                                <li key={index}>
                                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                                        <AvatarInitials name={user.name} sx={{ flexShrink: 0 }} />
                                                        <div>
                                                            <Typography variant="title-medium-bold" component={'p'} sx={{ marginBottom: '-2px' }}>{user.name}</Typography>
                                                            <Typography variant="body-medium" className="text-secondary">{user.email}</Typography>
                                                        </div>
                                                    </Stack>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </>
                    }

                    <div className="action-block">
                        <Button variant="text" onClick={() => setCurrentStep('action-required')}>Cancel Downgrade</Button>
                        <Button onClick={() => makeFreePlan()}>
                            <span>
                                <span>Confirm and Remove Users</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div></>
            }


            {
                currentStep === 'confirm' &&
                <>
                    <div className="block-title">
                        {currentTheme?.name === 'solvcrm' && <img src={Check3D} alt="Check" />}
                        <Typography variant="display-small-bold" component={'h1'}>You're All Set!</Typography>
                        <p className="text-secondary">Your Free plan is now active! Thank you for choosing SolvCRM. We've successfully removed the additional users as requested. You now have seamless access to essential features to manage your business with ease.<br /><br />Need more power? You can upgrade anytime to unlock advanced features and grow your business further.</p>
                    </div>

                    <div className="action-block mt-0">
                        <Button onClick={() => window.location.reload()}>
                            <span>
                                <span>View Subscription Details</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }



        </>
    );
}

export default ManageFreeUpgrade;