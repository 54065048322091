import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import { Button, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiHomeOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";

function AccessDenied() {
    let { currentTheme } = useContext(ThemeContext);

    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Access Denied</title>
            </Helmet>

            <section className="error-page">
                <img src={currentTheme?.pageNotFoundImage} alt="450" />
                <div className="content">
                    <Typography variant="headline-medium-bold" component={"h4"}>Oops! Access Denied, you need to upgrade your plan</Typography>
                    <Typography variant="body-large-medium" component={"p"}>It seems you don’t have the necessary permissions to access this page. If you believe this is a mistake, please contact your tenant for assistance. In the meantime, you can return to the homepage or explore other sections of the platform.</Typography>
                </div>
                <Button variant="contained" onClick={e => navigate('/settings/subscription')}>
                    <span>
                        <Icon path={mdiHomeOutline} color={'currentColor'} size={1} />
                        <span>Upgrade Plan</span>
                    </span>
                </Button>
            </section>
        </>
    );
}

export default AccessDenied;