import React from "react";
import { RadioCheckedIcon, RadioIcon } from "./Icons";
import { Typography } from "@mui/material";

function BigRadio({ title, description, input, content }) {
    return (
        <>
            <label className="big-radio">
                {input}
                <div className="content">
                    <div className="icon-wrapper">
                        <RadioIcon className="default-icon" />
                        <RadioCheckedIcon className="checked-icon" />
                    </div>
                    <Typography variant="title-medium-large-bold" component={'h5'} className="title">{title}</Typography>
                    <Typography variant="body-large-medium" component={'p'} className="description">{description}</Typography>
                    {content &&
                        <>
                            <hr />
                            {content}
                        </>
                    }
                </div>
            </label>
        </>
    );
}

export default BigRadio;