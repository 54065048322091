import React, { useContext, useEffect, useState } from "react";
import { Check3D, CheckoutArt } from "../../../assets/images";
import { Button, Checkbox, Grid, IconButton, Popover, Stack, TextField, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiArrowRight, mdiInformationOutline, mdiMinus, mdiPlus, mdiPlusCircleOutline, mdiTrashCanOutline } from "@mdi/js";
import AvatarInitials from "../../../components/common/AvatarInitials";
import { VisaLogo } from "../../../components/common/Icons";
import { ThemeContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { useSnackbar } from "../../../components/context/SnackbarContext";
//=========Stripe Payment data============//
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/stripe/CheckoutForm";
import { getMainDomain } from "../../../utils/Utils";
import ExistingCardPayment from "./components/stripe/ExistingCardPayment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function ManagePlan({ onReset, plan, planId }) {
    const { currentTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [preferredCurrencyData, setPreferredCurrencyData] = useState({});
    const hostname = getMainDomain()
    const [currentStep, setCurrentStep] = React.useState('manage-user-count');
    const [tenantUsers, setTenantUsers] = useState([]);
    const [tenantUserCount, setTenantUserCount] = useState(0);
    const [displayUserBox, setDisplayUserBox] = useState(true);
    const [displayNoteMsg, setDisplayNoteMsg] = useState(false);
    const [displayDeleteUserCnt, setDisplayDeleteUserCnt] = useState(0);
    const [displayNextBtn, setDisplayNextBtn] = useState(true);
    const [deleteUserId, setDeleteUserId] = useState([]);
    const [planUpdateType, setPlanUpdateType] = useState('');
    const [totalUserCnt, setTotalUserCnt] = useState([]);

    //for stripe
    const [stripe, setStripe] = useState(null)
    const [payAbleAmount, setPayAbleAmount] = useState(0);

    const [orderDetail, setOrderDetail] = useState([]);
    const [cardDetail, setCardDetail] = useState([]);
    const [addNewCard, setaddNewCard] = useState(false);

    const [calculationMenuAnchorEl, setCalculationMenuAnchorEl] = React.useState(null);

    const handleCalculationMenuClick = (event) => {
        setCalculationMenuAnchorEl(event.currentTarget);
    };

    const handleCalculationMenuClose = () => {
        setCalculationMenuAnchorEl(null);
    };

    const calculationMenuOpen = Boolean(calculationMenuAnchorEl);

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [currentStep]);


    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/subscription-package-detail/' + planId).then((response) => {
            if (response.status === 200) {
                setTenantUsers(response?.data?.data?.userLists);
                setTotalUserCnt(response?.data?.data?.userCountDetail)
                setTenantUserCount(parseInt(response?.data?.data?.userCountDetail?.planUserCount - 1))
                setDeleteUserId([])
                hideLoader();
                if (hostname === '.solvcrm.co') {
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                } else {
                    //setPreferredCurrencyData({ 'currencyCode': 'INR', 'currencySymbol': '₹' });
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                }
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/checkout");
                    }
                }
            });
    }

    useEffect(() => {
        if (tenantUserCount >= tenantUsers?.length) {
            setDisplayUserBox(false)
        } else {
            setDisplayUserBox(true)
        }

        //for show hide delete used box
        if (tenantUserCount <= tenantUsers?.length) {
            setDisplayDeleteUserCnt(Number(tenantUsers?.length) - Number(tenantUserCount))
        }

        //Handle click for show hide Next Button
        if (tenantUserCount < tenantUsers?.length) {
            setDisplayNoteMsg(true)
        } else {
            setDisplayNoteMsg(false)
        }


        if (tenantUserCount > parseInt(totalUserCnt?.planUserCount - 1)) {
            setDisplayNextBtn(false)
        } else if (tenantUserCount >= tenantUsers?.length && tenantUserCount < (parseInt(totalUserCnt?.planUserCount - 1))) {
            setDisplayNextBtn(false)
        } else {
            setDisplayNextBtn(true)
        }

        if (tenantUserCount > parseInt(totalUserCnt?.planUserCount - 1)) {
            setDisplayNoteMsg(true)
        } else {
            setDisplayNoteMsg(false)
        }

    }, [tenantUserCount, tenantUsers]);


    const handleCheckboxChange = (userId) => {
        setDeleteUserId((prevState) => {
            if (prevState.includes(userId)) {
                return prevState.filter((id) => id !== userId); // Uncheck
            } else {
                return [...prevState, userId]; // Check
            }
        });
    };

    const deleteUser = async (type, userIds) => {
        if (type === 'single') {
            userIds = [userIds]
        }

        showLoader();
        const data = {
            required_user: 10, //for testing make it static data
            user_ids: userIds
        }
        // Prepare query parameters
        const params = new URLSearchParams(data).toString();

        await axiosInstance.get(`api/subscription-package-detail/${planId}?${params}`).then((response) => {
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
                setTenantUsers(response?.data?.data?.userLists);
                setDeleteUserId([])
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }


    const updatePlan = async (type) => {
        setPlanUpdateType(type)
        const data = {
            id: planId,
            required_user: tenantUserCount,
        }
        showLoader();
        await axiosInstance.post('api/subscription-plan-upgrade', data).then((response) => {
            if (response.status === 200) {
                setOrderDetail(response?.data?.data?.displaypackageData)
                setPayAbleAmount(response?.data?.data?.displaypackageData?.payableAmount)
                setCardDetail(response?.data?.data?.cardDetail)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    const downPlan = async (type) => {
        setPlanUpdateType(type)
        const data = {
            id: planId,
            required_user: tenantUserCount,
        }
        showLoader();
        await axiosInstance.post('api/subscription-plan-downgrade', data).then((response) => {
            if (response.status === 200) {
                setOrderDetail(response?.data?.data?.displaypackageData)
                setPayAbleAmount(response?.data?.data?.displaypackageData?.payableAmount)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }



    const extraData = {
        'userCount': orderDetail?.newAddedUser,
        'plan_id': planId,
        'type': planUpdateType || 'upgrade',
    }

    // =========Start::Manage Stripe checkout process=============//
    const handlePaymentRes = async (res) => {
        if (res?.response) {
            showSnackbar(res?.message, "success");
            getSubscriptionDetail()
        } else {
            hideLoader()
            showSnackbar(res?.message, "error");
        }
    }
    // =========END::Manage Stripe checkout process=============//

    const getSubscriptionDetail = async () => {
        showLoader()
        await axiosInstance.get(`api/getsubscription`).then((response) => {
            if (response.status === 200) {
                setCurrentStep('update-success')
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    }

    // =====These two functions, (handlePaymentRequest and storePaymentDetail), are specifically used for users downgrading their plan. =====//
    const handlePaymentRequest = async () => {
        const data = ({
            amount: payAbleAmount,
            extraData: extraData,
        })

        showLoader()
        await axiosInstance.post("/api/subscription-make-payment", data).then((response) => {
            if (response.status === 200) {
                //Need to check subscription status and response (if success meand card are normal and payment are done)
                if (response?.data?.data?.paymentIntentResponse === 'success') {
                    showSnackbar('Your payment has been confirm', "success");
                    //navigate('/settings/subscription')
                    window.location.reload();
                    hideLoader()
                } else {
                    //if response get in else than handle 3D Secure card detail for this transection/subscrirption
                    storePaymentDetail(response)
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                showSnackbar(error?.response?.data?.message, "error");
            }
        });

    }

    const storePaymentDetail = async (responseData) => {
        if (responseData.data.data) {
            if (responseData.data.data.paymentIntentResponse['isSubscription']) {//recurring payment
                stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret']).then(async (result) => {
                    if (result.error) {
                        showSnackbar(result?.error?.message, "error");
                    } else {
                        // Successful subscription payment
                        // Post the transaction info to the server-side script and redirect to the payment status page
                        const requestData = {
                            'paymentIntent': result?.paymentIntent,
                            'subscriptionId': responseData?.data?.data?.paymentIntentResponse['subscriptionId'],
                            'clientSecret': responseData?.data?.data?.paymentIntentResponse['clientSecret'],
                            'customerId': responseData?.data?.data?.paymentIntentResponse['customerId'],
                            'orderId': responseData?.data?.data?.paymentIntentResponse['orderId'],
                            'isUpdate': responseData?.data?.data?.paymentIntentResponse['isUpdate'],
                            'requestUserCount': responseData?.data?.data?.paymentIntentResponse['requestUserCount'] ? responseData?.data?.data?.paymentIntentResponse['requestUserCount'] : '',
                            'amount': responseData?.data?.data?.paymentIntentResponse['amount'],
                            'subscriptionType': responseData?.data?.data?.paymentIntentResponse['subscriptionType'],
                        }

                        await axiosInstance.post("/api/subscription-recurring-payment-confirm", requestData).then(async (response) => {
                            if (response.status === 200) {
                                hideLoader()
                                showSnackbar('Payment was completed successfully.', "success");
                                //navigate('/settings/subscription')
                                window.location.reload();
                            }
                        }).then(json => {

                        }).catch(error => {
                            if (error.response) {
                                hideLoader();
                                showSnackbar(error?.response?.data?.message, "error");
                            }
                        });
                    }
                });
            }
        } else {
            hideLoader()
            showSnackbar('Payment failed!', "error");
        }

    }

    return (<>
        <div className="card">
            <section className="checkout-layout inner-page">
                <div className="form-block">

                    {currentStep === 'manage-user-count' &&
                        <>
                            <div className="block-title">
                                <Typography variant="display-small-bold" component={'h1'}>Adjust Your Billable Users</Typography>
                                <p className="text-secondary">Your current billing includes {tenantUsers?.length} user(s). Use the controls below to increase or decrease the number of billable users to match your team’s needs. Once finalized, proceed to confirm and update your billing.</p>
                            </div>

                            <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Users to Be Included in Billing</Typography>

                            <Grid item sx={{ marginBottom: 4 }}>
                                <TextField
                                    variant="filled"
                                    value={tenantUserCount}
                                    onChange={(e) => setTenantUserCount(e.target.value)}
                                    className="no-label text-center number-stepper"
                                    //disabled={isEdittable}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton onClick={() => {
                                                const newCount = Math.max(tenantUserCount - 1, 0)
                                                setTenantUserCount(newCount)
                                            }}>
                                                <Icon path={mdiMinus} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        ),
                                        endAdornment: (
                                            <IconButton onClick={() => setTenantUserCount(tenantUserCount + 1)}>
                                                <Icon path={mdiPlus} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        ),

                                    }}
                                />
                            </Grid>

                            {
                                displayUserBox &&
                                <>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ marginBottom: 0.5 }}>
                                        <Typography variant="body-large-bold" component={'p'}>Delete {displayDeleteUserCnt === 1 ? `${displayDeleteUserCnt} User` : `${displayDeleteUserCnt} Users`}</Typography>
                                        <Icon path={mdiAlertCircleOutline} color={'currentColor'} size={0.833334} className="text-danger" />
                                    </Stack>
                                    <Typography variant="body-medium" className="text-secondary" component={'p'} sx={{ marginBottom: 2 }}>Deleting a user will permanently remove all data associated with that user, including leads, deals, quotes, and any other records linked to them. This action cannot be undone.</Typography>


                                    <div className="checkout-user-list">
                                        <ul className="inner">
                                            {tenantUsers?.length > 0 && tenantUsers.map(
                                                (user, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Stack direction="row" spacing={1.5} alignItems="center">
                                                                <Checkbox className='ml-n8 mr-n8' onClick={e => { e.stopPropagation(); }}
                                                                    onChange={() => handleCheckboxChange(user.id)}
                                                                    checked={deleteUserId.includes(user.id)}
                                                                />
                                                                <AvatarInitials name={user.name} sx={{ flexShrink: 0 }} />
                                                                <div>
                                                                    <Typography variant="title-medium-bold" component={'p'} sx={{ marginBottom: '-2px' }}>{user.name}</Typography>
                                                                    <Typography variant="body-medium" className="text-secondary">{user.email}</Typography>
                                                                </div>
                                                                <button type="button" onClick={() => deleteUser('single', user.id)} className="icon-button ml-auto flex-shrink-0 mr-n8">
                                                                    <Icon path={mdiTrashCanOutline} color={"currentColor"} size={1} />
                                                                </button>
                                                            </Stack>
                                                        </li>
                                                    )
                                                })
                                            }

                                            {
                                                deleteUserId.length !== 0 &&
                                                <li className="action-wrapper">
                                                    <Button size="small" onClick={() => deleteUser('multiple', deleteUserId)}>
                                                        <span>
                                                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                            <span>Delete Selected ({deleteUserId.length})</span>
                                                        </span>
                                                    </Button>
                                                </li>
                                            }

                                        </ul>
                                    </div>

                                </>
                            }
                            {displayNoteMsg &&
                                <div className="alert">
                                    <div className="title">
                                        <Icon path={mdiInformationOutline} color={'currentColor'} size={0.8333334} />
                                        <Typography variant="body-medium-bold" component={'p'}>Note</Typography>
                                    </div>
                                    <Typography variant="body-medium">When you add users to your current active plan, you will be billed on a pro rated basis on the number of users you add for the current billing cycle. After the end of the current billing cycle, you will be billed based on the total no of users (existing + added) in your plan from the next billing cycle.</Typography>
                                </div>
                            }
                            <div className="action-block">
                                <Button variant="text" onClick={onReset}>Cancel</Button>
                                <Button onClick={e => {
                                    if (tenantUserCount > parseInt(totalUserCnt?.planUserCount - 1)) {
                                        setCurrentStep('review-payment-details');
                                        updatePlan('upgrade');  // This will execute when the condition is true
                                    } else {
                                        downPlan('downgrade');
                                        setCurrentStep('review-changes');
                                    }
                                }} disabled={displayNextBtn}>
                                    <span>
                                        <span>Next</span>
                                        <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                    </span>
                                </Button>
                            </div>
                        </>
                    }



                    {
                        currentStep === 'review-payment-details' &&


                        <>
                            <div className="block-title">
                                <Typography variant="display-small-bold" component={'h1'}>Review and Complete Your Payment</Typography>
                                <p className="text-secondary">Here’s a summary of your payment details. Please review everything carefully and provide your payment information to complete your subscription.</p>
                            </div>
                            <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Order Summary</Typography>
                            {
                                orderDetail &&

                                <div className="item-summary">
                                    <ul>
                                        <li>
                                            <Typography component={'p'}>Plan</Typography>
                                            <Typography variant="body-large-medium">{orderDetail?.planName || 'N/A'} Plan</Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>Total Billable Users ({parseInt(orderDetail?.totalBillableUser - 1)} Users + 1 Tenant)</Typography>
                                            <Typography variant="body-large-medium">{orderDetail?.totalBillableUser || 'N/A'} Users</Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>New Users Added</Typography>
                                            <Typography variant="body-large-bold" className="text-success">+{orderDetail?.newAddedUser || 'N/A'} Users</Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>Cost Per User (Monthly)</Typography>
                                            <Typography variant="body-large-medium">
                                                {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                                {orderDetail?.costPerUser ? (Math.round(orderDetail?.costPerUser * 100) / 100).toFixed(2) : 'N/A'}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>
                                                Pro-Rated Cost Per New User<br />
                                                <a href="-" onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCalculationMenuClick(e);
                                                }} className="primary-link underlined">
                                                    <Typography variant="body-medium-medium">See Calculation</Typography>
                                                </a>
                                            </Typography>
                                            <Typography variant="body-large-medium">
                                                {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                                {orderDetail?.proRateCostPerUser ? (Math.round(orderDetail?.proRateCostPerUser * 100) / 100).toFixed(2) : 'N/A'}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>Next Bill Amount (Monthly)</Typography>
                                            <Typography variant="body-large-medium">
                                                {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                                {orderDetail?.nextBillAmount ? (Math.round(orderDetail?.nextBillAmount * 100) / 100).toFixed(2) : 'N/A'}
                                            </Typography>
                                        </li>
                                    </ul>
                                    <div className="highlight">
                                        <Typography variant="title-large" component={'p'}>Amount Payable Now</Typography>
                                        <Typography variant="title-large-semibold">
                                            {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                            {orderDetail?.payableAmount ? (Math.round(orderDetail?.payableAmount * 100) / 100).toFixed(2) : 'N/A'}
                                        </Typography>
                                    </div>
                                </div>
                            }
                            <Popover
                                open={calculationMenuOpen}
                                anchorEl={calculationMenuAnchorEl}
                                onClose={handleCalculationMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                classes={{
                                    paper: ''
                                }}
                            >
                                <div className="item-summary">
                                    <ul>
                                        <li>
                                            <Typography component={'p'}>Cost Per User (Monthly)</Typography>
                                            <Typography variant="body-large-medium">
                                                {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                                {orderDetail?.costPerUser ? ' ' + (Math.round(orderDetail?.costPerUser * 100) / 100).toFixed(2) : 'N/A'}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>Total Days in Billing Cycle</Typography>
                                            <Typography variant="body-large-medium">30</Typography>
                                        </li>
                                        <li>
                                            <Typography component={'p'}>Remaining Days in Billing Cycle</Typography>
                                            <Typography variant="body-large-medium">{orderDetail?.remainingDays}</Typography>
                                        </li>
                                        <li>
                                            <hr />
                                        </li>
                                        <li>
                                            <div>
                                                <Typography variant="body-large-medium" className="text-primary" component={'p'}>Pro-Rated Cost</Typography>
                                                <span className="text-secondary">({preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                                    {orderDetail?.costPerUser ? ' ' + (Math.round(orderDetail?.costPerUser * 100) / 100).toFixed(2) : 'N/A'} ÷ 30) × {orderDetail?.remainingDays}</span>
                                            </div>
                                            <Typography variant="body-large-medium" className="text-brand">
                                                {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                                {orderDetail?.proRateCostPerUser ? ' ' + (Math.round(orderDetail?.proRateCostPerUser * 100) / 100).toFixed(2) : 'N/A'}
                                            </Typography>
                                        </li>
                                    </ul>
                                </div>
                            </Popover>

                            <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Saved Payment Method</Typography>

                            <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                                <Grid item xs={'auto'}>
                                    <button type="button" className={`saved-card ${addNewCard ? '' : 'selected'}`} onClick={() => setaddNewCard(false)}>
                                        <VisaLogo className="logo" />
                                        <Typography variant="title-medium-large-semibold" component={'h6'}>xxxx {cardDetail?.last4}</Typography>
                                        <p>Exp. {cardDetail?.exp_month}/{cardDetail?.sort_exp_year}</p>
                                    </button>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <button type="button" className={`saved-card add-card ${addNewCard ? 'selected' : ''}`} onClick={() => setaddNewCard(true)}>
                                        <Icon path={mdiPlusCircleOutline} color={'currentColor'} size={1} />
                                        <Typography variant="body-large-medium" component={'p'}>Add New</Typography>
                                    </button>
                                </Grid>
                            </Grid>

                            {
                                addNewCard &&
                                <>
                                    <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Add a New Payment Method</Typography>

                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm amount={(Math.round(payAbleAmount * 100) / 100).toFixed(2)} onReset={(e, data) => {
                                            if (data && data === 'cancel') {
                                                onReset(e);
                                            }
                                        }} handlePaymentRes={handlePaymentRes} extraData={extraData} />
                                    </Elements>
                                </>
                            }


                            {
                                !addNewCard &&
                                <div className="action-block">

                                    <React.Fragment>
                                        <div className="col-md-12 order-md-1">
                                            <Elements stripe={stripePromise}>
                                                <ExistingCardPayment
                                                    amount={(Math.round(payAbleAmount * 100) / 100).toFixed(2)}
                                                    onReset={(e, data) => {
                                                        if (data && data === 'cancel') {
                                                            onReset(e);
                                                        }
                                                    }}
                                                    handlePaymentRes={handlePaymentRes}
                                                    extraData={extraData}
                                                />
                                            </Elements>
                                        </div>
                                    </React.Fragment>
                                </div>
                            }
                        </>
                    }



                    {
                        currentStep === 'review-changes' &&

                        <>

                            <div className="block-title">
                                <Typography variant="display-small-bold" component={'h1'}>Review Your Changes</Typography>
                                <p className="text-secondary">Take a moment to review the updated details of your subscription. These changes will reflect in your next billing cycle.<br /><br />By reducing the number of users, your monthly cost will be adjusted accordingly. Confirm the details below to proceed.</p>
                            </div>
                            <Typography variant="body-large-bold" component={'p'} sx={{ marginBottom: 1 }}>Order Summary</Typography>
                            <div className="item-summary">
                                <ul>
                                    <li>
                                        <Typography component={'p'}>Plan</Typography>
                                        <Typography variant="body-large-medium">{orderDetail?.planName || 'N/A'} Plan</Typography>
                                    </li>
                                    <li>
                                        <Typography component={'p'}>Total Billable Users ({orderDetail?.requiredUser} Users + 1 Tenant)</Typography>
                                        <Typography variant="body-large-medium">{orderDetail?.totalBillableUser || 'N/A'} Users</Typography>
                                    </li>
                                    <li>
                                        <Typography component={'p'}>Users Removed</Typography>
                                        <Typography variant="body-large-bold" className="text-danger">-{orderDetail?.removeUser} Users</Typography>
                                    </li>
                                    <li>
                                        <Typography component={'p'}>Cost Per User (Monthly)</Typography>
                                        <Typography variant="body-large-medium">
                                            {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                            {orderDetail?.costPerUser ? (Math.round(orderDetail?.costPerUser * 100) / 100).toFixed(2) : 'N/A'}
                                        </Typography>
                                    </li>
                                </ul>
                                <div className="highlight">
                                    <Typography variant="title-large" component={'p'}>Next Bill Amount (Monthly)</Typography>
                                    <Typography variant="title-large-semibold">
                                        {preferredCurrencyData.currencySymbol ? preferredCurrencyData.currencySymbol : '$'}
                                        {orderDetail?.payableAmount ? (Math.round(orderDetail?.payableAmount * 100) / 100).toFixed(2) : 'N/A'}
                                    </Typography>
                                </div>
                            </div>
                            <div className="action-block">
                                <Button variant="text" onClick={onReset}>Back</Button>
                                <Button onClick={() => handlePaymentRequest()}>
                                    <span>
                                        <span>Confirm</span>
                                        <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                    </span>
                                </Button>
                            </div>
                        </>
                    }

                    {
                        currentStep === 'update-success' &&
                        <>

                            <div className="block-title">
                                {currentTheme?.name === 'solvcrm' && <img src={Check3D} alt="Check" />}
                                <Typography variant="display-small-bold" component={'h1'}>Update Successful!</Typography>
                                <p className="text-secondary">Your changes have been saved, and the number of users has been updated. You’re all set to continue using SolvCRM with your adjusted team size.</p>
                            </div>

                            <div className="action-block mt-0">
                                <Button onClick={() => window.location.reload()}>
                                    <span>
                                        <span>View Subscription Details</span>
                                        <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                    </span>
                                </Button>
                            </div>
                        </>
                    }

                </div>

                <div className="image-block">
                    <img src={CheckoutArt} alt="Abstract Art" />
                </div>
            </section>
        </div>
    </>);
}

export default ManagePlan;