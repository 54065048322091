export const ContactIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 13.3332C6 8.30485 6 5.7907 7.56209 4.2286C9.1242 2.6665 11.6383 2.6665 16.6667 2.6665H18.6667C23.6949 2.6665 26.2092 2.6665 27.7712 4.2286C29.3333 5.7907 29.3333 8.30485 29.3333 13.3332V18.6665C29.3333 23.6948 29.3333 26.209 27.7712 27.771C26.2092 29.3332 23.6949 29.3332 18.6667 29.3332H16.6667C11.6383 29.3332 9.1242 29.3332 7.56209 27.771C6 26.209 6 23.6948 6 18.6665V13.3332Z" stroke="currentColor" strokeWidth="2" />
                <path d="M6 8H2.66666M6 16H2.66666M6 24H2.66666" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.3664 11.3233C20.3664 12.796 19.1725 13.99 17.6999 13.99C16.2272 13.99 15.0333 12.796 15.0333 11.3233C15.0333 9.8506 16.2272 8.65674 17.6999 8.65674C19.1725 8.65674 20.3664 9.8506 20.3664 11.3233Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.4264 20.9547C13.8376 18.7823 16.0785 17.9681 17.6999 17.9697C19.3213 17.9715 21.4965 18.7823 22.9076 20.9547C22.9989 21.0952 23.024 21.2681 22.9417 21.4141C22.6117 21.9993 21.5871 23.1605 20.8469 23.2393C19.9967 23.3297 17.7721 23.3424 17.7016 23.3428C17.6309 23.3424 15.3379 23.3297 14.4871 23.2393C13.7471 23.1605 12.7224 21.9993 12.3924 21.4141C12.31 21.2681 12.3352 21.0952 12.4264 20.9547Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const WebMobileIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.6667 22.9148C25.124 23.9998 22.9476 23.9998 18.5949 23.9998H13.2855C8.9328 23.9998 6.75645 23.9998 5.21373 22.9148C4.64297 22.5133 4.14653 22.0146 3.74689 21.4413C2.66666 19.8916 2.66666 17.7054 2.66666 13.3332C2.66666 8.96086 2.66666 6.77472 3.74689 5.22505C4.14653 4.65172 4.64297 4.15304 5.21373 3.7516C6.75645 2.6665 8.9328 2.6665 13.2855 2.6665" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M16 24V29.3333" stroke="currentColor" strokeWidth="2" />
                <path d="M10.6667 29.3335H21.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M24 2.6665C26.5141 2.6665 27.7712 2.6665 28.5523 3.44756C29.3333 4.2286 29.3333 5.48568 29.3333 7.99984V13.3332C29.3333 15.8473 29.3333 17.1044 28.5523 17.8854C27.7712 18.6665 26.5141 18.6665 24 18.6665H22.6667C20.1525 18.6665 18.8955 18.6665 18.1144 17.8854C17.3333 17.1044 17.3333 15.8473 17.3333 13.3332V7.99984C17.3333 5.48568 17.3333 4.2286 18.1144 3.44756C18.8955 2.6665 20.1525 2.6665 22.6667 2.6665H24Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M23.3333 15.3335H23.3423" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const MapLocationIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.1716 18.3291C19.9468 18.5456 19.6463 18.6667 19.3335 18.6667C19.0207 18.6667 18.7201 18.5456 18.4953 18.3291C16.4363 16.3339 13.6768 14.1051 15.0225 10.8692C15.7501 9.11955 17.4967 8 19.3335 8C21.1703 8 22.9168 9.11955 23.6444 10.8692C24.9884 14.1009 22.2357 16.3407 20.1716 18.3291Z" stroke="currentColor" strokeWidth="2" />
                <path d="M19.3333 12.6665H19.3423" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.33334 16.0002C3.33334 10.029 3.33334 7.04348 5.18832 5.18848C7.04332 3.3335 10.0289 3.3335 16 3.3335C21.9711 3.3335 24.9567 3.3335 26.8117 5.18848C28.6667 7.04348 28.6667 10.029 28.6667 16.0002C28.6667 21.9712 28.6667 24.9568 26.8117 26.8119C24.9567 28.6668 21.9711 28.6668 16 28.6668C10.0289 28.6668 7.04332 28.6668 5.18832 26.8119C3.33334 24.9568 3.33334 21.9712 3.33334 16.0002Z" stroke="currentColor" strokeWidth="2" />
                <path d="M22.6667 28.0002L4 9.3335" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.3333 18.6665L5.33333 26.6665" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const EmailUserIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33334 10L13.256 12.3192C15.5429 13.6713 16.4571 13.6713 18.744 12.3192L22.6667 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M29.328 13.3335C29.3244 12.8445 29.3192 13.0271 29.3123 12.7022C29.2252 8.61356 29.1816 6.56925 27.6735 5.05489C26.1652 3.54053 24.0663 3.48777 19.8683 3.38227C17.2809 3.31724 14.7191 3.31724 12.1318 3.38225C7.93377 3.48776 5.83477 3.54051 4.3266 5.05487C2.81842 6.56924 2.77485 8.61353 2.68768 12.7021C2.65965 14.0168 2.65966 15.3236 2.68769 16.6383C2.77485 20.727 2.81844 22.7712 4.32661 24.2856C5.83477 25.7999 7.93377 25.8527 12.1318 25.9582C12.9424 25.9786 13.9943 25.9926 14.6667 26.0002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.7813 24.8643C19.9429 25.3556 17.7448 26.3591 19.0836 27.6147C19.7376 28.228 20.466 28.6667 21.3819 28.6667H26.6075C27.5232 28.6667 28.2516 28.228 28.9056 27.6147C30.2445 26.3591 28.0463 25.3556 27.2079 24.8643C25.2419 23.7119 22.7473 23.7119 20.7813 24.8643Z" stroke="currentColor" strokeWidth="2" />
                <path d="M26.6613 18.6667C26.6613 20.1395 25.4673 21.3333 23.9947 21.3333C22.5219 21.3333 21.328 20.1395 21.328 18.6667C21.328 17.1939 22.5219 16 23.9947 16C25.4673 16 26.6613 17.1939 26.6613 18.6667Z" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const FileUploadIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 15.9998V19.3921C6 23.7188 6 25.8821 7.18143 27.3474C7.42011 27.6434 7.68975 27.913 7.98577 28.1517C9.45108 29.3332 11.6144 29.3332 15.9411 29.3332C16.8819 29.3332 17.3521 29.3332 17.7829 29.1812C17.8725 29.1496 17.9603 29.1132 18.046 29.0722C18.4581 28.875 18.7907 28.5425 19.4559 27.8773L25.7712 21.562C26.542 20.7912 26.9273 20.4058 27.1304 19.9157C27.3333 19.4257 27.3333 18.8806 27.3333 17.7906V13.3332C27.3333 8.30485 27.3333 5.7907 25.7712 4.2286C24.2092 2.6665 21.6949 2.6665 16.6667 2.6665M18 28.6665V27.9998C18 24.2286 18 22.343 19.1716 21.1714C20.3432 19.9998 22.2288 19.9998 26 19.9998H26.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 6.6665C13.2135 5.85738 11.1204 2.6665 10 2.6665C8.87964 2.6665 6.78645 5.85738 6 6.6665M10 3.99984V13.3332" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const AudioCameraIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0002 8.00049C8.3727 8.00537 7.47199 8.0443 6.73186 8.35495C5.69515 8.79009 4.8509 9.59386 4.3577 10.6153C3.95513 11.449 3.88926 12.5172 3.7575 14.6534L3.55105 18.001C3.22341 23.3136 3.05958 25.9698 4.61847 27.6516C6.17735 29.3332 8.80359 29.3332 14.0561 29.3332H19.2777C24.5302 29.3332 27.1564 29.3332 28.7153 27.6516C30.2742 25.9698 30.1104 23.3136 29.7828 18.001L29.5762 14.6534C29.4445 12.5172 29.3786 11.449 28.9761 10.6153C28.4829 9.59386 27.6386 8.79009 26.602 8.35495C25.8618 8.0443 24.961 8.00537 23.3336 8.00049" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M23.3336 9.33317L22.1525 6.38041C21.6429 5.10645 21.1995 3.6613 19.8891 3.01257C19.1901 2.6665 18.3491 2.6665 16.6669 2.6665C14.9848 2.6665 14.1437 2.6665 13.4447 3.01257C12.1344 3.6613 11.6909 5.10645 11.1814 6.38041L10.0002 9.33317" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.2775 19.6633C22.4599 19.142 22.1851 18.5716 21.6637 18.3893C21.1424 18.2069 20.572 18.4817 20.3896 19.003L22.2775 19.6633ZM12.9442 19.003C12.7618 18.4817 12.1914 18.2069 11.6701 18.3893C11.1488 18.5716 10.874 19.142 11.0563 19.6633L12.9442 19.003ZM15.6669 24.6665C15.6669 25.2188 16.1147 25.6665 16.6669 25.6665C17.2192 25.6665 17.6669 25.2188 17.6669 24.6665H15.6669ZM18.0003 14.9998V17.6665H20.0003V14.9998H18.0003ZM15.3336 17.6665V14.9998H13.3336V17.6665H15.3336ZM16.6669 18.9998C15.9305 18.9998 15.3336 18.4029 15.3336 17.6665H13.3336C13.3336 19.5074 14.826 20.9998 16.6669 20.9998V18.9998ZM18.0003 17.6665C18.0003 18.4029 17.4033 18.9998 16.6669 18.9998V20.9998C18.5079 20.9998 20.0003 19.5074 20.0003 17.6665H18.0003ZM16.6669 13.6665C17.4033 13.6665 18.0003 14.2634 18.0003 14.9998H20.0003C20.0003 13.1589 18.5079 11.6665 16.6669 11.6665V13.6665ZM16.6669 11.6665C14.826 11.6665 13.3336 13.1589 13.3336 14.9998H15.3336C15.3336 14.2634 15.9305 13.6665 16.6669 13.6665V11.6665ZM20.3896 19.003C19.8445 20.5616 18.3779 21.6665 16.6669 21.6665V23.6665C19.2649 23.6665 21.4641 21.9888 22.2775 19.6633L20.3896 19.003ZM16.6669 21.6665C14.956 21.6665 13.4893 20.5616 12.9442 19.003L11.0563 19.6633C11.8697 21.9888 14.0689 23.6665 16.6669 23.6665V21.6665ZM15.6669 22.6665V24.6665H17.6669V22.6665H15.6669Z" fill="currentColor" />
            </svg>
        </>
    )
}

export const WhatsAppIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6666 29.3332C24.0304 29.3332 30 23.3636 30 15.9998C30 8.63604 24.0304 2.6665 16.6666 2.6665C9.30285 2.6665 3.33331 8.63604 3.33331 15.9998C3.33331 17.8384 3.70541 19.59 4.37841 21.1834C4.75035 22.064 4.93633 22.5044 4.95935 22.8372C4.98238 23.17 4.88443 23.536 4.68854 24.2681L3.33331 29.3332L8.39834 27.978C9.13049 27.7821 9.49657 27.6841 9.82934 27.7072C10.1621 27.7301 10.6024 27.9161 11.4831 28.2881C13.0766 28.961 14.8281 29.3332 16.6666 29.3332Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
                <path d="M12.1175 16.5032L13.2788 15.061C13.7682 14.4531 14.3732 13.8872 14.4207 13.0778C14.4325 12.8734 14.2888 11.9556 14.0011 10.12C13.888 9.39858 13.2145 9.3335 12.6311 9.3335C11.8709 9.3335 11.4907 9.3335 11.1133 9.50591C10.6362 9.72383 10.1464 10.3366 10.0389 10.8499C9.95385 11.2561 10.0171 11.536 10.1435 12.0957C10.6803 14.4732 11.9397 16.8212 13.8926 18.7742C15.8456 20.7271 18.1936 21.9866 20.5711 22.5234C21.1308 22.6498 21.4107 22.713 21.8169 22.6279C22.3303 22.5204 22.9429 22.0307 23.1609 21.5535C23.3333 21.176 23.3333 20.796 23.3333 20.0358C23.3333 19.4523 23.2683 18.7788 22.5468 18.6658C20.7112 18.378 19.7935 18.2343 19.5889 18.2462C18.7796 18.2936 18.2137 18.8986 17.6059 19.388L16.1636 20.5492" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const EmailShieldIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.83334 10L13.756 12.3192C16.0429 13.6713 16.9571 13.6713 19.244 12.3192L23.1667 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.1667 26.0002C17.1667 26.0002 13.9255 25.9839 12.6318 25.9514C8.43376 25.8459 6.33476 25.7932 4.8266 24.2794C3.31843 22.7654 3.27484 20.7218 3.18768 16.6343C3.15966 15.32 3.15964 14.0136 3.18767 12.6993C3.27484 8.61196 3.31842 6.56827 4.82659 5.05436C6.33476 3.54044 8.43376 3.48771 12.6318 3.38224C15.2191 3.31724 17.7809 3.31724 20.3683 3.38226C24.5663 3.48774 26.6652 3.54047 28.1735 5.05438C29.6816 6.56828 29.7252 8.61198 29.8123 12.6994C29.8263 13.3564 29.8333 14.6783 29.8333 15.3335" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M29.8333 22.6667V19.3333C27.1667 19.3333 25.1667 18 25.1667 18C25.1667 18 23.1667 19.3333 20.5 19.3333V22.6667C20.5 27.3333 25.1667 28.6667 25.1667 28.6667C25.1667 28.6667 29.8333 27.3333 29.8333 22.6667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const BriefcaseDollarIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.00006 18.6667C3.00006 13.9912 3.00006 11.6534 4.21067 10.0799C4.4323 9.79184 4.67991 9.52772 4.94997 9.29132C6.4251 8 8.61676 8 13.0001 8H19.6667C24.0501 8 26.2417 8 27.7169 9.29132C27.9869 9.52772 28.2345 9.79184 28.4561 10.0799C29.6667 11.6534 29.6667 13.9912 29.6667 18.6667C29.6667 23.3421 29.6667 25.68 28.4561 27.2535C28.2345 27.5415 27.9869 27.8056 27.7169 28.042C26.2417 29.3333 24.0501 29.3333 19.6667 29.3333H13.0001C8.61676 29.3333 6.4251 29.3333 4.94997 28.042C4.67991 27.8056 4.4323 27.5415 4.21067 27.2535C3.00006 25.68 3.00006 23.3421 3.00006 18.6667Z" stroke="currentColor" strokeWidth="2" />
                <path d="M21.6667 7.99984C21.6667 5.48568 21.6667 4.2286 20.8857 3.44756C20.1046 2.6665 18.8475 2.6665 16.3334 2.6665C13.8193 2.6665 12.5622 2.6665 11.7811 3.44756C11.0001 4.2286 11.0001 5.48568 11.0001 7.99984" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.3334 14.6668C14.8606 14.6668 13.6667 15.5623 13.6667 16.6668C13.6667 17.7714 14.8606 18.6668 16.3334 18.6668C17.8062 18.6668 19 19.5623 19 20.6668C19 21.7714 17.8062 22.6668 16.3334 22.6668M16.3334 14.6668C17.4944 14.6668 18.4822 15.2234 18.8483 16.0002M16.3334 14.6668V13.3335M16.3334 22.6668C15.1723 22.6668 14.1846 22.1103 13.8184 21.3335M16.3334 22.6668V24.0002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M8.33339 16H3.00006" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M29.6667 16H24.3334" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </>
    )
}

export const UserGroupIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.1549 26.6665H25.8085C27.3415 26.6665 28.561 25.968 29.6559 24.9913C32.4374 22.51 25.8989 19.9998 23.6667 19.9998M21.0001 6.7582C21.3029 6.69814 21.6173 6.6665 21.9398 6.6665C24.3663 6.6665 26.3334 8.45737 26.3334 10.6665C26.3334 12.8756 24.3663 14.6665 21.9398 14.6665C21.6173 14.6665 21.3029 14.6349 21.0001 14.5748" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M6.30846 21.4816C4.7365 22.324 0.614893 24.0441 3.12522 26.1965C4.3515 27.248 5.71726 28 7.43434 28H17.2324C18.9495 28 20.3152 27.248 21.5415 26.1965C24.0519 24.0441 19.9303 22.324 18.3583 21.4816C14.672 19.5061 9.9947 19.5061 6.30846 21.4816Z" stroke="currentColor" strokeWidth="2" />
                <path d="M17.6667 9.99984C17.6667 12.9454 15.2789 15.3332 12.3334 15.3332C9.38787 15.3332 7.00006 12.9454 7.00006 9.99984C7.00006 7.05432 9.38787 4.6665 12.3334 4.6665C15.2789 4.6665 17.6667 7.05432 17.6667 9.99984Z" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const PaperEditIcon = () => {
    return (
        <>
            <svg width="100%" height="100%" className="icon" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.667 14.6665V13.3332C26.667 8.30485 26.667 5.7907 25.1048 4.2286C23.5427 2.6665 21.0286 2.6665 16.0003 2.6665H14.6671C9.63881 2.6665 7.12466 2.6665 5.56257 4.22858C4.00047 5.79066 4.00045 8.3048 4.00041 13.3331L4.00037 18.6665C4.00031 23.6948 4.0003 26.2089 5.56234 27.771C7.12443 29.333 9.63867 29.3332 14.667 29.3332" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.0004 9.3335H20.667M10.0004 16.0002H20.667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                <path d="M18.0003 27.7689V29.3332H19.5648C20.1107 29.3332 20.3836 29.3332 20.629 29.2316C20.8744 29.1298 21.0674 28.9369 21.4534 28.551L27.8848 22.119C28.2488 21.755 28.4308 21.573 28.5282 21.3768C28.7134 21.0032 28.7134 20.5646 28.5282 20.191C28.4308 19.9946 28.2488 19.8126 27.8848 19.4486C27.5207 19.0846 27.3387 18.9026 27.1423 18.8053C26.7687 18.6202 26.33 18.6202 25.9564 18.8053C25.7602 18.9026 25.578 19.0846 25.214 19.4486L18.7826 25.8806C18.3966 26.2665 18.2036 26.4594 18.102 26.7048C18.0003 26.9502 18.0003 27.223 18.0003 27.7689Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const RadioIcon = ({ className }) => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={"icon " + className}>
                <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
            </svg>
        </>
    )
}

export const RadioCheckedIcon = ({ className }) => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={"icon " + className}>
                <circle cx="12" cy="12" r="7" stroke="currentColor" strokeWidth="6" />
            </svg>
        </>
    )
}

export const VisaLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="24" viewBox="0 0 42 24" fill="none" className={"icon " + className} xmlns="http://www.w3.org/2000/svg">
                <path d="M20.7957 5.32664L17.9897 18.6532H14.5957L17.402 5.32664H20.7957ZM35.0739 13.9318L36.8602 8.92738L37.8881 13.9318H35.0739ZM38.8618 18.6532H42L39.2584 5.32664H36.3636C35.7112 5.32664 35.1612 5.7109 34.9178 6.30348L29.8255 18.6532H33.3897L34.0973 16.663H38.4508L38.8618 18.6532ZM30.0022 14.3026C30.017 10.7854 25.2162 10.5906 25.2483 9.0191C25.2587 8.54162 25.7071 8.03296 26.6873 7.90288C27.1733 7.83933 28.5143 7.78778 30.0344 8.49935L30.6289 5.67224C29.8125 5.3722 28.7618 5.08325 27.4544 5.08325C24.0994 5.08325 21.7393 6.89366 21.7204 9.48819C21.6988 11.4068 23.4063 12.4766 24.6901 13.1156C26.0137 13.7687 26.4572 14.1875 26.4507 14.7711C26.4415 15.6652 25.3953 16.0611 24.421 16.0761C22.7138 16.1031 21.7242 15.6073 20.9355 15.2338L20.3195 18.1554C21.1137 18.5247 22.5772 18.846 24.0926 18.8625C27.6595 18.8625 29.9916 17.0733 30.0022 14.3026ZM15.9462 5.32664L10.4473 18.6532H6.86029L4.15406 8.01768C3.99001 7.36365 3.84691 7.12326 3.34797 6.8469C2.53186 6.3967 1.18464 5.97557 0 5.7136L0.0802555 5.32664H5.85504C6.59061 5.32664 7.25242 5.8239 7.42089 6.68475L8.85043 14.397L12.3807 5.32664H15.9462Z" fill="#1434CB" />
            </svg>
        </>
    )
}

export const CallUser = ({ className }) => {
    return (
        <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" className={"icon " + className} xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6057 33.3905C20.5719 39.3567 24.5493 35.3792 24.5493 35.3792L28.5268 31.4017L38.4705 41.3454L34.4931 45.3229C34.4931 45.3229 24.5493 51.289 10.6281 37.3679C-3.29298 23.4468 2.67327 13.5031 2.67327 13.5031L6.65062 9.52556L16.5944 19.4692L12.6169 23.4468C12.6169 23.4468 8.63943 27.4242 14.6057 33.3905Z" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.65039 9.52555L16.5941 19.4692" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.67383 13.503L12.6174 23.4467" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M24.5508 35.3792L34.4945 45.3229" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.5273 20.143V19.6876C32.5273 17.3576 34.4161 15.4688 36.7461 15.4688C39.0761 15.4688 40.9648 17.3576 40.9648 19.6876V20.143" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M36.7461 21.0938C31.3188 21.0938 26.9023 16.6773 26.9023 11.25C26.9023 5.82272 31.3188 1.40625 36.7461 1.40625C42.1734 1.40625 46.5898 5.82272 46.5898 11.25C46.5898 16.6773 42.1734 21.0938 36.7461 21.0938Z" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M39.5586 9.84375C39.5586 11.397 38.2993 12.6562 36.7461 12.6562C35.1928 12.6562 33.9336 11.397 33.9336 9.84375C33.9336 8.2905 35.1928 7.03125 36.7461 7.03125C38.2993 7.03125 39.5586 8.2905 39.5586 9.84375Z" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.373 11.2914C17.8594 10.6498 19.4663 10.2346 21.1505 10.0892" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M37.9092 26.8481C37.7638 28.5322 37.3487 30.1391 36.707 31.6254" stroke="#29A385" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export const Twitter = ({ className }) => {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" className={"icon " + className} xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.93449 2.3327C2.0912 2.0262 2.40642 1.83334 2.75065 1.83334H7.33398C7.62833 1.83334 7.90477 1.97469 8.07711 2.21331L12.4442 8.26008L18.6025 2.10183C18.9604 1.74385 19.5409 1.74385 19.8988 2.10183C20.2568 2.45981 20.2568 3.04021 19.8988 3.39819L13.5315 9.76554L19.9938 18.7133C20.1954 18.9923 20.2235 19.3608 20.0668 19.6674C19.9101 19.9738 19.5949 20.1667 19.2506 20.1667H14.6673C14.373 20.1667 14.0965 20.0253 13.9242 19.7867L9.55708 13.7399L3.39883 19.8982C3.04086 20.2561 2.46045 20.2561 2.10248 19.8982C1.74449 19.5402 1.74449 18.9598 2.10248 18.6018L8.46982 12.2345L2.00753 3.28671C1.80599 3.00765 1.77777 2.63919 1.93449 2.3327Z" fill="currentColor" />
            </svg>
        </>
    )
}

export const AmericanExpressLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="24" className={"icon " + className} viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRuleˀ="evenodd" clipRule="evenodd" d="M41.9999 12.3426H39.8152C39.1558 12.3426 38.6769 12.5 38.3377 12.7448V12.3426H35.1062C34.5895 12.3426 33.9829 12.4703 33.696 12.7448V12.3426H27.9254V12.7448C27.4661 12.4145 26.6912 12.3426 26.3336 12.3426H22.5272V12.7448C22.1639 12.394 21.3559 12.3426 20.8634 12.3426H16.6035L15.6287 13.3944L14.7156 12.3426H8.35217V19.2147H14.5959L15.6003 18.1463L16.5466 19.2147L20.3952 19.2182V17.6016H20.7736C21.2842 17.6094 21.8865 17.5889 22.4179 17.3599V19.2146H25.5923V17.4235H25.7454C25.9409 17.4235 25.9601 17.4315 25.9601 17.6262V19.2144H35.6035C36.2157 19.2144 36.8557 19.0582 37.2101 18.7747V19.2144H40.2689C40.9054 19.2144 41.5271 19.1255 42 18.8977C42 -18.7021 41.9999 28.0854 41.9999 12.3426Z" fill="#006FCF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.01713 10.7385L2.47192 9.64483H3.49009L3.94369 10.7385H7.91226V9.90236L8.2665 10.7421H10.3267L10.6809 9.88988V10.7385H20.5436L20.539 8.94324H20.7298C20.8634 8.94784 20.9024 8.96017 20.9024 9.18012V10.7385H26.0034V10.3206C26.4149 10.5404 27.0548 10.7385 27.8969 10.7385H30.0429L30.5022 9.64483H31.5203L31.9695 10.7385H36.1049V9.69963L36.7311 10.7385H40.045V3.87109H36.7654V4.68214L36.3061 3.87109H32.9409V4.68214L32.5191 3.87109H27.9735C27.2126 3.87109 26.5437 3.97699 26.0034 4.27209V3.87109H22.8665V4.27209C22.5227 3.96793 22.0542 3.87109 21.5333 3.87109H10.073L9.30404 5.64483L8.51438 3.87109H4.90466V4.68214L4.50812 3.87109H1.42961L0 7.13623V10.7385H2.01713Z" fill="#006FCF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.8259 16.5882C21.8578 16.5882 22.8816 16.3057 22.8816 14.9376C22.8816 13.5736 21.8291 13.3151 20.8922 13.3151H17.1201L15.6186 14.9294L14.1662 13.3151H9.41821V18.2402H14.0942L15.6049 16.6098L17.0585 18.2402H19.353V16.5882H20.8259ZM19.353 15.588H20.8785C21.3321 15.588 21.6143 15.3636 21.6143 14.9376C21.6143 14.5069 21.3184 14.3338 20.8922 14.3338H19.353V15.588ZM13.4966 17.2138H10.5941V16.2339H13.1858V15.229H10.5941V14.3336H13.5538L14.845 15.7689L13.4966 17.2138ZM18.1772 17.7923L16.3647 15.7861L18.1772 13.8437V17.7923Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M27.9302 16.3649C27.8295 16.2214 27.6147 16.0481 27.3519 15.9514C27.6627 15.8284 28.1885 15.4273 28.1887 14.6412C28.1887 14.0793 27.9543 13.7719 27.5818 13.5475C27.1943 13.3414 26.759 13.3151 26.1614 13.3151H23.4359V18.2402H24.6221V16.4413H25.8837C26.3144 16.4413 26.5773 16.4835 26.7498 16.66C26.9473 16.8661 26.9439 17.2422 26.9412 17.5313C26.9409 17.5634 26.9406 17.5944 26.9406 17.624V18.2402H28.1258V17.2638C28.121 16.8297 28.0969 16.6053 27.9302 16.3649ZM26.639 15.3385C26.4811 15.4319 26.2858 15.4399 26.0561 15.4399H24.622V14.3338H26.0756C26.2858 14.3338 26.4961 14.3382 26.639 14.4227C26.792 14.5025 26.8834 14.655 26.8834 14.8658C26.8834 15.0765 26.792 15.2463 26.639 15.3385Z" fill="white" />
                <path d="M37.2912 15.6426C37.521 15.8798 37.6442 16.1792 37.6442 16.6861C37.6442 17.7456 36.9803 18.2402 35.7897 18.2402H33.4905V17.1841H35.7805C36.0044 17.1841 36.1632 17.1545 36.2627 17.0621C36.3439 16.986 36.4021 16.8753 36.4021 16.7409C36.4021 16.5974 36.3391 16.4835 36.2581 16.4152C36.1678 16.3401 36.0433 16.3059 35.8377 16.3059C35.7654 16.3034 35.6919 16.3014 35.6177 16.2994C34.5586 16.2707 33.3512 16.238 33.3512 14.7815C33.3512 14.0672 33.8014 13.3152 35.039 13.3152H37.4056V14.3634H35.2401C35.0254 14.3634 34.8858 14.3715 34.7671 14.4524C34.6378 14.5322 34.5898 14.6505 34.5898 14.8068C34.5898 14.9926 34.6996 15.1189 34.8481 15.1736C34.9727 15.2168 35.1064 15.2294 35.3075 15.2294L35.943 15.2465C36.5839 15.2621 37.0238 15.3726 37.2912 15.6426Z" fill="white" />
                <path d="M42.0001 14.3631H39.8486C39.6338 14.3631 39.4911 14.3712 39.3708 14.4521C39.2463 14.5318 39.1983 14.6502 39.1983 14.8065C39.1983 14.9923 39.3035 15.1186 39.4565 15.1733C39.581 15.2165 39.7148 15.2291 39.9114 15.2291L40.5512 15.2462C41.1969 15.2621 41.6278 15.3728 41.8906 15.6426C41.9309 15.6743 41.9577 15.7093 41.9849 15.7448L41.9922 15.7544L41.9969 15.7605L42.0001 15.7645V17.6102C41.7133 18.0283 41.1546 18.2402 40.3981 18.2402H38.1184V17.1841H40.3889C40.6141 17.1841 40.7717 17.1545 40.8666 17.0622C40.9488 16.986 41.0062 16.8753 41.0062 16.7409C41.0062 16.5974 40.9488 16.4835 40.862 16.4152C40.7763 16.3401 40.6516 16.3059 40.4461 16.3059C40.3736 16.3034 40.2999 16.3015 40.2255 16.2994C39.1626 16.2707 37.9548 16.2381 37.9548 14.7815C37.9548 14.0672 38.4097 13.3152 39.6485 13.3152H41.9999L42.0001 14.3631Z" fill="white" />
                <path d="M28.8432 13.3151H32.7832V14.3336H30.0188V15.229H32.7157V16.2339H30.0188V17.2138L32.7832 17.2182V18.2402H28.8432V13.3151Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.78588 7.63944L2.9779 5.6765L2.17453 7.63944H3.78588ZM21.5857 6.85785C21.4235 6.95602 21.2316 6.95928 21.0017 6.95928H19.5677V5.86561H21.0213C21.227 5.86561 21.4416 5.87482 21.5811 5.95442C21.7342 6.02615 21.8289 6.17883 21.8289 6.38972C21.8289 6.60492 21.7388 6.77809 21.5857 6.85785ZM31.8163 7.63944L30.9994 5.6765L30.1869 7.63944H31.8163ZM12.7458 9.76413H11.5356L11.5311 5.90775L9.8194 9.76413H8.7829L7.06668 5.90433V9.76413H4.66566L4.21207 8.66585H1.75415L1.29593 9.76413H0.0137939L2.12775 4.84021H3.88166L5.88941 9.50215V4.84021H7.81612L9.36103 8.1805L10.7802 4.84021H12.7457V9.76413H12.7458ZM17.5692 9.76413H13.6256V4.84021H17.5692V5.86557H14.8062V6.75311H17.5029V7.76242H14.8062V8.74575H17.5692V9.76413ZM23.1294 6.16632C23.1294 6.95137 22.6038 7.35697 22.2976 7.47876C22.5559 7.57678 22.7765 7.74995 22.8815 7.89342C23.0482 8.13832 23.077 8.35709 23.077 8.79684V9.76413H21.8863L21.8818 9.14318C21.8818 8.84689 21.9103 8.4208 21.6955 8.18392C21.523 8.01074 21.26 7.97317 20.835 7.97317H19.5678V9.76413H18.3874V4.84021H21.1026C21.7059 4.84021 22.1504 4.8561 22.532 5.07605C22.9055 5.29601 23.1294 5.6171 23.1294 6.16632ZM25.0186 9.76413H23.814V4.84021H25.0186V9.76413ZM38.9927 9.76413H37.3198L35.0822 6.06844V9.76413H32.678L32.2186 8.66585H29.7664L29.3207 9.76413H27.9393C27.3655 9.76413 26.639 9.6376 26.2276 9.21952C25.8127 8.80145 25.5969 8.23515 25.5969 7.33975C25.5969 6.60949 25.7259 5.94191 26.2332 5.41438C26.6149 5.0214 27.2125 4.84021 28.026 4.84021H29.1689V5.89527H28.05C27.6192 5.89527 27.3759 5.95913 27.1416 6.18696C26.9404 6.39429 26.8023 6.78622 26.8023 7.30232C26.8023 7.82985 26.9074 8.2102 27.1269 8.45867C27.3086 8.65352 27.6389 8.71263 27.9496 8.71263H28.4798L30.1436 4.84036H31.9124L33.911 9.49769V4.84036H35.7084L37.7835 8.26961V4.84036H38.9927V9.76413Z" fill="white" />
            </svg>
        </>
    )
}

export const JCBLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="25" className={"icon " + className} viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36.5801 19.3255C36.5801 21.9921 34.4087 24.1636 31.742 24.1636H5.41821V5.00167C5.41821 2.335 7.58964 0.163574 10.2563 0.163574H36.5801V19.3255Z" fill="white" />
                <path d="M29.78 1.78296C27.8753 1.78296 26.3134 3.32582 26.3134 5.24963V8.84963H31.2086C31.3229 8.84963 31.4562 8.84963 31.5515 8.86867C32.6562 8.92582 33.4753 9.49725 33.4753 10.4877C33.4753 11.2687 32.9229 11.9353 31.8943 12.0687V12.1068C33.0181 12.183 33.8753 12.8115 33.8753 13.783C33.8753 14.8306 32.9229 15.5163 31.6657 15.5163H26.2943V22.5639H31.38C33.2848 22.5639 34.8467 21.0211 34.8467 19.0972V1.78296H29.78Z" fill="url(#paint0_linear_5675_14761)" />
                <path d="M28.0086 14.4115H30.0086C30.0372 14.4115 30.0848 14.4068 30.1324 14.402C30.18 14.3972 30.2276 14.3925 30.2562 14.3925C30.6372 14.3163 30.961 13.9734 30.961 13.4972C30.961 13.0401 30.6372 12.6972 30.2562 12.602C30.1991 12.5829 30.0848 12.5829 30.0086 12.5829H28.0086V14.4115Z" fill="url(#paint1_linear_5675_14761)" />
                <path d="M30.0086 9.89724C30.3895 9.95438 30.7134 10.2591 30.7134 10.7163C30.7134 11.1734 30.3895 11.4782 30.0086 11.5353C29.9895 11.5544 29.8753 11.5544 29.8181 11.5544H28.0086V9.87819H29.8181C29.8496 9.87819 29.8926 9.88396 29.9312 9.88915C29.9628 9.89339 29.9915 9.89724 30.0086 9.89724Z" fill="url(#paint2_linear_5675_14761)" />
                <path d="M10.6183 1.78296C8.71352 1.78296 7.15162 3.32582 7.15162 5.24963V13.802C8.12304 14.2782 9.13257 14.583 10.1421 14.583C11.3421 14.583 11.9897 13.8591 11.9897 12.8687V8.83058H14.9611V12.8496C14.9611 14.4115 13.9897 15.6877 10.6945 15.6877C8.69447 15.6877 7.13257 15.2496 7.13257 15.2496V22.5449H12.2183C14.123 22.5449 15.6849 21.002 15.6849 19.0782V1.78296H10.6183Z" fill="url(#paint3_linear_5675_14761)" />
                <path d="M20.1996 1.78296C18.2948 1.78296 16.7329 3.32582 16.7329 5.24963V9.78296C17.6091 9.0401 19.1329 8.56391 21.5901 8.6782C22.9043 8.73534 24.3139 9.09724 24.3139 9.09724V10.5639C23.6091 10.202 22.771 9.8782 21.6853 9.80201C19.8186 9.66867 18.6948 10.583 18.6948 12.183C18.6948 13.802 19.8186 14.7163 21.6853 14.5639C22.771 14.4877 23.6091 14.1449 24.3139 13.802V15.2687C24.3139 15.2687 22.9234 15.6306 21.5901 15.6877C19.1329 15.802 17.6091 15.3258 16.7329 14.583V22.583H21.8186C23.7234 22.583 25.2853 21.0401 25.2853 19.1163V1.78296H20.1996Z" fill="url(#paint4_linear_5675_14761)" />
                <defs>
                    <linearGradient id="paint0_linear_5675_14761" x1="26.3096" y1="10.7134" x2="34.8694" y2="10.7134" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#007940" />
                        <stop offset="0.2285" stopColor="#00873F" />
                        <stop offset="0.7433" stopColor="#40A737" />
                        <stop offset="1" stopColor="#5CB531" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_5675_14761" x1="26.3096" y1="10.7134" x2="34.8694" y2="10.7134" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#007940" />
                        <stop offset="0.2285" stopColor="#00873F" />
                        <stop offset="0.7433" stopColor="#40A737" />
                        <stop offset="1" stopColor="#5CB531" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_5675_14761" x1="26.3096" y1="10.7134" x2="34.8694" y2="10.7134" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#007940" />
                        <stop offset="0.2285" stopColor="#00873F" />
                        <stop offset="0.7433" stopColor="#40A737" />
                        <stop offset="1" stopColor="#5CB531" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_5675_14761" x1="7.14729" y1="12.1648" x2="15.8395" y2="12.1648" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1F286F" />
                        <stop offset="0.4751" stopColor="#004E94" />
                        <stop offset="0.8261" stopColor="#0066B1" />
                        <stop offset="1" stopColor="#006FBC" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_5675_14761" x1="16.6834" y1="12.1648" x2="25.1253" y2="12.1648" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6C2C2F" />
                        <stop offset="0.1735" stopColor="#882730" />
                        <stop offset="0.5731" stopColor="#BE1833" />
                        <stop offset="0.8585" stopColor="#DC0436" />
                        <stop offset="1" stopColor="#E60039" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    )
}

export const MasterCardLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="25" className={"icon " + className} viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.2497 2.78955H15.7498V21.6583H26.2497V2.78955Z" fill="#FF5F00" />
                <path d="M16.4163 12.2246C16.4147 10.4074 16.8265 8.61365 17.6205 6.97912C18.4146 5.3446 19.5701 3.91214 20.9996 2.79021C19.2294 1.39876 17.1034 0.533435 14.8646 0.293144C12.6258 0.0528528 10.3646 0.447293 8.33937 1.43138C6.31416 2.41546 4.60667 3.94948 3.41208 5.85811C2.21749 7.76674 1.58398 9.97296 1.58398 12.2246C1.58398 14.4763 2.21749 16.6825 3.41208 18.5911C4.60667 20.4997 6.31416 22.0338 8.33937 23.0178C10.3646 24.0019 12.6258 24.3964 14.8646 24.1561C17.1034 23.9158 19.2294 23.0505 20.9996 21.659C19.5701 20.5371 18.4146 19.1046 17.6206 17.4701C16.8265 15.8356 16.4147 14.0418 16.4163 12.2246V12.2246Z" fill="#EB001B" />
                <path d="M40.4147 12.2246C40.4148 14.4762 39.7813 16.6824 38.5868 18.5911C37.3923 20.4997 35.6849 22.0337 33.6597 23.0178C31.6345 24.0019 29.3733 24.3964 27.1346 24.1561C24.8958 23.9158 22.7698 23.0505 20.9996 21.659C22.4279 20.5359 23.5825 19.1033 24.3764 17.469C25.1704 15.8347 25.5829 14.0415 25.5829 12.2246C25.5829 10.4077 25.1704 8.6145 24.3764 6.98023C23.5825 5.34596 22.4279 3.91327 20.9996 2.79021C22.7698 1.39875 24.8958 0.53343 27.1346 0.293142C29.3733 0.0528537 31.6345 0.4473 33.6597 1.4314C35.6849 2.41549 37.3923 3.94952 38.5868 5.85815C39.7813 7.76678 40.4148 9.97299 40.4147 12.2246V12.2246Z" fill="#F79E1B" />
                <path d="M39.27 19.6601V19.2738H39.4258V19.1951H39.0291V19.2738H39.1849V19.6601H39.27ZM40.0402 19.6601V19.1943H39.9186L39.7787 19.5147L39.6388 19.1943H39.5171V19.6601H39.603V19.3088L39.7342 19.6117H39.8232L39.9544 19.308V19.6601H40.0402Z" fill="#F79E1B" />
            </svg>
        </>
    )
}

export const ChinaUnionPayLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="25" className={"icon " + className} viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M37.7948 0.866398L28.6172 0.864014C28.6161 0.864014 28.6149 0.864014 28.6149 0.864014C28.6078 0.864014 28.6008 0.865286 28.5939 0.865286C27.3331 0.904223 25.7628 1.92358 25.4768 3.18053L21.1367 22.5394C20.8507 23.808 21.6287 24.8404 22.8804 24.8594H32.5218C33.7544 24.7979 34.9521 23.7903 35.2332 22.5476L39.5735 3.18864C39.8641 1.90705 39.0673 0.866398 37.7948 0.866398" fill="#01798A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.1367 22.5393L25.4769 3.18047C25.7629 1.92352 27.3331 0.904165 28.5939 0.865229L24.9449 0.862845L18.3706 0.861572C17.1063 0.887637 15.5156 1.91415 15.2297 3.18047L10.8882 22.5393C10.6012 23.808 11.3804 24.8403 12.6312 24.8593H22.8804C21.6287 24.8403 20.8507 23.808 21.1367 22.5393" fill="#024381" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.8882 22.5393L15.2297 3.1805C15.5156 1.91417 17.1063 0.887664 18.3706 0.8616L9.94846 0.859375C8.67709 0.859375 7.04705 1.89765 6.7565 3.1805L2.41506 22.5393C2.38864 22.6575 2.37398 22.7733 2.36523 22.8866V23.2457C2.45022 24.1705 3.15088 24.844 4.15803 24.8594H12.6312C11.3804 24.8404 10.6012 23.808 10.8882 22.5393" fill="#DD0228" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5482 14.9678H18.7075C18.854 14.9678 18.9525 14.9182 18.9987 14.8201L19.4129 14.1953H20.522L20.2907 14.6063H21.6206L21.4519 15.2359H19.8695C19.6872 15.5123 19.4629 15.6423 19.1931 15.6269H18.3689L18.5482 14.9678ZM18.366 15.8702H21.2796L21.0938 16.5542H19.9221L19.7433 17.2145H20.8835L20.6978 17.8984H19.5576L19.2928 18.874C19.2272 19.0371 19.3134 19.1104 19.5501 19.0938H20.4793L20.3071 19.7294H18.5231C18.1849 19.7294 18.0689 19.5343 18.175 19.1434L18.5136 17.8984H17.7848L17.9699 17.2145H18.6989L18.8775 16.5542H18.1808L18.366 15.8702ZM23.0163 14.1906L22.9704 14.591C22.9704 14.591 23.52 14.175 24.0192 14.175H25.8636L25.1583 16.7489C25.0998 17.0432 24.8489 17.1895 24.4059 17.1895H22.3154L21.8257 18.9969C21.7975 19.0937 21.8373 19.1434 21.9428 19.1434H22.3541L22.203 19.7044H21.1572C20.7558 19.7044 20.5889 19.5827 20.6551 19.3383L22.0388 14.1906H23.0163ZM24.5782 14.9182H22.932L22.7351 15.6127C22.7351 15.6127 23.0092 15.4131 23.4674 15.406C23.9243 15.3988 24.4458 15.406 24.4458 15.406L24.5782 14.9182ZM23.9818 16.5293C24.1035 16.546 24.1716 16.4974 24.1798 16.3828L24.2806 16.0167H22.6318L22.4935 16.5293H23.9818ZM22.8697 17.3609H23.8201L23.8024 17.7754H24.0555C24.1833 17.7754 24.2467 17.7342 24.2467 17.6527L24.3216 17.3845H25.1114L25.006 17.7754C24.9167 18.1015 24.6802 18.2716 24.2959 18.2882H23.7896L23.7873 18.9969C23.778 19.1104 23.8798 19.1682 24.0895 19.1682H24.5653L24.4118 19.7293H23.2705C22.9506 19.7446 22.7937 19.591 22.797 19.2651L22.8697 17.3609Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3535 10.7615C11.2246 11.3981 10.9258 11.8872 10.4623 12.2344C10.003 12.5758 9.4106 12.747 8.68533 12.747C8.00278 12.747 7.50239 12.5722 7.18307 12.2214C6.96161 11.9721 6.85144 11.6556 6.85144 11.2729C6.85144 11.1146 6.87023 10.9445 6.90774 10.7615L7.6805 7.00525H8.84761L8.08536 10.7189C8.0619 10.8217 8.05254 10.9174 8.05377 11.0036C8.05254 11.1938 8.0994 11.3497 8.19431 11.4713C8.33257 11.6521 8.55698 11.7418 8.86927 11.7418C9.22838 11.7418 9.52429 11.6533 9.75395 11.4749C9.98366 11.2977 10.1336 11.0462 10.201 10.7189L10.9656 7.00525H12.1268L11.3535 10.7615" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.255 9.28385H17.169L16.453 12.6362H15.5408L16.255 9.28385ZM16.5427 8.0625H17.4649L17.2926 8.87621H16.3705L16.5427 8.0625Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.9781 12.3808C17.7391 12.1505 17.6184 11.8399 17.6172 11.4454C17.6172 11.378 17.6213 11.3013 17.6301 11.2173C17.6389 11.1323 17.6501 11.0496 17.6658 10.9729C17.7742 10.4283 18.0051 9.99601 18.3607 9.67704C18.7157 9.35695 19.1441 9.19629 19.6456 9.19629C20.0563 9.19629 20.382 9.31207 20.6206 9.54358C20.8589 9.77631 20.9785 10.0904 20.9785 10.4898C20.9785 10.5582 20.9732 10.6374 20.9644 10.7224C20.9539 10.8086 20.9411 10.8913 20.9263 10.9729C20.8203 11.5091 20.5901 11.9367 20.2345 12.2498C19.8788 12.5652 19.4517 12.7222 18.9537 12.7222C18.5412 12.7222 18.2166 12.6088 17.9781 12.3808ZM19.72 11.717C19.8811 11.541 19.9965 11.274 20.0669 10.9185C20.0774 10.863 20.0867 10.8051 20.0926 10.7473C20.0984 10.6905 20.1008 10.6374 20.1008 10.589C20.1008 10.3822 20.0486 10.2216 19.9437 10.1082C19.8395 9.99361 19.6913 9.93698 19.4998 9.93698C19.2466 9.93698 19.0403 10.0266 18.8787 10.2062C18.7157 10.3858 18.6003 10.6575 18.5276 11.0189C18.5177 11.0744 18.5095 11.13 18.5019 11.1843C18.4961 11.2398 18.4944 11.2918 18.4955 11.339C18.4955 11.5446 18.5477 11.7029 18.6525 11.8151C18.7567 11.9273 18.9043 11.9828 19.0984 11.9828C19.3527 11.9828 19.5589 11.8942 19.72 11.717Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M26.9089 14.9961L27.1293 14.2142H28.2436L28.1955 14.5011C28.1955 14.5011 28.765 14.2142 29.175 14.2142C29.5853 14.2142 30.553 14.2142 30.553 14.2142L30.334 14.9961H30.1172L29.0778 18.6839H29.2945L29.0883 19.4162H28.8715L28.7814 19.734H27.7022L27.7922 19.4162H25.6632L25.8707 18.6839H26.084L27.1244 14.9961H26.9089ZM28.1111 14.9961L27.8275 15.9942C27.8275 15.9942 28.3128 15.8065 28.7311 15.7533C28.8234 15.4048 28.9442 14.9961 28.9442 14.9961L28.1111 14.9961ZM27.6962 16.462L27.4116 17.5074C27.4116 17.5074 27.9494 17.2404 28.3185 17.2179C28.4251 16.8139 28.5318 16.462 28.5318 16.462L27.6962 16.462ZM27.9048 18.6839L28.1181 17.9255H27.2863L27.0719 18.6839H27.9048ZM30.5999 14.1658H31.6476L31.692 14.5554C31.6851 14.6546 31.7436 14.702 31.8678 14.702H32.0529L31.8656 15.3623H31.0956C30.8016 15.3776 30.6504 15.2643 30.6364 15.0197L30.5999 14.1658ZM30.293 15.582H33.6864L33.4873 16.2908H32.4068L32.2216 16.9498H33.3009L33.1005 17.6574H31.8984L31.6264 18.0721H32.2148L32.3507 18.9024C32.367 18.9851 32.4396 19.0253 32.5639 19.0253H32.7466L32.5546 19.7091H31.9077C31.5725 19.7257 31.3993 19.6123 31.3851 19.3678L31.2291 18.6095L30.6937 19.4163C30.5671 19.6442 30.3726 19.7506 30.1102 19.734H29.1224L29.3145 19.05H29.6228C29.7494 19.05 29.8547 18.9933 29.9496 18.8787L30.7875 17.6574H29.7071L29.9073 16.9498H31.0792L31.2656 16.2908H30.0926L30.293 15.582Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.4285 9.28257H13.2528L13.1586 9.76685L13.2769 9.62866C13.5441 9.34048 13.8686 9.19751 14.2518 9.19751C14.5987 9.19751 14.8488 9.29914 15.0059 9.50344C15.1605 9.70782 15.2027 9.99016 15.1295 10.3528L14.6754 12.6372H13.8282L14.2383 10.5666C14.2806 10.3528 14.2689 10.1933 14.2037 10.0905C14.1394 9.98774 14.0163 9.93701 13.8388 9.93701C13.6208 9.93701 13.4374 10.0054 13.288 10.1413C13.138 10.2783 13.039 10.4686 12.9904 10.7107L12.6125 12.6372H11.7635L12.4285 9.28257" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.8818 9.28257H22.7068L22.6131 9.76685L22.7301 9.62866C22.9975 9.34048 23.3232 9.19751 23.7052 9.19751C24.0521 9.19751 24.3028 9.29914 24.4585 9.50344C24.6122 9.70782 24.6567 9.99016 24.5817 10.3528L24.1293 12.6372H23.281L23.6912 10.5666C23.7333 10.3528 23.7216 10.1933 23.6571 10.0905C23.5903 9.98774 23.4695 9.93701 23.2928 9.93701C23.0748 9.93701 22.892 10.0054 22.7408 10.1413C22.5907 10.2783 22.4913 10.4686 22.4444 10.7107L22.0648 12.6372H21.2169L21.8818 9.28257" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.9597 7.20361H28.3548C28.8153 7.20361 29.1715 7.30882 29.4163 7.51543C29.6601 7.72458 29.7821 8.02463 29.7821 8.41559V8.42735C29.7821 8.50173 29.7772 8.58564 29.7703 8.67655C29.7584 8.76634 29.7431 8.85725 29.7234 8.95181C29.618 9.46919 29.373 9.88501 28.9946 10.2004C28.6148 10.5146 28.165 10.6729 27.6471 10.6729H26.3627L25.9655 12.6372H24.8534L25.9597 7.20361ZM26.5583 9.72793H27.6236C27.9012 9.72793 28.1215 9.66287 28.2821 9.53418C28.4415 9.40424 28.547 9.20584 28.6055 8.93654C28.6148 8.8868 28.6206 8.84198 28.6277 8.80066C28.6313 8.76172 28.6359 8.72262 28.6359 8.68496C28.6359 8.49234 28.5681 8.35296 28.4321 8.26555C28.2962 8.17687 28.083 8.13444 27.7875 8.13444H26.8829L26.5583 9.72793Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.7598 13.2857C34.4082 14.0393 34.0732 14.4786 33.8764 14.683C33.6793 14.8851 33.2893 15.3552 32.3495 15.3197L32.4304 14.7445C33.2212 14.4988 33.6489 13.3919 33.8926 12.9018L33.6021 9.29195L34.2138 9.28369H34.727L34.7821 11.5481L35.744 9.28369H36.7179L34.7598 13.2857" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.0367 9.55655L31.6498 9.82474C31.2456 9.50583 30.8765 9.30854 30.1642 9.6416C29.1939 10.0952 28.383 13.5739 31.0547 12.4281L31.207 12.61L32.2581 12.6372L32.9483 9.47621L32.0367 9.55655ZM31.439 11.2847C31.2702 11.7867 30.8931 12.1186 30.5978 12.0241C30.3025 11.932 30.197 11.4476 30.3681 10.9445C30.5368 10.4412 30.9164 10.1105 31.2093 10.205C31.5046 10.2972 31.6112 10.7814 31.439 11.2847Z" fill="white" />
            </svg>
        </>
    )
}

export const DiscoverLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="24" className={"icon " + className} viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4977 15.2921C14.5066 15.2921 12.9598 13.831 12.9598 11.9453C12.9598 10.0826 14.5448 8.59277 16.5245 8.59277C17.0906 8.59277 17.5605 8.70755 18.1331 8.98464V10.4565C17.5909 9.94539 17.1174 9.73527 16.4977 9.73527C15.2694 9.73527 14.3073 10.7082 14.3073 11.9364C14.3073 13.2362 15.2392 14.1471 16.5549 14.1471C17.1497 14.1471 17.6094 13.9485 18.1331 13.4448V14.9184C17.5405 15.1861 17.0591 15.2921 16.4977 15.2921Z" fill="#E6772F" />
                <path d="M10.6193 11.1952C9.83329 10.9193 9.60355 10.7356 9.60355 10.3891C9.60355 9.98623 10.0141 9.68076 10.5787 9.68076C10.97 9.68076 11.2923 9.83063 11.6358 10.1969L12.3156 9.34341C11.7539 8.87038 11.0816 8.63088 10.3487 8.63088C9.16282 8.63088 8.25897 9.42034 8.25897 10.4642C8.25897 11.3506 8.67915 11.8002 9.90536 12.2272C10.4179 12.3977 10.6783 12.5142 10.8102 12.5931C11.0715 12.7547 11.2015 12.9862 11.2015 13.2539C11.2015 13.7755 10.7697 14.1589 10.1858 14.1589C9.56427 14.1589 9.06135 13.8611 8.76033 13.3019L7.91788 14.0827C8.52018 14.9272 9.24217 15.3046 10.2368 15.3046C11.5934 15.3046 12.5493 14.4355 12.5493 13.1976C12.5493 12.1776 12.1059 11.7153 10.6193 11.1952Z" fill="#E6772F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 8.73763H1.92035C4.03869 8.73763 5.51649 10.0524 5.51649 11.9453C5.51649 12.8908 5.06352 13.8035 4.30017 14.408C3.65754 14.9184 2.92436 15.1497 1.90864 15.1497H0V8.73763ZM1.65834 14.0632C2.50417 14.0632 3.03311 13.9079 3.44861 13.5526C3.89845 13.1699 4.16877 12.5543 4.16877 11.9363C4.16877 11.3214 3.89845 10.7269 3.44861 10.3415C3.01281 9.96852 2.50417 9.82363 1.65834 9.82363H1.30737V14.0632H1.65834Z" fill="#E6772F" />
                <path d="M7.42148 8.73763H6.1193V15.1497H7.42148V8.73763Z" fill="#E6772F" />
                <path d="M28.7202 13.0425L26.9333 8.73763H25.5086L28.3491 15.3115H29.0498L31.9455 8.73763H30.532L28.7202 13.0425Z" fill="#E6772F" />
                <path d="M36.2442 15.1497H32.5385V8.73763H36.2442V9.82361H33.8459V11.2462H36.1531V12.3324H33.8459V14.0632H36.2442V15.1497Z" fill="#E6772F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.4172 10.6295C41.4172 9.42875 40.5566 8.73763 39.0478 8.73763H37.1069V15.1497H38.4171V12.5717H38.587L40.3924 15.1497H42L39.8918 12.4472C40.8771 12.2545 41.4172 11.6107 41.4172 10.6295ZM38.7965 11.6862H38.4171V9.74674H38.8178C39.6342 9.74674 40.0716 10.0746 40.0716 10.6993C40.0716 11.3411 39.6342 11.6862 38.7965 11.6862Z" fill="#E6772F" />
                <path d="M22.1997 15.3025C24.1296 15.3025 25.6928 13.8063 25.6928 11.9602C25.6928 10.1139 24.1296 8.61737 22.1997 8.61737C20.2702 8.61737 18.7068 10.1139 18.7068 11.9602C18.7068 13.8063 20.2702 15.3025 22.1997 15.3025Z" fill="#E6772F" />
            </svg>
        </>
    )
}

export const DinersClubLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="25" className={"icon " + className} viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36.4352 13.0284C36.4352 5.86979 30.4602 0.921913 23.9132 0.924073H18.2794C11.6545 0.921697 6.20166 5.8713 6.20166 13.0284C6.20166 19.5761 11.6547 24.9556 18.2794 24.9239H23.9132C30.4596 24.9554 36.4352 19.5743 36.4352 13.0284Z" fill="#0079BE" />
                <path d="M18.3148 1.93799C12.2612 1.93986 7.35604 6.85663 7.35388 12.9248C7.35539 18.9919 12.2608 23.908 18.3148 23.9101C24.3697 23.9083 29.2763 18.992 29.2771 12.9248C29.2763 6.85648 24.3698 1.94015 18.3148 1.93799Z" fill="white" />
                <path d="M11.387 12.8952C11.3927 9.92998 13.2409 7.40139 15.8471 6.39661V19.3924C13.2409 18.3882 11.3927 15.8608 11.387 12.8952ZM20.8199 19.3956V6.39697C23.4271 7.39937 25.2781 9.9294 25.2828 12.896C25.2781 15.8636 23.4271 18.3917 20.8199 19.396V19.3956Z" fill="#0079BE" />
            </svg>
        </>
    )
}

export const EftposAustraliaLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="25" className={"icon " + className} viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_5675_14795)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.50159 22.2089V1.9322C2.50159 1.9322 2.77128 0.314453 5.03613 0.314453C7.30099 0.314453 37.7697 0.368505 37.7697 0.368505C37.7697 0.368505 39.4953 0.368506 39.4953 2.74129C39.4953 5.11405 39.4953 22.6941 39.4953 22.6941C39.4953 22.6941 39.3334 24.3119 37.2302 24.3119C35.1272 24.3119 4.65874 24.3119 4.65874 24.3119C4.65874 24.3119 2.82513 24.4738 2.50159 22.2089Z" fill="#8D1238" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M38.4515 14.2818L38.4524 10.7944H29.249C29.249 10.7944 29.0873 10.8306 29.0873 10.6507C29.0873 10.4708 29.0885 8.65535 29.0885 8.65535C29.0885 8.65535 29.0514 8.50632 29.3028 8.50632C29.5543 8.50632 37.6433 8.50076 37.6433 8.50076C37.6433 8.50076 38.3984 8.52954 38.3984 7.6847C38.3984 6.83963 38.3984 5.204 38.3984 5.204C38.3984 5.204 38.3081 1.96849 34.5695 1.48315C33.689 1.48315 29.5185 1.48315 29.5185 1.48315C29.5185 1.48315 25.5154 1.48728 25.2457 5.56763C25.2457 9.27079 25.2584 10.7403 25.2584 10.7403C25.2584 10.7403 25.4741 14.1016 29.2847 14.2845L38.4515 14.2818Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M29.2086 4.88489H34.5204L34.5212 5.93633C34.5212 5.93633 34.5743 6.04424 34.3587 6.04424C34.1428 6.04424 29.0383 6.04424 29.0383 6.04424L29.0333 5.0736C29.0333 5.0736 29.0065 4.88489 29.2086 4.88489Z" fill="#8D1238" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.5859 21.1782L12.2893 22.3218L8.81098 22.3167C8.81098 22.3167 7.77291 22.3302 7.74597 21.1708C8.0021 20.0249 8.36615 18.8383 8.36615 18.8383C8.36615 18.8383 8.60872 17.8677 9.82213 17.881C10.9546 17.8938 12.1813 17.8903 12.1813 17.8903C12.1813 17.8903 13.2535 17.9639 13.1522 18.9596C13.0712 19.7552 12.9904 19.8495 12.9904 19.8495C12.9904 19.8495 13.0307 20.3484 12.2218 20.3307L9.51194 20.3214C9.51194 20.3214 9.32324 20.2943 9.24224 20.4966C9.16147 20.6989 9.06711 20.9281 9.06711 20.9281C9.06711 20.9281 9.01327 21.1683 9.21553 21.1817L12.5859 21.1782Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.80869 19.0542H11.7231C11.7231 19.0542 11.8581 19.027 11.831 19.1754C11.8041 19.3238 11.8715 19.5529 11.5479 19.5529C11.2244 19.5529 9.63354 19.5604 9.63354 19.5604C9.63354 19.5604 9.47177 19.5798 9.51206 19.4451C9.55256 19.3099 9.59306 19.0405 9.80869 19.0542Z" fill="#8D1238" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.4711 22.3178L16.6979 17.9766C16.6979 17.9766 17.0078 17.0193 17.8436 17.0193C18.0323 17.0058 18.2346 17.0327 18.2346 17.0327L18.0323 17.936L19.0435 17.9461L18.7333 19.2109L17.8572 19.2033C17.8572 19.2033 17.6954 19.1764 17.6146 19.4056C17.5067 19.7111 16.7651 22.3235 16.7651 22.3235L15.4711 22.3178Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6534 22.3251L14.2712 16.6494C14.2712 16.6494 14.6622 15.8 15.5251 15.8C16.3878 15.8 16.8193 15.8049 16.8193 15.8049L16.5361 16.9468L15.862 16.9458C15.862 16.9458 15.4846 16.9056 15.4036 17.1615C15.3229 17.4177 15.2688 17.6065 15.2688 17.6065C15.2688 17.6065 15.1341 17.9166 15.4036 17.9166C15.6735 17.9166 16.2799 17.9216 16.2799 17.9216L15.943 19.1568H15.2283C15.2283 19.1568 14.8644 19.1004 14.7701 19.4401C14.6581 19.8432 13.961 22.3251 13.961 22.3251L12.6534 22.3251Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.884 23.4627L19.2052 18.8114C19.2052 18.8114 19.5289 17.9082 20.4724 17.9082C21.4162 17.9082 23.0203 17.9082 23.0203 17.9082C23.0203 17.9082 24.0988 17.9488 23.9776 19.108L23.3979 21.319C23.3979 21.319 23.1956 22.3168 21.8476 22.3168C20.4996 22.3168 19.9602 22.3168 19.9602 22.3168L20.284 21.0494L21.8611 21.0614C21.8611 21.0614 22.1846 21.1168 22.252 20.8069C22.36 20.4293 22.6565 19.3509 22.6565 19.3509C22.6565 19.3509 22.7778 19.1486 22.4945 19.1486C22.2116 19.1486 20.7017 19.1486 20.7017 19.1486C20.7017 19.1486 20.4457 19.0946 20.3647 19.3642C20.2702 19.6745 19.1784 23.476 19.1784 23.476L17.884 23.4627Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.9373 20.8471L24.4497 18.9596C24.4497 18.9596 24.7463 17.9082 25.7303 17.9082C26.7146 17.9082 28.2786 17.9082 28.2786 17.9082C28.2786 17.9082 29.5322 17.989 29.2898 19.1217C29.0471 20.2541 28.7233 21.3056 28.7233 21.3056C28.7233 21.3056 28.4941 22.3168 27.2404 22.3168C25.9865 22.3168 25.07 22.3168 25.07 22.3168C25.07 22.3168 23.6273 22.3168 23.9373 20.8471Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.0135 19.1349L27.712 19.1441C27.712 19.1441 28.0088 19.0945 27.9682 19.2968C27.928 19.499 27.5638 20.7662 27.5638 20.7662C27.5638 20.7662 27.483 20.982 27.2538 20.9954C26.9298 21.0144 25.3934 20.9954 25.3934 20.9954C25.3934 20.9954 25.1911 21.0091 25.2585 20.7932C25.3513 20.4962 25.6898 19.2833 25.6898 19.2833C25.6898 19.2833 25.7033 19.1349 26.0135 19.1349Z" fill="#8D1238" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M29.047 22.3234L29.3301 21.1219L32.3903 21.1167C32.3903 21.1167 32.6599 21.1708 32.7275 20.874C32.7544 20.6584 32.7544 20.6332 32.7544 20.6332L30.9747 20.6315C30.9747 20.6315 29.5861 20.6988 29.6671 19.3776C29.7479 18.0563 30.7861 17.881 31.1366 17.881C31.487 17.881 34.5474 17.881 34.5474 17.881L34.2373 19.0271H31.3252C31.3252 19.0271 31.1096 19.0002 31.0692 19.2563C31.0692 19.2563 30.9208 19.5123 31.1904 19.526C31.4601 19.5393 32.9971 19.526 32.9971 19.526C32.9971 19.526 34.1969 19.4854 34.1296 20.6448C34.062 21.8044 33.413 22.3167 32.645 22.3167L29.047 22.3234Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_5675_14795">
                        <rect width="36.9937" height="24" fill="white" transform="translate(2.50391 0.313477)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export const CreditCardLogo = ({ className }) => {
    return (
        <>
            <svg width="42" height="24" className={"icon " + className} viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 21.8944V1.61774C3 1.61774 3.26969 0 5.53454 0C7.79941 0 38.2681 0.0540519 38.2681 0.0540519C38.2681 0.0540519 39.9937 0.0540532 39.9937 2.42684C39.9937 4.7996 39.9937 22.3796 39.9937 22.3796C39.9937 22.3796 39.8319 23.9975 37.7286 23.9975C35.6256 23.9975 5.15715 23.9975 5.15715 23.9975C5.15715 23.9975 3.32355 24.1593 3 21.8944Z" fill="#F5F5F5" />
                <path d="M27.6666 8.66671H14.3333V7.00004H27.6666M27.6666 17H14.3333V12H27.6666M27.6666 5.33337H14.3333C13.4083 5.33337 12.6666 6.07504 12.6666 7.00004V17C12.6666 17.4421 12.8422 17.866 13.1548 18.1786C13.4673 18.4911 13.8913 18.6667 14.3333 18.6667H27.6666C28.1087 18.6667 28.5326 18.4911 28.8451 18.1786C29.1577 17.866 29.3333 17.4421 29.3333 17V7.00004C29.3333 6.07504 28.5833 5.33337 27.6666 5.33337Z" fill="#45525F" />
            </svg>
        </>
    )
}
