import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Checkbox, FormControlLabel, Grid, InputAdornment, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiMagnify, mdiSend, mdiTuneVertical } from "@mdi/js";
import AvatarInitials from "../../components/common/AvatarInitials";
import MessageChatItem from "../../components/common/MessageChatItem";
import { capitalize } from "../../utils/Utils";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import EmptyState from "../../components/common/EmptyState";

function Inbox(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [contacts, setContacts] = useState([]);
    const [messages, setMessages] = useState([]);

    // State management for search fields
    const [search, setSearch] = useState();
    const [contactSearch, setContactSearch] = useState();
    const [messageId, setMessageId] = useState();
    const [readStatus, setReadStatus] = useState(false);
    const [isEnableTextBox, setIsEnableTextBox] = useState(false);

    //handle checkbox
    const [reloadPage, setReloadPage] = useState(false);


    const [currentActiveContact, setCurrentActiveContact] = useState({
        name: '',
        phone: '',
        unread_count: 0
    });



    useEffect(() => {
        async function fetchData() {
            await getData(
                search,
                messageId,
                readStatus
            );
        }
        fetchData();
    }, [search, reloadPage, messageId, readStatus]);

    const getData = async (search, messageId, readStatus) => {
        const params = {
            search: search,
            id: messageId,
            read_status: readStatus,
        };
        showLoader()
        await axiosInstance.get(`api/whatsapp-inbox?`, { params }).then((response) => {
            if (response.status === 200) {
                console.log(response)
                setContacts(response?.data?.data?.contacts);
                setMessages(response?.data?.data?.messages);
                setIsEnableTextBox(response?.data?.data?.isEnableSendMessage)
                //set default selected message
                if (!messageId) {
                    setMessageId(response?.data?.data?.contacts?.[0]['id'] || '')
                    setCurrentActiveContact({
                        name: response?.data?.data?.contacts?.[0]['name'] || '',
                        phone: response?.data?.data?.contacts?.[0]['phone'] || '',
                        unread_count: response?.data?.data?.contacts?.[0]['unread_count'] || 0
                    });
                }
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    // const fetchMessages = async () => {
    //     console.log('Fetching messages...');
    //     getData(search, messageId, readStatus);
    // };

    // useEffect(() => {
    //     fetchMessages(); // Initial load
    //     const interval = setInterval(fetchMessages, 5000); // Fetch new messages every 5 seconds

    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, []);

    // setInterval(async () => {
    //     await getData(
    //         search,
    //         messageId,
    //         readStatus
    //     );
    // }, 50000);

    const [defaultValue, setdefaultValue] = useState({
        message: '',
        recipients: [],
        message_type: '',
        media_url: '',
        media_caption: '',
        bulk_message_id: '',

    })

    const validationSchema = yup.object().shape({
        message: yup.string().required('Enter Message').min(2, 'Must be at least 1 characters').max(255, 'Must be at most 255 characters'),
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            message_body: values?.message,
            message_type: 'text',
            recipients: [messages?.sender_id]
        }
        try {
            showLoader();
            const response = await axiosInstance.post('/api/send-message', data);
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success');
                //setMessageId()
                onSubmitProps.resetForm();
                getData('', messages?.id, '')
            }
        } catch (error) {
            hideLoader();
            if (error.response) {
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error');
                }
            }
        } finally {
            hideLoader();
        }
    }

    const containerRef = useRef(null);

    useEffect(() => {
        scrollMessagestoBottom();
    }, [messages, currentActiveContact]);

    const scrollMessagestoBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    const [filterPanelAnchorEl, setFilterPanelAnchorEl] = React.useState(null);

    const handleFilterPanelClick = (event) => {
        setFilterPanelAnchorEl(event.currentTarget);
    };
    const handleFilterPanelClose = () => {
        setFilterPanelAnchorEl(null);
    };

    const filterPanelOpen = Boolean(filterPanelAnchorEl);

    return (
        <>
            <Helmet>
                <title>Inbox | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            {
                contacts?.length ?
                    <>
                        <div className="inbox-layout">
                            <div className="contacts card">
                                <div className="p-16 border-bottom">
                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                        <TextField
                                            variant="filled"
                                            placeholder="Search contacts"
                                            className='size-small page-search no-margin'
                                            sx={{ flexGrow: 1 }}
                                            value={contactSearch || ''}
                                            onChange={(e) => setContactSearch(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setSearch(e.target.value);
                                                    e.preventDefault();
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Tooltip title="Filter">
                                            <button type="button" className="icon-button flex-shrink-0" onClick={handleFilterPanelClick}>
                                                <Icon path={mdiTuneVertical} color={'currentColor'} size={1} />
                                            </button>
                                        </Tooltip>
                                        <Popover
                                            open={filterPanelOpen}
                                            anchorEl={filterPanelAnchorEl}
                                            onClose={handleFilterPanelClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            classes={{
                                                paper: 'utility-menu'
                                            }}
                                        >
                                            <FormControlLabel value="Show Unread Only" control={<Checkbox onClick={() => setReadStatus(readStatus ? false : true)} />} label="Show Unread Only" />
                                        </Popover>
                                    </Stack>
                                </div>

                                <div className="contact-list">
                                    {contacts?.length > 0 && contacts.map((contact, index) => (
                                        <button type="button" className={"contact-btn " + (messageId === contact.id ? ' active ' : '')} key={index} onClick={e => {
                                            setCurrentActiveContact(contact);
                                            setMessageId(contact?.id)
                                        }}>
                                            <AvatarInitials name={contact?.name} />
                                            <div className="contact">
                                                <Typography variant="title-medium-bold" component={'p'} className="name">{contact?.name}</Typography>
                                                <Typography variant="body-medium" component={'p'} className="phone">{contact?.phone}</Typography>
                                            </div>
                                            {contact?.unread_count > 0 && <span className="count">{contact?.unread_count}</span>}
                                        </button>
                                    ))}

                                    {(contacts?.length == 0) &&
                                        <EmptyState type={'contact'} hideActionBtn />
                                    }
                                </div>
                            </div>

                            <div className="message-panel">
                                <div className="inner">
                                    <div className="message-info">
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Stack direction={"row"} alignItems={"center"} spacing={1.25} className="minw0">
                                                <AvatarInitials name={messages?.name || currentActiveContact.name} invert className="flex-shrink-0" />
                                                <Stack className="minw0">
                                                    <Typography component={'p'} variant="title-medium-bold" className="text-overflow">{messages?.name || currentActiveContact?.name}</Typography>
                                                    <Typography component={'p'} className="text-secondary text-overflow" variant="body-medium">{messages?.phone || currentActiveContact?.phone}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Button color="secondary" size="small" onClick={() => messages?.type === 'lead' ? navigate('/lead-detail/' + messages?.type_id) : navigate('/deal-detail/' + messages?.type_id)} className="ml-auto flex-shrink-0">View {messages?.type || ''} Details</Button>
                                        </Stack>
                                    </div>

                                    <div className="chat-box">
                                        <Formik
                                            initialValues={defaultValue}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSubmit}
                                        >
                                            {props => {
                                                const {
                                                    touched,
                                                    errors,
                                                    values,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    setFieldTouched
                                                } = props;
                                                return (
                                                    <>
                                                        <form onSubmit={handleSubmit}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs>
                                                                    <Tooltip title={errors.message}>
                                                                        <input
                                                                            type="text"
                                                                            className={`message-input ${(errors.message && touched.message) ? 'error' : ''}`}
                                                                            placeholder="Message"
                                                                            onChange={handleChange}
                                                                            value={values?.message || ''}
                                                                            name="message"
                                                                            onBlur={handleBlur}
                                                                            disabled={!isEnableTextBox}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    <Tooltip title={isEnableTextBox ? 'Send Message' : "Meta allows replies within 24 hours of the user's last message. After 24 hours, you can only respond if the user contacts you again."}>
                                                                        <span>
                                                                            <button
                                                                                type="submit"
                                                                                className="send-btn"
                                                                                disabled={!isEnableTextBox}
                                                                            >
                                                                                <Icon path={mdiSend} color={'currentColor'} size={1} />
                                                                            </button>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </form >
                                                    </>
                                                );
                                            }}
                                        </Formik>
                                    </div>


                                    <div className="messages" ref={containerRef}>
                                        {
                                            messages?.messageLists?.length > 0 && messages?.messageLists?.map((message, index) => {
                                                let currentTime = message?.message_time ? moment.utc(message.message_time).tz(preTimeZoneKey).format(preferredDateFormat) : moment.utc(new Date()).tz(preTimeZoneKey).format(preferredDateFormat)
                                                const sendtime = moment(currentTime, 'DD-MM-YYYY h:mm a').format('h:mm a');

                                                // let formattedHeader = { head: '', fileLink: '', fileName: '' };
                                                // let headerType = 'text';
                                                // let formattedBody = '';
                                                // let formattedFooter = '';
                                                // let formattedButtons = [];

                                                // if (message?.template_content) {
                                                //     let jsonData = [];

                                                //     try {
                                                //         jsonData = typeof message?.template_content === "string" ? JSON.parse(message?.template_content) : message?.template_content;
                                                //     } catch (error) {
                                                //         showSnackbar('Error parsing JSON data', "error");
                                                //         return null;
                                                //     }

                                                //     // Extract Header, Body, Footer, and Buttons
                                                //     const header = jsonData.find(item => item.type === "HEADER");
                                                //     const body = jsonData.find(item => item.type === "BODY");
                                                //     const footer = jsonData.find(item => item.type === "FOOTER");
                                                //     const buttons = jsonData.find(item => item.type === "BUTTONS");

                                                //     // Get formatted values
                                                //     if (header) {
                                                //         if (header?.format === "IMAGE" && header?.example?.length > 0) {
                                                //             formattedHeader = ({ head: '', fileLink: header?.example?.[0]?.header_handle?.link, fileName: '' }); // Image URL
                                                //             headerType = "image";
                                                //         } else if (header.format === "DOCUMENT" && header?.example?.length > 0) {
                                                //             formattedHeader = ({ head: '', fileLink: header?.example?.[0]?.header_handle?.link, fileName: header?.example?.[0]?.header_handle?.filename }); // Image URL
                                                //             headerType = "document";
                                                //         } else if (header.format === "VIDEO" && header?.example?.length > 0) {
                                                //             formattedHeader = ({ head: '', fileLink: header?.example?.[0]?.header_handle?.link, fileName: '' }); // Image URL
                                                //             headerType = ("video");
                                                //         } else if (header.format === "TEXT") {
                                                //             formattedHeader = ({ head: header.text || "", fileLink: '', fileName: '' });
                                                //             headerType = "text";
                                                //         }
                                                //     }

                                                //     formattedBody = body?.text ? body.text : "";
                                                //     formattedFooter = footer ? footer?.text : "";
                                                //     formattedButtons = buttons?.buttons?.map(btn => ({ type: btn.type, text: btn.text })) || [];

                                                //     return (
                                                //         <MessageChatItem
                                                //             key={index}
                                                //             head={formattedHeader}
                                                //             headType={headerType}
                                                //             body={formattedBody}
                                                //             footer={formattedFooter}
                                                //             buttons={formattedButtons}
                                                //             time={sendtime}
                                                //             read={message?.status == 'read' ? true : false}
                                                //             delivered={message?.status == 'delivered' ? true : false}
                                                //             failed={message?.status == 'failed' ? true : false}
                                                //             sent={message?.is_reply == 0 ? true : false}
                                                //         />
                                                //     )
                                                // }

                                                if (message?.message_content) {
                                                    let formattedHeader = { head: '', fileLink: '', fileName: '' };
                                                    let headerType = 'text';
                                                    let formattedBody = '';
                                                    let formattedFooter = '';
                                                    let formattedButtons = [];

                                                    formattedBody = message?.message_content || '';

                                                    if (message?.message_type != 'text') {
                                                        let jsonData = [];
                                                        const contentData = formattedBody || [];

                                                        try {
                                                            jsonData = typeof contentData === "string" ? JSON.parse(contentData) : contentData;
                                                        } catch (error) {
                                                            showSnackbar('Error parsing JSON data', "error");
                                                            return null;
                                                        }

                                                        // // Extract Header, Body, Footer, and Buttons
                                                        const header = jsonData.find(item => item.type === "HEADER");
                                                        const body = jsonData.find(item => item.type === "BODY");
                                                        const footer = jsonData.find(item => item.type === "FOOTER");
                                                        const buttons = jsonData.find(item => item.type === "BUTTONS");

                                                        // // Get formatted values
                                                        if (header) {
                                                            if (message?.message_type == 'template') {
                                                                if (header?.format === "IMAGE" && header?.example?.length > 0) {
                                                                    formattedHeader = ({ head: '', fileLink: header?.example?.[0]?.header_handle?.link, fileName: '' }); // Image URL
                                                                    headerType = "image";
                                                                } else if (header.format === "DOCUMENT" && header?.example?.length > 0) {
                                                                    formattedHeader = ({ head: '', fileLink: header?.example?.[0]?.header_handle?.link, fileName: header?.example?.[0]?.header_handle?.filename }); // Image URL
                                                                    headerType = "document";
                                                                } else if (header.format === "VIDEO" && header?.example?.length > 0) {
                                                                    formattedHeader = ({ head: '', fileLink: header?.example?.[0]?.header_handle?.link, fileName: '' }); // Image URL
                                                                    headerType = ("video");
                                                                } else if (header.format === "TEXT") {
                                                                    formattedHeader = ({ head: header?.example?.[0]?.text || "", fileLink: '', fileName: '' });
                                                                    headerType = "text";
                                                                }
                                                            } else {
                                                                if (header?.format === "IMAGE" && header?.parameters?.length > 0) {
                                                                    formattedHeader = { head: '', fileLink: header?.parameters?.[0]?.image?.link, fileName: '' }; // Image URL
                                                                    headerType = "image";
                                                                } else if (header?.format === "DOCUMENT" && header?.parameters?.length > 0) {
                                                                    formattedHeader = { head: '', fileLink: header?.parameters?.[0]?.document?.link, fileName: header?.parameters?.[0]?.document?.filename }; // Image URL
                                                                    headerType = "document";
                                                                } else if (header.format === "VIDEO" && header?.parameters?.length > 0) {
                                                                    formattedHeader = { head: '', fileLink: header?.parameters?.[0]?.video?.link, fileName: header?.parameters?.[0]?.video?.filename }; // Image URL
                                                                    headerType = "video";
                                                                } else if (header.format === "TEXT") {
                                                                    formattedHeader = { head: header?.text || "", fileLink: '', fileName: '' };
                                                                    headerType = "text";
                                                                }
                                                            }

                                                        }

                                                        formattedBody = body?.text ? body?.text : false;
                                                        formattedFooter = footer ? footer?.text : "";
                                                        formattedButtons = buttons?.buttons?.map(btn => ({ type: btn.type, text: btn.text })) || [];
                                                    }

                                                    return (
                                                        <MessageChatItem
                                                            key={index}
                                                            head={(formattedHeader?.head?.length || formattedHeader?.fileLink?.length) ? formattedHeader : false}
                                                            headType={headerType}
                                                            body={formattedBody}
                                                            footer={formattedFooter}
                                                            buttons={formattedButtons}
                                                            hideBody={true}
                                                            time={sendtime}
                                                            read={message?.is_reply == 0 && message?.status == 'read' ? true : false
                                                            }
                                                            delivered={message?.is_reply == 0 && message?.status == 'delivered' ? true : false}
                                                            failed={message?.is_reply == 0 && message?.status == 'failed' ? true : false}
                                                            sent={message?.is_reply == 0 ? true : false}
                                                        />
                                                    )
                                                }
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="card">
                        <EmptyState type={'chat'} hideActionBtn />
                    </div>
            }
        </>
    );
}

export default Inbox;