import { mdiCheckCircleOutline, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";

const displayLarge = {
    fontSize: '57px',
    lineHeight: '64px'
}
const displayMedium = {
    fontSize: '45px',
    lineHeight: '52px'
}
const displaySmall = {
    fontSize: '36px',
    lineHeight: '44px'
}
const displaySmallBold = {
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '44px'
}
const headlineLarge = {
    fontSize: '32px',
    lineHeight: '40px'
}
const headlineMedium = {
    fontSize: '28px',
    lineHeight: '36px'
}
const headlineSmall = {
    fontSize: '24px',
    lineHeight: '32px'
}
const headlineSmallBold = {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px'
}
const headlineMediumBold = {
    fontSize: '28px',
    fontWeight: '700',
    lineHeight: '36px'
}
const headlineMediumSemibold = {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '36px'
}
const titleLarge = {
    fontSize: '22px',
    lineHeight: '28px'
}
const titleMedium = {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.0015em'
}
const titleSmall = {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.001em'
}
const titleMediumBold = {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.0015em'
}
const titleLargeBold = {
    fontSize: '22px',
    fontWeight: '700',
    lineHeight: '28px'
}
const titleLargeSemibold = {
    fontSize: '22px',
    fontWeight: '600',
    lineHeight: '28px'
}
const titleSmallBold = {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0.001em'
}
const titleMediumLargeSemibold = {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px'
}
const titleMediumLargeBold = {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px'
}
const titleSmallSemibold = {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0.001em'
}
const labelLarge = {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.001em'
}
const labelMedium = {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0.005em'
}
const labelMediumSemibold = {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.005em'
}
const labelSmall = {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0.005em'
}
const labelLargeBold = {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0.001em'
}
const labelSmallBold = {
    fontSize: '11px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: '0.005em'
}
const labelMediumBold = {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: '0.005em'
}
const labelSmallSemibold = {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.004em'
}
const bodyLarge = {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.0015em'
}
const bodyMedium = {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.0025em'
}
const bodySmall = {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.004em'
}
const bodySmallMedium = {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500',
    letterSpacing: '0.004em'
}
const bodyLargeSemibold = {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '0.0015em'
}
const bodyMediumSemibold = {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0.0025em'
}
const bodyLargeBold = {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.0015em'
}
const bodyMediumMedium = {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.0025em'
}
const bodyLargeMedium = {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.0015em'
}
const bodyMediumBold = {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
}

export const componentConfig = (currentTheme) => {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    '&:not(.no-margin)': {
                        marginBottom: 24
                    },
                    '&.size-small .MuiFilledInput-input': {
                        height: 36,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 12
                    },
                    '&.size-medium .MuiFilledInput-input': {
                        height: 42,
                        paddingTop: 19,
                        paddingBottom: 5,
                        paddingLeft: 12,
                        ...bodyMediumMedium
                    },
                    '&.size-small .MuiFilledInput-root': {
                        paddingRight: 8
                    },
                    '&.size-medium .MuiFilledInput-root': {
                        paddingRight: 12
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    ...bodyMediumMedium,
                    backdropFilter: 'blur(6px)'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    right: 0,
                    marginTop: 0,
                    marginLeft: 16,
                    marginRight: 16,
                    color: currentTheme.colors.system.textSecondary,
                    ...labelMedium
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: currentTheme.colors.system.textSecondary
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    background: 'none',
                    color: 'inherit',
                    '&:hover,&.Mui-focused': {
                        background: 'none',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        border: '1px solid ' + currentTheme.colorVariables.textBox.hoverBorderColor
                    },
                    '&:before': {
                        top: 0,
                        borderRadius: 8,
                        borderBottom: 'none',
                        border: '1px solid ' + currentTheme.colors.system.border,
                        transition: 'all 0.3s ease'
                    },
                    '&:after': {
                        top: 0,
                        borderRadius: 8,
                        borderBottom: 'none',
                        border: '2px solid ' + currentTheme.colorVariables.textBox.focusBorderColor,
                        transition: 'all 0.3s ease',
                        transform: 'scale(1)',
                        opacity: 0
                    },
                    '&.Mui-focused:after': {
                        opacity: 1
                    },
                    '& .MuiInputAdornment-positionEnd .MuiIconButton-root': {
                        marginRight: -8,
                        color: currentTheme.colors.system.textSecondary
                    },
                    '&.Mui-error:before,&.Mui-error:after,&.Mui-error.Mui-focused:after': {
                        borderColor: currentTheme.colorVariables.textBox.errorBorderColor
                    },
                    '&.Mui-disabled': {
                        background: 'none',
                        opacity: 0.56
                    },
                    '&.Mui-disabled:before': {
                        borderStyle: 'solid',
                        borderColor: currentTheme.colors.system.border
                    },
                    '& .mui-select-icon': {
                        position: 'absolute',
                        color: currentTheme.colors.system.textSecondary,
                        transition: 'all 0.3s ease',
                        right: 12,
                        top: '50%',
                        transform: 'translateY(-50%)'
                    },
                    '.size-small & .mui-select-icon,.size-medium & .mui-select-icon': {
                        marginRight: 0,
                        marginLeft: 4
                    },
                    '&.MuiInputBase-multiline .MuiFilledInput-input': {
                        paddingLeft: 4,
                        paddingRight: 4
                    },
                    '.no-label &.MuiInputBase-multiline': {
                        paddingTop: 12
                    },
                    '& .text-adornment': {
                        paddingRight: 4,
                        paddingLeft: 16,
                        color: currentTheme.colors.system.textSecondary,
                        ...bodyLarge
                    }
                },
                input: {
                    height: 48,
                    color: currentTheme.colors.system.textPrimary,
                    paddingLeft: 16,
                    paddingRight: 16,
                    textAlign: 'inherit',
                    '.no-label &': {
                        paddingTop: 4,
                        paddingBottom: 4
                    },
                    '&.MuiInputBase-inputAdornedEnd': {
                        paddingRight: 0
                    },
                    '&:focus': {
                        background: 'none'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(16px, 13px) scale(1)',
                    '&.Mui-focused': {
                        color: currentTheme.colorVariables.textBox.focusLabelColor
                    },
                    '.size-medium &': {
                        transform: 'translate(12px, 10px) scale(1)',
                    },
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(16px, 7px) scale(0.75)',
                    },
                    '.size-medium &.MuiInputLabel-shrink': {
                        transform: 'translate(12px, 2px) scale(0.75)',
                    },
                    '&.Mui-error,&.Mui-error.Mui-focused': {
                        color: currentTheme.colorVariables.textBox.errorLabelColor
                    },
                    '&.Mui-disabled': {
                        color: currentTheme.colors.system.textSecondary,
                        opacity: 0.56
                    }
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    ...bodyLargeSemibold,
                    textTransform: 'none',
                    borderRadius: 8,
                    minWidth: 'auto',
                    height: 44,
                    position: 'relative',
                    paddingLeft: 24,
                    paddingRight: 24,
                    transition: 'all 0.3s ease',
                    '&>span': {
                        display: 'flex',
                        alignItems: 'center'
                    },
                    '&>span>svg:first-of-type': {
                        marginRight: 8,
                        marginLeft: -8
                    },
                    '&>span>svg:last-child': {
                        marginLeft: 8,
                        marginRight: -8
                    },
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: -5,
                        right: -5,
                        bottom: -5,
                        left: -5,
                        display: 'block',
                        border: '2px solid ' + currentTheme.colorVariables.button.focusRing,
                        borderRadius: 13,
                        transform: 'scale(1.02)',
                        transition: 'all 0.3s ease',
                        opacity: 0,
                        visibility: 'hidden'
                    },
                    '&:focus:before': {
                        transform: 'scale(1)',
                        opacity: 1,
                        visibility: 'visible'
                    },
                    '&.btn-block': {
                        width: '100%'
                    },
                    '&.edge-start': {
                        marginLeft: -16
                    },
                    '&.edge-end': {
                        marginRight: -16
                    },
                    '& .MuiCircularProgress-root': {
                        color: 'currentColor',
                        height: '24px !important',
                        width: '24px !important',
                    },
                    '& .MuiCircularProgress-svg, & .MuiCircularProgress-svg:first-of-type, & .MuiCircularProgress-svg:last-child': {
                        margin: 0,
                        color: 'currentColor',
                        height: '100%!important',
                        width: '100%!important'
                    }
                },
                containedPrimary: {
                    color: currentTheme.colorVariables.button.priamryTextColor,
                    background: currentTheme.colorVariables.button.primaryBackground,
                    '&.Mui-disabled': {
                        color: currentTheme.colorVariables.button.priamryTextColor,
                        background: currentTheme.colorVariables.button.primaryBackground,
                        opacity: 0.56
                    },
                    '&:hover': {
                        background: currentTheme.colorVariables.button.hoverPrimaryBackground
                    }
                },
                containedSecondary: {
                    color: currentTheme.colorVariables.button.secondaryTextColor,
                    background: currentTheme.colorVariables.button.secondaryBackgroundColor,
                    '&.Mui-disabled': {
                        color: currentTheme.colorVariables.button.secondaryTextColor,
                        background: currentTheme.colorVariables.button.secondaryBackgroundColor,
                        opacity: 0.56
                    },
                    '&:hover': {
                        background: currentTheme.colorVariables.button.hoverSecondaryBackgroundColor
                    }
                },
                textPrimary: {
                    color: currentTheme.colorVariables.button.textTextColor,
                    '&:hover': {
                        background: currentTheme.colorVariables.button.hoverTextBackgroundColor
                    }
                },
                sizeLarge: {
                    height: 56,
                    paddingLeft: 32,
                    paddingRight: 32,
                    '&.edge-start': {
                        marginLeft: -24
                    },
                    '&.edge-end': {
                        marginRight: -24
                    }
                },
                sizeSmall: {
                    ...bodyMediumSemibold,
                    height: 36,
                    paddingLeft: 16,
                    paddingRight: 16,
                    '&.edge-start': {
                        marginLeft: -12
                    },
                    '&.edge-end': {
                        marginRight: -12
                    },
                    '&>span>svg': {
                        height: '20px !important',
                        width: '20px !important'
                    },
                    '&>span>svg:first-of-type': {
                        marginRight: 4,
                        marginLeft: -4
                    },
                    '&>span>svg:last-child': {
                        marginLeft: 4,
                        marginRight: -4
                    },
                    '& .MuiCircularProgress-root': {
                        height: '20px !important',
                        width: '20px !important',
                    },
                    '&.icon-only': {
                        paddingLeft: 6,
                        paddingRight: 6
                    },
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    ...currentTheme.shadows.shadow4,
                    borderRadius: 8,
                    color: 'inherit',
                    backgroundColor: currentTheme.colorVariables.menu.backgroundColor,
                    "&.spaced": {
                        padding: 24
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: () => <Icon path={mdiChevronDown} className="mui-select-icon" color={'currentColor'} size={1} />
            },
            styleOverrides: {
                select: {
                    minHeight: 'auto',
                    height: 48,
                    zIndex: 1,
                    padding: '21px 16px 4px 16px',
                    '.no-label &': {
                        paddingTop: 13,
                        paddingBottom: 12
                    },
                    '.no-label.size-small &': {
                        paddingTop: 6,
                        paddingBottom: 5
                    },
                    '.MuiTextField-root.size-small &': {
                        paddingRight: 28
                    },
                    '.MuiTextField-root.size-small &.MuiInputBase-inputAdornedEnd': {
                        paddingRight: 54
                    },
                    '& ~ .mui-select-icon': {
                        right: 8
                    },
                    '&[aria-expanded="true"] ~ .mui-select-icon': {
                        transform: 'translateY(-50%) rotate(180deg)'
                    },
                    '& ~ .clear-filter': {
                        position: 'absolute',
                        zIndex: 2,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: 34
                    },
                    '&.MuiFilledInput-input': {
                        paddingRight: 40
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: currentTheme.colorVariables.menu.backgroundColor
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ...bodyLarge,
                    padding: '12px 20px',
                    transition: 'all 0.3s ease',
                    color: currentTheme.colors.system.textPrimary,
                    '&.Mui-selected,&.Mui-focusVisible,&.Mui-selected.Mui-focusVisible': {
                        background: currentTheme.colorVariables.menu.menuItemHoverBackgroundColor,
                        '&:hover': {
                            background: currentTheme.colorVariables.menu.menuItemHoverBackgroundColor
                        }
                    },
                    '&:hover': {
                        background: currentTheme.colorVariables.menu.menuItemHoverBackgroundColor
                    },
                    'svg': {
                        marginRight: 12
                    }
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    transition: 'all 0.3s ease'
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                checkedIcon:
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6 16.2L17.65 9.15L16.25 7.75L10.6 13.4L7.75 10.55L6.35 11.95L10.6 16.2ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5Z" fill="currentColor" />
                    </svg>,
                icon:
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z" fill="currentColor" />
                    </svg>,
                indeterminateIcon:
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 13H17V11H7V13ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5Z" fill="currentColor" />
                    </svg>,
            },
            styleOverrides: {
                root: {
                    color: currentTheme.colorVariables.checkboxRadio.textColor,
                    '& .MuiTouchRipple-root': {
                        display: 'none'
                    },
                    '&:hover': {
                        background: 'none'
                    },
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        height: 28,
                        width: 28,
                        border: '2px solid ' + currentTheme.colorVariables.checkboxRadio.focusRing,
                        borderRadius: 7,
                        top: 7,
                        left: 7,
                        transition: 'all 0.3s ease',
                        opacity: 0,
                        visibility: 'hidden',
                        transform: 'scale(1.1)'
                    },
                    '&.Mui-focusVisible:after': {
                        opacity: 1,
                        visibility: 'visible',
                        transform: 'scale(1)'
                    },
                    '&.Mui-disabled': {
                        opacity: 0.56
                    },
                    '&.Mui-checked': {
                        color: currentTheme.colorVariables.checkboxRadio.checkedTextColor
                    }
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                checkedIcon:
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="7" stroke="currentColor" strokeWidth="6" />
                    </svg>,
                icon:
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
                    </svg>
            },
            styleOverrides: {
                root: {
                    color: currentTheme.colorVariables.checkboxRadio.textColor,
                    '& .MuiTouchRipple-root': {
                        display: 'none'
                    },
                    '&:hover': {
                        background: 'none'
                    },
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        height: 30,
                        width: 30,
                        border: '2px solid ' + currentTheme.colorVariables.checkboxRadio.focusRing,
                        borderRadius: 20,
                        top: 6,
                        left: 6,
                        transition: 'all 0.3s ease',
                        opacity: 0,
                        visibility: 'hidden',
                        transform: 'scale(1.1)'
                    },
                    '&.Mui-focusVisible:after': {
                        opacity: 1,
                        visibility: 'visible',
                        transform: 'scale(1)'
                    },
                    '&.Mui-disabled': {
                        opacity: 0.56
                    },
                    '&.Mui-checked': {
                        color: currentTheme.colorVariables.checkboxRadio.checkedTextColor
                    },
                    '& ~ .MuiTypography-root': {
                        ...bodyMedium,
                    },
                    '& ~ .MuiTypography-root.Mui-disabled': {
                        color: currentTheme.colors.system.textPrimary,
                        opacity: 0.56
                    },
                    '.MuiRadioGroup-root &': {
                        marginTop: -2,
                        marginBottom: -2
                    }
                }
            }
        },
        MuiTablePagination: {
            defaultProps: {
                labelDisplayedRows: ({ from, to, count }) => { return `Showing ${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`; },
                labelRowsPerPage: 'Rows Per Page'
            },
            styleOverrides: {
                root: {
                    overflow: 'visible',
                    color: 'inherit',
                    '& .MuiTablePagination-toolbar': {
                        minHeight: 'auto',
                        padding: '0 2px'
                    },
                    '&.center': {
                        '.MuiTablePagination-spacer': {
                            display: 'none'
                        },
                        '.MuiTablePagination-toolbar': {
                            justifyContent: 'center'
                        }
                    }
                },
                spacer: {
                    order: 1
                },
                selectLabel: {
                    ...bodyMedium,
                    order: 3,
                    paddingRight: 12
                },
                select: {
                    '&.MuiInputBase-input.MuiSelect-select': {
                        padding: '8px 32px 8px 10px',
                        height: 36,
                        border: '1px solid ' + currentTheme.colors.system.border,
                        borderRadius: 8,
                        transition: 'all 0.3s ease'
                    }
                },
                input: {
                    order: 4,
                    margin: 0,
                    marginRight: 16,
                    '& .mui-select-icon': {
                        height: '20px!important',
                        width: '20px!important',
                        position: 'absolute',
                        top: '50%',
                        right: 6,
                        transform: 'translateY(-50%)!important',
                        marginTop: -1,
                        zIndex: 0
                    },
                    '& .MuiSelect-select': {
                        background: 'none'
                    },
                    '&.Mui-focused .MuiInputBase-input.MuiSelect-select': {
                        borderColor: currentTheme.colorVariables.textBox.focusBorderColor
                    }
                },
                displayedRows: {
                    ...bodyMedium,
                    order: 2,
                    paddingRight: 24
                },
                actions: {
                    order: 5,
                    margin: 0,
                    marginRight: -4,
                    color: currentTheme.colors.system.textSecondary,
                    '& .MuiIconButton-root': {
                        padding: 6
                    },
                    '& .MuiIconButton-root.Mui-disabled': {
                        color: currentTheme.colors.system.textSecondary,
                        opacity: 0.56
                    }
                }
            }
        },
        MuiDialog: {
            defaultProps: {
                slotProps: {
                    backdrop: {
                        classes: {
                            root: 'MuiDialog-backdrop'
                        }
                    }
                }
            },
            styleOverrides: {
                root: {
                    '& .MuiDialog-backdrop': {
                        background: currentTheme.colorVariables.dialog.backdropBackgroundColor
                    }
                },
                paper: {
                    borderRadius: 16,
                    padding: 24,
                    width: 600,
                    color: 'inherit',
                    background: currentTheme.colorVariables.dialog.backgroundColor,
                    ...currentTheme.shadows.shadow5
                },
                paperWidthMd: {
                    width: 400
                },
                paperWidthLg: {
                    width: 800
                },
            }
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'primary'
            },
            styleOverrides: {
                svg: {
                    color: currentTheme.colorVariables.progress.color
                }
            }
        },
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    success: <Icon path={mdiCheckCircleOutline} color={'currentColor'} size={1} />
                }
            },
            styleOverrides: {
                root: {
                    padding: '4px 12px',
                    borderRadius: 8,
                    marginBottom: 16,
                    ...bodyMedium,
                    '.MuiSnackbar-root &': {
                        marginBottom: 0,
                        ...currentTheme.shadows.shadow3
                    }
                },
                icon: {
                    marginRight: 8,
                    transform: 'translateY(-1px)'
                },
                standardError: {
                    background: currentTheme.colorVariables.alert.errorBackgroundColor,
                    color: currentTheme.colorVariables.alert.errorTextColor,
                    '& .MuiAlert-icon': {
                        color: 'currentColor'
                    }
                },
                standardWarning: {
                    background: currentTheme.colorVariables.alert.warningBackgroundColor,
                    color: currentTheme.colorVariables.alert.warningTextColor,
                    '& .MuiAlert-icon': {
                        color: 'currentColor'
                    }
                },
                standardSuccess: {
                    background: currentTheme.colorVariables.alert.successBackgroundColor,
                    color: currentTheme.colorVariables.alert.successTextColor,
                    '& .MuiAlert-icon': {
                        color: 'currentColor'
                    }
                },
                standardInfo: {
                    background: currentTheme.colorVariables.alert.infoBackgroundColor,
                    color: currentTheme.colorVariables.alert.infoTextColor,
                    '& .MuiAlert-icon': {
                        color: 'currentColor'
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    height: 22,
                    width: 40,
                    padding: 0,
                    borderRadius: 50,
                    overflow: 'visible',
                    '& ~ .MuiTypography-root': {
                        ...bodyMedium,
                        marginLeft: 12
                    },
                    '& ~ .MuiTypography-root.Mui-disabled': {
                        color: currentTheme.colors.system.textPrimary,
                        opacity: 0.56
                    },
                    '.MuiFormControlLabel-root &': {
                        marginLeft: 11
                    },
                    '& .MuiTouchRipple-root': {
                        display: 'none'
                    },
                    '.MuiFormControlLabel-root.Mui-disabled &': {
                        opacity: 0.56
                    }
                },
                switchBase: {
                    padding: 0,
                    top: 3,
                    left: 3,
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        height: 32,
                        width: 50,
                        border: '2px solid ' + currentTheme.colorVariables.checkboxRadio.focusRing,
                        borderRadius: 40,
                        top: -8,
                        left: -8,
                        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
                        opacity: 0,
                        visibility: 'hidden',
                        transform: 'scale(1.1)'
                    },
                    '&.Mui-focusVisible:after': {
                        opacity: 1,
                        visibility: 'visible',
                        transform: 'scale(1)'
                    },
                    '&.Mui-checked': {
                        transform: 'translateX(18px)',
                        color: currentTheme.colorVariables.checkboxRadio.switchThumbColor
                    },
                    '&.Mui-checked+.MuiSwitch-track,&.Mui-disabled+.MuiSwitch-track': {
                        opacity: 1
                    },
                    '&.Mui-checked:after': {
                        left: -26
                    },
                    '&.Mui-disabled,&.Mui-checked.Mui-disabled': {
                        color: currentTheme.colorVariables.checkboxRadio.switchThumbColor
                    }
                },
                thumb: {
                    height: 16,
                    width: 16,
                    boxShadow: 'none'
                },
                track: {
                    opacity: 1,
                    background: currentTheme.colorVariables.checkboxRadio.switchTrackColor,
                    borderRadius: 20
                }
            }
        },
        MuiChip: {
            defaultProps: {
                variant: 'outlined'
            },
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    ...labelLarge,
                    color: 'inherit'
                },
                deleteIcon: {
                    color: 'inherit',
                    opacity: 0.64,
                    '&:hover': {
                        color: 'inherit',
                        opacity: 1
                    }
                },
                labelMedium: {
                    paddingLeft: 16,
                    paddingRight: 16
                },
                labelSmall: {
                    paddingLeft: 10,
                    paddingRight: 10
                },
                outlined: {
                    border: '1px solid ' + currentTheme.colors.system.border
                },
                filledPrimary: {
                    background: currentTheme.colorVariables.chip.primaryBackgroundColor,
                    color: currentTheme.colorVariables.chip.primaryTextColor
                },
                icon: {
                    height: '18px!important',
                    width: '18px!important',
                    marginLeft: 8,
                    marginRight: -8
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    ...bodySmall,
                    color: currentTheme.colors.system.textSecondary,
                    padding: 12,
                    borderBottom: '1px solid ' + currentTheme.colors.system.borderLight,
                    borderTop: '1px solid ' + currentTheme.colors.system.borderLight,
                    '&.long-text': {
                        minWidth: 240
                    },
                    '.vertical-top &': {
                        verticalAlign: 'top'
                    }
                },
                body: {
                    ...bodyMediumMedium,
                    color: 'inherit',
                    padding: 12,
                    borderBottom: '1px solid ' + currentTheme.colors.system.borderLight,
                    '.vertical-top &': {
                        verticalAlign: 'top'
                    },
                    '& .grid-checkbox': {
                        marginTop: -12,
                        marginBottom: -12
                    },
                    '& .icon-button': {
                        marginTop: -8,
                        marginBottom: -8
                    },
                }
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <Icon path={mdiChevronDown} color={'currentColor'} size={1} />
            },
            styleOverrides: {
                paper: {
                    background: currentTheme.colorVariables.menu.backgroundDark,
                    ...currentTheme.shadows.shadow4
                },
                listbox: {
                    '& .MuiAutocomplete-option.Mui-focused': {
                        backgroundColor: currentTheme.colorVariables.menu.menuItemHoverBackgroundColor
                    }
                },
                option: {
                    ...bodyLarge,
                    padding: '12px 20px',
                    transition: 'all 0.3s ease',
                    color: currentTheme.colors.system.textPrimary
                },
                inputRoot: {
                    paddingTop: 26,
                    paddingBottom: 1,
                    paddingLeft: 12,
                    '& .MuiFilledInput-input': {
                        padding: '0 4px',
                        height: 21,
                        minWidth: 80,
                        transform: 'translateY(-5px)'
                    },
                    '& .MuiAutocomplete-tag + .MuiFilledInput-input': {
                        transform: 'none'
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.button-radio': {
                        margin: 0,
                        display: 'flex',
                        padding: 4,
                        border: '1px solid ' + currentTheme.colors.system.border,
                        borderRadius: 8
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    "&.Mui-disabled": {
                        color: 'inherit',
                        opacity: 0.32
                    }
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: 'inherit'
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: currentTheme.colorVariables.linearProgress.backgroundColor
                },
                bar: {
                    backgroundColor: currentTheme.colorVariables.linearProgress.bar,
                    '.danger &': {
                        backgroundColor: currentTheme.colorVariables.linearProgress.barDanger
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    'strong': {
                        fontWeight: 700
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid ' + currentTheme.colors.system.border,
                    '.MuiTabs-indicator': {
                        display: 'none'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    ...bodyLarge,
                    color: currentTheme.colors.system.textSecondary,
                    fontWeight: 400,
                    minWidth: 'auto',
                    paddingTop: 10,
                    paddingBottom: 10,
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: '50%',
                        right: '50%',
                        bottom: 0,
                        height: 3,
                        opacity: 0,
                        background: currentTheme.colorVariables.tab.indicatorColor,
                        borderRadius: '2px 2px 0 0',
                        transition: 'all 0.3s ease',
                    },
                    '&.Mui-selected': {
                        ...bodyLargeBold,
                        color: currentTheme.colors.system.textPrimary,
                        '&:after': {
                            opacity: 1,
                            left: 16,
                            right: 16
                        }
                    }

                }
            }
        }
    }
}

export const typographyConfig = (currentTheme) => {
    return {
        fontFamily: currentTheme.fontFamily,
        'display-large': { ...displayLarge },
        'display-medium': { ...displayMedium },
        'display-small': { ...displaySmall },
        'display-small-bold': { ...displaySmallBold },
        'headline-large': { ...headlineLarge },
        'headline-medium': { ...headlineMedium },
        'headline-small': { ...headlineSmall },
        'headline-small-bold': { ...headlineSmallBold },
        'headline-medium-bold': { ...headlineMediumBold },
        'headline-medium-semibold': { ...headlineMediumSemibold },
        'title-large': { ...titleLarge },
        'title-medium': { ...titleMedium },
        'title-small': { ...titleSmall },
        'title-medium-bold': { ...titleMediumBold },
        'title-large-bold': { ...titleLargeBold },
        'title-large-semibold': { ...titleLargeSemibold },
        'title-small-bold': { ...titleSmallBold },
        'title-medium-large-semibold': { ...titleMediumLargeSemibold },
        'title-medium-large-bold': { ...titleMediumLargeBold },
        'title-small-semibold': { ...titleSmallSemibold },
        'label-large': { ...labelLarge },
        'label-medium': { ...labelMedium },
        'label-medium-semibold': { ...labelMediumSemibold },
        'label-small': { ...labelSmall },
        'label-large-bold': { ...labelLargeBold },
        'label-small-bold': { ...labelSmallBold },
        'label-medium-bold': { ...labelMediumBold },
        'label-small-semibold': { ...labelSmallSemibold },
        'body-large': { ...bodyLarge },
        'body-medium': { ...bodyMedium },
        'body-small': { ...bodySmall },
        'body-small-medium': { ...bodySmallMedium },
        'body-large-semibold': { ...bodyLargeSemibold },
        'body-medium-semibold': { ...bodyMediumSemibold },
        'body-large-bold': { ...bodyLargeBold },
        'body-medium-medium': { ...bodyMediumMedium },
        'body-large-medium': { ...bodyLargeMedium },
        'body-medium-bold': { ...bodyMediumBold }
    }
}

export const breakpointConfig = {
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        'xxl': 1400
    },
}