import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "./components/SettingsTab";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, MenuItem, Typography, TextField } from "@mui/material";
import Icon from "@mdi/react";
import { mdiPencilOutline } from "@mdi/js";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomTextbox from "../../components/common/EasyEditCustomTextbox";
import FormGroupStatic from "../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import EasyEditCustomSelect from "../../components/common/EasyEditCustomSelect";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useDispatch, useSelector } from "react-redux";
import { login, loginUser, setTimeAndCurrency, workspaces } from "../../redux/slices/userSlice";
import { capitalize, validateField } from "../../utils/Utils";
import EmptyState from "../../components/common/EmptyState";
import { Formik } from "formik";
import * as yup from "yup";
import WhatsAppSignup from "../../components/common/WhatsAppSignup";
import WhatsAppSignupCopy from "../../components/common/WhatsAppSignupCopy";
import MetaAddAccount from "../../components/common/MetaAddAccount";
//import VCard from 'vcard-js';

function SettingsOverview(props) {
    var isDisplayPage = false
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { showLoader, hideLoader } = useLoader();
    const [pageReload, setPageReload] = useState(false)
    const { preferredDateFormat, preTimeZoneKey, currencySymbol, currencyCode, currencyStringValue } = useSelector(state => state?.user);
    const [locationTracking, setLocationTracking] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState();

    const loginUserDetail = useSelector(loginUser);
    const user = loginUserDetail?.user || [];

    const roleNames = ["admin", "superadmin", "super admin"];
    isDisplayPage = roleNames.includes(user?.role?.toLowerCase() || "");

    const userPermissions = useSelector((state) => state.user.permission);
    const userWorkspaces = useSelector((state) => state.user.workspace);

    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});

    const [currentTenant, setCurrentTenant] = useState([])
    const [tenantData, setTenantData] = useState([])
    const [currency, setCurrency] = useState([])
    const [dateFormate, setDateFormate] = useState([])
    const [timeZoneList, setTimeZoneList] = useState([])
    const [allowEditInline, setAllowEditInline] = useState(true);

    const [templateList, setTemplateList] = useState([
        { id: 1, name: 'hello_world', content: 'Hello World' },
        { id: 2, name: 'first_marketing_temp', content: 'First Marketing Template' },
    ]);

    const [contactList, setContactList] = useState([
        { id: 919662035673, name: '+91 9662035673' },
        { id: 919427865533, name: '+91 9427865533' },
        { id: 918401908134, name: '+91 8401908134' },
    ]);

    const [templateCategory, setTemplateCategory] = useState([
        { id: 'MARKETING', name: 'MARKETING' },
        { id: 'UTILITY', name: 'UTILITY' },
        { id: 'AUTHENTICATION', name: 'AUTHENTICATION' },
    ]);

    const [templateLanguage, setTemplateLanguage] = useState([
        { id: 'en', name: 'English' },
        { id: 'en_GB', name: 'English (UK)' },
        { id: 'en_US', name: 'English (US)' },
    ]);




    useEffect(() => {
        const tracking = user.is_location_traking === 'yes'
            ? 'is_location_traking'
            : (user.lead_location_traking === 'yes'
                ? 'lead_location_traking'
                : (user.real_time_location_traking === 'yes'
                    ? 'real_time_location_traking'
                    : 'is_location_traking'
                )
            )
        setLocationTracking(tracking);
    }, [user.is_location_traking, user.lead_location_traking, user.real_time_location_traking]);

    useEffect(() => {
        const fetchData = async () => {
            await getData();
        };
        fetchData();

    }, [pageReload]);

    async function getData() {
        showLoader()
        await axiosInstance.get('api/tenant-profile').then(response => {
            if (response?.data?.code === 200) {
                setTenantData(response?.data?.data?.record)
                setCurrency(response?.data?.data?.currency)
                setDateFormate(response?.data?.data?.dateFormateArray)
                setTimeZoneList(response?.data?.data?.timeZoneArray)
                setCurrentTenant(response?.data?.data?.currentTenant)

            }
            hideLoader()
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    }

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    // var tenantImage = '';
    // if (currentTenant?.logo) {
    //     tenantImage = `${process.env.REACT_APP_API_URL}storage/${currentTenant?.logo}`
    // }


    //=======Start::Write acode for update tenant logo==========//
    const handleImageInput = async (e) => {
        e.persist();
        const formData = new FormData();
        formData.append("logo", e.target.files[0]);
        showLoader()
        await axiosInstance.post("/api/change-tenant-data", formData).then((response) => {
            if (response.status === 200) {
                setPageReload(pageReload ? false : true);
                const updatedWorkspace = { ...userWorkspaces, logo: response?.data?.data?.data?.logo };
                dispatch(workspaces(updatedWorkspace));
                setAllowEditInline(true);
                setErrorMessages({})
                showSnackbar(response.data.message, "success");
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };
    //=======End::Write acode for update tenant logo==========//

    useEffect(() => {
        const selectedCurrName = currency.find(priCurrency => priCurrency.id === parseInt(tenantData.preferred_currency));
        if (selectedCurrName) {
            const displayCurryName = `${selectedCurrName.name}-${selectedCurrName.code} (${selectedCurrName.symbol})`
            setSelectedCurrency(displayCurryName)
        }
    }, [tenantData, pageReload]);

    const handleSave = async (value, name) => {
        const data = {
            [name]: value,
        };

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(tenantData, name);

        if (newValue !== value && value !== 'N/A') {
            showLoader()
            await axiosInstance.post("api/change-tenant-data", data).then(response => {
                if (response.status === 200) {
                    hideLoader()
                    showSnackbar(response.data.message, "success");
                    const updatedWorkspace = { ...userWorkspaces, name: value };
                    dispatch(workspaces(updatedWorkspace));

                    setAllowEditInline(true)
                    setPageReload(pageReload ? false : true);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }

            });
        } else {
            hideLoader()
            setAllowEditInline(true)
        }

    }

    const getCurrencyDetail = (id) => {
        const selectedCurrName = currency.find(priCurrency => priCurrency.id === parseInt(id));
        if (selectedCurrName) {
            return selectedCurrName;
        }
    }

    const handleSaveData = async (value, name) => {
        console.log(value, name)
        const data = {
            [name]: value,
        };

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(tenantData, name);

        if (newValue !== value && value !== 'N/A') {
            showLoader()
            await axiosInstance.post("api/update-tenant-profile", data).then(response => {
                if (response.status === 200) {
                    hideLoader()
                    setAllowEditInline(true)
                    setPageReload(pageReload ? false : true);
                    if (name === 'preferred_timezone') {
                        dispatch(setTimeAndCurrency({
                            preferredDateFormat: preferredDateFormat || 'YYYY-MM-DD HH:mm',
                            preTimeZoneKey: data.preferred_timezone || 'Asia/Calcutta',
                            currencySymbol: currencySymbol || '₹',
                            currencyCode: currencyCode || 'INR',
                            currencyStringValue: currencyStringValue || 'en-IN'
                        }));

                        user.preferred_timezone = data.preferred_timezone;
                        dispatch(login(user));
                    } else if (name === 'preferred_currency') {
                        const currencyData = getCurrencyDetail(value)
                        dispatch(setTimeAndCurrency({
                            preferredDateFormat: preferredDateFormat || 'YYYY-MM-DD HH:mm',
                            preTimeZoneKey: preTimeZoneKey || 'Asia/Calcutta',
                            currencySymbol: currencyData?.symbol || '₹',
                            currencyCode: currencyData?.code || 'INR',
                            currencyStringValue: currencyData?.string_value || 'en-IN'
                        }));

                        user.preferred_currency = data.preferred_currency;
                        dispatch(login(user));
                    } else if (name === 'preferred_date_format') {
                        //dispatch(preferredDateFormat(data.preferred_date_format || 'DD-MMM-YYYY h:mm a'))
                        dispatch(setTimeAndCurrency({
                            preferredDateFormat: data.preferred_date_format || 'YYYY-MM-DD HH:mm',
                            preTimeZoneKey: preTimeZoneKey || 'Asia/Calcutta',
                            currencySymbol: currencySymbol || '₹',
                            currencyCode: currencyCode || 'INR',
                            currencyStringValue: currencyStringValue || 'en-IN'
                        }));

                        user.preferred_date_format = data.preferred_date_format;
                        dispatch(login(user));
                    }
                    showSnackbar(response.data.message, "success");
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }

            });
        } else {
            hideLoader()
            setAllowEditInline(true)
        }

    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(true);
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const updateLocationSetting = async (e) => {
        setLocationTracking(e.target.value);
        const data = {
            location_type: e.target.value,
            location_value: 'yes',
        };
        showLoader()
        await axiosInstance.post("/api/update-tenant-profile", data).then((response) => {
            if (response.status === 200) {
                setAllowEditInline(true)
                hideLoader()
                if (data.location_type === "is_location_traking") {
                    user.is_location_traking = data.location_value;
                    user.lead_location_traking = "no";
                    user.real_time_location_traking = "no";
                } else if (data.location_type === "lead_location_traking") {
                    user.is_location_traking = "no";
                    user.lead_location_traking = data.location_value;
                    user.real_time_location_traking = "no";
                } else if (data.location_type === "real_time_location_traking") {
                    user.is_location_traking = "no";
                    user.lead_location_traking = "no";
                    user.real_time_location_traking = data.location_value;
                }
                dispatch(login(user));
                showSnackbar(response.data.message, "success");
                setPageReload(pageReload ? false : true);

            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    setAllowEditInline(true)
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const timezoneOptions = Object.entries(timeZoneList).map(([key, value]) => ({
        value: key,
        option: value,
    }));

    const currencyOptions = currency.map(currency => ({
        value: currency.id, // or use currency.code if you prefer
        option: `${currency.name}-${currency.code} (${currency.symbol})`
    }));

    const dateFormateOptions = Object.entries(dateFormate).map(([key, value]) => ({ key, option: value }));


    //=================START: Write a code for WhatsApp API =========================//
    const validationSchema = yup.object().shape({
        template_id: yup.string().required('Please select template'),
        mobile_number: yup.string().required("Please enter mobile number"),
        //text_maessage: yup.string().required("Please enter text message"),
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            template_id: values.template_id,
            mobile_number: values.mobile_number,
            text_maessage: values.text_maessage,
        };

        showLoader()
        await axiosInstance.post("/api/send-whatsapp-message/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    //---------------------------------------------------------------------------

    const validationTemplateSchema = yup.object().shape({
        template_name: yup.string().required('Please enter template name'),
        template_category: yup.string().required("Please select category"),
        template_language: yup.string().required("Please select language"),
    })

    const handleTemplateSubmit = async (values, onSubmitProps) => {
        const data = {
            template_name: values.template_name,
            template_category: values.template_category,
            template_language: values.template_language,
        };

        showLoader()
        await axiosInstance.post("/api/create-template", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }
    //=================END: Write a code for WhatsApp API =========================//


    return (
        <>
            <Helmet>
                <title>Settings | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />
            {
                isDisplayPage ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="card p-16">
                                <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                                    <Grid item xs={'auto'} className="flex-shrink-1">
                                        <Grid container alignItems={'center'} spacing={1.5}>
                                            <Grid item xs="auto">
                                                <img onError={addDefaultProfileSrc} src={currentTenant?.logo} alt={currentTenant?.name || ''} className="user-avatar" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='title-medium-bold' component={'h6'}>{currentTenant?.name || ''}</Typography>
                                                <ul className='inline-details'>
                                                    <li><Tooltip title="Email"><span>{tenantData?.email || ''}</span></Tooltip></li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                        <div className="psuedo-upload-block">
                                            <div className="content">
                                                <Button size={"small"} color="secondary" tabIndex={-1}>
                                                    <span>
                                                        <Icon path={mdiPencilOutline} color={'currentColor'} size={1} />
                                                        <span>Change Photo</span>
                                                    </span>
                                                </Button>
                                            </div>
                                            <input
                                                type="file"
                                                className="psuedo-upload-element"
                                                id="profile_image"
                                                name="profile_image"
                                                accept="image/jpeg, image/png, image/jpg"
                                                onChange={handleImageInput} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="card p-16">
                                <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Tenant Information</Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={6} lg={4}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSave(value, 'name') }}
                                            onCancel={() => { onCancelEdit('name') }}
                                            value={userWorkspaces?.name || "N/A"}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Name" value={tenantData?.name} errorMessages={errorMessages['name']} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Name" value={userWorkspaces?.name} />}
                                            onValidate={(value) => handleValidation(value, 'name', 'Name')}
                                            validationMessage={errorMessages['name']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="card p-16">
                                <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Settings</Typography>

                                <Grid container spacing={1.5}>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'preferred_timezone') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={tenantData?.preferred_timezone || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Preferred Timezone" fieldValue={tenantData?.preferred_timezone} hideDefaultSelectValue={true}
                                                    items={timezoneOptions}
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Preferred Timezone" value={tenantData?.preferred_timezone || 'N/A'} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['preferred_timezone'] && <span className="easy-edit-validation-error">{backendErrorMessages['preferred_timezone']}</span>}
                                    </Grid>

                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'preferred_currency') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={selectedCurrency || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Preferred Currency" fieldValue={tenantData?.preferred_currency} hideDefaultSelectValue={true}
                                                    items={currencyOptions}
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Preferred Currency"
                                                value={selectedCurrency || 'N/A'}
                                            />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['preferred_currency'] && <span className="easy-edit-validation-error">{backendErrorMessages['preferred_currency']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'preferred_date_format') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={dateFormateOptions.find(item => item.key === tenantData?.preferred_date_format)?.option || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Preferred Date Format" fieldValue={tenantData?.preferred_date_format} hideDefaultSelectValue={true}
                                                    items={dateFormateOptions}
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Preferred Date Format"
                                                value={
                                                    dateFormateOptions.find(item => item.key === tenantData?.preferred_date_format)?.option || 'N/A'
                                                }
                                            />
                                            }
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['preferred_date_format'] && <span className="easy-edit-validation-error">{backendErrorMessages['preferred_date_format']}</span>}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {
                            userPermissions && userPermissions.includes("location_edit") &&
                            <Grid item xs={12}>
                                <div className="card p-16">
                                    <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Location Settings</Typography>

                                    <RadioGroup
                                        aria-label="location-tracking"
                                        name="location_traking"
                                        value={locationTracking}
                                        onChange={(e) => updateLocationSetting(e)}
                                    >
                                        <Grid container spacing={1.5}>
                                            <Grid item lg={4} xs={6}>
                                                <FormControlLabel className="button-radio" value={'is_location_traking'} control={<Radio />} label="No Location Tracking" />
                                            </Grid>
                                            <Grid item lg={4} xs={6}>
                                                <FormControlLabel className="button-radio" value={'lead_location_traking'} control={<Radio />} label="Location Tracking at Lead" />
                                            </Grid>
                                            <Grid item lg={4} xs={6}>
                                                <FormControlLabel className="button-radio" value={'real_time_location_traking'} control={<Radio />} label="Real Time Location Tracking" />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </div>
                            </Grid>
                        }

                        {
                            user?.packageType !== 'free' &&
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="card p-16">
                                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Connect with WhatsApp</Typography>
                                            <p>Set up WhatsApp messaging easily through Meta using a phone number, no WhatsApp account needed.</p>
                                            <br />
                                            <WhatsAppSignup currentTenant={currentTenant} handleSave={handleSave} />

                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="card p-16">
                                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Meta Ads Lead Capture</Typography>
                                            <p>Seamlessly capture and manage leads from your Meta ad campaigns.</p>
                                            <br />
                                            <MetaAddAccount currentTenant={currentTenant} handleSave={handleSave} />
                                        </div>
                                    </Grid>

                                </Grid>
                            </Grid>
                        }


                    </Grid >
                    :
                    <EmptyState type={'settings'} hideActionBtn />
            }

        </>
    );
}

export default SettingsOverview;