import axiosInstance from '../config/axiosConfig';
import { useSnackbar } from '../components/context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { useLoader } from "../components/context/LoaderContext";
import { useDispatch } from 'react-redux';
import { logout } from "../redux/slices/userSlice";
import Cookies from "universal-cookie";
import { getMainDomain } from '../utils/Utils';

const useLogoutSubmit = () => {
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();


    const logoutSubmit = async () => {
        showLoader();
        try {
            const response = await axiosInstance.post('api/logout');
            if (response.status === 200) {
                hideLoader();
                showSnackbar('You have successfully logged out', 'success');
                const hostname = getMainDomain()
                cookies.remove("auth_token", { path: '/', domain: hostname || '' });
                cookies.remove("auth_name", { path: '/', domain: hostname || '' });
                cookies.remove("auth_tenant", { path: '/', domain: hostname || '' });
                cookies.remove("tenant_workspace", { path: '/', domain: hostname || '' });

                dispatch(logout());
                navigate('/sign-in');
            }
        } catch (error) {
            //showSnackbar('Logout failed. Please try again.', 'error');
        }
    };

    return logoutSubmit;

};

export default useLogoutSubmit;