import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Autocomplete, Button, FormControlLabel, Grid, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import CreateQuoteItem from "./components/CreateQuoteItem";
import { capitalize } from "../../utils/Utils";

function CreateCloneQuote(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const quoteId = id

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);

    const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(false);

    const [productIds, setProductIds] = useState();

    const [quoteDetail, setQuoteDetail] = useState({});
    const [quoteStages, setQuoteStages] = useState();
    const [users, setUsers] = useState([]);
    const [deals, setDeals] = useState([]);
    const [countries, setCountries] = useState([]);
    const [productList, setProductList] = useState([]);

    const [accounts, setAccounts] = useState();
    const [contacts, setContacts] = useState();

    const [defaultValue, setdefaultValue] = useState({
        subject: '',
        user_id: '',
        account_id: '',
        contact_id: '',
        deal_id: '',
        quote_stage: '',
        owner_id: '',

        address: '',
        city: '',
        state: '',
        zipcode: '',
        country_id: '',

        is_same_billing: '',
        s_address: '',
        s_city: '',
        s_state: '',
        s_zipcode: '',
        s_country_id: '',

        terms_and_condition: '',
        description: '',

        productId: '',

        quote_final_total: '',

        quote_item_array: [],

        error_list: [],
    })

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [reloadPage, quoteId]);


    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/quote/edit/' + quoteId).then((response) => {
            if (response.status === 200) {
                setQuoteDetail(response?.data?.data?.record || {})
                setQuoteStages(response?.data?.data?.quoteStageLists || [])
                setUsers(response?.data?.data?.users || [])
                setDeals(response?.data?.data?.deals || [])
                setCountries(response?.data?.data?.countries || [])
                setAccounts(response?.data?.data?.accounts || [])
                setContacts(response?.data?.data?.contacts || [])
                setProductList(response?.data?.data?.product || [])
                setIsShippingSameAsBilling(response?.data?.data?.record?.quote_shipping?.is_same_billing === 1 ? true : false)


                setdefaultValue({
                    subject: response?.data?.data?.record?.subject || '',
                    user_id: response?.data?.data?.record?.user_id || '',
                    account_id: response?.data?.data?.record?.account_id || '',
                    contact_id: response?.data?.data?.record?.contact_id || '',
                    deal_id: response?.data?.data?.record?.deal_id || '',
                    quote_stage: response?.data?.data?.record?.quote_stage || '',
                    owner_id: response?.data?.data?.record?.owner_id || '',

                    address: response?.data?.data?.record?.quote_billing?.address || '',
                    city: response?.data?.data?.record?.quote_billing?.city || '',
                    state: response?.data?.data?.record?.quote_billing?.state || '',
                    zipcode: response?.data?.data?.record?.quote_billing?.zipcode || '',
                    country_id: response?.data?.data?.record?.quote_billing?.country_id || '',

                    is_same_billing: response?.data?.data?.record?.quote_shipping?.is_same_billing || '',
                    s_address: response?.data?.data?.record?.quote_shipping?.address || '',
                    s_city: response?.data?.data?.record?.quote_shipping?.city || '',
                    s_state: response?.data?.data?.record?.quote_shipping?.state || '',
                    s_zipcode: response?.data?.data?.record?.quote_shipping?.zipcode || '',
                    s_country_id: response?.data?.data?.record?.quote_shipping?.country_id || '',

                    terms_and_condition: response?.data?.data?.record?.terms_and_condition,
                    description: response?.data?.data?.record?.description,
                })
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quotes");
                    }
                }
            });
    }



    const validationSchema = yup.object().shape({
        subject: yup.string().required('Enter subject').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        account_id: yup.string().required('Select Account'),
        deal_id: yup.string().required('Select Deal'),

        address: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        city: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        state: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        zipcode: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),

        s_address: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        s_city: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        s_state: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        s_zipcode: yup.string().min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),

        terms_and_condition: yup.string().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters'),
        description: yup.string().min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters'),
    })

    const getContactData = async (id, setFieldValue, type) => {
        if (id) {
            await axiosInstance.get(`api/quote/getAddress/${id}?type=${type}`).then(response => {
                if (response.status === 200) {
                    if (type === 'deal') {
                        setAccounts(response?.data?.data?.getAccountLists || [])
                        setContacts(response?.data?.data?.getContactLists || [])

                        if (response?.data?.data?.getContactLists?.length === 0) {
                            setFieldValue('address', '')
                            setFieldValue('city', '')
                            setFieldValue('state', '')
                            setFieldValue('zipcode', '')
                            setFieldValue('country_id', '')
                        }
                    } else {
                        setFieldValue('address', response?.data?.data?.getBillingAddress?.address1 || '')
                        setFieldValue('city', response?.data?.data?.getBillingAddress?.city || '')
                        setFieldValue('state', response?.data?.data?.getBillingAddress?.state || '')
                        setFieldValue('zipcode', response?.data?.data?.getBillingAddress?.zipcode || '')
                        setFieldValue('country_id', response?.data?.data?.getBillingAddress?.country_id || '')
                    }
                }
            }).then(() => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    showSnackbar(error.response.data.message, 'error')
                }
            });
        } else {
            setAccounts([])
            setContacts([])

            setFieldValue('address', '')
            setFieldValue('city', '')
            setFieldValue('state', '')
            setFieldValue('zipcode', '')
            setFieldValue('country_id', '')
        }

    }

    const handleSwitchChange = (event, setFieldValue, props) => {
        setIsShippingSameAsBilling(event.target.checked);
        setFieldValue('is_same_billing', event.target.checked)
        if (event.target.checked) {
            setFieldValue('s_address', props.values.address ? props.values.address : '')
            setFieldValue('s_city', props.values.city ? props.values.city : '')
            setFieldValue('s_state', props.values.state ? props.values.state : '')
            setFieldValue('s_zipcode', props.values.zipcode ? props.values.zipcode : '')
            setFieldValue('s_country_id', props.values.country_id ? props.values.country_id : '')
        } else {
            setFieldValue('s_address', '')
            setFieldValue('s_city', '')
            setFieldValue('s_state', '')
            setFieldValue('s_zipcode', '')
            setFieldValue('s_country_id', '')
        }
    };


    const handleSubmit = async (values) => {
        const data = {
            subject: values.subject,
            contact_id: values.contact_id,
            account_id: values.account_id,
            deal_id: values.deal_id,
            quote_stage: values.quote_stage === '-1' ? '' : values.quote_stage,
            owner_id: values.owner_id === '-1' ? '' : values.owner_id,

            address: values.address,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            country_id: values.country_id,

            is_same_billing: values.is_same_billing,
            s_address: values.s_address,
            s_city: values.s_city,
            s_state: values.s_state,
            s_zipcode: values.s_zipcode,
            s_country_id: values.s_country_id,

            terms_and_condition: values.terms_and_condition,
            description: values.description,

            quote_item_array: values.QuoteItemArray,
            quote_final_total: values.finalData,
        }

        showLoader();
        await axiosInstance.post("/api/quote/store", data).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
                navigate("/quotes");
            }
        }).then(json => {

        }).catch(error => {
            hideLoader();
            if (error.response) {
                hideLoader()
                if (error.response.status === 400 || error.response.status === 300) {
                    showSnackbar(error?.response?.data?.message, "warning");
                } else {
                    showSnackbar('Oops Something went wrong..!', "warning");
                }
            }
        });
    }



    return (
        <>
            <Helmet>
                <title>Add Quote | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>
            <Formik
                initialValues={defaultValue}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {props => {
                    const {
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched
                    } = props;
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>


                                    <Grid item xxl={4} xs={12}>
                                        <div className="card p-16 height-100p pb-0">
                                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Quote Information</Typography>

                                            <Grid container rowSpacing={0} columnSpacing={1.5}>
                                                <Grid item xxl={12} xs={6}>
                                                    <TextField
                                                        label="Subject"
                                                        variant="filled"
                                                        name="subject"
                                                        value={values?.subject || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.subject && touched.subject}
                                                        helperText={(errors.subject && touched.subject) && errors.subject}
                                                    />

                                                </Grid>

                                                <Grid item xxl={12} xs={6}>
                                                    <Autocomplete
                                                        options={deals || []}
                                                        getOptionLabel={(option) => option.deal_name || ''}
                                                        value={deals.find(deal => deal.id === values?.deal_id) || ''}
                                                        onChange={(event, newValue) => {
                                                            const e = {
                                                                target: {
                                                                    name: 'deal_id',
                                                                    value: newValue ? newValue.id : ''
                                                                }
                                                            };
                                                            handleChange(e);
                                                            getContactData(e.target.value, setFieldValue, 'deal')
                                                            setContacts([]);
                                                            setAccounts([]);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Deal"
                                                                variant="filled"
                                                                error={errors.deal_id && touched.deal_id}
                                                                helperText={(errors.deal_id && touched.deal_id) && errors.deal_id}
                                                                onBlur={handleBlur}
                                                                name="deal_id"
                                                            />
                                                        )}
                                                    />
                                                </Grid>



                                                <Grid item xxl={12} xs={6}>
                                                    <TextField
                                                        select
                                                        label="Account"
                                                        value={values?.account_id || ''}
                                                        name="account_id"
                                                        variant="filled"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.account_id && touched.account_id}
                                                        helperText={(errors.account_id && touched.account_id) && errors.account_id}
                                                    >
                                                        {
                                                            accounts && accounts.length > 0
                                                                ? accounts.map((account, index) => (
                                                                    <MenuItem key={index} value={account?.id}>
                                                                        {account?.organization_name}
                                                                    </MenuItem>
                                                                ))
                                                                : <MenuItem value={'-1'}></MenuItem>
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xxl={12} xs={6}>
                                                    <TextField
                                                        select
                                                        label="Contact"
                                                        value={values?.contact_id || ''}
                                                        name="contact_id"
                                                        variant="filled"
                                                        onChange={(e) => { handleChange(e); getContactData(e.target.value, setFieldValue, 'contact') }}
                                                        onBlur={handleBlur}
                                                        error={errors.contact_id && touched.contact_id}
                                                        helperText={(errors.contact_id && touched.contact_id) && errors.contact_id}
                                                    >
                                                        {
                                                            contacts && contacts.length > 0
                                                                ? contacts.map((contact, index) => (
                                                                    <MenuItem key={index} value={contact?.id}>
                                                                        {contact?.salutation ? contact.salutation + '. ' : ''}{contact?.first_name} {contact?.last_name}
                                                                    </MenuItem>
                                                                ))
                                                                : <MenuItem value={'-1'}></MenuItem>
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xxl={12} xs={6}>
                                                    <TextField
                                                        select
                                                        label="Quote Stage"
                                                        value={values?.quote_stage || ''}
                                                        name="quote_stage"
                                                        variant="filled"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.quote_stage && touched.quote_stage}
                                                        helperText={(errors.quote_stage && touched.quote_stage) && errors.quote_stage}
                                                    >
                                                        <MenuItem key="all-sources" value="-1">
                                                            Select Stage
                                                        </MenuItem>
                                                        {
                                                            quoteStages &&
                                                            Object.keys(quoteStages).map((index) => (
                                                                <MenuItem key={index} value={quoteStages[index]}>
                                                                    {quoteStages[index]}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xxl={12} xs={6}>
                                                    <TextField
                                                        select
                                                        label="Quote Owner"
                                                        value={values?.owner_id || ''}
                                                        name="owner_id"
                                                        variant="filled"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.owner_id && touched.owner_id}
                                                        helperText={(errors.owner_id && touched.owner_id) && errors.owner_id}
                                                    >
                                                        <MenuItem key="all-sources" value="-1">
                                                            Select Owner
                                                        </MenuItem>
                                                        {
                                                            users && users.length > 0
                                                                ? users.map((user, index) => (
                                                                    <MenuItem key={index} value={user?.id}>
                                                                        {user?.name}
                                                                    </MenuItem>
                                                                ))
                                                                : <MenuItem value={'-1'}></MenuItem>
                                                        }
                                                    </TextField>
                                                </Grid>


                                            </Grid>
                                        </div>
                                    </Grid>

                                    <Grid item xxl={8} xs={12}>
                                        <div className="card p-16 height-100p pb-0">
                                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Address Information</Typography>

                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="title-small-bold" component={'h4'} className="mb-8">Billing Address</Typography>
                                                    <TextField
                                                        label="Street"
                                                        variant="filled"
                                                        name="address"
                                                        value={values?.address || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.address && touched.address}
                                                        helperText={(errors.address && touched.address) && errors.address}
                                                    />

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="City"
                                                                variant="filled"
                                                                name="city"
                                                                value={values?.city || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.city && touched.city}
                                                                helperText={(errors.city && touched.city) && errors.city}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="State"
                                                                variant="filled"
                                                                name="state"
                                                                value={values?.state || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.state && touched.state}
                                                                helperText={(errors.state && touched.state) && errors.state}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Postal Code"
                                                                variant="filled"
                                                                name="zipcode"
                                                                value={values?.zipcode || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.zipcode && touched.zipcode}
                                                                helperText={(errors.zipcode && touched.zipcode) && errors.zipcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                select
                                                                label="Country"
                                                                value={values?.country_id || ''}
                                                                name="country_id"
                                                                variant="filled"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.country_id && touched.country_id}
                                                                helperText={(errors.country_id && touched.country_id) && errors.country_id}
                                                            >
                                                                {
                                                                    countries && countries.length > 0
                                                                        ? countries.map((country, index) => (
                                                                            <MenuItem key={index} value={country?.id}>
                                                                                {country?.country_name}
                                                                            </MenuItem>
                                                                        ))
                                                                        : <MenuItem value={'-1'}></MenuItem>
                                                                }
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>

                                                    <div className="form-group">
                                                        <FormControlLabel control={
                                                            <Switch
                                                                checked={isShippingSameAsBilling}
                                                                onChange={(event) => handleSwitchChange(event, setFieldValue, props)}
                                                            />} label="Shipping address same as billing" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="title-small-bold" component={'h4'} className="mb-8">Shipping Address</Typography>

                                                    <TextField
                                                        label="Street"
                                                        variant="filled"
                                                        name="s_address"
                                                        value={values?.s_address || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.s_address && touched.s_address}
                                                        helperText={(errors.s_address && touched.s_address) && errors.s_address}
                                                    />

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="City"
                                                                variant="filled"
                                                                name="s_city"
                                                                value={values?.s_city || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.s_city && touched.s_city}
                                                                helperText={(errors.s_city && touched.s_city) && errors.s_city}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="State"
                                                                variant="filled"
                                                                name="s_state"
                                                                value={values?.s_state || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.s_state && touched.s_state}
                                                                helperText={(errors.s_state && touched.s_state) && errors.s_state}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Postal Code"
                                                                variant="filled"
                                                                name="s_zipcode"
                                                                value={values?.s_zipcode || ''}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.s_zipcode && touched.s_zipcode}
                                                                helperText={(errors.s_zipcode && touched.s_zipcode) && errors.s_zipcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                select
                                                                label="Country"
                                                                value={values?.s_country_id || ''}
                                                                name="s_country_id"
                                                                variant="filled"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.s_country_id && touched.s_country_id}
                                                                helperText={(errors.s_country_id && touched.s_country_id) && errors.s_country_id}
                                                            >
                                                                {
                                                                    countries && countries.length > 0
                                                                        ? countries.map((country, index) => (
                                                                            <MenuItem key={index} value={country?.id}>
                                                                                {country?.country_name}
                                                                            </MenuItem>
                                                                        ))
                                                                        : <MenuItem value={'-1'}></MenuItem>
                                                                }
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>

                                    <CreateQuoteItem data={{ setFieldValue, values, setFieldTouched, productList, productIds, quoteDetail, errors, touched }} />

                                    <Grid item xs={12}>
                                        <div className="card p-16">
                                            <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                                <Button type="submit">Create Quote</Button>
                                                <Button color="secondary" onClick={e => { navigate('/quotes'); }}>Cancel</Button>
                                            </Stack>
                                        </div>
                                    </Grid>

                                </Grid>
                            </form>
                        </>
                    );
                }}
            </Formik>
        </>
    );
}

export default CreateCloneQuote;