import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, Dialog, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import MessageChatItem from "../../../components/common/MessageChatItem";
import { useSnackbar } from "../../../components/context/SnackbarContext";

function TemplateErrorPreviewDialog({ onClose, open, data, name = null, type = null }) {

    let jsonData = [];

    try {
        jsonData = typeof data === "string" ? JSON.parse(data) : data;
    } catch (error) {
        onClose()
        return null;
    }


    const message = jsonData?.error?.message;
    const errorUserTitle = jsonData?.error?.error_user_title;
    const errorUserMsg = type == 'message' ? jsonData?.error?.error_data?.details : jsonData?.error?.error_user_msg;



    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Reason for {name ? name : "Template"} Rejection</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Box sx={{ padding: 2, borderRadius: '16px' }} className="mb-24 bordered bg-weak">
                    <Typography variant="body-large-bold" component={'p'}>Message</Typography>
                    <Typography variant="body-large" component={'p'}>{message}</Typography>
                    <br />
                    <Typography variant="body-large-bold" component={'p'}>Error User Title</Typography>
                    <Typography variant="body-large" component={'p'}>{errorUserTitle}</Typography>
                    <br />
                    <Typography variant="body-large-bold" component={'p'}>Error User Message</Typography>
                    <Typography variant="body-large" component={'p'}>{errorUserMsg}</Typography>
                </Box>
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Button onClick={onClose} color="secondary">Close</Button>
                </Stack>
            </Dialog>
        </>
    );
}

export default TemplateErrorPreviewDialog;