import React, { Suspense, useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Stack, Switch, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import AddSingleContactDialog from "./components/AddSingleContactDialog";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from '../../components/context/SnackbarContext';
import { useNavigate, useParams } from "react-router-dom";

function ContactListDetails(props) {
    const { id } = useParams();
    const ContactId = id;
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader, loading } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [records, setrecords] = useState([]);
    const [contacts, setcontacts] = useState([]);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("All Contacts");
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);

    const [selectedContacts, setSelectedContacts] = useState([]);
    const [clearType, setClearType] = useState(false);
    const [totalContactCount, setTotalContactCount] = useState(0);


    const handleCheckboxToggle = (contactId) => {
        setSelectedContacts(prevState => {
            if (prevState.includes(contactId)) {
                return prevState.filter(id => id !== contactId);
            } else {
                return [...prevState, contactId];
            }
        });
    };

    const handleSelectAllToggle = () => {
        if (selectAll) {
            setSelectedContacts([]);
        } else {
            setSelectedContacts(contacts.map(contact => contact.id));  // Select all contacts
        }
        setSelectAll(!selectAll);
    };


    const [addSingleContactDialogOpen, setAddSingleContactDialogOpen] = React.useState(false);

    const openAddSingleContactDialog = () => {
        setAddSingleContactDialogOpen(true);
        setReloadPage(true);
    }

    const closeAddSingleContactDialog = () => {
        setAddSingleContactDialogOpen(false);
        setReloadPage(true);
    }

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(currentPage, perPage, search, filter);
        }
        fetchData();
    }, [reloadPage, filter, addSingleContactDialogOpen, currentPage, perPage, clearType]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const getData = async (currentPage, perPage, search, filter) => {
        showLoader()
        const params = {
            search: search || "",
            search_subscriber: filter === "All Contacts" ? "" : filter || "",
            per_page: perPage,
        };
        await axiosInstance.get(`api/whatsapp-contact-list/` + ContactId + `?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setrecords(response?.data?.data?.records);
                setcontacts(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setTotalContactCount(response?.data?.data?.total_counts);
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const handleToggle = async (contact_Id) => {
        showLoader()
        await axiosInstance.get(`api/manage-contact-list-subscriber/` + contact_Id).then((response) => {
            if (response.status === 200) {
                getData();
                hideLoader();
                setReloadPage(reloadPage ? false : true);
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    async function handleBulkDelete() {
        if (selectedContacts && selectedContacts.length > 0) {
            bulkDeleteData(selectedContacts)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    const bulkDeleteData = async (ids) => {
        const data = {
            id: Array.isArray(ids) ? ids : [ids]
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        try {
                            showLoader()
                            const response = await axiosInstance.delete('api/whatsapp-contact-list', { data });
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                setSelectedContacts([]);
                                getData();
                                setReloadPage(reloadPage ? false : true);
                            }
                        } catch (error) {
                            if (error.response) {
                                hideLoader();
                                if (error.response.status === 400) {
                                    showSnackbar(error.response.data.message, "warning");
                                }
                            }
                        }
                    },
                    cancel: () => {
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    };


    const deleteContactslist = async () => {
        const data = {
            id: ContactId,
        };

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        try {
                            showLoader()
                            const response = await axiosInstance.delete('api/whatsapp-contacts/destroy', { data });
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                hideLoader();
                                setReloadPage(reloadPage ? false : true);
                            }
                        } catch (error) {
                            if (error.response) {
                                hideLoader();
                                if (error.response.status === 400) {
                                    showSnackbar(error.response.data.message, "warning");
                                }
                            }
                        }
                    },
                    cancel: () => {
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            getData(
                currentPage,
                perPage,
                search,
                filter
            );
            setReloadPage(reloadPage ? false : true);
        }
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'user') {
            setFilter('All Contacts')
        } else {
            setFilter("All Contacts");
            setSearch('')
        }
    }


    return (
        <>
            <Helmet>
                <title>Contact List | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Stack spacing={2}>
                <div className="card p-16">
                    <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                        <Grid item xs={'auto'} className="flex-shrink-1">
                            <Typography variant='title-medium-bold' component={'h6'}>{records.name}</Typography>
                            <ul className='inline-details'>
                                <li><span className="title">Total Contacts: </span>{totalContactCount.total_contact_count}</li>
                                <li><span className="title">Subscribed: </span>{totalContactCount.subscribe_count}</li>
                                <li><span className="title">Unsubscribed: </span>{totalContactCount.un_subscribe_count}</li>
                            </ul>
                        </Grid>
                        <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                            <Tooltip title="Delete">
                                <Button color="secondary" className="icon-only" size="small" onClick={() => deleteContactslist()}>
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>

                <Stack direction="row" spacing={1} alignItems={'center'}>
                    <TextField
                        variant="filled"
                        placeholder="Search contacts"
                        className='size-small page-search no-margin'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {
                                        search &&
                                        <IconButton onClick={() => clearUserFilter('search')} >
                                            <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        defaultValue="All Contacts"
                        variant="filled"
                        className='size-small no-label no-margin'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end' className='clear-filter'>
                                    {
                                        filter && filter !== 'All Contacts' &&
                                        <IconButton onClick={() => clearUserFilter('user')}>
                                            <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem key={1} value={'All Contacts'}>All Contacts</MenuItem>
                        <MenuItem key={2} value={'yes'}>Subscribed</MenuItem>
                        <MenuItem key={3} value={'no'}>Unsubscribed</MenuItem>
                    </TextField>
                    {
                        (search || (filter && filter !== 'All Contacts')) &&
                        <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                    }
                    {
                        selectedContacts?.length > 0 && <Button variant="contained" className="ml-auto" size='small' onClick={() => handleBulkDelete()}>
                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                            <span>Delete Selected</span>
                        </Button>
                    }

                    <Button variant="contained" className="ml-auto" size='small' onClick={openAddSingleContactDialog}>
                        <span>
                            <Icon path={mdiPlus} color="currentColor" size={1} />
                            <span>Add Contact</span>
                        </span>
                    </Button>
                </Stack>

                <div className="card pt-4">
                    <Stack direction={"row"} className="px-16 py-12 pl-4 border-bottom">
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllToggle}
                            className="grid-checkbox mt-n8"
                        />
                        <Typography component={'p'} variant="title-medium-bold"></Typography>
                    </Stack>
                    {contacts.length > 0 ? (
                        contacts.map((contact, index) => (
                            <Stack key={index} direction={"row"} className="px-16 py-12 pl-4 border-bottom">
                                <Stack direction={"row"} alignItems={"flex-start"} flexGrow={1}>
                                    <Checkbox className="grid-checkbox mt-n8" checked={selectedContacts.includes(contact.id)} onChange={() => handleCheckboxToggle(contact.id)} />
                                    <Stack>
                                        <Typography component={'p'} variant="title-medium-bold">{contact.name}</Typography>
                                        <Typography component={'p'} className="text-secondary" variant="body-medium">{contact.mobile_number}</Typography>
                                    </Stack>
                                </Stack>
                                <FormControlLabel sx={{ flexShrink: 0 }} control={<Switch checked={contact.subscribe === "yes"} onChange={() => handleToggle(contact.id)} />} label={'Subscribed'} />
                                <Box sx={{ flexShrink: 0 }}>
                                    <Tooltip title="Delete">
                                        <button className="icon-button" onClick={() => bulkDeleteData(contact.id)}>
                                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                        </button>
                                    </Tooltip>
                                </Box>
                            </Stack>
                        ))
                    ) : (
                        <Typography className="p-16">No contacts found</Typography>
                    )}
                    {
                        contacts && contacts?.length > 0 &&
                        <div className="p-16">
                            <TablePagination
                                component="div"
                                count={totalCount}
                                page={currentPage}
                                rowsPerPage={perPage}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                            />
                        </div>
                    }
                </div>
            </Stack >

            <AddSingleContactDialog onClose={closeAddSingleContactDialog} open={addSingleContactDialogOpen} />
        </>
    );
}

export default ContactListDetails;