import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PublicLayout from './components/layouts/PublicLayout';
import PrivateLayout from './components/layouts/PrivateLayout';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
import CreateTenantPanel from './pages/auth/CreateTenantPanel';
import TenantInvitation from './pages/auth/TenantInvitation';
import Lead from './pages/lead/Lead';
import LeadDetail from './pages/lead/LeadDetail';
import Deal from './pages/deal/Deal';
import DealDetail from './pages/deal/DealDetail';
import Contact from './pages/contact/Contact'
import ContactDetail from './pages/contact/ContactDetail'
import Account from './pages/account/Accounts';
import AccountDetail from './pages/account/AccountDetails'
import Task from './pages/task/Tasks';
import Event from './pages/event/Events';
import Quote from './pages/quote/Quote';
import CreateQuote from './pages/quote/CreateQuote';
import QuoteDetail from './pages/quote/QuoteDetail';
import QuoteClone from './pages/quote/CreateCloneQuote';
import Report from './pages/report/ReportOverview';
import TrackUser from './pages/report/TrackUsers';
import VisitLog from './pages/report/VisitLogActivities';
import DashboardXL from './pages/DashboardXlFinancial';
import Settings from './pages/settings/SettingsOverview';
import Profile from './pages/profile/MyAccount';
import User from './pages/settings/user/users';
import Products from './pages/settings/product/Products';
import UserDetail from './pages/settings/user/UserDetails';
import Role from './pages/settings/role/Roles';
import Subscription from './pages/settings/subscription/Subscriptions';
import RoleDetail from './pages/settings/role/RoleDetail';
import ProductDetail from './pages/settings/product/ProductDetails';
import Stages from './pages/settings/stage/Stages';
import Source from './pages/settings/source/Sources';
import ActivityLogs from './pages/settings/activityLog/ActivityLogs';
import Checkout from './pages/checkout/Checkout';
import Notifications from './pages/notification/Notifications';
import PageNotFound from './components/common/PageNotFound';
import InternalServerError from './components/common/InternalServerError';
import PermissionDenied from './components/common/PermissionDenied';
import AccessDenied from './components/common/AccessDenied';
import MetaAuth from './components/common/MetaAuth';
import MarketingCampaings from './pages/marketing/MarketingCampaings';
import MarketingAddCampaings from './pages/marketing/AddCampaign';
import MarketingContactLists from './pages/marketing/MarketingContactLists';
import ContactListDetails from './pages/marketing/ContactListDetails';
import AddContactList from './pages/marketing/AddContactList';
import MarketingTemplates from './pages/marketing/MarketingTemplates';
import MarketingAddTemplates from './pages/marketing/AddTemplate';
import MarketingEditTemplates from './pages/marketing/EditTemplate';
import MarketingCampaingDetails from './pages/marketing/CampaignDetails';
import Inbox from './pages/inbox/Inbox';
import PublicProfile from './pages/PublicProfile/PublicProfile';
import Orders from './pages/settings/order/Orders';
import OrderDetails from './pages/settings/order/OrderDetails';


const AppRoutes = (props) => {
    const name = props?.headerTitle || 'SolvCRM'

    return (
        <Router>
            <Routes>
                {/* Redirect to sign-in by default */}
                <Route path="/" element={<Navigate to="/sign-in" />} />

                <Route element={<PublicLayout />}>
                    <Route path="/" element={<Home name={name} />} />
                    <Route path="/sign-in" element={<SignIn name={name} />} />
                    <Route path="/sign-up" element={<SignUp name={name} />} />
                    <Route path="/forgot-password" element={<ForgotPassword name={name} />} />
                    {/* <Route path="/verify-email/:id/:token" element={<VerifyEmail name={name} />} /> */}
                    <Route path="/verify-email/:token/:email" element={<VerifyEmail name={name} />} />
                    <Route path="/welcome/:id/:token" element={<CreateTenantPanel name={name} />} />
                    <Route path="/reset-password/:token" element={<ResetPassword name={name} />} />
                    <Route path="/invitation/:id/:email" element={<TenantInvitation name={name} />} />


                    {/* <Route path="/settings" element={<Settings />} />
                    <Route path="/workspace" element={<Workspace />} /> */}
                </Route>

                <Route path="/public-profile/:id" element={<PublicProfile name={name} />} />

                <Route element={<PrivateLayout />}>
                    <Route path="/dashboard" element={name === 'xl-financial' ? <DashboardXL name={name} /> : <Dashboard name={name} />} />
                    <Route path="/leads" element={<Lead name={name} />} />
                    <Route path="/lead-detail/:id" element={<LeadDetail name={name} />} />
                    <Route path="/deals" element={<Deal name={name} />} />
                    <Route path="/deal-detail/:id" element={<DealDetail name={name} />} />
                    <Route path="/contacts" element={<Contact name={name} />} />
                    <Route path="/contact-detail/:id" element={<ContactDetail name={name} />} />
                    <Route path="/accounts" element={<Account name={name} />} />
                    <Route path="/account-detail/:id" element={<AccountDetail name={name} />} />
                    <Route path="/tasks" element={<Task name={name} />} />
                    <Route path="/events" element={<Event name={name} />} />
                    <Route path="/quotes" element={<Quote name={name} />} />
                    <Route path="/create-quote" element={<CreateQuote name={name} />} />
                    <Route path="/quote-detail/:id" element={<QuoteDetail name={name} />} />
                    <Route path="/quote-clone/:id" element={<QuoteClone name={name} />} />
                    <Route path="/reports" element={<Report name={name} />} />
                    <Route path="/reports/track-users" element={<TrackUser name={name} />} />
                    <Route path="/reports/visit-log-activities" element={<VisitLog name={name} />} />
                    <Route path="/marketing" element={<MarketingCampaings name={name} />} />
                    <Route path="/marketing/add-campaign" element={<MarketingAddCampaings name={name} />} />
                    <Route path="/marketing/campaign-details/:id" element={<MarketingCampaingDetails name={name} />} />
                    <Route path="/marketing/contact-lists" element={<MarketingContactLists name={name} />} />
                    <Route path="/marketing/add-contact-list" element={<AddContactList name={name} />} />
                    <Route path="/marketing/contact-list-details/:id" element={<ContactListDetails name={name} />} />
                    <Route path="/marketing/templates" element={<MarketingTemplates name={name} />} />
                    <Route path="/marketing/add-template" element={<MarketingAddTemplates name={name} />} />
                    <Route path="/marketing/edit-template/:id" element={<MarketingEditTemplates name={name} />} />
                    <Route path="/inbox" element={<Inbox name={name} />} />
                    <Route path="/settings" element={<Settings name={name} />} />
                    <Route path="/profile" element={<Profile name={name} />} />
                    <Route path="/settings/users" element={<User name={name} />} />
                    <Route path="/settings/subscription" element={<Subscription name={name} />} />
                    <Route path="/settings/user-detail/:id" element={<UserDetail name={name} />} />
                    <Route path="/settings/roles" element={<Role name={name} />} />
                    <Route path="/settings/role-detail/:id" element={<RoleDetail name={name} />} />
                    <Route path="/settings/products" element={<Products name={name} />} />
                    <Route path="/settings/product-detail/:id" element={<ProductDetail name={name} />} />
                    <Route path="/settings/stages" element={<Stages name={name} />} />
                    <Route path="/settings/sources" element={<Source name={name} />} />
                    <Route path="/settings/activity-logs" element={<ActivityLogs name={name} />} />
                    <Route path="/orders" element={<Orders name={name} />} />
                    <Route path="/order-details/:id" element={<OrderDetails name={name} />} />
                    <Route path="/meta/oauth/callback" element={<MetaAuth name={name} />} />
                    <Route path="/notifications" element={<Notifications name={name} />} />
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/permission-denied" element={<PermissionDenied />} />
                    <Route path="/upgrade-plan" element={<AccessDenied />} />
                    <Route path="/500" element={<InternalServerError />} />
                </Route>

                <Route element={<PrivateLayout hideUI />}>
                    <Route path="/checkout" element={<Checkout name={name} />} />
                </Route>

            </Routes>
        </Router>
    );
};

export default AppRoutes;