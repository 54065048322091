import { Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function MarketingTab() {
    const navigate = useNavigate();

    const getActiveButtonProps = (link) => {
        const url = window.location.pathname;
        let variant = 'text';
        let color = 'primary';
        let redirectUrl = '/marketing';
        if (url.includes(link) || (link === 'root' && url === '/marketing')) {
            variant = 'contained';
            color = 'secondary';
        }
        switch (link) {
            case 'contact-lists':
                redirectUrl = '/marketing/contact-lists';
                break;
            case 'templates':
                redirectUrl = '/marketing/templates';
                break;
            default:
                break;
        }
        return {
            variant,
            size: 'small',
            color,
            onClick: e => { navigate(redirectUrl); }
        }
    }

    return (
        <>
            <Stack spacing={{ xl: 0.5, xs: 0 }} direction={'row'} className="page-tabs">
                <Button {...getActiveButtonProps('root')}>Campaigns</Button>
                <Button {...getActiveButtonProps('contact-lists')}>Contact Lists</Button>
                <Button {...getActiveButtonProps('templates')}>Templates</Button>
            </Stack>
        </>
    );
}

export default MarketingTab;