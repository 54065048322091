import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, capitalize, FormControlLabel, Grid, Stack, Switch, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiDownloadOutline, mdiEmailOutline, mdiFacebook, mdiInstagram, mdiLinkedin, mdiLockOutline, mdiPencilOutline, mdiPhoneOutline, mdiWeb, mdiYoutube } from "@mdi/js";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomTextbox from "../../components/common/EasyEditCustomTextbox";
import FormGroupStatic from "../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import EasyEditCustomSelect from "../../components/common/EasyEditCustomSelect";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { validateField } from "../../utils/Utils";
import AvatarInitials from "../../components/common/AvatarInitials";

import { useDispatch, useSelector } from "react-redux";
import { login, loginUser } from "../../redux/slices/userSlice";

import PropTypes from 'prop-types';
import { SampleQR, UserAvatar } from "../../assets/images";
import { Twitter } from "../../components/common/Icons";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`,
    };
}

function MyAccount(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginUserDetail = useSelector(loginUser);
    const user = loginUserDetail?.user || [];

    const [profileData, setProfileData] = useState();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [reloadPage, setReloadPage] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [countryArray, setCountryArray] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const publicProfileUrl = `public-profile/${profileData?.uuid}`;

    const handleTabChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };


    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [reloadPage]);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/profile').then((response) => {
            if (response.status === 200) {
                setProfileData(response?.data?.data?.record);
                setCountryArray(response?.data?.data?.countries)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/profile");
                    }
                }
            });
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }
    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const handleSaveData = async (value, name, type = null) => {
        const data = {
            [name]: value,
        };
        showLoader();
        await axiosInstance.post("api/update-profile", data).then(response => {
            if (response.status === 200) {
                hideLoader();
                showSnackbar(response.data.message, "success");
                setAllowEditInline(true)
                handleReloadFunc();

                if (name === 'name') {
                    const updatedUser = { ...user, name: value };
                    dispatch(login(updatedUser));
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    setAllowEditInline(true)
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        setBackendErrorMessages({ [key]: errors[key] });
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });

    }

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };


    const handleImageChange = async (e) => {
        e.persist();
        const formData = new FormData();
        formData.append('profile_image', e.target.files[0])

        showLoader()
        await axiosInstance.post("/api/update-profile", formData).then(response => {
            if (response.status === 200) {
                hideLoader();
                const updatedUser = { ...user, profile_image: response.data.data.userData.profile_image };
                dispatch(login(updatedUser));
                showSnackbar(response.data.message, "success");
                setAllowEditInline(true)
                handleReloadFunc();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    setAllowEditInline(true)
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        showSnackbar(errors[key], 'error')
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    };

    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = React.useState(false);
    const openChangePasswordDialog = (message, actionText) => { setChangePasswordDialogOpen(true); }
    const closeChangePasswordDialog = () => { setChangePasswordDialogOpen(false); }

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    const handleSwitchChange = async (event, name) => {
        const newValue = event.target.checked ? 1 : 0;

        setProfileData(prevData => ({
            ...prevData,
            [name]: newValue,
        }));

        await handleSaveData(newValue, name);
    };

    const handleDownload = () => {
        // Check if the QR code URL is available
        if (profileData?.profile_qrcode) {
            const link = document.createElement('a');

            // Set the href to the URL of the image (QR code image in this case)
            link.href = profileData.profile_qrcode;

            // Make sure it is a direct download by setting the download attribute
            link.download = 'ProfileQR.png';
            link.target = '_blank';
            // Append the link to the body (required for it to work in some browsers)
            document.body.appendChild(link);

            // Trigger the click to download
            link.click();

            // Remove the link from the DOM after triggering the download
            document.body.removeChild(link);
        } else {
            console.error('No QR code URL available to download.');
        }
    };



    const handleCopyUrl = () => {
        const textToCopy = window.location.origin + '/' + publicProfileUrl;

        // Create a hidden textarea element
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;

        // Append it to the DOM temporarily (required for execCommand to work)
        document.body.appendChild(textarea);

        // Select the text in the textarea
        textarea.select();
        textarea.setSelectionRange(0, textarea.value.length); // For mobile devices

        // Try to execute the copy command
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                showSnackbar('Public URL copied to clipboard!', "success");
            } else {
                console.error('Failed to copy to clipboard');
            }
        } catch (err) {
            console.error('Error copying to clipboard: ', err);
        }

        // Clean up by removing the textarea element
        document.body.removeChild(textarea);
    };


    return (
        <>
            <Helmet>
                <title>My Account | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item xs={'auto'} className="flex-shrink-1">
                                <Grid container alignItems={'center'} spacing={1.5}>
                                    <Grid item xs="auto">
                                        {user?.profile_image ? (
                                            <img
                                                onError={addDefaultProfileSrc}
                                                src={user?.profile_image}
                                                alt="Profile"
                                                title="Profile Image"
                                                className="user-avatar"
                                            />
                                        ) : (
                                            <AvatarInitials name={profileData?.name} />
                                        )}
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{user?.name || 'N/A'}</Typography>
                                        <ul className='inline-details'>
                                            <li><Tooltip title="Email"><span>{profileData?.email || 'N/A'}</span></Tooltip></li>
                                            <li>
                                                <Tooltip title="Role">
                                                    <span className="status-badge">{profileData?.selected_role || 'N/A'}</span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                <div className="psuedo-upload-block">
                                    <div className="content">
                                        <Button size={"small"} color="secondary" tabIndex={-1} >
                                            <span>
                                                <Icon path={mdiPencilOutline} color={'currentColor'} size={1} />
                                                <span>Change Photo</span>
                                            </span>
                                        </Button>
                                    </div>
                                    <input type="file" className="psuedo-upload-element" onChange={handleImageChange} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Personal Details" {...a11yProps(0)} />
                            <Tab label="Public Profile" {...a11yProps(1)} />
                        </Tabs>

                        <CustomTabPanel value={currentTabIndex} index={0}>
                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Personal Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'name') }}
                                        onCancel={() => { onCancelEdit('name') }}
                                        value={profileData?.name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Name" value={profileData?.name} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Name" value={profileData?.name || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'name', 'Name')}
                                        validationMessage={errorMessages['name']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <FormGroupStatic label="Email" element={
                                        <a href={"mailto:" + profileData?.email} className="primary-link">{profileData?.email}</a>
                                    } />
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'secondry_email') }}
                                        onCancel={() => { onCancelEdit('secondry_email') }}
                                        value={profileData?.secondry_email || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Secondary email" value={profileData?.secondry_email} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Secondary email" element={
                                            <a href={"mailto:" + profileData?.secondry_email} className="primary-link">{profileData?.secondry_email || 'N/A'}</a>
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'secondry_email', 'Secondary email')}
                                        validationMessage={errorMessages['secondry_email']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['secondry_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['secondry_email']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Address Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item lg={6} xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'address1') }}
                                        onCancel={() => { onCancelEdit('address1') }}
                                        value={profileData?.address1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 1" value={profileData?.address1} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Address 1" value={profileData?.address1 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'address1', 'Address 1')}
                                        validationMessage={errorMessages['address1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['address1'] && <span className="easy-edit-validation-error">{backendErrorMessages['address1']}</span>}
                                </Grid>

                                <Grid item lg={6} xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'address2') }}
                                        onCancel={() => { onCancelEdit('address2') }}
                                        value={profileData?.address2 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 2" value={profileData?.address2} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Address 2" value={profileData?.address2 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'address2', 'Address 2')}
                                        validationMessage={errorMessages['address2']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['address2'] && <span className="easy-edit-validation-error">{backendErrorMessages['address2']}</span>}
                                </Grid>
                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'phone1') }}
                                        onCancel={() => { onCancelEdit('phone1') }}
                                        value={profileData?.phone1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone 1" value={profileData?.phone1} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Phone 1" value={profileData?.phone1 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'phone1', 'Phone 1')}
                                        validationMessage={errorMessages['phone1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['phone1'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone1']}</span>}
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'phone2') }}
                                        onCancel={() => { onCancelEdit('phone2') }}
                                        value={profileData?.phone2 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone 2" value={profileData?.phone2} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Phone 2" value={profileData?.phone2 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'phone2', 'Phone 2')}
                                        validationMessage={errorMessages['phone2']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['phone2'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone2']}</span>}
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'city') }}
                                        onCancel={() => { onCancelEdit('city') }}
                                        value={profileData?.city || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="City" value={profileData?.city} />}
                                        displayComponent={<FormGroupStatic easyEdit label="City" value={profileData?.city || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'city', 'City')}
                                        validationMessage={errorMessages['city']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['city'] && <span className="easy-edit-validation-error">{backendErrorMessages['city']}</span>}
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'state') }}
                                        onCancel={() => { onCancelEdit('state') }}
                                        value={profileData?.state || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="State" value={profileData?.state} />}
                                        displayComponent={<FormGroupStatic easyEdit label="State" value={profileData?.state || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'state', 'State')}
                                        validationMessage={errorMessages['state']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['state'] && <span className="easy-edit-validation-error">{backendErrorMessages['state']}</span>}
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                        onCancel={() => { onCancelEdit('zipcode') }}
                                        value={profileData?.zipcode || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Zipcode" value={profileData?.zipcode} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Zipcode" value={profileData?.zipcode || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'zipcode', 'Zipcode')}
                                        validationMessage={errorMessages['zipcode']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['zipcode'] && <span className="easy-edit-validation-error">{backendErrorMessages['zipcode']}</span>}
                                </Grid>

                                <Grid item xl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'country_id') }}
                                        onCancel={() => { onCancelEdit('country_id') }}
                                        value={profileData?.countryName || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Country" fieldValue={profileData?.countryName || ''}
                                                items={
                                                    countryArray && countryArray.length > 0 && countryArray.map((country) => (
                                                        { value: country.id, option: country.country_name }
                                                    ))
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Country" value={profileData?.country_id || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['country_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['country_id']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Button size="small" className="action" onClick={openChangePasswordDialog}>
                                <span>
                                    <Icon path={mdiLockOutline} color={'currentColor'} size={1} />
                                    <span>Change Password</span>
                                </span>
                            </Button>
                        </CustomTabPanel>
                        <CustomTabPanel value={currentTabIndex} index={1}>
                            <Grid container columnSpacing={3} rowSpacing={5}>
                                <Grid item xxl={8} xl={7} xs={12}>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <EasyEdit
                                                type={Types.TEXT}
                                                onSave={(value) => { handleSaveData(value, 'whatsapp_number') }}
                                                onCancel={() => { onCancelEdit('whatsapp_number') }}
                                                value={profileData?.whatsapp_number || 'N/A'}
                                                allowEdit={allowEditInline}
                                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Whatsapp Number" value={profileData?.whatsapp_number} />}
                                                displayComponent={<FormGroupStatic easyEdit label="Whatsapp Number" value={profileData?.whatsapp_number} />}
                                                onValidate={(value) => handleValidation(value, 'whatsapp_number', 'Whatsapp Number')}
                                                validationMessage={errorMessages['whatsapp_number']}
                                                saveButtonLabel={<EasyEditSaveButton />}
                                                cancelButtonLabel={<EasyEditCancelButton />}
                                                onFocus={() => handleClick('')}
                                            />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_whatsapp === 1} onChange={(event) => handleSwitchChange(event, 'is_show_whatsapp')} />
                                                }
                                                label={profileData?.is_show_whatsapp === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <hr className="my-16" />

                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <FormGroupStatic label="Email" value={profileData?.email} />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_email === 1} onChange={(event) => handleSwitchChange(event, 'is_show_email')} />
                                                }
                                                label={profileData?.is_show_email === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <hr className="my-16" />

                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <EasyEdit
                                                type={Types.TEXT}
                                                onSave={(value) => { handleSaveData(value, 'linkedin_profile_url') }}
                                                onCancel={() => { onCancelEdit('linkedin_profile_url') }}
                                                value={profileData?.linkedin_profile_url || 'N/A'}
                                                allowEdit={allowEditInline}
                                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="LinkedIn Profile" value={profileData?.linkedin_profile_url} />}
                                                displayComponent={<FormGroupStatic easyEdit label="LinkedIn Profile" value={profileData?.linkedin_profile_url} />}
                                                onValidate={(value) => handleValidation(value, 'linkedin_profile_url', 'LinkedIn Profile')}
                                                validationMessage={errorMessages['linkedin_profile_url']}
                                                saveButtonLabel={<EasyEditSaveButton />}
                                                cancelButtonLabel={<EasyEditCancelButton />}
                                                onFocus={() => handleClick('')}
                                            />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_linkedin === 1} onChange={(event) => handleSwitchChange(event, 'is_show_linkedin')} />
                                                }
                                                label={profileData?.is_show_linkedin === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <hr className="my-16" />

                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <EasyEdit
                                                type={Types.TEXT}
                                                onSave={(value) => { handleSaveData(value, 'facebook_profile_url') }}
                                                onCancel={() => { onCancelEdit('facebook_profile_url') }}
                                                value={profileData?.facebook_profile_url || 'N/A'}
                                                allowEdit={allowEditInline}
                                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Facebook Profile" value={profileData?.facebook_profile_url} />}
                                                displayComponent={<FormGroupStatic easyEdit label="Facebook Profile" value={profileData?.facebook_profile_url} />}
                                                onValidate={(value) => handleValidation(value, 'facebook_profile_url', 'Facebook Profile')}
                                                validationMessage={errorMessages['facebook_profile_url']}
                                                saveButtonLabel={<EasyEditSaveButton />}
                                                cancelButtonLabel={<EasyEditCancelButton />}
                                                onFocus={() => handleClick('')}
                                            />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_facebook === 1} onChange={(event) => handleSwitchChange(event, 'is_show_facebook')} />
                                                }
                                                label={profileData?.is_show_facebook === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <hr className="my-16" />

                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <EasyEdit
                                                type={Types.TEXT}
                                                onSave={(value) => { handleSaveData(value, 'instagram_profile_url') }}
                                                onCancel={() => { onCancelEdit('instagram_profile_url') }}
                                                value={profileData?.instagram_profile_url || 'N/A'}
                                                allowEdit={allowEditInline}
                                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Instagram Profile" value={profileData?.instagram_profile_url} />}
                                                displayComponent={<FormGroupStatic easyEdit label="Instagram Profile" value={profileData?.instagram_profile_url} />}
                                                onValidate={(value) => handleValidation(value, 'instagram_profile_url', 'Instagram Profile')}
                                                validationMessage={errorMessages['instagram_profile_url']}
                                                saveButtonLabel={<EasyEditSaveButton />}
                                                cancelButtonLabel={<EasyEditCancelButton />}
                                                onFocus={() => handleClick('')}
                                            />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_instagram === 1} onChange={(event) => handleSwitchChange(event, 'is_show_instagram')} />
                                                }
                                                label={profileData?.is_show_instagram === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <hr className="my-16" />

                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <EasyEdit
                                                type={Types.TEXT}
                                                onSave={(value) => { handleSaveData(value, 'youtube_profile_url') }}
                                                onCancel={() => { onCancelEdit('youtube_profile_url') }}
                                                value={profileData?.youtube_profile_url || 'N/A'}
                                                allowEdit={allowEditInline}
                                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Youtube Channel" value={profileData?.youtube_profile_url} />}
                                                displayComponent={<FormGroupStatic easyEdit label="Youtube Channel" value={profileData?.youtube_profile_url} />}
                                                onValidate={(value) => handleValidation(value, 'youtube_profile_url', 'Youtube Channel')}
                                                validationMessage={errorMessages['youtube_profile_url']}
                                                saveButtonLabel={<EasyEditSaveButton />}
                                                cancelButtonLabel={<EasyEditCancelButton />}
                                                onFocus={() => handleClick('')}
                                            />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_youtube === 1} onChange={(event) => handleSwitchChange(event, 'is_show_youtube')} />
                                                }
                                                label={profileData?.is_show_youtube === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <hr className="my-16" />

                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs>
                                            <EasyEdit
                                                type={Types.TEXT}
                                                onSave={(value) => { handleSaveData(value, 'twitter_profile_url') }}
                                                onCancel={() => { onCancelEdit('twitter_profile_url') }}
                                                value={profileData?.twitter_profile_url || 'N/A'}
                                                allowEdit={allowEditInline}
                                                editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Twitter/X Profile" value={profileData?.twitter_profile_url} />}
                                                displayComponent={<FormGroupStatic easyEdit label="Twitter/X Profile" value={profileData?.twitter_profile_url} />}
                                                onValidate={(value) => handleValidation(value, 'twitter_profile_url', 'Twitter/X Profile')}
                                                validationMessage={errorMessages['twitter_profile_url']}
                                                saveButtonLabel={<EasyEditSaveButton />}
                                                cancelButtonLabel={<EasyEditCancelButton />}
                                                onFocus={() => handleClick('')}
                                            />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={profileData?.is_show_twitter === 1} onChange={(event) => handleSwitchChange(event, 'is_show_twitter')} />
                                                }
                                                label={profileData?.is_show_twitter === 1 ? "Enabled" : "Disabled"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xxl={4} xl={5} xs={12}>
                                    <div className="user-public-profile-info">
                                        <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                            <img src={profileData?.profile_image} alt={profileData?.name} className="user" />
                                            <Stack direction={'column'} spacing={1}>
                                                <Stack direction={'column'} spacing={0.5}>
                                                    <Typography variant="title-large-bold">{profileData?.name}</Typography>

                                                    <ul className="user-details">
                                                        {profileData?.is_show_email === 1 && (
                                                            <li>
                                                                <a href={`mailto:${profileData?.name}`}>
                                                                    <Icon path={mdiEmailOutline} color="currentColor" size={0.833334} />
                                                                    <Typography variant="body-medium">{profileData?.email}</Typography>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {profileData?.is_show_whatsapp === 1 && (
                                                            <li>
                                                                <a href={`tel:${profileData?.whatsapp_number}`}>
                                                                    <Icon path={mdiPhoneOutline} color="currentColor" size={0.833334} />
                                                                    <Typography variant="body-medium">{profileData?.whatsapp_number}</Typography>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Stack>

                                                <ul className="social-btns">
                                                    {profileData?.is_show_linkedin === 1 && (
                                                        <li>
                                                            <Tooltip title="Follow on Linkedin">
                                                                <a href={profileData?.linkedin_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                                    <Icon path={mdiLinkedin} color='currentColor' size={0.91667} />
                                                                </a>
                                                            </Tooltip>
                                                        </li>
                                                    )}
                                                    {profileData?.is_show_facebook === 1 && (
                                                        <li>
                                                            <Tooltip title="Follow on Facebook">
                                                                <a href={profileData?.facebook_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                                    <Icon path={mdiFacebook} color='currentColor' size={0.91667} />
                                                                </a>
                                                            </Tooltip>
                                                        </li>
                                                    )}
                                                    {profileData?.is_show_instagram === 1 && (
                                                        <li>
                                                            <Tooltip title="Follow on Instagram">
                                                                <a href={profileData?.instagram_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                                    <Icon path={mdiInstagram} color='currentColor' size={0.91667} />
                                                                </a>
                                                            </Tooltip>
                                                        </li>
                                                    )}
                                                    {profileData?.is_show_youtube === 1 && (
                                                        <li>
                                                            <Tooltip title="Follow on YouTube">
                                                                <a href={profileData?.youtube_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                                    <Icon path={mdiYoutube} color='currentColor' size={0.91667} />
                                                                </a>
                                                            </Tooltip>
                                                        </li>
                                                    )}
                                                    {profileData?.is_show_twitter === 1 && (
                                                        <li>
                                                            <Tooltip title="Follow on Twitter">
                                                                <a href={profileData?.twitter_profile_url} target="_blank" className="icon-button size-small" rel="noreferrer">
                                                                    <Twitter />
                                                                </a>
                                                            </Tooltip>
                                                        </li>
                                                    )}
                                                </ul>
                                            </Stack>
                                        </Stack>
                                    </div>

                                    <hr className="mt-16 mb-32" />

                                    <Stack direction={'column'} spacing={2} alignItems={'center'} className="user-qr">
                                        <img src={profileData?.profile_qrcode} alt="QR Code" />
                                        <Typography variant="body-large">Scan this QR code to view your public profile</Typography>
                                    </Stack>

                                    <ul className="vertical-action-btns">
                                        <li>
                                            <button className="vertical-action-btn" onClick={handleDownload}>
                                                <Icon path={mdiDownloadOutline} color="currentColor" size={1} />
                                                <span>Download QR Code</span>
                                            </button>
                                        </li>
                                        <li>
                                            <a href={publicProfileUrl} className="vertical-action-btn">
                                                <Icon path={mdiWeb} color="currentColor" size={1} />
                                                <span>View Public Profile</span>
                                            </a>
                                        </li>
                                        <li>
                                            <button onClick={handleCopyUrl} className="vertical-action-btn">
                                                <Icon path={mdiContentCopy} color="currentColor" size={1} />
                                                <span>Copy Public URL</span>
                                            </button>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>

                        </CustomTabPanel>
                    </div>
                </Grid>
            </Grid>

            <ChangePasswordDialog open={changePasswordDialogOpen} onClose={closeChangePasswordDialog} />
        </>
    );
}

export default MyAccount;