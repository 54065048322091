import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { capitalize, Grid, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import { mdiAlertCircleOutline, mdiCalendarWeekBeginOutline, mdiCheckAll } from "@mdi/js";
import Icon from "@mdi/react";
import MessageChatItem from "../../components/common/MessageChatItem";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../components/common/EmptyState";
import TemplateErrorPreviewDialog from "./components/TemplateErrorPreviewDialog";


function CampaignDetails(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [campaign, setCampaign] = useState();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [contacts, setContacts] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );

    const [formattedHeader, setFormattedHeader] = useState({ head: '', fileLink: '', fileName: '' });
    const [headerType, setHeaderType] = useState('text');
    const [formattedBody, setFormattedBody] = useState('');
    const [formattedFooter, setFormattedFooter] = useState();
    const [formattedButtons, setFormattedButtons] = useState([]);
    const [errorData, setErrorData] = useState();

    let currentTime = moment.utc(new Date()).tz(preTimeZoneKey).format(preferredDateFormat)
    const timeOnly = moment(currentTime, 'DD-MM-YYYY h:mm a').format('h:mm a');


    const [sortBy, setSortBy] = useState("created_at");

    // State management for search fields
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ) => {
        const params = {
            search_status: search,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/whatsapp-edit-campaign/${id}?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {

                setCampaign(response?.data?.data?.campaignDetail);
                setContacts(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader()

                //set up preview template code here 
                let jsonData = [];
                const contentData = response?.data?.data?.campaignDetail?.template_content?.content || []
                try {
                    jsonData = typeof contentData === "string" ? JSON.parse(contentData) : contentData;
                } catch (error) {
                    showSnackbar('Error parsing JSON data', "error");
                    return null;
                }

                // Extract Header, Body, Footer, and Buttons
                const header = jsonData.find(item => item.type === "HEADER");
                const body = jsonData.find(item => item.type === "BODY");
                const footer = jsonData.find(item => item.type === "FOOTER");
                const buttons = jsonData.find(item => item.type === "BUTTONS");

                // Get formatted values
                if (header) {
                    if (header.format === "IMAGE" && header.example?.header_handle?.length > 0) {
                        setFormattedHeader({ head: '', fileLink: header.example.header_handle[0], fileName: '' }); // Image URL
                        setHeaderType("image");
                    } else if (header.format === "DOCUMENT" && header.example?.header_handle?.length > 0) {
                        const uplodedFileurl = header?.example?.header_handle[0] || '';
                        const expFileUrl = uplodedFileurl?.split('?')[0] || '';
                        const sendFileName = expFileUrl?.slice(-20) || '';

                        setFormattedHeader({ head: '', fileLink: header.example.header_handle[0], fileName: sendFileName }); // Image URL
                        setHeaderType("document");
                    } else if (header.format === "VIDEO" && header.example?.header_handle?.length > 0) {
                        setFormattedHeader({ head: '', fileLink: header.example.header_handle[0], fileName: '' }); // Image URL
                        setHeaderType("video");
                    } else if (header.format === "TEXT") {
                        setFormattedHeader({ head: header.text || "", fileLink: '', fileName: '' });
                        setHeaderType("text");
                    }
                }

                //setFormattedBody(body ? replacePlaceholders(body.text, body.example?.body_text?.[0]) : "");
                setFormattedBody(body?.text ? body?.text : "");
                setFormattedFooter(footer ? footer.text : "");
                setFormattedButtons(buttons?.buttons?.map(btn => ({ type: btn.type, text: btn.text })) || []);


            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate('/marketing')
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const statusClasses = {
        'completed': 'success',
        'failed': 'danger',
        'pending': 'warning',
        'in_progress': 'info',
        'schedule': 'warning',
    };

    const statusClass = statusClasses[campaign?.status] || 'danger';

    const getPercentage = (value = null, total = null) => {
        return parseFloat(((value / total) * 100).toFixed(1));
    };


    const [templateErrorPreviewDialogOpen, setTemplateErrorPreviewDialogOpen] = React.useState(false);

    const openTemplateErrorPreviewDialog = (contentData) => {
        setErrorData(contentData)
        setTemplateErrorPreviewDialogOpen(true);
    };

    const closeTemplateErrorPreviewDialog = () => {
        setErrorData()
        setTemplateErrorPreviewDialogOpen(false);
    };


    return (
        <>
            <Helmet>
                <title>Campaigns Detail| {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2} alignItems={"flex-start"}>
                <Grid item xxl={8} xl={7} xs={12}>
                    <Stack spacing={2}>
                        <div className="card p-16">
                            <Typography variant='title-medium-bold' component={'h6'}>{campaign?.message_title}</Typography>
                            <ul className='inline-details'>
                                <li>
                                    <Tooltip title="Created Date">
                                        <span>
                                            <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                            {campaign?.created_at
                                                ? moment.utc(campaign.created_at)
                                                    .tz(preTimeZoneKey)
                                                    .format(preferredDateFormat)
                                                : "N/A"}

                                        </span>
                                    </Tooltip>
                                </li>
                                <li>
                                    <span className={`status-badge ${statusClass}`}>{campaign?.status}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="card">
                            <Grid container className="border-bottom">
                                <Grid item xs={3}>
                                    <button type="button" className={search === '' ? 'campaign-stat active' : 'campaign-stat'} onClick={() => setSearch('')}>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} alignItems={'center'}>
                                            <Typography className="text-secondary" variant="body-medium-medium">Total</Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} className="pt-4" alignItems={'baseline'}>
                                            <Typography variant="title-large-semibold">{campaign?.total_recipients || 0}</Typography>
                                            <Typography className="text-secondary" variant="body-medium">100%</Typography>
                                        </Stack>
                                    </button>
                                </Grid>
                                <Grid item xs={3}>
                                    <button type="button" className={search === 'delivered' ? 'campaign-stat active' : 'campaign-stat'} onClick={() => setSearch('delivered')}>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} alignItems={'center'}>
                                            <Typography className="text-secondary" variant="body-medium-medium">Delivered</Typography>
                                            <Icon path={mdiCheckAll} color="currentColor" className="text-secondary" size={0.83333334} />
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} className="pt-4" alignItems={'baseline'}>
                                            <Typography variant="title-large-semibold">{campaign?.delivered_count || 0}</Typography>
                                            <Typography className="text-secondary" variant="body-medium">{getPercentage(campaign?.delivered_count, campaign?.total_recipients)}%</Typography>
                                        </Stack>
                                    </button>
                                </Grid>
                                <Grid item xs={3}>
                                    <button type="button" className={search === 'read' ? 'campaign-stat active' : 'campaign-stat'} onClick={() => setSearch('read')}>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} alignItems={'center'}>
                                            <Typography className="text-secondary" variant="body-medium-medium">Read</Typography>
                                            <Icon path={mdiCheckAll} color="#34B7F1" size={0.83333334} />
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} className="pt-4" alignItems={'baseline'}>
                                            <Typography variant="title-large-semibold">{campaign?.read_count || 0}</Typography>
                                            <Typography className="text-secondary" variant="body-medium">{getPercentage(campaign?.read_count, campaign?.total_recipients)}%</Typography>
                                        </Stack>
                                    </button>
                                </Grid>
                                <Grid item xs={3}>
                                    <button type="button" className={search === 'failed' ? 'campaign-stat active' : 'campaign-stat'} onClick={() => setSearch('failed')}>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} alignItems={'center'}>
                                            <Typography className="text-secondary" variant="body-medium-medium">Failed</Typography>
                                            <Icon path={mdiAlertCircleOutline} color="currentColor" className="text-danger" size={0.83333334} />
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} className="pt-4" alignItems={'baseline'}>
                                            <Typography variant="title-large-semibold">{campaign?.faile_count || 0}</Typography>
                                            <Typography className="text-danger" variant="body-medium">{getPercentage(campaign?.faile_count, campaign?.total_recipients)}%</Typography>
                                        </Stack>
                                    </button>
                                </Grid>
                            </Grid>
                            <div className="pt-4">
                                {contacts?.length > 0 && contacts.map((contact, index) => {
                                    return (
                                        <Stack key={index} direction={"row"} className="px-16 py-12 border-bottom">
                                            <Stack direction={"row"} alignItems={"flex-start"} flexGrow={1} spacing={0.75}>
                                                {contact?.status === 'delivered' && <Icon path={mdiCheckAll} className="text-secondary" color="currentColor" size={0.8333334} style={{ transform: 'translateY(2px)' }} />}
                                                {contact?.status === 'read' && <Icon path={mdiCheckAll} color="#34B7F1" size={0.8333334} style={{ transform: 'translateY(2px)' }} />}
                                                {contact?.status === 'failed' &&
                                                    <Tooltip title="View Reason for Failed">
                                                        <span onClick={() => openTemplateErrorPreviewDialog(contact?.json_data)}>
                                                            <Icon path={mdiAlertCircleOutline} className="text-danger" color="currentColor" size={0.8333334} style={{ transform: 'translateY(2px)' }} />
                                                        </span>
                                                    </Tooltip>
                                                }
                                                <Stack>
                                                    <Typography component={'p'} variant="title-medium-bold">{contact?.name || 'N/A'}</Typography>
                                                    <Typography component={'p'} className="text-secondary" variant="body-medium">{contact?.phone || 'N/A'}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography component={'p'} variant="label-medium" className="text-secondary">
                                                {contact?.get_status?.status_timestamp
                                                    ? moment.utc(contact.get_status.status_timestamp)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : contact?.created_at ? moment.utc(contact.created_at)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat) : "N/A"}</Typography>
                                        </Stack>
                                    )
                                })}
                            </div>

                            {contacts?.length === 0 && (
                                <EmptyState
                                    type={`whatsapp-${search || 'contact'}`}
                                    hideActionBtn
                                    size="small"
                                />
                            )}

                            {
                                contacts && contacts.length > 0 &&
                                <div className="p-16">
                                    <TablePagination
                                        component="div"
                                        count={totalCount}
                                        page={currentPage}
                                        rowsPerPage={perPage}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                        className="center"
                                    />
                                </div>
                            }

                        </div>
                    </Stack>
                </Grid>
                <Grid item xxl={4} xl={5} className="sticky-top" sx={{
                    display: { xs: 'none', xl: 'block' }
                }}>
                    <div className="card p-16">
                        <div className="card-header">
                            <h6 className="title">Template Preview</h6>
                        </div>

                        <div className="message-preview">
                            <MessageChatItem
                                head={formattedHeader}
                                headType={headerType}
                                body={formattedBody}
                                footer={formattedFooter}
                                buttons={formattedButtons}
                                time={timeOnly}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>

            {
                errorData &&
                <TemplateErrorPreviewDialog onClose={closeTemplateErrorPreviewDialog} open={templateErrorPreviewDialogOpen} data={errorData} name={'Message'} type='message' />
            }
        </>
    );
}

export default CampaignDetails;