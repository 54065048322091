import { Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BigRadio from "../../components/common/BigRadio";
import { mdiArrowRight } from "@mdi/js";
import Icon from "@mdi/react";
import AvatarInitials from "../../components/common/AvatarInitials";
import { ThemeContext } from "../../App";
import { Check3D } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";

function FreePlanCheckoutFlow({ onReset, selectedPlan }) {

    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();

    const { currentTheme } = useContext(ThemeContext);
    const [currentStep, setCurrentStep] = React.useState('manage-users-warning');
    const [manageUsersState, setManageUsersState] = React.useState('remove-users');

    const [tenantUsers, setTenantUsers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/package-detail/' + selectedPlan).then((response) => {
            if (response.status === 200) {
                setTenantUsers(response?.data?.data?.userLists);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/checkout");
                    }
                }
            });
    }

    const updatePlan = async () => {
        showLoader();
        const data = {
            id: selectedPlan
        }
        await axiosInstance.post('api/update-package', data).then((response) => {
            if (response.status === 200) {
                hideLoader();
                navigate("/dashboard");
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/checkout");
                    }
                }
            });
    }

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [currentStep]);

    return (
        <>
            {currentStep === 'manage-users-warning' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Action Required: Manage Your Users</Typography>
                        <p className="text-secondary">Your Free plan allows only 1 user. Since your trial period allowed multiple users, please remove any additional users to continue with the Free plan. You can always upgrade to the Basic or Premium plans to keep multiple users.</p>
                    </div>

                    <Stack spacing={2}>
                        <BigRadio
                            title={"Proceed to Remove Additional Users"}
                            description={'Removing a user will delete all leads, deals, quotes, and other data associated with that user. This action cannot be undone.'}
                            input={<input type="radio" name="remove-user-radio" value="remove-users" checked={manageUsersState === 'remove-users'} onChange={(e) => setManageUsersState(e.target.value)} />}
                        />
                        <BigRadio
                            title={"Upgrade to Basic Plan"}
                            description={'Keep all your users and their data by upgrading to the Basic plan for just $9/month per user.'}
                            input={<input type="radio" name="remove-user-radio" value="upgrade-plan" checked={manageUsersState === 'upgrade-plan'} onChange={
                                (e) => {
                                    setManageUsersState(e.target.value);
                                    onReset('upgrade-plan');
                                }
                            } />}
                        />
                    </Stack>

                    <div className="action-block">
                        <Button variant="text" onClick={onReset}>Back</Button>
                        <Button onClick={e => { setCurrentStep('final-check') }}>
                            <span>
                                <span>Next</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }


            {currentStep === 'final-check' &&
                <>
                    <div className="block-title">
                        <Typography variant="display-small-bold" component={'h1'}>Final Check: Users Marked for Deletion</Typography>
                        <p className="text-secondary">Heads up! The users listed below, along with all their associated data—leads, deals, quotes, and more—are set to be permanently removed.<br /><br />Changed your mind? No worries! You can still upgrade to the Basic plan to keep everyone and everything intact. Your team doesn’t have to shrink if you don’t want it to!</p>
                    </div>

                    <Typography variant="body-large-bold" sx={{ marginBottom: 1 }} component={'p'}>Users Marked for Removal ({tenantUsers?.length} users)</Typography>
                    {tenantUsers?.length > 0 &&
                        <div className="checkout-user-list">
                            <ul className="inner">
                                {tenantUsers?.length > 0 && tenantUsers.map(
                                    (user, index) => {
                                        return (
                                            <li key={index}>
                                                <Stack direction="row" spacing={1.5} alignItems="center">
                                                    <AvatarInitials name={user.name} sx={{ flexShrink: 0 }} />
                                                    <div>
                                                        <Typography variant="title-medium-bold" component={'p'} sx={{ marginBottom: '-2px' }}>{user.name}</Typography>
                                                        <Typography variant="body-medium" className="text-secondary">{user.email}</Typography>
                                                    </div>
                                                </Stack>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    }


                    <div className="action-block">
                        <Button variant="text" onClick={e => {
                            setCurrentStep('manage-users-warning');
                            setManageUsersState('remove-users');
                        }}>Upgrade Plan</Button>
                        <Button onClick={e => { setCurrentStep('plan-activated'); updatePlan() }}>
                            <span>
                                <span>Confirm and Remove Users</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }

            {currentStep === 'plan-activated' &&
                <>
                    <div className="block-title">
                        {currentTheme?.name === 'solvcrm' && <img src={Check3D} alt="Check" />}
                        <Typography variant="display-small-bold" component={'h1'}>You're All Set!</Typography>
                        <p className="text-secondary">Your Free plan is now active! Thank you for choosing SolvCRM. We've successfully removed the additional users as requested. Enjoy seamless access to essential features and manage your business with ease.<br /><br />Need more power? You can upgrade anytime to unlock advanced features and take your business to the next level.</p>
                    </div>

                    <div className="action-block mt-0">
                        <Button onClick={navigate('/dashboard')}>
                            <span>
                                <span>Go to Dashboard</span>
                                <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                            </span>
                        </Button>
                    </div>
                </>
            }

        </>
    );
}

export default FreePlanCheckoutFlow;