import React, { useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useNavigate } from "react-router-dom";

const MetaAuth = () => {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        if (authCode) {
            // Send the code to the Laravel backend
            const formData = {
                code: authCode
            };
            axiosInstance.post("/api/facebook-callback", formData).then((response) => {
                if (response?.data?.code === 200) {
                    showSnackbar(response.data.message, "success");
                    // Notify the parent window and close the popup
                    if (window.opener && !window.opener.closed) {
                        window.opener.onPopupSuccess(); // Call the parent function
                    }
                    window.close(); // Close the popup
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
        }
    }, []);

    return <div>Authenticating...</div>;
};

export default MetaAuth;
