import React, { useState } from 'react';
import {
    useStripe, useElements,
    CardNumberElement, CardExpiryElement, CardCvcElement, CardElement
} from '@stripe/react-stripe-js';
import { stripePaymentMethodHandler } from './script';

import { useSnackbar } from '../../../../components/context/SnackbarContext';
import { useLoader } from '../../../../components/context/LoaderContext';
import { getMainDomain } from '../../../../utils/Utils';
import useAxios from '../../../../hooks/useAxios';
import { Button, Grid, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiArrowRight, mdiInformationOutline } from '@mdi/js';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            lineHeight: "27px",
            color: "#212529",
            fontSize: "1.1rem",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function CheckoutForm(props) {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const isDisablepaymentButton = props?.amount == '0.00' ? true : false;

    const stripe = useStripe();
    const elements = useElements();

    const hostname = getMainDomain()

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            showSnackbar('Stripe is not loaded yet.', "warning");
            return;
        }

        const paymentMethodObj = {
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name,
                email
            },
        };
        const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

        if (paymentMethodResult?.error) {
            showSnackbar(paymentMethodResult.error.message, "error");
        } else {
            showLoader();
            const data = ({
                payment_method_id: paymentMethodResult.paymentMethod.id,
                name: paymentMethodResult.paymentMethod.billing_details.name,
                email: paymentMethodResult.paymentMethod.billing_details.email,
                amount: props.amount,
                extraData: props.extraData,
            })
            stripePayment(data);
        }
    };

    const stripePayment = async (data) => {
        await axiosInstance.post("/api/make-first-payment", data).then(async (response) => {
            if (response.status === 200) {
                //Need to check subscription status and response (if success meand card are normal and payment are done)
                if (response?.data?.data?.paymentIntentResponse === 'success') {
                    hideLoader();
                    props.handlePaymentRes({
                        'response': true,
                        'message': response.data.message,
                    });
                } else {
                    //if response get in else than handle 3D Secure card detail for this transection/subscrirption
                    storePaymentDetail(response)
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                showSnackbar(error?.response?.data?.message, "error");
                props.handlePaymentRes({
                    'response': false,
                    'message': error.response.data.message,
                });
            }
        });

    }

    const storePaymentDetail = async (responseData) => {
        if (responseData.data.data) {
            const CardElement = elements.getElement(CardNumberElement);
            if (responseData.data.data.paymentIntentResponse['isSubscription']) {//recurring payment
                stripe.confirmCardPayment(responseData.data.data.paymentIntentResponse['clientSecret'], {
                    payment_method: { card: CardElement }
                }).then(async (result) => {
                    if (result.error) {
                        props.handlePaymentRes({
                            'response': false,
                            'message': result.error.message,
                        });
                        showSnackbar(result?.error?.message, "error");
                    } else {
                        // Successful subscription payment
                        // Post the transaction info to the server-side script and redirect to the payment status page
                        const requestData = {
                            'paymentIntent': result?.paymentIntent,
                            'subscriptionId': responseData?.data?.data?.paymentIntentResponse['subscriptionId'],
                            'clientSecret': responseData?.data?.data?.paymentIntentResponse['clientSecret'],
                            'customerId': responseData?.data?.data?.paymentIntentResponse['customerId'],
                            'orderId': responseData?.data?.data?.paymentIntentResponse['orderId'],
                            'isUpdate': responseData?.data?.data?.paymentIntentResponse['isUpdate'],
                            'requestUserCount': responseData?.data?.data?.paymentIntentResponse['requestUserCount'] ? responseData?.data?.data?.paymentIntentResponse['requestUserCount'] : '',
                            'amount': responseData?.data?.data?.paymentIntentResponse['amount'],
                            'subscriptionType': responseData?.data?.data?.paymentIntentResponse['subscriptionType'],
                        }

                        await axiosInstance.post("/api/recurring-payment-confirm", requestData).then(async (response) => {
                            if (response.status === 200) {
                                hideLoader()
                                props.handlePaymentRes({
                                    'response': true,
                                    'message': 'Payment successfull',
                                });
                                showSnackbar('Payment was completed successfully.', "success");
                            }
                        }).then(json => {

                        }).catch(error => {
                            if (error.response) {
                                hideLoader();
                                props.handlePaymentRes({
                                    'response': false,
                                    'message': error.response.data.message,
                                });
                                showSnackbar(error?.response?.data?.message, "error");
                            }
                        });
                    }
                });
            }
        } else {
            hideLoader()
            props.handlePaymentRes({
                'response': false,
                'message': 'Payment failed!',
            });
            showSnackbar('Payment failed!', "error");
        }

    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="stripe-group">
                    <label className='control-label' htmlFor="cc-number">Card Number<span className='asterisk'>*</span></label>
                    <CardNumberElement
                        id="cc-number"
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                    />
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className="stripe-group">
                            <label className='control-label' htmlFor="expiry">Expiration Date<span className='asterisk'>*</span></label>
                            <CardExpiryElement
                                id="expiry"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="stripe-group">
                            <label className='control-label' htmlFor="cvc">CVC<span className='asterisk'>*</span></label>
                            <CardCvcElement
                                id="cvc"
                                className="form-control"
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </div>
                    </Grid>
                </Grid>

                <div className="alert">
                    <div className="title">
                        <Icon path={mdiInformationOutline} color={'currentColor'} size={0.8333334} />
                        <Typography variant="body-medium-bold" component={'p'}>Note</Typography>
                    </div>
                    <Typography variant="body-medium">This payment method will be used for current and future billings.</Typography>
                </div>

                {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}

                <div className="action-block">
                    <Button variant="text" onClick={props.onReset}>Back</Button>
                    <Button type='submit' disabled={isDisablepaymentButton}>
                        <span>
                            <span>{hostname === '.solvcrm.co' ? `PAY $${props.amount}` : `PAY $${props.amount}`}</span>
                            <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                        </span>
                    </Button>
                </div>

            </form>
        </React.Fragment>
    );
}
