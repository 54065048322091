import { mdiClose, mdiFileDocument, mdiFilePlusOutline, mdiImage, mdiMusicBox } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";

function AttachmentCenter({ showTitle = true, canUpload = true, attachments }) {
    return (
        <>
            <div className="attachment-center">
                {showTitle && <div className="title">
                    <Typography variant="title-medium-bold">Attachments</Typography> <Typography variant="title-small" className="file-size">(Max Size 2 MB)</Typography>
                </div>}

                {canUpload && <div className="psuedo-upload-block">
                    <div className="content">
                        <Button color="secondary" className="btn-block" tabIndex={-1}>
                            <span>
                                <Icon path={mdiFilePlusOutline} color={'currentColor'} size={1} />
                                <span>Upload File</span>
                            </span>
                        </Button>
                    </div>
                    <input type="file" className="psuedo-upload-element" />
                </div>}

                {attachments && attachments.length > 0 && <ul className="attachments">
                    {attachments.map((attachment, index) =>
                        <li key={index + ''}>
                            <a href={attachment.fileUrl}>
                                <div className="icon-wrapper">
                                    {attachment.type === 'image' && <Icon path={mdiImage} color={'currentColor'} size={1} />}
                                    {attachment.type === 'audio' && <Icon path={mdiMusicBox} color={'currentColor'} size={1} />}
                                    {attachment.type === 'file' && <Icon path={mdiFileDocument} color={'currentColor'} size={1} />}
                                </div>
                                <div className="info">
                                    <Typography variant="body-large" component={'p'}>{attachment.name}</Typography>
                                    {attachment.size && <Typography variant="body-medium">Size: {attachment.size}</Typography>}
                                </div>
                            </a>
                            {canUpload && <Tooltip title="Remove">
                                <button className="icon-button size-small" onClick={e => { e.stopPropagation(); }}>
                                    <Icon path={mdiClose} color={'currentColor'} size={1} />
                                </button>
                            </Tooltip>}
                        </li>
                    )}
                </ul>}
            </div>
        </>
    );
}

export default AttachmentCenter;