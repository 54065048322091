import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Autocomplete, Button, Grid, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useAxios from "../../hooks/useAxios";
import Icon from "@mdi/react";
import { useSelector } from "react-redux";
import { mdiArrowRight, mdiInformation, mdiMagnify } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../utils/Utils";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from '../../components/context/SnackbarContext';
import { Formik } from "formik";
import { displayDate } from "../../utils/Utils";

function AddContactList(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);
    const { showSnackbar } = useSnackbar();

    const [sources, setsources] = useState([]);
    const [productLists, setproductLists] = useState([]);
    const [dealStage, setdealStage] = useState([]);
    const [salutations, setsalutations] = useState([]);
    const [unSavedContactDetail, setunSavedContactDetail] = useState([]);
    const [country_id, setcountryLists] = useState([]);
    const [recordCount, setrecordCount] = useState(0);
    const [contactId, setcontactId] = useState();
    const [finalData, setFinalData] = useState();
    const { preferredDateFormat } = useSelector(state => state?.user);

    const [searchData, setSearchData] = useState();
    const [addData, setAddData] = useState();

    const productOptions = productLists.map(product => ({
        key: product.id,
        value: product.name
    }));

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData();
        }
        fetchData();
    }, [
        reloadPage
    ]);

    const getData = async () => {
        showLoader()
        await axiosInstance.get(`api/whatsapp-contacts/create`).then((response) => {
            if (response.status === 200) {
                setsources(response?.data?.data?.sources);
                setproductLists(response?.data?.data?.productLists);
                setdealStage(response?.data?.data?.dealStage);
                setsalutations(response?.data?.data?.salutations);
                setcountryLists(response?.data?.data?.countryLists);

                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const handleSubmit = async (values, onSubmitProps) => {

        const data = {
            name: values.name,
            salutation: values.salutation,
            type: values.type,
            first_name: values.first_name,
            last_name: values.last_name,
            title: values.title,
            lead_source: values.lead_source,
            organization_name: values.organization_name,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            country_id: values.country_id,
            lead_from_date: values.lead_from_date,
            lead_to_date: values.lead_to_date,
            deal_name: values.deal_name,
            stage_id: values.dealStage,
            deal_source: values.deal_source,
            deal_from_date: values.deal_from_date,
            deal_to_date: values.deal_to_date,
            closing_date_from: values.closing_date_from,
            closing_date_to: values.closing_date_to,
            expected_closing_date_from: values.expected_closing_date_from,
            expected_closing_date_to: values.expected_closing_date_to,
            product_id: values.product_id,
            search_data: searchData,
            add_data: addData,
            contact_id: contactId,
        }
        showLoader()

        if (finalData == "yes") {
            await axiosInstance.post(`/api/whatsapp-contacts/store`, data).then(response => {
                if (response.status === 200) {
                    hideLoader()
                    showSnackbar(response.data.message, 'success')
                    onSubmitProps.resetForm();
                    navigate(-1);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            onSubmitProps.setFieldError(key, errors[key][0]);
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        } else {
            await axiosInstance.post(`/api/whatsapp-contacts/create`, data).then(response => {
                if (response.status === 200) {
                    hideLoader()
                    showSnackbar(response.data.message, 'success')
                    setrecordCount(response?.data?.data?.recordCount);
                    setunSavedContactDetail(response?.data?.data?.unSavedContactDetail);
                    setcontactId(response?.data?.data?.contactId);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    onSubmitProps.setSubmitting(false)
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            onSubmitProps.setFieldError(key, errors[key][0]);
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        }
    }

    const couretreset = () => {
        setrecordCount(0);
    }

    return (
        <>
            <Helmet>
                <title>Add Contact List | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>
            <Formik
                initialValues={{
                    name: '',
                    salutation: salutations?.salutations || '',
                    type: 'lead',
                    first_name: '',
                    last_name: '',
                    title: '',
                    lead_source: sources?.sources || '',
                    organization_name: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    country_id: '',
                    lead_from_date: '',
                    lead_to_date: '',
                    deal_name: '',
                    dealStage: '',
                    deal_source: '',
                    deal_from_date: '',
                    deal_to_date: '',
                    closing_date_from: '',
                    closing_date_to: '',
                    expected_closing_date_from: '',
                    expected_closing_date_to: '',
                    product_id: '',
                    search_data: '',
                    add_data: '',
                    finalData: '',
                }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {props => {
                    const {
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        resetForm,
                    } = props;

                    const handleTypeChange = (event) => {
                        const newType = event.target.value;
                        setFieldValue('type', newType); // Update the type field

                        // Reset fields based on the selected type
                        if (newType === 'lead') {
                            // Reset deal-related fields
                            setFieldValue('deal_name', '');
                            setFieldValue('dealStage', '');
                            setFieldValue('deal_source', '');
                            setFieldValue('deal_from_date', '');
                            setFieldValue('deal_to_date', '');
                            setFieldValue('closing_date_from', '');
                            setFieldValue('closing_date_to', '');
                            setFieldValue('expected_closing_date_from', '');
                            setFieldValue('expected_closing_date_to', '');
                            setFieldValue('product_id', '');
                            setrecordCount(0);
                        } else if (newType === 'deal') {
                            // Reset lead-related fields
                            setFieldValue('salutation', '');
                            setFieldValue('first_name', '');
                            setFieldValue('last_name', '');
                            setFieldValue('title', '');
                            setFieldValue('lead_source', '');
                            setFieldValue('organization_name', '');
                            setFieldValue('city', '');
                            setFieldValue('state', '');
                            setFieldValue('zipcode', '');
                            setFieldValue('country_id', '');
                            setFieldValue('lead_from_date', '');
                            setFieldValue('lead_to_date', '');
                            setFieldValue('product_id', '');
                            setrecordCount(0);
                        }
                    };

                    const handleFinalSubmit = () => {
                        setFinalData("yes");
                        handleSubmit();
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xxl={8} xs={12}>
                                    <Stack spacing={2}>
                                        <div className="card p-16 pb-0">
                                            <div className="card-header">
                                                <h6 className="title">Contact List Details</h6>
                                            </div>

                                            <TextField
                                                label="Contact List Name"
                                                variant="filled"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.name && touched.name}
                                                helperText={(errors.name && touched.name) && errors.name}
                                            />
                                        </div>
                                        <div className="card p-16">
                                            <div className="card-header">
                                                <h6 className="title">Import Contacts</h6>
                                            </div>

                                            <TextField
                                                select
                                                label="Import Contacts from"
                                                variant="filled"
                                                name="type"
                                                value={values.type}
                                                onChange={handleTypeChange}
                                                onBlur={handleBlur}
                                                error={errors.type && touched.type}
                                                helperText={(errors.type && touched.type) && errors.type}
                                            >
                                                <MenuItem key={1} value={'lead'}>Leads</MenuItem>
                                                <MenuItem key={2} value={'deal'}>Deals</MenuItem>
                                            </TextField>

                                            {values.type === 'lead' && (
                                                <>
                                                    <Typography variant="body-large-bold" component={'p'}>Search Leads</Typography>
                                                    <Typography variant="body-medium" component={'p'} className="text-secondary pb-8">Apply given filters and search leads in order to import those leads in this contact list.</Typography>

                                                    <Grid container columnSpacing={2}>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                select
                                                                variant="filled"
                                                                label="Salutation"
                                                                name="salutation"
                                                                value={values.salutation}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.salutation && touched.salutation}
                                                                helperText={(errors.salutation && touched.salutation) && errors.salutation}
                                                            >
                                                                {Object.entries(salutations).map(([key, value], index) => (
                                                                    <MenuItem key={index} value={key}>
                                                                        {value}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                label="First Name"
                                                                variant="filled"
                                                                name="first_name"
                                                                value={values.first_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.first_name && touched.first_name}
                                                                helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                label="Last Name"
                                                                variant="filled"
                                                                name="last_name"
                                                                value={values.last_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.last_name && touched.last_name}
                                                                helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                label="Title"
                                                                variant="filled"
                                                                name="title"
                                                                value={values.title}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.title && touched.title}
                                                                helperText={(errors.title && touched.title) && errors.title}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                select
                                                                value={values.lead_source || ''}
                                                                name="lead_source"
                                                                variant="filled"
                                                                label='Source'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.lead_source && touched.lead_source}
                                                                helperText={(errors.lead_source && touched.lead_source) && errors.lead_source}
                                                            >
                                                                <MenuItem>Select</MenuItem>
                                                                {sources.map((item, index) => (
                                                                    <MenuItem key={index} value={item.title}>
                                                                        {item.title}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                variant="filled"
                                                                label='Organization Name'
                                                                name="organization_name"
                                                                value={values.organization_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.organization_name && touched.organization_name}
                                                                helperText={(errors.organization_name && touched.organization_name) && errors.organization_name}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                variant="filled"
                                                                label='City'
                                                                name="city"
                                                                value={values.city}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.city && touched.city}
                                                                helperText={(errors.city && touched.city) && errors.city}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                variant="filled"
                                                                label='State'
                                                                name="state"
                                                                value={values.state}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.state && touched.state}
                                                                helperText={(errors.state && touched.state) && errors.state}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                variant="filled"
                                                                label='Zipcode'
                                                                name="zipcode"
                                                                value={values.zipcode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.zipcode && touched.zipcode}
                                                                helperText={(errors.zipcode && touched.zipcode) && errors.zipcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <TextField
                                                                select
                                                                value={values.country_id || ''}
                                                                name="country_id"
                                                                variant="filled"
                                                                label='Country'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.country_id && touched.country_id}
                                                                helperText={(errors.country_id && touched.country_id) && errors.country_id}
                                                            >
                                                                <MenuItem>Select</MenuItem>
                                                                {country_id.map((item, index) => (
                                                                    <MenuItem key={index} value={item.id}>
                                                                        {item.country_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Lead From Date"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'lead_from_date',
                                                                            type: 'text',
                                                                            error: errors.lead_from_date && touched.lead_from_date,
                                                                            helperText: (errors.lead_from_date && touched.lead_from_date) && errors.lead_from_date
                                                                        }
                                                                    }}
                                                                    value={values?.lead_from_date || null}
                                                                    onChange={(value) => setFieldValue("lead_from_date", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xxl={3} xl={4} md={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Lead To Date"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'lead_to_date',
                                                                            type: 'text',
                                                                            error: errors.lead_to_date && touched.lead_to_date,
                                                                            helperText: (errors.lead_to_date && touched.lead_to_date) && errors.lead_to_date
                                                                        }
                                                                    }}
                                                                    value={values?.lead_to_date || null}
                                                                    onChange={(value) => setFieldValue("lead_to_date", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}



                                            {values.type === 'deal' && (
                                                <>
                                                    <Typography variant="body-large-bold" component={'p'}>Search Deals</Typography>
                                                    <Typography variant="body-medium" component={'p'} className="text-secondary pb-8">Apply given filters and search deals in order to import those deals in this contact list.</Typography>

                                                    <Grid container columnSpacing={2}>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                variant="filled"
                                                                label='Deal Name'
                                                                name="deal_name"
                                                                value={values.deal_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.deal_name && touched.deal_name}
                                                                helperText={(errors.deal_name && touched.deal_name) && errors.deal_name}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                select
                                                                value={values.dealStage || ''}
                                                                name="dealStage"
                                                                variant="filled"
                                                                label='Stage'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.dealStage && touched.dealStage}
                                                                helperText={(errors.dealStage && touched.dealStage) && errors.dealStage}
                                                            >
                                                                <MenuItem>Select</MenuItem>
                                                                {dealStage.map((item, index) => (
                                                                    <MenuItem key={index} value={item.id}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                select
                                                                value={values.deal_source || ''}
                                                                name="deal_source"
                                                                variant="filled"
                                                                label='Deal Source'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.deal_source && touched.deal_source}
                                                                helperText={(errors.deal_source && touched.deal_source) && errors.deal_source}
                                                            >
                                                                <MenuItem>Select</MenuItem>
                                                                {sources.map((item, index) => (
                                                                    <MenuItem key={index} value={item.title}>
                                                                        {item.title}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container columnSpacing={2}>
                                                        <Grid item xs={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Deal From Date"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'deal_from_date',
                                                                            type: 'text',
                                                                            error: errors.deal_from_date && touched.deal_from_date,
                                                                            helperText: (errors.deal_from_date && touched.deal_from_date) && errors.deal_from_date
                                                                        }
                                                                    }}
                                                                    value={values?.deal_from_date || null}
                                                                    onChange={(value) => setFieldValue("deal_from_date", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Deal To Date"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'deal_to_date',
                                                                            type: 'text',
                                                                            error: errors.deal_to_date && touched.deal_to_date,
                                                                            helperText: (errors.deal_to_date && touched.deal_to_date) && errors.deal_to_date
                                                                        }
                                                                    }}
                                                                    value={values?.deal_to_date || null}
                                                                    onChange={(value) => setFieldValue("deal_to_date", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Closing Date From"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'closing_date_from',
                                                                            type: 'text',
                                                                            error: errors.closing_date_from && touched.closing_date_from,
                                                                            helperText: (errors.closing_date_from && touched.closing_date_from) && errors.closing_date_from
                                                                        }
                                                                    }}
                                                                    value={values?.closing_date_from || null}
                                                                    onChange={(value) => setFieldValue("closing_date_from", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Closing Date To"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'closing_date_to',
                                                                            type: 'text',
                                                                            error: errors.closing_date_to && touched.closing_date_to,
                                                                            helperText: (errors.closing_date_to && touched.closing_date_to) && errors.closing_date_to
                                                                        }
                                                                    }}
                                                                    value={values?.closing_date_to || null}
                                                                    onChange={(value) => setFieldValue("closing_date_to", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Expected Closing Date From"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'expected_closing_date_from',
                                                                            type: 'text',
                                                                            error: errors.expected_closing_date_from && touched.expected_closing_date_from,
                                                                            helperText: (errors.expected_closing_date_from && touched.expected_closing_date_from) && errors.expected_closing_date_from
                                                                        }
                                                                    }}
                                                                    value={values?.expected_closing_date_from || null}
                                                                    onChange={(value) => setFieldValue("expected_closing_date_from", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    label="Expected Closing Date To"
                                                                    format={displayDate(preferredDateFormat, 'half')}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{
                                                                        textField: {
                                                                            variant: 'filled',
                                                                            name: 'expected_closing_date_to',
                                                                            type: 'text',
                                                                            error: errors.expected_closing_date_to && touched.expected_closing_date_to,
                                                                            helperText: (errors.expected_closing_date_to && touched.expected_closing_date_to) && errors.expected_closing_date_to
                                                                        }
                                                                    }}
                                                                    value={values?.expected_closing_date_to || null}
                                                                    onChange={(value) => setFieldValue("expected_closing_date_to", value, true)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}


                                            <Autocomplete
                                                multiple
                                                id="multi_selects"
                                                options={productOptions || []}
                                                getOptionLabel={(option) => option.value}
                                                value={productOptions.filter(option => values.product_id.includes(option.key))} // Show selected products
                                                onChange={(event, newValue) => {
                                                    const selectedProductIds = newValue.map(option => option.key); // Extract product IDs
                                                    setFieldValue('product_id', selectedProductIds); // Update Formik state
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="filled"
                                                        label="Select Product"
                                                        placeholder="Select product"
                                                        autoFocus
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onFocus: props.focusCallback,
                                                        }}
                                                    />
                                                )}
                                                error={errors.product_id && touched.product_id}
                                                helperText={(errors.product_id && touched.product_id) && errors.product_id}
                                            />


                                            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
                                                <Button size="small" type="button" onClick={() => { setSearchData("yes"); setAddData(""); handleSubmit(); }}>{values.type === "lead" ? "Search Leads" : "Search Deals"}</Button>
                                                <Button size="small" type="button" color="secondary" onClick={() => { resetForm(); couretreset(); }}>Reset</Button>
                                            </Stack>
                                        </div>
                                    </Stack>
                                </Grid>

                                <Grid item xxl={4} xs={12}>
                                    <Stack spacing={2}>
                                        <div className="card p-16 pb-24">
                                            <div className="card-header">
                                                <h6 className="title">Search Results</h6>
                                            </div>
                                            <hr className="hr mb-32" />
                                            <div className="text-center">
                                                <div className="icon-badge mb-16">
                                                    <Icon path={mdiMagnify} color={'currentColor'} size={1.333333} />
                                                </div>
                                                <Typography component={'p'} className="pb-24" variant="title-large-semibold">{recordCount} <span className="text-secondary">{values.type === "lead" ? "Leads found" : "Deals found"}</span></Typography>
                                                <Button type="button" onClick={() => { setAddData("yes"); setSearchData("yes"); handleSubmit(); }}>
                                                    <span>
                                                        <span>Add {recordCount} contacts in this list</span>
                                                        <Icon path={mdiArrowRight} color={'currentColor'} size={1} />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="card p-16 pb-24">
                                            <div className="card-header">
                                                <Stack direction={"row"} spacing={0.75} alignItems={'center'}>
                                                    <h6 className="title">Total Unsaved Contacts</h6>
                                                    <Tooltip title="Below shown numbers are just temporary kept aside, to save them finally click on Save Contact List">
                                                        <span className="text-secondary">
                                                            <Icon path={mdiInformation} color="currentColor" size={0.8333334} className="d-block" />
                                                        </span>
                                                    </Tooltip>
                                                </Stack>
                                            </div>
                                            <hr className="hr mb-24" />

                                            <Grid container className="unsaved-contacts">
                                                <Grid item xs={4}>
                                                    <Typography component={'p'} variant="headline-medium-semibold">{unSavedContactDetail.total}</Typography>
                                                    <Typography component={'p'} variant="body-medium-medium" className="text-secondary">Total</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography component={'p'} variant="headline-medium-semibold">{unSavedContactDetail.fromLead}</Typography>
                                                    <Typography component={'p'} variant="body-medium-medium" className="text-secondary">From Lead</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography component={'p'} variant="headline-medium-semibold">{unSavedContactDetail.fromDeal}</Typography>
                                                    <Typography component={'p'} variant="body-medium-medium" className="text-secondary">From Deal</Typography>
                                                </Grid>
                                            </Grid>

                                            <Stack justifyContent={'center'} direction={'row'} spacing={1}>
                                                <Button type="button" onClick={() => { handleFinalSubmit(); }} >Save Contact List</Button>
                                                <Button color="secondary" onClick={e => navigate(-1)}>Cancel</Button>
                                            </Stack>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
}

export default AddContactList;