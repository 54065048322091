import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, capitalize, FormControlLabel, Grid, MenuItem, Popper, Stack, Switch, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import MessageChatItem from "../../components/common/MessageChatItem";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Formik } from "formik";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

function PickerWrapper(props) {
    return (
        <Popper {...props} className="date-picker-popper">
            {props.children}
        </Popper>
    );
}

function AddCampaign(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [templates, setTemplates] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [variables, setVariables] = useState([]); // Initial empty variable
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [reloadPage, setReloadPage] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [isScheduleCampaign, setIsScheduleCampaign] = useState(false);
    const [serverTimeArray, setServerTimeArray] = useState([]);

    let currentTime = moment.utc(new Date()).tz(preTimeZoneKey).format(preferredDateFormat)
    const timeOnly = moment(currentTime, 'DD-MM-YYYY h:mm a').format('h:mm a');


    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [reloadPage]);

    const getData = async () => {
        showLoader()
        await axiosInstance.get(`api/whatsapp-add-campaign`).then((response) => {
            if (response.status === 200) {
                setTemplates(response?.data?.data?.templates);
                setContacts(response?.data?.data?.contacts);
                setServerTimeArray(response?.data?.data?.staticData)
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    const [defaultValue, setDefaultValue] = useState({
        message_title: '',
        template_id: '',
        contact_id: '',
        is_schedule: '',
        schedule_date: '',
        variable_examples: [],
        recipients: [],
        error_list: [],
    })

    const validationSchema = yup.object().shape({
        message_title: yup.string().required('Enter Campaign Name').min(2, 'Must be at least 2 characters').max(255, 'Must be at most 255 characters'),
        template_id: yup.string().required('Select Template'),
        contact_id: yup.string().required('Select Contact'),
        is_schedule: yup.boolean().nullable(),
        schedule_date: yup.string()
            .test('is-schedule-required', 'Select Date', function (value) {
                const { is_schedule } = this.parent; // Access parent (the form's values)
                if (is_schedule && !value) {
                    return false; // If `is_schedule` is true but `schedule_date` is empty, validation fails
                }
                return true; // Otherwise, the validation passes
            })
            .nullable(), // Allow it to be nullable if `is_schedule` is false
    });

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            message_title: values.message_title,
            template_id: values.template_id,
            contact_id: values.contact_id,
            is_schedule: values?.is_schedule ? true : false,
            schedule_date: values.schedule_date
                ? moment.utc(values.schedule_date).tz(preTimeZoneKey).format('YYYY-MM-DD HH:mm:ss')
                : '',
            variable_examples: defaultValue?.variable_examples ? defaultValue?.variable_examples : values.variable_examples,
            message_type: 'template',
        };
        showLoader()
        await axiosInstance.post(`/api/send-bulk-messages`, data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                navigate("/marketing");
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });

                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    const [formattedHeader, setFormattedHeader] = useState({ head: '', fileLink: '', fileName: '' });
    const [headerType, setHeaderType] = useState('text');
    const [formattedBody, setFormattedBody] = useState('');
    const [formattedFooter, setFormattedFooter] = useState();
    const [formattedButtons, setFormattedButtons] = useState([]);

    // Function to handle changes in the select dropdown for the template
    const handleTemplateChange = (e) => {
        const selectedId = e.target.value;
        setSelectedTemplateId(selectedId); // Update state which will trigger the useEffect
    };


    useEffect(() => {
        // This effect runs when selectedTemplateId changes
        if (selectedTemplateId) {
            selectTemplate(selectedTemplateId);
        }
    }, [selectedTemplateId]);


    const selectTemplate = async (id) => {

        const template = templates.find(item => item.id === id);

        let jsonData = [];

        try {
            jsonData = typeof template?.content === "string" ? JSON.parse(template?.content) : template?.content;
        } catch (error) {
            showSnackbar('Error parsing JSON data', "error");
            return null;
        }

        // Extract Header, Body, Footer, and Buttons
        const header = jsonData.find(item => item.type === "HEADER");
        const body = jsonData.find(item => item.type === "BODY");
        const footer = jsonData.find(item => item.type === "FOOTER");
        const buttons = jsonData.find(item => item.type === "BUTTONS");

        // Get formatted values
        if (header) {
            if (header.format === "IMAGE" && header.example?.header_handle?.length > 0) {
                setFormattedHeader({ head: '', fileLink: header.example.header_handle[0], fileName: '' }); // Image URL
                setHeaderType("image");
            } else if (header.format === "DOCUMENT" && header.example?.header_handle?.length > 0) {
                const uplodedFileurl = header?.example?.header_handle[0] || '';
                const expFileUrl = uplodedFileurl?.split('?')[0] || '';
                const sendFileName = expFileUrl?.slice(-20) || '';

                setFormattedHeader({ head: '', fileLink: header.example.header_handle[0], fileName: sendFileName }); // Image URL
                setHeaderType("document");
            } else if (header.format === "VIDEO" && header.example?.header_handle?.length > 0) {
                setFormattedHeader({ head: '', fileLink: header.example.header_handle[0], fileName: '' }); // Image URL
                setHeaderType("video");
            } else if (header.format === "TEXT") {
                setFormattedHeader({ head: header.text || "", fileLink: '', fileName: '' });
                setHeaderType("text");
            }
        }

        //setFormattedBody(body ? replacePlaceholders(body.text, body.example?.body_text?.[0]) : "");
        setFormattedBody(body?.text ? body?.text : "");
        setFormattedFooter(footer ? footer.text : "");
        setFormattedButtons(buttons?.buttons?.map(btn => ({ type: btn.type, text: btn.text })) || []);

        const bodyDynamicVariable = body?.example?.body_text || [];
        if (bodyDynamicVariable?.length > 0) {
            const formattedVariables = bodyDynamicVariable?.length && bodyDynamicVariable.flat().map((item, index) => ({
                id: index + 1, // or generate a unique id based on your logic
                value: item, // Assuming each element inside the array has only one value
            }));

            setVariables(formattedVariables);
        } else {
            setVariables([]);
        }
    }

    useEffect(() => {
        if (variables && variables.length > 0) {
            const initialVariables = variables.map((variable) => ({
                id: variable.id,  // or whatever properties you need
                value: "name"  // Initialize the value to an empty string or default value
            }));
            setDefaultValue(prevState => ({
                ...prevState,
                variable_examples: initialVariables
            }));
        }
    }, [variables]);

    const handleVariableChange = (index, value) => {
        const updatedVariables = [...defaultValue.variable_examples];
        updatedVariables[index].value = value;

        setDefaultValue((prevState) => ({
            ...prevState,
            variable_examples: updatedVariables,
        }));
    };

    const handleSwitchChange = (event, setFieldValue, props) => {
        setIsScheduleCampaign(event.target.checked);
        setFieldValue('is_schedule', event.target.checked)
        if (!event.target.checked) {
            setFieldValue('schedule_date', '')
        } else {
            setFieldValue('schedule_date', moment())

        }
    };

    return (
        <>

            <Helmet>
                <title>Add Campaign | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2} alignItems={"flex-start"}>
                <Grid item xxl={8} xl={7} xs={12}>
                    <Formik
                        initialValues={defaultValue}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    //enableReinitialize
                    >
                        {props => {
                            const {
                                touched,
                                errors,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched
                            } = props;
                            return (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <Stack spacing={2}>
                                            <div className="card p-16 pb-1">
                                                <div className="card-header">
                                                    <h6 className="title">Campaign Configuration</h6>
                                                </div>


                                                <TextField
                                                    label="Campaign Title"
                                                    variant="filled"
                                                    name="message_title"
                                                    value={values?.message_title || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.message_title && touched.message_title}
                                                    helperText={(errors.message_title && touched.message_title) && errors.message_title}
                                                />

                                                <TextField
                                                    select
                                                    label="Template"
                                                    value={values?.template_id || ''}
                                                    name="template_id"
                                                    variant="filled"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleTemplateChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={errors.template_id && touched.template_id}
                                                    helperText={(errors.template_id && touched.template_id) && errors.template_id}
                                                >
                                                    <MenuItem key="all-templates" value="">
                                                        Select Template
                                                    </MenuItem>
                                                    {
                                                        templates && templates.length > 0
                                                            ? templates.map((template, index) => (
                                                                <MenuItem key={index} value={template?.id}>
                                                                    {template?.template_name}
                                                                </MenuItem>
                                                            ))
                                                            : <MenuItem value={'-1'}></MenuItem>
                                                    }
                                                </TextField>

                                                <TextField
                                                    select
                                                    label="Target Contact List"
                                                    value={values?.contact_id || ''}
                                                    name="contact_id"
                                                    variant="filled"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.contact_id && touched.contact_id}
                                                    helperText={(errors.contact_id && touched.contact_id) && errors.contact_id}
                                                >
                                                    <MenuItem key="all-contacts" value="" >
                                                        Select Contact
                                                    </MenuItem>
                                                    {
                                                        contacts && contacts.length > 0
                                                            ? contacts.map((contact, index) => (
                                                                <MenuItem key={index} value={contact?.id}>
                                                                    {contact?.name}
                                                                </MenuItem>
                                                            ))
                                                            : <MenuItem key={'non-select'} value={'-1'}></MenuItem>
                                                    }
                                                </TextField>

                                                {variables?.length > 0 && (
                                                    <>
                                                        <Typography variant="body-large-bold" component={'p'} className="pb-8">Template Variables</Typography>
                                                        {variables.map((variable, index) => (
                                                            <Stack direction={"row"} spacing={2} alignItems={"center"} className="mb-24" key={index}>
                                                                <Box component={"span"} sx={{ flexShrink: 0 }}>
                                                                    {`{{${variable.id}}}`}
                                                                </Box>
                                                                <TextField
                                                                    select
                                                                    label="Value"
                                                                    name={`variable_examples[${index}].value`}  // Ensure name reflects the form field structure
                                                                    value={values?.variable_examples[index]?.value || "name"}
                                                                    variant="filled"
                                                                    className="no-margin"
                                                                    sx={{ flexGrow: 1 }}
                                                                    onChange={(e) => {
                                                                        handleChange(e);  // Sync Formik state
                                                                        handleVariableChange(index, e.target.value);  // Sync custom state
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors.variable_examples && touched.variable_examples}
                                                                    helperText={(errors.variable_examples && touched.variable_examples) && errors.variable_examples}
                                                                >
                                                                    <MenuItem value="phone">Phone</MenuItem>
                                                                    <MenuItem value="name">Name</MenuItem>
                                                                </TextField>
                                                            </Stack>
                                                        ))}
                                                    </>
                                                )}
                                            </div>

                                            <div className="card p-16 pb-1">
                                                <div className="card-header">
                                                    <h6 className="title">Schedule</h6>
                                                </div>

                                                <div className="mb-24">
                                                    <FormControlLabel control={
                                                        <Switch
                                                            checked={isScheduleCampaign}
                                                            onChange={(event) => handleSwitchChange(event, setFieldValue, props)}
                                                        />} label="Schedule Campaign" />
                                                </div>
                                                {
                                                    isScheduleCampaign &&
                                                    <>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DateTimePicker
                                                                label="Schedule Date & Time"
                                                                format={preferredDateFormat}
                                                                views={["day", "hours", "minutes", "month", "year"]}
                                                                name="schedule_date"
                                                                slots={{
                                                                    popper: PickerWrapper
                                                                }}
                                                                slotProps={{
                                                                    textField: {
                                                                        variant: 'filled',
                                                                        name: "schedule_date",
                                                                        type: 'text',
                                                                        error: errors.schedule_date && touched.schedule_date,
                                                                        helperText: (errors.schedule_date && touched.schedule_date) && errors.schedule_date
                                                                    }
                                                                }}
                                                                value={values?.schedule_date ? moment.utc(values.schedule_date).tz(preTimeZoneKey) : moment()}
                                                                onChange={(value) => setFieldValue("schedule_date", value, true)}
                                                                onBlur={handleBlur}
                                                                minDate={moment()} // Disable past dates
                                                                minTime={moment().startOf('minute')} // Disable past times
                                                            />
                                                        </LocalizationProvider>

                                                        <Stack direction={'row'} spacing={0.75} alignItems={"flex-start"} className="pb-16 text-secondary">
                                                            <Icon path={mdiInformation} color={'currentColor'} size={0.8333334} className="flex-shrink-0" />
                                                            <span>
                                                                <Typography variant="label-large">The campaign will be scheduled in your profile timezone: </Typography>
                                                                {/* <Typography variant="label-large-bold">(GMT+5:30) Asia/Calcutta (India Standard Time).</Typography> */}
                                                                <Typography variant="label-large-bold">{serverTimeArray?.preTimeZone}</Typography>

                                                            </span>
                                                        </Stack>
                                                    </>
                                                }

                                            </div>
                                            <Box
                                                sx={{
                                                    display: { xs: 'block', xl: 'none' }
                                                }}
                                                className="card p-16">
                                                <div className="card-header">
                                                    <h6 className="title">Template Preview</h6>
                                                </div>

                                                <div className="message-preview">
                                                    <MessageChatItem
                                                        head={formattedHeader}
                                                        headType={headerType}
                                                        body={formattedBody}
                                                        footer={formattedFooter}
                                                        buttons={formattedButtons}
                                                        time={timeOnly}
                                                    />
                                                </div>
                                            </Box>
                                            <div className="card p-16 sticky-bottom">
                                                <Stack direction={'row'} spacing={1}>
                                                    {/* <Button>Save Draft</Button> */}
                                                    <Button className="ml-auto" type="submit">{isScheduleCampaign ? 'Schedule' : 'Run'} Campaign</Button>
                                                    <Button color="secondary" onClick={e => navigate(-1)}>Cancel</Button>
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </form >
                                </>
                            );
                        }}
                    </Formik>
                </Grid>


                <Grid item xxl={4} xl={5} className="sticky-top" sx={{
                    display: { xs: 'none', xl: 'block' }
                }}>
                    <div className="card p-16">
                        <div className="card-header">
                            <h6 className="title">Template Preview</h6>
                        </div>

                        <div className="message-preview">
                            <MessageChatItem
                                head={formattedHeader}
                                headType={headerType}
                                body={formattedBody}
                                footer={formattedFooter}
                                buttons={formattedButtons}
                                time={timeOnly}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid >
        </>
    );
}

export default AddCampaign;