import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import MarketingTab from "./components/MarketingTab";
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import { mdiAlertCircle, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Icon from "@mdi/react";
import TemplatePreviewDialog from "./components/TemplatePreviewDialog";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import TemplateErrorPreviewDialog from "./components/TemplateErrorPreviewDialog";

function MarketingTemplates(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [templates, setTemplates] = useState();
    const [errorData, setErrorData] = useState();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [templateId, setTemplateId] = useState('');
    const [contentData, setContentData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );

    const [sortBy, setSortBy] = useState("created_at");

    // State management for search fields
    const [search, setSearch] = useState("");

    //handle checkbox
    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        reloadPage,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ) => {
        const params = {
            search: search,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/whatsapp-templates?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setTemplates(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    const [templatePreviewDialogOpen, setTemplatePreviewDialogOpen] = React.useState(false);
    const [templateErrorPreviewDialogOpen, setTemplateErrorPreviewDialogOpen] = React.useState(false);

    const openTemplatePreviewDialog = (id, contentData) => {
        setTemplateId(id)
        setContentData(contentData)
        setTemplatePreviewDialogOpen(true);
    };

    const closeTemplatePreviewDialog = () => {
        setTemplateId()
        setContentData()
        setTemplatePreviewDialogOpen(false);
    };

    const openTemplateErrorPreviewDialog = (contentData) => {
        setErrorData(contentData)
        setTemplateErrorPreviewDialogOpen(true);
    };

    const closeTemplateErrorPreviewDialog = () => {
        setErrorData()
        setTemplateErrorPreviewDialogOpen(false);
    };

    const statusClasses = {
        'APPROVED': 'success',
        'REJECTED': 'danger',
        'APPROVAL PENDING': 'warning',
        'PENDING': 'warning',
        'DRAFT': 'info',
    };

    const formatTemplateName = (name) => {
        return name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Handle single delete record
    async function deleteTemplate(id) {
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        const data = {
                            id: id
                        }
                        await axiosInstance.delete("api/whatsapp-template/", { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    return (
        <>
            <Helmet>
                <title>Templates | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <MarketingTab />

            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Templates</h6>
                    <Button size="small" className="action" onClick={e => navigate('/marketing/add-template')}>
                        <span>
                            <Icon path={mdiPlus} color={'currentColor'} size={1} />
                            <span>Add Template</span>
                        </span>
                    </Button>
                </div>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="long-text">Template Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell className="long-text">Created Date</TableCell>
                                <TableCell width={10}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates && templates.length > 0 && templates.map((template, index) => {
                                const statusClass = statusClasses[template.status] || 'danger';
                                const formattedName = formatTemplateName(template.template_name);
                                return (
                                    <TableRow key={index}>
                                        <TableCell className={template?.status == 'PENDING' ? '' : 'row-click'}>
                                            {template.status == 'PENDING' ?
                                                <span>{formattedName}</span>
                                                :
                                                <Tooltip title={template.status == 'PENDING' ? 'Sorry, you cannot update the template at this moment as its current status is In Review by Meta. Once the review process is completed, you will be able to edit the template."' : ''}
                                                    onClick={e => { navigate('/marketing/edit-template/' + template?.id); }}>
                                                    <span>{formattedName}</span>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction={'row'} alignItems={'center'} columnGap={1}>
                                                <span className={`status-badge ${statusClass}`}>
                                                    {template?.status}
                                                </span>
                                                {template?.status == 'REJECTED' &&
                                                    <Tooltip title="View Reason for Rejection">
                                                        <button type="button" className="icon-button size-small text-danger ml-n8" onClick={() => openTemplateErrorPreviewDialog(template?.error_response)}>
                                                            <Icon path={mdiAlertCircle} color={"currentColor"} className="text-danger" size={0.75} />
                                                        </button>
                                                    </Tooltip>
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell>{template?.category}</TableCell>
                                        <TableCell>{template?.language}</TableCell>
                                        <TableCell> {template?.created_at
                                            ? moment(template.created_at)
                                                .tz(preTimeZoneKey)
                                                .format(preferredDateFormat)
                                            : "N/A"}</TableCell>
                                        <TableCell className="pr-0">
                                            <Stack direction="row">
                                                {
                                                    template.status != 'PENDING' &&
                                                    <Tooltip title="Edit">
                                                        <button type="button" className='icon-button' onClick={e => { navigate('/marketing/edit-template/' + template?.id); }}>
                                                            <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                        </button>
                                                    </Tooltip>
                                                }
                                                <Tooltip title="Preview">
                                                    <button type="button" className='icon-button' onClick={() => openTemplatePreviewDialog(template?.id, template?.content)}>
                                                        <Icon path={mdiEyeOutline} color="currentColor" size={1} />
                                                    </button>
                                                </Tooltip>
                                                {
                                                    template.status != 'PENDING' &&
                                                    <Tooltip title="Delete">
                                                        <button type="button" className='icon-button' onClick={() => deleteTemplate(template?.id)}>
                                                            <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                        </button>
                                                    </Tooltip>
                                                }

                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {
                    templates && templates.length > 0 &&
                    <div className="pt-16">
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPage}
                            rowsPerPage={perPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </div>
                }
            </div >
            {
                templateId &&
                <TemplatePreviewDialog onClose={closeTemplatePreviewDialog} open={templatePreviewDialogOpen} id={templateId} data={contentData} />
            }

            {
                errorData &&
                <TemplateErrorPreviewDialog onClose={closeTemplateErrorPreviewDialog} open={templateErrorPreviewDialogOpen} data={errorData} />
            }
        </>
    );
}

export default MarketingTemplates;