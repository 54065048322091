import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { mdiAccountMultipleOutline, mdiCalendarCheckOutline, mdiCashMultiple, mdiDiamondStone, mdiHandCoinOutline, mdiRocketOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { AudioCameraIcon, BriefcaseDollarIcon, ContactIcon, EmailShieldIcon, EmailUserIcon, FileUploadIcon, MapLocationIcon, PaperEditIcon, WebMobileIcon, WhatsAppIcon } from "../../../components/common/Icons";

import { capitalize, displayDate, getMainDomain } from "../../../utils/Utils";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import SettingsTab from "../components/SettingsTab";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import UpgradePlanFlow from "./UpgradePlan";
import ManagePlanFlow from "./ManagePlan"

function Subscriptions(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [preferredCurrencyData, setPreferredCurrencyData] = useState({});
    const hostname = getMainDomain()
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [userCountDetail, setUserCountDetail] = useState([]);


    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, []);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/subscription').then((response) => {
            if (response.status === 200) {
                setSubscriptionData(response?.data?.data?.subscriptionData);
                setUserCountDetail(response?.data?.data?.userCountDetail);
                hideLoader();
                if (hostname === '.solvcrm.co') {
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                } else {
                    //setPreferredCurrencyData({ 'currencyCode': 'INR', 'currencySymbol': '₹' });
                    setPreferredCurrencyData({ 'currencyCode': 'USD', 'currencySymbol': '$' });
                }
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/settings/subscription");
                    } else if (error.response.status === 300) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    }


    const [currentPlan, setCurrentPlan] = React.useState('');
    const [showPlanStep, setShowPlanStep] = React.useState(false);
    const [showManageStep, setShowManageStep] = React.useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [showPlanStep]);


    return (<>
        <Helmet>
            <title>Subscription | {capitalize(props?.name || 'SolvCRM')}</title>
        </Helmet>
        {
            !showPlanStep && !showManageStep &&
            <SettingsTab />
        }

        {
            !showPlanStep && !showManageStep && subscriptionData?.packageSlug == 'free' &&
            <Grid container spacing={2}>

                <Grid item xxl={8} xl={7.5} xs={12}>
                    <div className="card mb-16">
                        <div className="p-20">
                            <Typography variant="headline-small-bold" component={'h4'} sx={{ marginBottom: 0.5 }}>{subscriptionData?.packageName || 'Free'} Plan</Typography>
                            <Typography variant="body-medium" className="text-secondary" component={'p'}>You're using the Free Plan, designed to give you essential access to SolvCRM's core features.</Typography>
                        </div>
                        <hr />
                        <div className="p-20">
                            <Grid container spacing={2}>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiAccountMultipleOutline} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Users</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>{userCountDetail?.planUserCount || 1} User</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Plan Validity</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>Lifetime</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiHandCoinOutline} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Per User Pay</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>$0.00/mon</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiCashMultiple} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Monthly Billing</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>$0.00/mon</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'p'} className="mb-16">Plan Benefits</Typography>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <ul className="feature-list g-16 stroked no-wrap">
                                    <li>
                                        <div className="icon-wrapper">
                                            <ContactIcon />
                                        </div>
                                        <p>Unlimited Contacts</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <BriefcaseDollarIcon />
                                        </div>
                                        <p>Unlimited Deals</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <AudioCameraIcon />
                                        </div>
                                        <p>Audio notes, Image capture</p>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item md={6}>
                                <ul className="feature-list g-16 stroked no-wrap">
                                    <li>
                                        <div className="icon-wrapper">
                                            <FileUploadIcon />
                                        </div>
                                        <p>Unlimited Leads</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <WebMobileIcon />
                                        </div>
                                        <p>Web and Mobile App</p>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xxl={4} xl={4.5} lg={6} xs={12}>
                    <div className="card upgrade-card">
                        <div className="icon-wrapper">
                            <Icon path={mdiRocketOutline} color={'currentColor'} size={1.666667}></Icon>
                        </div>
                        <Typography variant="title-medium-large-semibold" component={'h6'}>Need More Features?</Typography>
                        <p className="text-secondary">Looking for More? Unlock additional features, increase your team size, and access advanced tools to support your growing business.</p>
                        <Button className="action-btn" onClick={e => { setShowPlanStep(true); setCurrentPlan('basic'); }}>Upgrade Your Plan</Button>
                    </div>
                </Grid>
            </Grid>
        }


        {
            !showPlanStep && !showManageStep && subscriptionData?.packageSlug == 'basic' &&
            <Grid container spacing={2} className="mb-16">
                <Grid item xxl={8} xl={7.5} xs={12}>
                    <div className="card mb-16">
                        <div className="p-20">
                            <Grid container spacing={4}>
                                <Grid item xs>
                                    <Typography variant="headline-small-bold" component={'h4'} sx={{ marginBottom: 0.5 }}>{subscriptionData?.packageName || 'Basic'} Plan</Typography>
                                    <Typography variant="body-medium" className="text-secondary" component={'p'}>You’re currently on the Basic plan. Adjust your team size and manage your users as needed to ensure you’re getting the most out of your plan.</Typography>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Button size="small" onClick={(e) => {
                                        setShowManageStep(true);
                                        setCurrentPlan('basic');
                                    }}>
                                        <span>
                                            <Icon path={mdiAccountMultipleOutline} color={'currentColor'} size={1} />
                                            <span>Manage Billable Users</span>
                                        </span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <hr />
                        <div className="p-20">
                            <Grid container spacing={2}>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiAccountMultipleOutline} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Users Billable</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>{userCountDetail?.planUserCount || 1} Users</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Next Payment Date</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>{subscriptionData?.planNextPaymentDate
                                                ? displayDate(moment.utc(subscriptionData?.planNextPaymentDate).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                                : "N/A"}</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiHandCoinOutline} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Per User Pay</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>{subscriptionData?.perUserPrice ? preferredCurrencyData.currencySymbol + (Math.round(subscriptionData.perUserPrice * 100) / 100).toFixed(2) : '0.00'}/mon</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xxl={3} xl={6} md={3}>
                                    <Stack spacing={1.25} direction={"row"}>
                                        <Icon path={mdiCashMultiple} color={'currentColor'} size={1} className="text-brand flex-shrink-0" />
                                        <div>
                                            <Typography variant="body-small" className="text-secondary" component={'p'}>Monthly Billing</Typography>
                                            <Typography variant="body-large-semibold" component={'p'}>{subscriptionData?.monthlyAmount ? preferredCurrencyData.currencySymbol + (Math.round(subscriptionData.monthlyAmount * 100) / 100).toFixed(2) : '0.00'}/mon</Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'p'} className="mb-16">Plan Benefits</Typography>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <ul className="feature-list g-16 stroked no-wrap">
                                    <li>
                                        <div className="icon-wrapper">
                                            <ContactIcon />
                                        </div>
                                        <p>Unlimited Contacts</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <BriefcaseDollarIcon />
                                        </div>
                                        <p>Unlimited Deals</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <AudioCameraIcon />
                                        </div>
                                        <p>Audio notes, Image capture</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <WhatsAppIcon />
                                        </div>
                                        <p>One-on-One Whatsapp Messages</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <EmailUserIcon />
                                        </div>
                                        <p>One-on-One email messages</p>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item md={6}>
                                <ul className="feature-list g-16 stroked no-wrap">
                                    <li>
                                        <div className="icon-wrapper">
                                            <FileUploadIcon />
                                        </div>
                                        <p>Unlimited Leads</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <WebMobileIcon />
                                        </div>
                                        <p>Web and Mobile App</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <MapLocationIcon />
                                        </div>
                                        <p>Geo tracking</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <PaperEditIcon />
                                        </div>
                                        <p>Create and Send Quotes</p>
                                    </li>
                                    <li>
                                        <div className="icon-wrapper">
                                            <EmailShieldIcon />
                                        </div>
                                        <p>Emails through SolvCRM.co domain</p>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xxl={4} xl={4.5} lg={6} xs={12}>
                    <div className="card upgrade-card">
                        <div className="icon-wrapper">
                            <Icon path={mdiDiamondStone} color={'currentColor'} size={1.666667}></Icon>
                        </div>
                        <Typography variant="title-medium-large-semibold" component={'h6'}>Unlock More Possibilities</Typography>
                        <p className="text-secondary">Ready to Take It to the Next Level? Unlock exclusive features, advanced customizations, and premium support to power your business growth. Upgrade now for even more possibilities.</p>
                        <Button className="action-btn" onClick={e => { setShowPlanStep(true); setCurrentPlan('premium'); }}>Upgrade to Premium</Button>
                    </div>
                </Grid>
            </Grid>
        }


        {
            !showPlanStep && !showManageStep && subscriptionData?.packageSlug == 'basic' &&
            <Grid container spacing={2} className="mb-16">
                <Grid item xxl={8} xl={7.5} xs={12}>
                    If you no longer need the additional features and users, you can downgrade to the Free plan at any time. Keep in mind that downgrading will remove extra users and certain features. <a href="-"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowPlanStep(true);
                            setCurrentPlan('free');
                        }} className="primary-link underlined">Downgrade Now</a>
                </Grid>
            </Grid>
        }

        {showPlanStep && <UpgradePlanFlow plan={currentPlan} onReset={(e) => {
            setShowPlanStep(false);
        }} />}

        {showManageStep && <ManagePlanFlow plan={currentPlan} planId={3} onReset={(e) => {
            setShowPlanStep(false);
            setShowManageStep(false);
            setCurrentPlan('basic');

        }} />}

    </>);
}

export default Subscriptions;