import React, { useContext } from "react";
import { ThemeContext } from "../../App";

function AvatarInitials({ name, invert }) {
    const { currentTheme } = useContext(ThemeContext);

    if (!name) {
        return (
            <div className="avatar-initial" style={{
                backgroundColor: '#FFEBEE',
                color: '#B71C1C'
            }}>
                {'JD'}
            </div>
        )
    }

    let letters = name.split(' ');

    if (letters.length === 1) {
        // If there is only one word, use the first letter of the first name twice
        letters = [letters[0][0], letters[0][1] || letters[0][0]];
    } else {
        // Otherwise, use the first letter of the first and second words
        letters = [letters[0][0], letters[1] ? letters[1][0] : letters[0][1]];
    }

    let totalAsciiValue = letters[0].charCodeAt(0) + letters[1].charCodeAt(0);

    const colors = [
        {
            backgroundColor: "#FFEBEE",
            textColor: "#B71C1C"
        }, {
            backgroundColor: "#FCE4EC",
            textColor: "#880E4F"
        }, {
            backgroundColor: "#F3E5F5",
            textColor: "#4A148C"
        }, {
            backgroundColor: "#EDE7F6",
            textColor: "#311B92"
        }, {
            backgroundColor: "#E8EAF6",
            textColor: "#1A237E"
        }, {
            backgroundColor: "#E3F2FD",
            textColor: "#0D47A1"
        }, {
            backgroundColor: "#E1F5FE",
            textColor: "#01579B"
        }, {
            backgroundColor: "#E0F7FA",
            textColor: "#006064"
        }, {
            backgroundColor: "#E0F2F1",
            textColor: "#004D40"
        }, {
            backgroundColor: "#E8F5E9",
            textColor: "#1B5E20"
        }, {
            backgroundColor: "#F1F8E9",
            textColor: "#33691E"
        }, {
            backgroundColor: "#F9FBE7",
            textColor: "#827717"
        }, {
            backgroundColor: "#FFFDE7",
            textColor: "#F57F17"
        }, {
            backgroundColor: "#FFF8E1",
            textColor: "#FF6F00"
        }, {
            backgroundColor: "#FFF3E0",
            textColor: "#E65100"
        }, {
            backgroundColor: "#FBE9E7",
            textColor: "#BF360C"
        }, {
            backgroundColor: "#EFEBE9",
            textColor: "#3E2723"
        }, {
            backgroundColor: "#ECEFF1",
            textColor: "#263238"
        }
    ];

    let colorIndex = totalAsciiValue % colors.length;

    return (
        <>
            <div className="avatar-initial" style={{
                backgroundColor: (currentTheme?.name !== 'xl-financial' && !invert) ? colors[colorIndex].backgroundColor : colors[colorIndex].textColor,
                color: (currentTheme?.name !== 'xl-financial' && !invert) ? colors[colorIndex].textColor : colors[colorIndex].backgroundColor
            }}>
                {letters.join('')}
            </div>
        </>
    );
}

export default AvatarInitials;